import { Title } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { AvatarIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { QuestionAssignation } from 'types/question-assignation';

import { ToAnswerDateIndicator } from '../shared';

export default function QuestionCard({
  question
}: {
  question: QuestionAssignation['question'];
}) {
  const { t } = useTranslation('lawyer');
  const formattedCreatedDateLabel: string = useMemo(() => {
    const day = formatDateToIntl(question.createdDate);
    const time = getDateAsHhMm(question.createdDate);
    return `${day} à ${time}`;
  }, [question.createdDate]);

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow">
      <div className="pb-7 border-b border-gray-500">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-400">
              <AvatarIcon />
            </span>
            <Title variant="h3">{t('general.ekieClient')}</Title>
          </div>
          <span className="text-gray-700 text-sm">
            {formattedCreatedDateLabel}
          </span>
        </div>
      </div>
      <div className="py-6 border-b border-gray-500">
        <SanitizedContent content={question.anonymizedContent} />
      </div>
      <div className="pt-6">
        <ToAnswerDateIndicator answerDeadLine={question.answerDeadLine} />
      </div>
    </div>
  );
}
