import { Variant } from 'types/icon';

export default function DashboardIcon({ variant }: { variant: Variant }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className="w-6 h-6"
    >
      {variant === Variant.Active ? (
        <path
          d="M18 5H6C4.875 5 4 5.90625 4 7V17C4 18.125 4.875 19 6 19H18C19.0938 19 20 18.125 20 17V7C20 5.90625 19.0938 5 18 5ZM11 17H6V9H11V17ZM18 17H13V9H18V17Z"
          fill="#AA97FF"
        />
      ) : (
        <path
          d="M18 5H6C4.875 5 4 5.90625 4 7V17C4 18.125 4.875 19 6 19H18C19.0938 19 20 18.125 20 17V7C20 5.90625 19.0938 5 18 5ZM11.5 18H6C5.4375 18 5 17.5625 5 17V10H11.5V18ZM19 17C19 17.5625 18.5312 18 18 18H12.5V10H19V17ZM19 9H5V7C5 6.46875 5.4375 6 6 6H18C18.5312 6 19 6.46875 19 7V9Z"
          fill="#1D1D1D"
        />
      )}
    </svg>
  );
}
