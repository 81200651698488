import clsx from 'clsx';
import { ReactNode } from 'react';

import { LoadingSpinner } from 'features/shared/loading';

export interface ButtonProps {
  submit?: boolean;
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'transparent'
    | 'warning'
    | 'outline'
    | 'custom';
  size: 'small' | 'medium' | 'large';
  label?: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  uppercase?: boolean;
  icon?: JSX.Element | null;
  onClick?: () => void;
  isLoading?: boolean;
  customClassNames?: string;
  children?: ReactNode;
  className?: string;
}

function Button({
  submit = false,
  variant,
  size,
  disabled = false,
  label,
  fullWidth,
  icon,
  uppercase = false,
  onClick,
  isLoading = false,
  customClassNames = '',
  className = '',
  children
}: ButtonProps) {
  const btnVariants = {
    primary: 'bg-black hover:bg-gray-800 text-white',
    secondary:
      'bg-transparent text-black border border-gray-700 hover:bg-black hover:text-white',
    tertiary: 'bg-purple-900 hover:bg-purple-800 text-white font-medium',
    outline:
      'bg-white border text-purple-900 border-purple-900 hover:bg-purple-900 hover:text-white font-medium',
    transparent: 'bg-transparent text-black hover:text-gray-800',
    warning: 'bg-red-800 text-white',
    custom: customClassNames
  };

  const btnSizes = {
    small: 'px-3 py-2 text-sm',
    medium: 'px-3 py-2 text-sm sm:px-4 sm:py-2 sm:text-base',
    large: 'px-4 py-2 text-base sm:px-6 sm:py-3'
  };

  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={clsx(btnVariants[variant], btnSizes[size], {
        'flex items-center rounded font-medium px-4 py-2 focus:outline-none':
          true,
        'gap-2': icon && (label || children),
        uppercase,
        'w-full justify-center': Boolean(fullWidth),
        'opacity-60 cursor-not-allowed': disabled,
        [className]: true
      })}
    >
      {icon && !isLoading ? icon : null}
      {isLoading ? <LoadingSpinner /> : null}
      {children || <span>{label}</span>}
    </button>
  );
}

export default Button;
