import { useMutation, useQuery } from 'react-query';

import { getResource, voteResource } from './api';

export function useCreateResourceVoteMutation() {
  return useMutation(
    ({
      resourceType,
      resourceId,
      vote
    }: {
      resourceType: string;
      resourceId: string;
      vote: boolean;
    }) => voteResource(resourceType, resourceId, vote)
  );
}
export function useGetResourceVoteQuery(resourceId: string) {
  return useQuery(['resource-votes', resourceId], () =>
    getResource(resourceId)
  );
}
