import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { RateAnswerInput } from 'features/rating/RateAnswer';

interface VoteButtonProps {
  vote: RateAnswerInput['note'];
  handleClick: (vote: RateAnswerInput['note']) => void;
}

interface VoteEmojiButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

function ButtonWrapper({
  children,
  ...props
}: PropsWithChildren<VoteEmojiButtonProps>) {
  return (
    <button
      type="button"
      className="inline-flex justify-center items-center p-1.5 sm:px-4 sm:py-2 border border-transparent shadow-sm rounded text-black bg-gray-400 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-800"
      {...props}
    >
      <div className="flex flex-col items-center gap-1">{children}</div>
    </button>
  );
}

export default function VoteButton({ vote, handleClick }: VoteButtonProps) {
  const { t } = useTranslation('customer');
  switch (vote) {
    case 1:
      return (
        <ButtonWrapper onClick={() => handleClick(vote)}>
          <span>1</span>
          <span className="hidden md:block text-sm">
            {t('account.question.answer.vote.hasNotAnswered')}
          </span>
        </ButtonWrapper>
      );
    case 2:
      return (
        <ButtonWrapper onClick={() => handleClick(vote)}>
          <span>2</span>
          <span />
        </ButtonWrapper>
      );
    case 3:
      return (
        <ButtonWrapper onClick={() => handleClick(vote)}>
          <span>3</span>
          <span />
        </ButtonWrapper>
      );
    case 4:
      return (
        <ButtonWrapper onClick={() => handleClick(vote)}>
          <span>4</span>
          <span />
        </ButtonWrapper>
      );
    case 5:
      return (
        <ButtonWrapper onClick={() => handleClick(vote)}>
          <span>5</span>
          <span className="hidden md:block text-sm">
            {t('account.question.answer.vote.hasAnswered')}
          </span>
        </ButtonWrapper>
      );
    default:
      throw Error('Vote not handled');
  }
}
