import { ArticleListSkeleton } from 'components/skeleton';
import { RatingStars } from 'components/statistic';
import { useGetRandomUserReview } from 'services/user-review/user-review';

import { useLocale } from 'hooks';

const RandomUserReview = () => {
  const { data, isLoading } = useGetRandomUserReview();
  const { locale } = useLocale();

  if (isLoading) return <ArticleListSkeleton />;
  if (!data?.userReview) return <div />;

  return (
    <div className="mb-10 flex justify-center">
      <div key={data.userReview.id} className="flex flex-col gap-5">
        <div className="prose text-center font-bold text-gray-700 italic">
          {`" ${data.userReview.content[locale]} "`}
        </div>
        <div>
          <RatingStars
            className="justify-center"
            value={data.userReview.note}
          />
          <div className="flex justify-center mt-1 font-medium text-sm">
            {data.userReview.fullName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomUserReview;
