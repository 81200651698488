import { SVGProps } from 'react';

export default function FileToUploadIcon({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.3906 9.8594L18.6406 1.1094C18.4025 0.877242 18.0826 0.748145 17.75 0.75002H2.75C2.08696 0.75002 1.45107 1.01341 0.982233 1.48225C0.513392 1.95109 0.25 2.58698 0.25 3.25002V30.75C0.25 31.4131 0.513392 32.0489 0.982233 32.5178C1.45107 32.9866 2.08696 33.25 2.75 33.25H25.25C25.913 33.25 26.5489 32.9866 27.0178 32.5178C27.4866 32.0489 27.75 31.4131 27.75 30.75V10.75C27.7519 10.4175 27.6228 10.0975 27.3906 9.8594ZM17.75 22H15.25V24.5C15.25 24.8315 15.1183 25.1495 14.8839 25.3839C14.6495 25.6183 14.3315 25.75 14 25.75C13.6685 25.75 13.3505 25.6183 13.1161 25.3839C12.8817 25.1495 12.75 24.8315 12.75 24.5V22H10.25C9.91848 22 9.60054 21.8683 9.36612 21.6339C9.1317 21.3995 9 21.0815 9 20.75C9 20.4185 9.1317 20.1006 9.36612 19.8661C9.60054 19.6317 9.91848 19.5 10.25 19.5H12.75V17C12.75 16.6685 12.8817 16.3506 13.1161 16.1161C13.3505 15.8817 13.6685 15.75 14 15.75C14.3315 15.75 14.6495 15.8817 14.8839 16.1161C15.1183 16.3506 15.25 16.6685 15.25 17V19.5H17.75C18.0815 19.5 18.3995 19.6317 18.6339 19.8661C18.8683 20.1006 19 20.4185 19 20.75C19 21.0815 18.8683 21.3995 18.6339 21.6339C18.3995 21.8683 18.0815 22 17.75 22ZM17.75 10.75V3.76565L24.7344 10.75H17.75Z"
        fill="#D8C9F9"
      />
    </svg>
  );
}
