import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  FileRemoveIcon,
  FileToUploadIcon,
  FileUploadedIcon
} from 'features/shared/icons';
import { LoadingSpinner } from 'features/shared/loading';
import { Toast } from 'features/shared/toasts';

import { BYTE_IN_MEGABYTES } from 'utils/consultation';

import { ConsultationFile } from 'types/consultation';
import { SetState } from 'types/core';

interface DropzoneFile extends Blob {
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export default function DocumentDropzone({
  document,
  setDocument,
  maxFile = false,
  typeLabel,
  required,
  label
}: {
  maxFile?: boolean;
  required: boolean;
  label: string;
  typeLabel: string;
  document?: ConsultationFile;
  setDocument: SetState<ConsultationFile | undefined>;
}) {
  const [isDragEntered, setIsDragEntered] = useState<boolean>();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((f: DropzoneFile) => {
        const reader = new FileReader();
        reader.onerror = (error: any) => {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('error.occurred')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        };
        reader.onloadstart = () => {
          setIsFileLoading(true);
        };
        reader.onloadend = () => {
          setIsFileLoading(false);
          const binaryStr = reader.result;
          setDocument({
            file: f,
            name: f.path,
            size: f.size,
            content: binaryStr as string
          });
          setIsFileUploaded(true);
        };
        reader.readAsArrayBuffer(f);
      });
      setIsDragEntered(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDocument]
  );

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      rejectedFiles.forEach((f: DropzoneFile) => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('file.upload.error.1')}
          />,
          { position: 'bottom-left', duration: 3000 }
        );
      });
    },
    [t]
  );

  const onDragEnter = () => setIsDragEntered(true);

  const onDragLeave = () => setIsDragEntered(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected,
    maxFiles: 1,
    maxSize: 5 / BYTE_IN_MEGABYTES,
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'application/xlsx': ['.xlsx'],
      'image/png': ['.png']
    }
  });

  const handleDeleteDocument = () => {
    setIsFileUploaded(false);
    setDocument(undefined);
  };

  return (
    <div>
      <label htmlFor="location" className="input-label">
        {tForm(label as any)}
        {!required ? ` (${t('general.optional')})` : ''}
      </label>
      <div className="mt-2">
        {isFileLoading && <LoadingSpinner purple />}
        {!isFileUploaded && !isFileLoading ? (
          <div
            className={clsx(
              'border border-dashed p-4 rounded',
              isDragEntered
                ? 'border-purple-900 bg-purple-900 bg-opacity-10'
                : 'border-gray-500'
            )}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="flex flex-row items-center gap-x-3">
              <div className="flex justify-center items-center">
                <FileToUploadIcon />
              </div>
              <div>
                <span className="block">
                  {t('file.upload.1')}{' '}
                  <span className="cursor-pointer text-purple-900 font-semibold">
                    {t('file.upload.2')}
                  </span>
                </span>
                <span className="block text-gray-600 text-sm mt-2">
                  {t(typeLabel as any)}
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {isFileUploaded && !isFileLoading && document ? (
          <div className="p-2">
            <div className="flex justify-between items-center">
              <div className="flex justify-start items-start gap-4">
                <div className="fill-purple-700 h-5 w-5">
                  <FileUploadedIcon />
                </div>
                <div>
                  <span className="block">{document.name}</span>
                  <span className="text-gray-700 text-sm">
                    {(document.size * BYTE_IN_MEGABYTES).toFixed(2)} Mo
                  </span>
                </div>
              </div>
              <button type="button" onClick={() => handleDeleteDocument()}>
                <FileRemoveIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        ) : null}
        {maxFile && (
          <div className="mt-2">
            <p className="text-sm">{t('file.upload.maxPageIndication')}</p>
          </div>
        )}
      </div>
    </div>
  );
}
