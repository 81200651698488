import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { useQueryErrorResetBoundary } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';

import Card from './Card';

export interface FeatureErrorCardProps {
  title: string;
}

const FeatureErrorCard = ({ title }: FeatureErrorCardProps) => {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const { reset } = useQueryErrorResetBoundary();

  const onClick = () => {
    reset();
    return navigate(0);
  };

  return (
    <Card>
      <div className="flex flex-col gap-4">
        <Title
          gutterBottom
          variant="h3"
          className="text-md text-center font-medium"
        >
          {title}
        </Title>
        <div className="flex items-center justify-between gap-2">
          <Button
            variant="primary"
            size="medium"
            label={t('general.refresh')}
            fullWidth
            onClick={onClick}
          />
          <a className="w-full" href="mailto:service-client@ekie.co">
            <Button
              variant="secondary"
              size="medium"
              label={t('general.contactus')}
              fullWidth
            />
          </a>
        </div>
      </div>
    </Card>
  );
};

export default FeatureErrorCard;
