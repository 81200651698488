/* eslint-disable prettier/prettier */
import { Switch } from '@headlessui/react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useCancelAvailabilitySlotMutation } from 'api/lawyer/mutations.index';

import { useAuth } from 'context';

import { Button } from 'features/shared/buttons';
import { CameraIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

import { formatDateToIntl } from 'utils/date';

import { SetState } from 'types/core';
import { BookingOption } from 'types/lawyer';

interface ModalModifyAvailabilityProps {
  startStr: Date;
  endStr: Date;
  option: BookingOption;
  isModalModifyAvailabilityOpen: boolean;
  createAvailabilitySlotMutate: any;
  setIsModalModifyAvailabilityOpen: SetState<boolean>;
  setSelectedOption: SetState<BookingOption | undefined>;
}

export default function ModalModifyAvailability({
  startStr,
  endStr,
  option,
  isModalModifyAvailabilityOpen,
  createAvailabilitySlotMutate,
  setIsModalModifyAvailabilityOpen,
  setSelectedOption
}: ModalModifyAvailabilityProps) {
  const { t } = useTranslation('lawyer');
  const [availabilityOption, setAvailabilityOption] =
    useState<BookingOption>(option);

  const [isModifyLoading, setIsModifyLoading] = useState<boolean>(false);
  const { lawyerId } = useAuth();

  const {
    mutate: cancelAvailabilitySlotMutate,
    isLoading: cancelAvailabilitySlotIsLoading
  } = useCancelAvailabilitySlotMutation();

  const handleModalModifyAvailability = () => {
    setIsModalModifyAvailabilityOpen(false);
    setSelectedOption(undefined);
  };

  const handleConfirmCancel = () => {
    cancelAvailabilitySlotMutate(
      {
        lawyerId: lawyerId as number,
        startDate: startStr,
        endDate: endStr as any
      },
      {
        onSettled: () => {
          setIsModalModifyAvailabilityOpen(false);
        }
      }
    );
  };

  const onHandleError = () => {
    setIsModifyLoading(false);
    handleModalModifyAvailability();
    toast.custom(
      <Toast
        type="error"
        title={t('general.error')}
        message={t('general.error.occurred')}
      />,
      { position: 'top-right', duration: 3000 }
    );
  };

  const onHandleModify = () => {
    setIsModifyLoading(true);
    cancelAvailabilitySlotMutate(
      {
        lawyerId: lawyerId as number,
        startDate: startStr,
        endDate: endStr as any
      },
      {
        onSuccess: async () => {
          await createAvailabilitySlotMutate(
            {
              lawyerId: lawyerId as number,
              startDate: startStr,
              endDate: endStr,
              appointmentOptions:
                availabilityOption === BookingOption.BookingVideo
                  ? ['phone', 'video']
                  : ['phone']
            },
            {
              onSuccess: () => {
                setIsModifyLoading(false);
                handleModalModifyAvailability();
              },
              onError: () => {
                onHandleError();
              }
            }
          );
        },
        onError: () => {
          onHandleError();
        }
      }
    );
  };

  const formattedAvailabilityStartTime = useMemo(
    // @ts-ignore
    () => format(new Date(startStr), 'HH:mm', { locale: fr }),
    [startStr]
  );

  const formattedAvailabilityEndTime = useMemo(
    // @ts-ignore
    () => format(new Date(endStr), 'HH:mm', { locale: fr }),
    [endStr]
  );

  const formattedDay = useMemo(
    () => formatDateToIntl(new Date(endStr)),
    [endStr]
  );

  const onUpdateAvailabilityType = () => {
    setAvailabilityOption(
      availabilityOption === BookingOption.BookingVideo
        ? BookingOption.BookingPhone
        : BookingOption.BookingVideo
    );
  };

  return (
    <LayoutModal
      isModalOpen={isModalModifyAvailabilityOpen}
      handleModalClose={handleModalModifyAvailability}
    >
      <div>
        <div className="relative w-fit flex justify-start items-center pb-6">
          <h3 className="text-black font-sans font-bold text-xl">
            {t('account.availability.modify.title')}
          </h3>
          <div className="w-screen bg-gray-400 h-px absolute bottom-0 -left-6" />
        </div>
        <div className="text-center">
          <div className="py-6 border-b border-gray-300">
            <p className="text-md mt-2 font-bold">
              {t('account.availability.modify.p', {
                formattedAvailabilityStartTime,
                formattedAvailabilityEndTime,
                formattedDay
              })}
            </p>
          </div>

          <div className="py-4 flex flex-col w-full border-b border-gray-300 justify-start">
            <div className="flex w-full flex-col items-start mb-4">
              <p className="font-sans text-base text-black mb-1 w-auto">
                {t('account.availability.modify.video.title')}
              </p>
              <p className="text-[#909090] font-sans text-base">
                {t('account.availability.modify.video.text')}
              </p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center">
                <div className="w-6 h-6 fill-purple-700 mr-2">
                  <CameraIcon />
                </div>
                <p className="text-black font-sans font-medium text-lg">
                  {t('account.availability.modify.video.tag')}
                </p>
              </div>

              <Switch
                checked={availabilityOption === BookingOption.BookingVideo}
                onChange={onUpdateAvailabilityType}
                className={`${
                  availabilityOption !== BookingOption.BookingVideo
                    ? 'bg-gray-500'
                    : 'bg-purple-800'
                }
                relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">
                  {t('account.lawyer.question.answer.formal_notice.ask')}
                </span>
                <span
                  aria-hidden="true"
                  className={`${
                    availabilityOption === BookingOption.BookingVideo
                      ? 'translate-x-6'
                      : 'translate-x-0'
                  }
                  pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex gap-4 justify-center">
              <Button
                variant="secondary"
                size="medium"
                label={t('account.availability.modify.delete')}
                isLoading={cancelAvailabilitySlotIsLoading && !isModifyLoading}
                onClick={() => handleConfirmCancel()}
                disabled={cancelAvailabilitySlotIsLoading && !isModifyLoading}
              />
              <Button
                variant="tertiary"
                size="medium"
                fullWidth
                label={t('account.availability.modify.cta')}
                onClick={onHandleModify}
                isLoading={isModifyLoading}
                disabled={option === availabilityOption || isModifyLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
