// Output: '25 mai 2022'
export function formatDateToIntl(
  str: Date,
  length?: 'short' | 'long',
  locale?: string
) {
  if (length === 'short') {
    return Intl.DateTimeFormat(locale || 'fr', {
      month: 'short',
      day: '2-digit'
    }).format(new Date(str));
  }
  return Intl.DateTimeFormat(locale || 'fr', {
    dateStyle: 'long'
  }).format(new Date(str));
}

// Output: '25/05/22, 08:31'
export function formatConsultationTimeSlotDateToIntl(
  str: Date,
  locale?: string
) {
  return Intl.DateTimeFormat(locale || 'fr', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(str));
}

// Output: '25/05/2022'
export function getDateAsDdMmYyyy(date: Date, locale?: string) {
  if (!date) return '';
  return new Intl.DateTimeFormat(locale || 'fr', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  }).format(new Date(date));
}

// Output: '08:31'
export function getDateAsHhMm(date: Date, locale?: string) {
  if (!date) return '';

  return new Intl.DateTimeFormat(locale || 'fr', {
    hour: 'numeric',
    minute: 'numeric'
  }).format(new Date(date));
}
