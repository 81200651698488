import * as Sentry from '@sentry/react';
import { GraphQLClient } from 'graphql-request';

const DATO_BASE_URL = 'https://graphql.datocms.com/';

export async function graphQLRequest(query: string, variables: any) {
  try {
    const headers = {
      authorization: `Bearer ${process.env.REACT_APP_DATO_CMS_READONLY_API_TOKEN}`,
      ...(process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'production' && {
        'X-Include-Drafts': 'true'
      })
    };
    const client = new GraphQLClient(DATO_BASE_URL, { headers });

    return await client.request(query, variables);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
}

export default graphQLRequest;
