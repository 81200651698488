import Fuse from 'fuse.js';

import { Answer } from 'types/answer';
import { Question } from 'types/question';
import { QuestionAssignation } from 'types/question-assignation';

interface UseQuestionsPageSearchProps {
  query: string | null;
  entities: {
    answers: Answer[];
    questionAssignations: QuestionAssignation[];
    questionsToAnswer: Question[];
  };
}

interface UseQuestionsPageSearchOutput {
  answersHits: Answer[] | [];
  questionAssignationsHits: QuestionAssignation[] | [];
  questionsToAnswerHits: Question[] | [];
}

const trimStringForSearch = (str: string) => str.replace(/(\r\n|\n|\r)/gm, ' ');
const FUSE_CONFIG = {
  isCaseSensitive: false,
  includeScore: true,
  includeMatches: true,
  ignoreLocation: true,
  shouldSort: false,
  threshold: 0.2
};

export default function useQuestionsPageSearch({
  query,
  entities: { answers, questionAssignations, questionsToAnswer }
}: UseQuestionsPageSearchProps): UseQuestionsPageSearchOutput {
  const answersFuse = new Fuse(
    answers.map((i) => ({
      ...i,
      content: trimStringForSearch(i.content),
      question: {
        ...i.question,
        content: trimStringForSearch(i.question!.content)
      }
    })),
    {
      keys: [
        'content',
        'question.content',
        'question.customer.lastName',
        'question.customer.firstName'
      ],
      ...FUSE_CONFIG
    }
  );

  const questionAssingationsFuse = new Fuse(
    questionAssignations.map((i) => ({
      ...i,
      question: {
        ...i.question,
        content: trimStringForSearch(i.question.content)
      }
    })),
    {
      keys: ['question.content'],
      ...FUSE_CONFIG
    }
  );

  const questionsToAnswerFuse = new Fuse(
    questionsToAnswer.map((i) => ({
      ...i,
      content: trimStringForSearch(i.content)
    })),
    {
      keys: ['content', 'customer.lastName', 'customer.firstName'],
      ...FUSE_CONFIG
    }
  );

  const answerResults = query
    ? answersFuse.search(query).map((result) => result?.item)
    : answers;

  const questionAssignationsResults = query
    ? questionAssingationsFuse.search(query).map((result) => result?.item)
    : questionAssignations;

  const questionsToAnswerResults = query
    ? questionsToAnswerFuse.search(query).map((result) => result?.item)
    : questionsToAnswer;

  return {
    answersHits: answerResults as Answer[] | [],
    questionAssignationsHits: questionAssignationsResults,
    questionsToAnswerHits: questionsToAnswerResults
  };
}
