import { SVGProps } from 'types/core';

export default function PhoneIcon({ fill = '#545454' }: SVGProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8086 11.4909L14.2148 13.9896C14.1406 14.3607 13.8438 14.6081 13.4727 14.6081C7.23828 14.5833 2.16667 9.51172 2.16667 3.27734C2.16667 2.90625 2.38932 2.60937 2.76042 2.53516L5.25911 1.94141C5.60547 1.86719 5.97656 2.0651 6.125 2.38672L7.28776 5.08333C7.41146 5.40495 7.33724 5.77604 7.0651 5.97396L5.72917 7.0625C6.57031 8.76953 7.95573 10.1549 9.6875 10.9961L10.776 9.66016C10.974 9.41276 11.3451 9.3138 11.6667 9.4375L14.3633 10.6003C14.6849 10.7734 14.8828 11.1445 14.8086 11.4909Z"
        fill={fill}
        stroke="black"
      />
    </svg>
  );
}
