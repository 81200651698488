import { Transition } from '@headlessui/react';
import { ElementType } from 'react';

interface FadeProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: ElementType;
}

const Fade = ({ children, className, as = 'div' }: FadeProps) => (
  <Transition
    show
    appear
    as={as}
    enter="transition ease-out duration-150"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition ease-in duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-40"
    className={className}
  >
    {children}
  </Transition>
);

export default Fade;
