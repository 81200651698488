import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateUnauthenticatedAppointmentVoteMutation } from 'api/customer/mutations.index';

import { LayoutCard } from 'features/shared/layout';
import { LoadingSpinner } from 'features/shared/loading';

import { ConsultationVoteUnsatisfiedForm } from './forms';

interface ConsultationAppointmentVoteUnsatisfiedCardProps {
  voteLinkId: string;
  appointmentId: number;
  note: number;
}

export default function ConsultationAppointmentVoteUnsatisfiedCard({
  voteLinkId,
  appointmentId,
  note
}: ConsultationAppointmentVoteUnsatisfiedCardProps) {
  const { t } = useTranslation('customer');
  const { mutate, isPending: isCreateAppointmentVoteLoading } =
    useCreateUnauthenticatedAppointmentVoteMutation();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const handleUnsatisfiedMutate = (reasonText: string) =>
    mutate(
      {
        appointmentId,
        newAppointmentVote: {
          voteLinkId,
          note,
          reason: reasonText
        }
      },
      {
        onSuccess: () => setIsFormSubmitted(true)
      }
    );

  if (isFormSubmitted) {
    return (
      <LayoutCard
        title={t('account.question.vote.unsatisfied.thank')}
        body={
          <p className="text-md text-center font-medium ">
            {t('account.question.vote.unsatisifed.success')}
          </p>
        }
      />
    );
  }
  return (
    <LayoutCard
      title=""
      body={
        isCreateAppointmentVoteLoading ? (
          <div className="grid place-items-center">
            <LoadingSpinner purple />
          </div>
        ) : (
          <ConsultationVoteUnsatisfiedForm
            handleSubmitUnsatisfiedVote={handleUnsatisfiedMutate}
            isMutationLoading={isCreateAppointmentVoteLoading}
          />
        )
      }
    />
  );
}
