import { SVGProps } from 'react';

export default function BookIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.15 101.19H137.34C137.34 101.19 137.73 101.28 137.73 92.37C137.73 83.46 137.34 83.46 137.34 83.46M20.15 101.19L14.76 98.16M20.15 101.19C20.15 101.19 19.57 100.99 19.57 92.23C19.57 83.47 20.15 83.47 20.15 83.47M20.15 83.47H26.28M20.15 83.47L14.76 80.92V97.66M137.33 83.47H126.42M20.15 80.56H14.01L14.77 80.92M137.34 83.47L126.43 80.63M14.76 97.66H14L14.76 98.17V97.66Z"
        stroke="black"
        strokeWidth="0.4"
      />
      <path d="M15.81 82.9L18.86 84.3" stroke="black" strokeWidth="0.4" />
      <path d="M14.82 97.06L17.85 98.76" stroke="black" strokeWidth="0.4" />
      <path d="M16.6 96.64L19.57 98.23" stroke="black" strokeWidth="0.4" />
      <path d="M14.82 93.1L19.57 95.35" stroke="black" strokeWidth="0.4" />
      <path d="M15.61 92.2L19.68 94.23" stroke="black" strokeWidth="0.4" />
      <path d="M14.82 89.1L17.03 90" stroke="black" strokeWidth="0.4" />
      <path
        d="M133.21 87.65H98.23V98.02H133.21V87.65Z"
        stroke="black"
        strokeWidth="0.4"
      />
      <path
        d="M44.31 87.65H35.41V98.02H44.31V87.65Z"
        stroke="black"
        strokeWidth="0.4"
      />
      <path d="M31.25 87.65V98.02" stroke="black" strokeWidth="0.4" />
      <path d="M29.66 87.65V98.02" stroke="black" strokeWidth="0.4" />
      <path d="M27.84 87.65V98.02" stroke="black" strokeWidth="0.4" />
      <path
        d="M28.54 84.52H126.43V78.02H28.54M28.54 84.52V78.02M28.54 84.52L18.86 79.98M28.54 78.02L25.8 76.79M17.65 72.13L18.86 72.67M126.43 78.01L112.57 72.62M18.86 73.68V79.42M18.86 79.42H17.65L18.86 79.99V79.42Z"
        stroke="black"
        strokeWidth="0.4"
      />
      <path d="M18.86 76L22.54 77.59" stroke="black" strokeWidth="0.4" />
      <path d="M19.04 78.63L28.45 82.92" stroke="black" strokeWidth="0.4" />
      <path d="M28.45 81.55L21.24 78.38" stroke="black" strokeWidth="0.4" />
      <path
        d="M24.15 76.79V69.62H45.13M24.15 76.79L15.76 71.95M24.15 76.79H66.105H108.06V72.63M37.69 64.24H14.82L15.76 64.78M24.16 69.61L15.76 64.79V71.42M15.76 71.42H14.82L15.76 71.96V71.42Z"
        stroke="black"
        strokeWidth="0.4"
      />
      <path d="M15.75 69.97L21.79 73.38" stroke="black" strokeWidth="0.4" />
      <path d="M24.02 73.1L15.67 68.55" stroke="black" strokeWidth="0.4" />
      <path d="M24.02 71.8L20.55 69.89" stroke="black" strokeWidth="0.4" />
      <path
        d="M52.37 72.63H121.28C121.28 72.63 122.29 72.63 122.29 65.19C122.29 57.75 121.28 57.75 121.28 57.75M52.37 72.63L37.69 66.54M52.37 72.63C52.37 72.63 51.66 72.63 51.66 65.19C51.66 57.75 52.37 57.75 52.37 57.75M121.28 57.75H52.37M121.28 57.75L98.84 51H35.21L37.69 51.98M37.69 66.54L35.21 65.51H37.69M37.69 66.54V65.51M52.37 57.75L37.69 51.98M37.69 51.98V65.51"
        stroke="black"
        strokeWidth="0.4"
      />
      <path
        d="M90.45 61.7H55.47V68.55H90.45V61.7Z"
        stroke="black"
        strokeWidth="0.4"
      />
      <path d="M37.72 54.22L50.38 59.34" stroke="black" strokeWidth="0.4" />
      <path d="M39.9 65.46L51.6 70.02" stroke="black" strokeWidth="0.4" />
      <path d="M46.44 66.22L51.56 68.09" stroke="black" strokeWidth="0.4" />
      <path d="M37.83 60.5L44.21 62.93" stroke="black" strokeWidth="0.4" />
      <path d="M43.85 59.69L51.5 62.68" stroke="black" strokeWidth="0.4" />
    </svg>
  );
}
