import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PublicRateAnswerPayload, rateAnswer } from 'services/rating';

import { LayoutCard } from 'features/shared/layout';

import RateAnswerForm, { RateAnswerInput } from './components/RateAnswerForm';
import { answerRateSchema } from './validation';

interface RateAnswerProps {
  note: RateAnswerInput['note'];
  ratingLinkId: string;
}

const UnauthenticateRateAnswer = ({ note, ratingLinkId }: RateAnswerProps) => {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: (data: PublicRateAnswerPayload) => rateAnswer(data),
    onError: (error: any) => {
      if (error?.response?.status === 400) {
        return setError('root.alreadyRate', {
          message: t('account.rateAnswer.form.error.alreadyRate')
        });
      }

      return setError('root.invalid', {
        message: t('account.rateAnswer.form.error')
      });
    }
  });

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors }
  } = useForm<RateAnswerInput>({
    defaultValues: { note },
    resolver: yupResolver(answerRateSchema(tForm))
  });

  if (isSuccess) {
    return (
      <LayoutCard
        title={t('account.question.vote.unsatisfied.thank')}
        body={
          <p className="text-md text-center font-medium ">
            {t('account.question.vote.unsatisifed.success')}
          </p>
        }
      />
    );
  }

  return (
    <LayoutCard
      title={t('account.question.vote.unsatisfied.title')}
      body={
        <RateAnswerForm
          onSubmit={handleSubmit((data: RateAnswerInput) =>
            mutate({ ratingLinkId, ...data })
          )}
          isLoading={isPending}
          errors={errors}
          register={register}
        />
      }
    />
  );
};

export default UnauthenticateRateAnswer;
