import { privateAxios } from 'context/auth/auth.utils';

import { Lawyer } from 'types/lawyer';

import { formatLawyerAvailabilitySlotsFromApi } from './formatting';

export async function getLawyerAvailabiltySlots(lawyerId: Lawyer['id']) {
  const res = await privateAxios.get(`/lawyers/${lawyerId}/availability-slots`);
  const availabilitySlots = formatLawyerAvailabilitySlotsFromApi(res.data);
  return availabilitySlots;
}
