import { useTranslation } from 'react-i18next';

import { useLawyer } from 'hooks';

import { SectionLine } from 'features/customer/account/dashboard';

function CustomerProfile({ lawyerId }: any) {
  const { t } = useTranslation('lawyer');
  const { t: tForm } = useTranslation('form');
  const { firstName, lastName, phoneCellphone, email } = useLawyer({
    lawyerId
  });

  return (
    <div className="flex flex-col space-y-6 mt-4">
      <SectionLine
        title={tForm('label.lastName')}
        value={
          !firstName || !lastName
            ? t('general.undefined')
            : `${firstName} ${lastName}`
        }
      />
      <SectionLine
        title={tForm('label.phone')}
        value={!phoneCellphone ? t('general.undefined') : phoneCellphone}
      />
      <SectionLine title={tForm('label.email')} value={email} />
    </div>
  );
}

export default CustomerProfile;
