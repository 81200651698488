import { ProfileNav } from 'components/ProfileNav';
import { useLocation } from 'react-router-dom';

import { useCustomer } from 'hooks';

import { useAuth } from 'context';

import { HouseIcon } from 'features/shared/icons';
import GearIcon from 'features/shared/icons/GearIcon';

import { IMenuItem, PageName } from 'types/account';

export default function CustomerProfile() {
  const { pathname } = useLocation();
  const { logout, roles } = useAuth();
  const { firstName, lastName, email } = useCustomer();
  const accountTabs: IMenuItem[] = [
    {
      name: PageName.Profile,
      href: '/account/profile',
      current: pathname.includes(PageName.Profile),
      newUntil: new Date('05-01-2023'),
      icon: GearIcon
    },
    ...(process.env.REACT_APP_EKIE_ADMIN_AVAILIBILITY === '1' &&
    roles.includes('ROLE_CUSTOMER_ADMIN') &&
    !pathname.includes(PageName.Admin)
      ? [
          {
            name: PageName.Admin,
            href: '/admin/collaborators',
            current: pathname.includes(PageName.Admin),
            newUntil: new Date('05-01-2023')
          }
        ]
      : []),
    ...(pathname.includes(PageName.Admin)
      ? [
          {
            name: PageName.Home,
            href: '/account/home',
            icon: HouseIcon,
            current: pathname.includes(PageName.Home),
            newUntil: new Date('05-01-2023')
          }
        ]
      : [])
  ];

  return (
    <ProfileNav
      logout={logout}
      label={firstName && lastName ? `${firstName} ${lastName[0]}` : email}
      accountTabs={accountTabs}
    />
  );
}
