import { SVGProps } from 'react';

export default function SuccessionIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.0314 34.9544V126.652"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M103.868 25.4387C109.123 25.4387 113.384 29.6995 113.384 34.9546"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M113.383 82.8582V126.652"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M113.383 34.9544V62.8169"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5155 25.4387H103.868"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M43.5446 136.168H122.897"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0314 126.652C34.0314 131.907 38.2922 136.168 43.5473 136.168C48.8023 136.168 53.0631 131.907 53.0631 126.652H132.413C132.413 131.907 128.152 136.168 122.897 136.168"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0315 34.9544H14.9999C14.9999 29.6994 19.2607 25.4386 24.5157 25.4386C29.7708 25.4386 34.0315 29.6994 34.0315 34.9544Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 48.46H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 54.5022H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 60.5444H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 66.5868H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 72.629H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 78.6716H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 84.7137H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 90.7559H101.721"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 96.7955H100.496"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6917 102.838H98.2396"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M80.6342 116.878C80.6342 116.878 82.2035 109.368 83.1007 109.422C83.9978 109.476 83.5505 123.383 84.8949 123.383C86.2394 123.383 85.6796 114.521 86.4643 114.521C87.249 114.521 86.6892 117.213 87.9212 117.55C89.1532 117.887 89.4905 114.634 90.2778 114.409C91.065 114.184 93.3194 118.767 95.7756 118.669"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M104.842 85.4754L115.902 56.1356L122.953 58.873L111.324 88.0186"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M110.828 72.9104L114.467 63.254L114.631 62.822L114.958 61.9504"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M108.604 78.8043L110.022 75.0471L110.365 74.1371"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.672 107.983L97.754 106.074C97.4549 105.959 97.3042 105.626 97.4141 105.325L104.456 85.8382C104.568 85.529 104.913 85.3705 105.223 85.4906L111.042 87.7475C111.349 87.8677 111.5 88.2153 111.372 88.522L103.428 107.661C103.306 107.955 102.971 108.098 102.672 107.983V107.983Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.0628 103.638L99.6226 102.087"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.677 93.6338L104.571 88.3915"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M100.604 99.3747L101.849 95.9242L102.171 95.0398L102.48 94.1836"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.401 59.0468L115.454 55.9643C114.889 55.7445 114.621 55.0978 114.866 54.5406L130.984 18.0799C131.738 16.3751 133.699 15.5623 135.439 16.237C137.177 16.9118 138.079 18.8339 137.484 20.6001L124.796 58.3924C124.602 58.9675 123.968 59.2666 123.403 59.0468H123.401Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M118.107 49.7586L118.922 47.9132"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M122.45 39.9359L125.972 31.9664"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M119.355 46.9393L121.66 41.7252"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M131.687 17.0678L137.647 19.381"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M132.763 17.4845L128.722 27.9026C128.663 28.0534 128.658 28.2195 128.707 28.3729L129.231 30.07C129.294 30.2771 129.287 30.4969 129.208 30.6988L124.62 42.5227C124.569 42.6556 124.456 42.7501 124.321 42.791C124.147 42.8447 124.004 42.9929 123.973 43.1923L123.689 45.0352L123.457 46.5508C123.423 46.7732 123.715 46.8857 123.84 46.6991L124.691 45.4237L125.724 43.8722C125.836 43.7035 125.831 43.499 125.737 43.3431C125.665 43.2204 125.647 43.0747 125.698 42.9444L130.286 31.1205C130.365 30.9186 130.509 30.7499 130.693 30.6426L132.224 29.7429C132.362 29.6611 132.469 29.5358 132.528 29.385L136.569 18.967"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M115.871 52.2634L125.588 56.0335"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M98.0889 106.207L95.3515 112.016C95.262 112.208 95.2313 112.418 95.2671 112.627C95.4256 113.555 95.8473 116.354 95.6173 118.445C95.5968 118.631 95.8294 118.723 95.9419 118.575C97.2147 116.888 99.3899 115.086 100.116 114.506C100.277 114.378 100.394 114.204 100.453 114.008L102.352 107.863"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M95.722 118.632L100.22 107.033"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M97.345 112.722L98.5079 113.174"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
