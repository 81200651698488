import axios, { AxiosError, AxiosInstance } from 'axios';
import { getAccessTokenByRefreshToken } from 'services/authentication/refreshToken';

import { handleClearAuthAndLoginRedirect } from 'context/auth/auth.utils';

const PYTHON_API_AXIOS_CONFIG = {
  baseURL: process.env.REACT_APP_NEW_API_EKIE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const apiPublicPythonClient: AxiosInstance = axios.create(
  PYTHON_API_AXIOS_CONFIG
);

export const apiPrivatePythonClient: AxiosInstance = axios.create(
  PYTHON_API_AXIOS_CONFIG
);

export const onError = async (error: any, clientAxios: AxiosInstance) => {
  const originalRequest = error.config as AxiosError['config'];
  if (originalRequest.url === '/auth/token/refresh') {
    handleClearAuthAndLoginRedirect();
  }

  if (error?.response?.status === 401) {
    await getAccessTokenByRefreshToken();

    return clientAxios(originalRequest);
  }

  return Promise.reject(error);
};

apiPrivatePythonClient.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
    }
  }),
  (error) => Promise.reject(error)
);

apiPrivatePythonClient.interceptors.response.use(
  (response) => response,
  (error) => onError(error, apiPrivatePythonClient)
);
