import { useQueries } from '@tanstack/react-query';
import { QuestionsListSkeleton } from 'components/skeleton';
import { flatMap } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAuthenticatedMemberInfo } from 'services/member';
import { getMemberQuestions } from 'services/question';

import { Paragraph } from 'features/shared/body';

import MemberQuestionOverview from './components/MemberQuestionOverview';

const MemberQuestionsConsultationAvailable = () => {
  const { t } = useTranslation('customer');
  const { data: member, isLoading: isMemberLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const { data, isLoading } = useQueries({
    queries: [
      {
        queryKey: ['member-questions-consultation-available', 'personal'],
        queryFn: () => getMemberQuestions({ source: 'personal' })
      },
      {
        queryKey: ['member-questions-consultation-available', 'cse'],
        queryFn: () => getMemberQuestions({ source: 'cse' }),
        enabled: !!member?.is_active_cse_member
      }
    ],
    combine: (results) => ({
      data: flatMap(results, (item) => item.data?.items || []),
      isLoading: results.some((result) => result.isLoading),
      isSuccess: results.some((result) => result.isSuccess)
    })
  });

  if (isMemberLoading || isLoading) return <QuestionsListSkeleton />;

  const allQuestionsWithConsultationAvailable = data.filter(
    (question) => question.can_ask_consultation
  );

  return (
    <div>
      {allQuestionsWithConsultationAvailable.length ? (
        <div className="grid grid-cols-1 gap-10">
          {allQuestionsWithConsultationAvailable.map((question) => (
            <MemberQuestionOverview key={question.id} question={question} />
          ))}
        </div>
      ) : (
        <Paragraph weight="normal">
          {allQuestionsWithConsultationAvailable.length
            ? t('account.consultations.choice.available')
            : t('account.consultations.choice.unavailable')}
        </Paragraph>
      )}
    </div>
  );
};

export default MemberQuestionsConsultationAvailable;
