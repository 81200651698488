import { TFunction } from 'react-i18next';

import { Answer } from 'types/answer';

export const answerMedContentDefaultValue = ({
  t,
  customerCanAskConsultationForFree,
  lawyerFullName
}: {
  t: TFunction;
  customerCanAskConsultationForFree: boolean;
  lawyerFullName: string;
}) =>
  t('template.lawyer.formal_notice', {
    offer: customerCanAskConsultationForFree
      ? t('template.lawyer.formal_notice.included')
      : ` `,
    lawyerFullName
  });

export const answerContentDefaultValue = ({
  t,
  customerCanAskConsultationForFree,
  lawyerFullName
}: {
  t: any;
  customerCanAskConsultationForFree: boolean;
  lawyerFullName: string;
}) =>
  t('template.lawyer.default', {
    offer: customerCanAskConsultationForFree
      ? t('template.lawyer.default.included')
      : ` `,
    lawyerFullName
  });

export interface AnswerInput {
  answerContent: Answer['content'];
}
