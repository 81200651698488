import { Button } from 'features/shared/buttons';

import { ButtonProps } from './Button';

interface LinkedButtonProps extends ButtonProps {
  href: string;
  isExternal?: boolean;
}

export default function LinkedButton({
  href,
  isExternal = false,
  ...props
}: LinkedButtonProps) {
  if (isExternal) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <Button {...props} />
      </a>
    );
  }

  return (
    <a href={href}>
      <Button {...props} />
    </a>
  );
}
