import { Tag } from 'components';
import { Card } from 'components/card';
import { PropsWithChildren } from 'react';

interface StatisticProps {
  label: string;
}

const Statistic = ({ label, children }: PropsWithChildren<StatisticProps>) => (
  <Card className="!gap-0">
    <Tag variant="none" text={label} className="mb-3" />
    {children}
  </Card>
);

export default Statistic;
