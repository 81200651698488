/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { MDXEditorMethods } from '@mdxeditor/editor';
import TranslatedAnswer from 'containers/answer/TranslatedAnswer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { questionAnswerSchema } from 'schemas';

import {
  useCreateAnswerMutation,
  useCreateDraftMutation
} from 'api/lawyer/answers';

import { useLawyer, useStorageClean } from 'hooks';

import { useAuth } from 'context';

import {
  ModalAnswerConfirmation,
  ModalAnswerSuccess
} from 'features/lawyer/account/questions/to-answer/modals';
import ModalAnswerTemplateConfirmation from 'features/lawyer/account/questions/to-answer/modals/ModalAnswerTemplateConfirmation';
import { ToAnswerCard } from 'features/lawyer/account/questions/to-answer/to-answer-card';
import {
  AnswerInput,
  answerContentDefaultValue,
  answerMedContentDefaultValue
} from 'features/lawyer/account/questions/to-answer/to-answer.constants';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

import { Answer } from 'types/answer';
import { LawyerQuestion, Question } from 'types/question';

export default function CreateEditAnswer({
  question,
  questionsToAnswer,
  lastDraftAnswer
}: {
  question: LawyerQuestion;
  questionsToAnswer: Question[];
  lastDraftAnswer: Answer | undefined;
}) {
  const { t: tForm } = useTranslation('form');
  const queryClient = useQueryClient();
  const ref = useRef<MDXEditorMethods>(null);
  const firstRender = useRef(true);

  const { lawyerId } = useAuth();

  const { firstName, lastName } = useLawyer({ lawyerId: lawyerId as number });

  const [isModalAnswerConfirmationOpen, setIsModalAnswerConfirmationOpen] =
    useState(false);

  const [isModalTemplateConfirmationOpen, setIsModalTemplateConfirmationOpen] =
    useState(false);

  const [isTranslateAnswerOpen, setIsTranslateAnswerOpen] = useState(false);
  const [isModalAnswerSuccessOpen, setIsModalAnswerSuccessOpen] =
    useState(false);

  const [isCreateAnswerSettled, setIsCreateAnswerSettled] = useState(true);

  const [formalNoticeProposal, setFormalNoticeProposal] = useState(false);

  const [isFromButtonAction, setIsFromButtonAction] = useState(false);

  const [isFormalNoticeUsed, setIsFormalNoticeUsed] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mutate: createAnswerMutate,
    isLoading: isCreateAnswerLoading,
    isSuccess: isCreateAnswerSuccess,
    reset: createAnswerReset
  } = useCreateAnswerMutation();

  const { mutate: createDraftMutate, isLoading: isCreateDraftLoading } =
    useCreateDraftMutation();

  useEffect(() => {
    if (searchParams.get('id')) {
      createAnswerReset();
    }
  }, [searchParams]);

  const answerDefaultValues = useMemo(
    (): AnswerInput => ({
      answerContent: answerContentDefaultValue({
        t: tForm,
        lawyerFullName: `${firstName} ${lastName}`,
        customerCanAskConsultationForFree: Boolean(question?.customer.isPremium)
      })
    }),
    [answerContentDefaultValue, tForm, question, firstName, lastName]
  );

  const answerMedDefaultValues = useMemo(
    (): AnswerInput => ({
      answerContent: answerMedContentDefaultValue({
        t: tForm,
        lawyerFullName: `${firstName} ${lastName}`,
        customerCanAskConsultationForFree: Boolean(question?.customer.isPremium)
      })
    }),
    [answerMedContentDefaultValue, tForm, question, firstName, lastName]
  );

  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, isDirty }
  } = useForm<AnswerInput>({
    defaultValues: answerDefaultValues,
    resolver: yupResolver(questionAnswerSchema(tForm))
  });

  const { clearItemInStorage } = useStorageClean({
    storage: window.localStorage,
    storageParentKey: 'answers',
    initialCleanupChildKeys: questionsToAnswer.map((q) => q.id)
  });

  const fieldValue = watch('answerContent');

  useEffect(() => {
    if (question?.id) {
      firstRender.current = true;
      if (lastDraftAnswer?.content) {
        setValue('answerContent', lastDraftAnswer?.content);
        ref.current?.setMarkdown(
          lastDraftAnswer?.content.replace(/<\!--.*?-->/g, '')
        );
      } else reset();

      const hasFormalNotice = !!lastDraftAnswer?.formalNotice;
      const hasCanceledFormalNotice =
        hasFormalNotice &&
        lastDraftAnswer?.formalNotice?.status === 'canceled_lawyer';

      setFormalNoticeProposal(hasFormalNotice && !hasCanceledFormalNotice);
    }
    if (!lastDraftAnswer) onSaveDraft();
  }, [question?.id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (fieldValue.length > 15) {
        if (!firstRender.current) onSaveDraft();
        else firstRender.current = false;
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [fieldValue]);

  useEffect(() => {
    if (!firstRender.current) onSaveDraft();
  }, [formalNoticeProposal]);

  const onSaveDraft = async (isButtonAction?: boolean) => {
    const { answerContent } = getValues();
    const answerId = lastDraftAnswer;

    createDraftMutate(
      {
        lawyerId: lawyerId as number,
        questionId: question!.id,
        content: answerContent,
        formalNoticeProposal,
        answerId: answerId ? answerId?.id : undefined
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['questions-to-answer']);
          await clearItemInStorage(question?.id ?? '');
          queryClient.invalidateQueries(['answers']);
          if (isFromButtonAction || isButtonAction) {
            toast.custom(
              <Toast
                type="success"
                title={tForm('template.lawyer.answer.draft.title')}
                message={tForm('template.lawyer.answer.draft.success')}
              />,
              { position: 'top-right', duration: 2000 }
            );
            setIsFromButtonAction(false);
          }
        },
        onError: () => {
          if (isFromButtonAction || isButtonAction) {
            toast.custom(
              <Toast
                type="error"
                title={tForm('template.lawyer.answer.draft.title')}
                message={tForm('template.lawyer.answer.draft.error')}
              />,
              { position: 'top-right', duration: 2000 }
            );
            setIsFromButtonAction(false);
          }
        }
      }
    );
  };

  const handleConfirm = async () => {
    const { answerContent } = getValues();
    setIsCreateAnswerSettled(false);
    const answerId = lastDraftAnswer;

    if (!answerId?.id) return;

    createAnswerMutate(
      {
        answerId: answerId?.id,
        content: answerContent,
        formalNoticeProposal
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['questions-to-answer']);
          await clearItemInStorage(question?.id ?? '');
          searchParams.delete('id');
          setSearchParams(searchParams);
          queryClient.invalidateQueries(['answers']);
          setIsModalAnswerConfirmationOpen(false);
          setIsModalAnswerSuccessOpen(true);
        },
        onSettled: () => {
          setIsModalAnswerConfirmationOpen(false);
        }
      }
    );
  };

  const onChangeDefaultContent = useCallback(() => {
    setValue(
      'answerContent',
      formalNoticeProposal
        ? answerMedDefaultValues.answerContent
        : answerDefaultValues.answerContent
    );
    ref.current?.setMarkdown(
      formalNoticeProposal
        ? answerMedDefaultValues.answerContent
        : answerDefaultValues.answerContent
    );
    setIsModalTemplateConfirmationOpen(false);
  }, [
    setValue,
    setIsModalTemplateConfirmationOpen,
    formalNoticeProposal,
    answerMedDefaultValues,
    answerDefaultValues
  ]);

  const onChangeFormalProposal = useCallback(() => {
    setIsFormalNoticeUsed(true);
    setFormalNoticeProposal(!formalNoticeProposal);
  }, [setFormalNoticeProposal, formalNoticeProposal]);

  const onChangeTemplateConfirmation = useCallback(() => {
    if (isDirty) setIsModalTemplateConfirmationOpen(true);
    else onChangeDefaultContent();
  }, [onChangeDefaultContent, setIsModalTemplateConfirmationOpen, isDirty]);

  const onSubmit = () => {
    setIsModalAnswerConfirmationOpen(true);
  };

  const onSubmitDraft = useCallback(async () => {
    setIsFromButtonAction(true);
    await onSaveDraft(true);
  }, [setIsFromButtonAction, onSaveDraft]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ToAnswerCard
        onChangeFormalProposal={onChangeFormalProposal}
        onChangeTemplateConfirmation={onChangeTemplateConfirmation}
        onSaveDraft={onSubmitDraft}
        onTranslateClick={() => setIsTranslateAnswerOpen(true)}
        isSavingDraftLoading={isCreateDraftLoading}
        isFromButtonAction={isFromButtonAction}
        formalNoticeProposal={formalNoticeProposal}
        isFormalNoticeUsed={isFormalNoticeUsed}
        control={control}
        errors={errors}
        ref={ref}
      />
      {!isCreateAnswerSuccess ? (
        <ModalAnswerConfirmation
          handleConfirm={handleConfirm}
          setIsOpen={setIsModalAnswerConfirmationOpen}
          isOpen={isModalAnswerConfirmationOpen}
          isLoading={isCreateAnswerLoading && !isCreateAnswerSettled}
        />
      ) : (
        <ModalAnswerSuccess
          isOpen={isModalAnswerSuccessOpen}
          setIsOpen={setIsModalAnswerSuccessOpen}
        />
      )}
      <ModalAnswerTemplateConfirmation
        handleConfirm={onChangeDefaultContent}
        isOpen={isModalTemplateConfirmationOpen}
        isFormalNotice={formalNoticeProposal}
        setIsOpen={setIsModalTemplateConfirmationOpen}
      />
      <LayoutModal
        isModalOpen={isTranslateAnswerOpen}
        handleModalClose={() => setIsTranslateAnswerOpen(false)}
      >
        <TranslatedAnswer answer={fieldValue} />
      </LayoutModal>
    </form>
  );
}
