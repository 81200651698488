import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { useSearchParams } from 'react-router-dom';

import ArrowButtonPagination from './ArrowButtonPagination';
import BreakPagePagination from './BreakPagePagination';

interface CommonPaginationProps extends ReactPaginateProps {
  onRefetch(value: number): void;
}

const Pagination = ({
  onRefetch,
  pageCount,
  initialPage = 1
}: CommonPaginationProps) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <div className="flex justify-center items-center my-4">
      <ReactPaginate
        onPageChange={(page) => {
          setSearchParams({ page: String(page.selected + 1) });
          onRefetch(page.selected + 1);
        }}
        initialPage={initialPage - 1}
        breakLabel={<BreakPagePagination />}
        nextLabel={<ArrowButtonPagination direction="next" />}
        containerClassName="flex justify-center items-center last:mr-0 first:ml-0 transition-all max-w-full gap-2"
        pageClassName="flex justify-center items-center px-2.5 cursor-pointer font-semibold"
        activeClassName="rounded-md text-purple-900 bg-purple-300 font-semibold"
        pageLinkClassName="p-1.5"
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={<ArrowButtonPagination />}
      />
    </div>
  );
};

export default Pagination;
