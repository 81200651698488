import { privateAxios } from 'context/auth/auth.utils';

import { Account } from 'types/account';

import { formatAccountFromApi } from './formatting';

export async function getAccount(email: string): Promise<Account> {
  const res = await privateAxios.get(
    `/accounts?email=${encodeURIComponent(email)}`
  );
  const account = await formatAccountFromApi(res.data[0]);
  return account;
}
