import { useTranslation } from 'react-i18next';

import { LinkedButton } from 'features/shared/buttons';

export default function StripePaymentsConfigured() {
  const { t } = useTranslation('lawyer');

  const stripeAccountDashboardUrl =
    'https://dashboard.stripe.com/balance/overview';

  return (
    <div>
      <div className="mb-4">
        <p>{t('payments.stripe.activated.p')}</p>
      </div>
      <div className="flex justify-end">
        <LinkedButton
          variant="primary"
          size="medium"
          href={stripeAccountDashboardUrl}
          label={t('payments.stripe.activated.button')}
          isExternal
        />
      </div>
    </div>
  );
}
