import { Navigate, Route, Routes } from 'react-router-dom';

import { useCustomerQuery } from 'api/customer/customers';

import {
  AccountPage,
  AdministratorsPage,
  AlphabetSheetPage,
  ArticlePage,
  CollaboratorsPage,
  ConsultationAppointmentVotePage,
  ConsultationBookingPage,
  ConsultationsPage,
  ContactPage,
  HomePage,
  NpsVotePage,
  PrivateContentPage,
  ResourcesPage,
  ThematicsPage,
  UpdatePasswordPage,
  VideoPage
} from 'pages/customer';
import AdminPage from 'pages/customer/AdminPage';
import {
  PasswordCompletionPage,
  ProfileCompletionPage,
  SituationCompletionPage,
  ThematicsCompletionPage
} from 'pages/customer/onboarding';
import { NotFoundPage } from 'pages/error';
import { ProfilePage } from 'pages/member';
import {
  AskQuestionPage,
  MemberQuestionPage,
  MemberQuestionsConsultationAvailablePage,
  MemberQuestionsPage
} from 'pages/questions';
import { AnswerRatingPage } from 'pages/rating';

import { useAuth } from 'context';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { RedirectToRateAnswer } from './PublicRouter';
import { MEMBER_DEFAULT_REDIRECT_URL } from './constants';

const CustomerRouter = () => {
  const customer = useCustomerQuery();
  const { roles } = useAuth();

  if (!customer.isSuccess) return <LayoutLoadingSkeleton />;

  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />}
      />
      {process.env.REACT_APP_EKIE_ADMIN_AVAILIBILITY === '1' &&
        roles.includes('ROLE_CUSTOMER_ADMIN') && (
          <Route path="admin" element={<AdminPage />}>
            <Route path="collaborators" element={<CollaboratorsPage />} />
            <Route path="settings" element={<AdministratorsPage />} />
          </Route>
        )}
      <Route path="rate-answer/:ratingLinkId" element={<AnswerRatingPage />} />
      <Route path="account" element={<AccountPage />}>
        <Route path="ask" element={<AskQuestionPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="home" element={<HomePage />} />
        <Route
          path="dashboard"
          element={<Navigate replace to={MEMBER_DEFAULT_REDIRECT_URL} />}
        />
        <Route path="questions" element={<MemberQuestionsPage />} />
        <Route path="consultations" element={<ConsultationsPage />} />
        <Route path="guarantees" element={<ThematicsPage />} />
        <Route path="resources" element={<ResourcesPage />} />
        <Route path="resources/videos" element={<ResourcesPage />} />
        <Route path="videos/:id" element={<VideoPage />} />
        <Route path="resources/documents" element={<ResourcesPage />} />
        <Route path="resources/alphabet" element={<ResourcesPage />} />
        <Route path="resources/:slug" element={<ArticlePage />} />
        <Route path="alphabet/:slug" element={<AlphabetSheetPage />} />
        <Route
          path="consultations/booking"
          element={<ConsultationBookingPage />}
        />
        <Route path="contact" element={<ContactPage />} />
        <Route path="password-new" element={<UpdatePasswordPage />} />
      </Route>
      <Route path="questions" element={<AccountPage />}>
        <Route path=":questionId" element={<MemberQuestionPage />} />
        <Route
          path="consultation-available"
          element={<MemberQuestionsConsultationAvailablePage />}
        />
      </Route>
      <Route
        path="consultations/vote/link/:voteLinkId"
        element={<ConsultationAppointmentVotePage />}
      />
      <Route path="nps/vote/:accountId" element={<NpsVotePage />} />
      <Route path="onboarding">
        <Route path="profile" element={<ProfileCompletionPage />} />
        <Route path="password" element={<PasswordCompletionPage />} />
        <Route path="situation" element={<SituationCompletionPage />} />
        <Route path="thematics" element={<ThematicsCompletionPage />} />
        <Route
          path="email"
          element={<Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />}
        />
      </Route>
      <Route path="content/:slug" element={<PrivateContentPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="login"
        element={<Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="questions/vote/link/:ratingLinkId"
        element={<RedirectToRateAnswer />}
      />
      <Route
        path="authenticate"
        element={<Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />}
      />
    </Routes>
  );
};

export default CustomerRouter;
