import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface CardContentProps {
  className?: string;
}

const CardContent = ({
  className,
  children
}: PropsWithChildren<CardContentProps>) => (
  <div className={classNames('flex flex-col flex-1', className)}>
    {children}
  </div>
);

export default CardContent;
