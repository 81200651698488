import classNames from 'classnames';

interface DividerProps {
  text?: string;
  className?: string;
}

const Divider = ({ className, text }: DividerProps) => (
  <div className={classNames('relative flex py-5 items-center', className)}>
    <div className="flex-grow border-t border-gray-400" />
    {text && (
      <span className="flex-shrink mx-4 font-semibold text-gray-600 uppercase">
        {text}
      </span>
    )}
    {text && <div className="flex-grow border-t border-gray-400" />}
  </div>
);

export default Divider;
