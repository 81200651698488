import { useParams, useSearchParams } from 'react-router-dom';
import { isPositiveRating } from 'services/rating';

import AutoSatisfiedRateAnswer from 'features/rating/AutoSatisfiedRateAnswer';
import UnauthenticateRateAnswer from 'features/rating/UnauthenticateRateAnswer';
import { LayoutDefault } from 'features/shared/layout';

import { RateAnswerInput } from '../../features/rating/components/RateAnswerForm';

const AnswerRatingPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const ratingLinkId = params.ratingLinkId as string;

  const note = parseInt(
    searchParams.get('note') || '5',
    10
  ) as RateAnswerInput['note'];

  return (
    <LayoutDefault>
      {isPositiveRating(note) ? (
        <AutoSatisfiedRateAnswer note={note} ratingLinkId={ratingLinkId} />
      ) : (
        <UnauthenticateRateAnswer note={note} ratingLinkId={ratingLinkId} />
      )}
    </LayoutDefault>
  );
};

export default AnswerRatingPage;
