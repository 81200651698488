import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface ChipProps {
  color?: string;
  className?: string;
}

const COLOR_MAPS = {
  'purple-outline': 'text-purple-600 border-purple-600 bg-purple-100',
  purple: 'text-white bg-purple-900',
  blue: 'text-blue-800 border-blue-600 bg-blue-100',
  red: 'text-red-800 border-red-600 bg-red-100'
} as { [key: string]: string };

const Chip = ({
  children,
  className,
  color = 'purple-outline'
}: PropsWithChildren<ChipProps>) => (
  <span
    className={classNames(
      className,
      COLOR_MAPS[color],
      'rounded-full w-fit py-1 font-bold px-2 border-2 leading-4 inline-block'
    )}
  >
    {children}
  </span>
);

export default Chip;
