import { SVGProps } from 'react';

export default function FamilyIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M57.6902 35.0609C58.6292 33.347 59.8113 31.788 61.191 30.4266L61.1849 30.4205C63.0782 28.582 64.5794 25.9138 65.9803 22.9448C67.6426 19.4197 66.9923 13.7947 66.9923 13.272C66.9923 8.62553 70.8213 4.87553 75.4951 5.00316H76.349C80.8679 5.0609 84.5115 8.74101 84.5115 13.272C84.5115 13.7947 84.3596 20.0275 85.5235 22.9448C86.6874 25.8622 88.4256 28.582 90.3189 30.4205L90.3128 30.4266C91.6134 31.712 92.7409 33.1738 93.6525 34.7753"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.943 38.9748H51.3966C50.6179 38.9748 49.9866 39.6061 49.9866 40.3848V54.1906C49.9866 54.9693 50.6179 55.6006 51.3966 55.6006H99.943C100.722 55.6006 101.353 54.9693 101.353 54.1906V40.3848C101.353 39.6061 100.722 38.9748 99.943 38.9748Z"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.6912 34.9088H96.6492C97.7979 34.9088 98.7279 35.8418 98.7279 36.9874V38.9749H52.6125V36.9874C52.6125 35.8387 53.5455 34.9088 54.6912 34.9088Z"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.3416 55.6039H97.9982V56.4639C97.9982 57.1112 97.4725 57.6369 96.8252 57.6369H54.5146C53.8673 57.6369 53.3416 57.1112 53.3416 56.4639V55.6039Z"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M51.5881 38.9748V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.1924 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.7969 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M56.4043 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M58.0093 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.6138 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M61.2212 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M62.8262 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M64.4307 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M66.0386 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M67.6426 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M69.2466 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M70.8545 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M72.459 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M74.064 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.6709 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M77.2754 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M78.8804 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M80.4849 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M82.0928 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M83.6973 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M85.3013 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M86.9087 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.5132 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M90.1187 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M91.7256 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M93.3306 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M94.9351 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M96.543 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M98.147 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.7515 38.9749V55.6037"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.39 57.637H96.9497C102.134 57.637 106.34 61.8459 106.34 67.0272V132.607C106.34 140.307 100.089 146.561 92.3853 146.561H58.9544C51.2538 146.561 44.9998 140.31 44.9998 132.607V67.0272C44.9998 61.8429 49.2086 57.637 54.39 57.637Z"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M91.3638 64.994V139.204"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0762 66.8296H94.6554"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 69.212H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 71.5947H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 73.977H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 76.3597H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 81.1246H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 83.5072H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 85.8895H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 88.2721H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 93.0372H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 95.4197H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 97.8021H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 100.185H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 104.95H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 107.332H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 109.712H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 112.094H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 116.859H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 119.242H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 121.624H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 124.007H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 128.772H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 131.154H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 133.537H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.4341 135.919H93.2935"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0762 78.7421H94.6554"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0762 90.6547H94.6554"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0762 102.567H94.6554"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0762 114.477H94.6554"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0762 126.389H94.6554"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M48.4797 75.8702V126.438"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.66 82.7776V103.296"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M48.4797 66.2795V71.6431"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M70.855 56.5157H96.9409"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.4448 37.7595H74.064"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M72.1099 36.3344H78.2454"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M86.9092 37.6561H96.3632"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M44.9998 67.7352H106.34"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M82.6763 18.3347C82.7948 20.2158 83.0531 22.2853 83.591 23.6376C84.8065 26.6887 86.4931 29.2748 88.3864 31.1133"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M82.333 11.9471C82.4941 12.5944 82.5822 13.272 82.5822 13.968C82.5822 14.1442 82.564 14.9799 82.5883 16.1195"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M63.4031 30.4234L63.397 30.4173C65.2902 28.5788 66.7914 25.9107 68.1924 22.9417"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.8975 137.688C97.4239 140.982 93.4824 143.115 89.0426 143.115H85.7241"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.608 129.549C102.608 131.421 102.228 133.208 101.542 134.831"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M96.5425 60.8369C100.147 61.9764 102.76 65.3496 102.76 69.3306V73.9771"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M83.8975 60.4235H91.7257"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M62.4338 143.504C57.2708 143.504 52.761 140.699 50.3481 136.53"
        stroke="#010101"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
