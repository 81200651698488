import { FieldError } from 'react-hook-form';

function InputErrorMessage({ error }: { error: FieldError }) {
  return (
    <p
      className="mt-2 text-left text-sm text-red-800"
      id={`${error.ref}-error`}
    >
      {error.message}
    </p>
  );
}

export default InputErrorMessage;
