import {
  AnnotationIcon,
  ChatAlt2Icon,
  DocumentSearchIcon
} from '@heroicons/react/solid';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Title } from 'components';
import { Card } from 'components/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getMemberQuestionAnswer } from 'services/answer';
import {
  getMemberQuestion,
  getMemberQuestionAdditionalRequest
} from 'services/question';

import RateAnswer from 'features/rating/RateAnswer';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { formatDateToIntl } from 'utils/date';

export interface TimelineEvent {
  id: number;
  content: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  date: string;
}

interface MemberQuestionTimelineProps {
  questionId: any;
}

const MemberQuestionTimeline = ({
  questionId
}: MemberQuestionTimelineProps) => {
  const { t } = useTranslation('customer');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: question,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['member-question', questionId],
    queryFn: () => getMemberQuestion(questionId)
  });

  const answerId = !!question?.answer_id;
  const additionalRequestId = !!question?.additional_request_id;

  const [
    { data: answer, isLoading: isAnswerLoading },
    { data: additionalRequest, isLoading: isAdditionalRequestLoading }
  ] = useQueries({
    queries: [
      {
        queryKey: ['member-question-answer', question?.answer_id],
        queryFn: () => getMemberQuestionAnswer(question?.answer_id as number),
        enabled: answerId
      },
      {
        queryKey: [
          'member-question-additional-request',
          question?.additional_request_id
        ],
        queryFn: () =>
          getMemberQuestionAdditionalRequest(
            question?.additional_request_id as string
          ),
        enabled: additionalRequestId
      }
    ]
  });

  if (!question || isLoading || isAnswerLoading || isAdditionalRequestLoading)
    return <div />;

  const timelineEvents: TimelineEvent[] = [
    {
      id: 1,
      content: t('account.question.timeline.asked'),
      icon: AnnotationIcon,
      date: formatDateToIntl(new Date(question.created_at), 'short')
    }
  ];

  if (question.assigned_at) {
    timelineEvents.push({
      id: 2,
      content: t('account.question.timeline.waiting'),
      icon: DocumentSearchIcon,
      date: formatDateToIntl(new Date(question.assigned_at), 'short')
    });
  }
  if (answer) {
    timelineEvents.push({
      id: 3,
      content: t('account.question.timeline.answered'),
      icon: ChatAlt2Icon,
      date: formatDateToIntl(new Date(answer.published_at), 'short')
    });
  }
  if (additionalRequest?.created_at) {
    timelineEvents.push({
      id: 3,
      content: t('account.questions.status.additional_request_in_progress'),
      icon: ChatAlt2Icon,
      date: formatDateToIntl(new Date(additionalRequest.created_at), 'short')
    });
  }

  if (additionalRequest?.answered_at) {
    timelineEvents.push({
      id: 3,
      content: t('account.questions.status.additional_request_answered'),
      icon: ChatAlt2Icon,
      date: formatDateToIntl(new Date(additionalRequest.answered_at), 'short')
    });
  }

  const hasActions = question.can_ask_consultation || question.can_rate_answer;

  return (
    <Card compact>
      <Title variant="h3">{t('account.question.timeline.title')}</Title>
      <div className="mt-6 flow-root">
        <ul className="flex flex-col gap-4">
          {timelineEvents.map((item) => (
            <li key={item.id}>
              <div className="flex space-x-3 items-center">
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-purple-800 ring-8 ring-white">
                  <item.icon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </span>
                <div className="flex flex-1 justify-between items-center">
                  <p className="text-md">{item.content}</p>
                  <div className="whitespace-nowrap text-right text-sm font-medium text-gray-700">
                    {item.date}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {hasActions && (
        <div className="justify-stretch mt-6 flex flex-col">
          {question.can_ask_consultation && (
            <Link
              to={`/account/consultations/booking?lawyerId=${question.lawyer_id}&questionId=${question.id}`}
            >
              <Button
                variant="tertiary"
                size="medium"
                fullWidth
                label={t('account.question.consultation.cta')}
              />
            </Link>
          )}
          {question.can_rate_answer && (
            <>
              <Button
                variant="outline"
                size="medium"
                fullWidth
                onClick={() => setIsModalOpen(true)}
                label={t('account.question.voteCta')}
              />
              <LayoutModal
                isModalOpen={isModalOpen}
                handleModalClose={() => {
                  setIsModalOpen(false);
                  refetch();
                }}
              >
                <RateAnswer
                  questionId={Number(question.id)}
                  answerId={question.answer_id}
                />
              </LayoutModal>
            </>
          )}
        </div>
      )}
    </Card>
  );
};

export default MemberQuestionTimeline;
