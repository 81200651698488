import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { FieldError, UseFormRegister } from 'react-hook-form';

interface CheckboxInputGroupProps {
  name: string;
  label: string | JSX.Element;
  error: FieldError | undefined;
  register: UseFormRegister<any>;
  disabled?: boolean;
}

export default function CheckboxInputGroup({
  name,
  label,
  register,
  disabled = false,
  error
}: CheckboxInputGroupProps) {
  return (
    <div>
      <div id="checkbox-input-group" className="flex flex-row">
        <input
          disabled={disabled}
          {...register(name)}
          type="checkbox"
          id={name}
          className={clsx(
            'rounded text-black align-middle focus:ring-0',
            error ? 'border-red-800' : 'border-gray-600'
          )}
        />
        <label htmlFor={name} className="ml-2 -mt-1.5 text-gray-900 text-left">
          {label}
        </label>
      </div>
      {error && (
        <div className="mt-2">
          <CheckboxErrorMessage error={error} />
        </div>
      )}
    </div>
  );
}

function CheckboxErrorMessage({ error }: { error: FieldError }) {
  return (
    <div className="flex">
      <div className="flex-shrink-0 align-middle">
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-800"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3 w-full align-middle">
        <p className="text-left text-sm text-red-800" id={`${error.ref}-error`}>
          {error.message}
        </p>
      </div>
    </div>
  );
}
