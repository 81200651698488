import { Account, ApiAccount } from 'types/account';

export function formatAccountFromApi(data: ApiAccount): Account {
  if (data.lawyer) {
    return {
      id: data.id,
      roles: data.roles,
      lawyerId: data.lawyer.id,
      lawyer: data.lawyer
    };
  }

  return { id: data.id, roles: data.roles, lawyerId: null, lawyer: null };
}
