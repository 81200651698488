import { ErrorBoundary } from '@sentry/react';
import { Section, Title } from 'components';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';
import { AskQuestion } from 'features/questions';
import { LaborLawSubscriptionWarning } from 'features/subscription';

const AskQuestionPage = () => {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Fade>
        <LaborLawSubscriptionWarning />
        <Section>
          <Title text={t('account.ask.title')} component="h1" variant="h2" />
          <ErrorBoundary fallback={<GenericErrorCard />}>
            <AskQuestion />
          </ErrorBoundary>
        </Section>
      </Fade>
    </PageLayout>
  );
};

export default AskQuestionPage;
