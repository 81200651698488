import { ErrorBoundary } from '@sentry/react';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { useLocation, useParams } from 'react-router-dom';

import { PageLayout } from 'features/customer/account/content';
import MemberQuestion from 'features/questions/MemberQuestion';

const MemberQuestionPage = () => {
  const questionId = useParams().questionId as string;
  const { state = {} } = useLocation();
  const { previousPage = 1 } = state || {};

  return (
    <PageLayout previousHref={`/account/questions?page=${previousPage}`}>
      <ErrorBoundary fallback={<GenericErrorCard />}>
        <MemberQuestion questionId={Number(questionId)} />
      </ErrorBoundary>
    </PageLayout>
  );
};

export default MemberQuestionPage;
