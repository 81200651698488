import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { useLawyer } from 'hooks';

import { useAuth } from 'context';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { Button } from 'features/shared/buttons';
import { AvatarIcon, CheckIcon, CrossIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { Answer } from 'types/answer';

interface AnswerCardProps {
  handleCancelProposal(): void;
  answer: Answer;
}

export default function AnswerCard({
  handleCancelProposal,
  answer
}: AnswerCardProps) {
  const { t } = useTranslation('lawyer');
  const { lawyerId } = useAuth();
  const { firstName, lastName } = useLawyer({ lawyerId: lawyerId as number });

  const hasFullName = firstName && lastName;

  const getFormattedDate = (publishedDate: Date) => {
    const day = formatDateToIntl(publishedDate);
    const time = getDateAsHhMm(publishedDate);
    return `${day} ${t('general.at')} ${time}`;
  };

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow">
      <div className="pb-7 border-gray-500">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-400">
              <AvatarIcon />
            </span>
            <Title variant="h3">
              {hasFullName
                ? `${firstName} ${lastName}`
                : t('general.ekieClient')}
            </Title>
          </div>
          <span className="text-gray-700 text-sm">
            {getFormattedDate(answer.publishedDate || answer.createdDate)}
          </span>
        </div>
      </div>

      {answer.formalNotice ? (
        <div>
          <p className="font-sans mb-4 text-gray-700 text-[10px] uppercase">
            {t('account.lawyer.question.answer.formal_notice')}
          </p>

          <div className="pl-6 border-l border-gray-500">
            <SanitizedContent content={answer.content} />
            {answer.formalNotice.status === 'pending' ? (
              <Button
                onClick={handleCancelProposal}
                submit
                variant="custom"
                size="large"
                label={t('account.lawyer.question.answer.proposal.cancel')}
                customClassNames="bg-transparent text-black border border-gray-700 hover:bg-black hover:text-white mt-6"
              />
            ) : (
              <div className="bg-transparent w-fit text-black border border-gray-700 mt-6 px-4 text-base sm:py-3 flex items-center rounded font-medium py-2 opacity-60 cursor-not-allowed">
                {answer.formalNotice.status === 'accepted' ? (
                  <CheckIcon className="h-4 w-4 stroke-black fill-black mr-2" />
                ) : (
                  <CrossIcon className="h-4 w-4 stroke-black mr-2" />
                )}
                <p>
                  {answer.formalNotice.status === 'accepted'
                    ? t('account.lawyer.question.answer.proposal.accepted')
                    : t('account.lawyer.question.answer.proposal.refused')}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="pt-6 border-t border-gray-500">
          <SanitizedContent content={answer.content} />
        </div>
      )}
    </div>
  );
}
