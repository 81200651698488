import { useMutation, useQuery } from 'react-query';

import { Answer } from 'types/answer';
import { Lawyer } from 'types/lawyer';
import { Question } from 'types/question';

import {
  cancelFormalNoticeProposal,
  createAnswer,
  createAnswerDraft,
  getAnswers
} from './api';

// QUERIES
export function useAnswersQuery({ lawyerId }: { lawyerId: Lawyer['id'] }) {
  return useQuery(['answers'], () => getAnswers(lawyerId));
}

// MUTATIONS
export function useCreateAnswerMutation() {
  return useMutation(
    ({
      answerId,
      content,
      formalNoticeProposal
    }: {
      answerId: Answer['id'];
      content: string;
      formalNoticeProposal: boolean;
    }) => createAnswer(answerId, content, formalNoticeProposal)
  );
}

export function useCreateDraftMutation() {
  return useMutation(
    ({
      lawyerId,
      questionId,
      content,
      formalNoticeProposal,
      answerId
    }: {
      lawyerId: Lawyer['id'];
      questionId: Question['id'];
      content: string;
      formalNoticeProposal: boolean;
      answerId?: number;
    }) =>
      createAnswerDraft(
        lawyerId,
        questionId,
        content,
        formalNoticeProposal,
        answerId
      )
  );
}

export function useCancelFormalNoticeProposalMutation() {
  return useMutation(
    ({
      relatedAnswerId,
      formalNoticeId
    }: {
      relatedAnswerId: number;
      formalNoticeId: string;
    }) => cancelFormalNoticeProposal(relatedAnswerId, formalNoticeId)
  );
}
