import { privateAxios } from 'context/auth/auth.utils';

import { ResourceVote } from '../../../types/resource-vote';

export async function voteResource(
  resourceType: string,
  resourceId: string,
  vote: boolean
): Promise<void> {
  const res = await privateAxios.post(`/resource-votes`, {
    resourceType,
    resourceId,
    voteType: vote,
    voteComment: null
  });

  return res.data;
}
export async function getResource(
  resourceId: string
): Promise<ResourceVote | null> {
  const res = await privateAxios.get(`/resource-votes/${resourceId}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return res.data;
}
