import { useQuery } from 'react-query';
import { graphQLRequest } from 'services/dato';

import { AllTemplateCommunicationB2Bs, TemplateB2B } from 'types/cms';

const GET_TEMPLATE_COMMUNICATION_B2B_BY_SLUG = `
  query TemplateCommunicationB2bBySlug($slug: String!) {
    templateCommunicationB2b(filter: { slug: { eq: $slug } }) {
      id
      illustration  
      heading
      image {
        responsiveImage {
          src
          width
          height
        }
      }
      titleKeyFigures
      keyFigure {
        content
        heading
        value
      }
      content
      titleFooter
      descriptionFooter
      ctaFooter
      ctaUrl
    }
  }
`;

const GET_LAST_TEMPLATE_COMMUNICATION_B2B = `{
  allTemplateCommunicationB2bs(first: 2, filter: { displayOnHome: { eq: true } }) {
    id
    titre
    heading
    slug
    image {
      responsiveImage(imgixParams: { fm: webp, fit: crop, q: 60, w: 600, h: 400 }) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      } 
    }
  }
}`;

export function useTemplateB2B({ slug }: { slug: string }) {
  return useQuery(
    [`content-b2b-${slug}`],
    (): Promise<TemplateB2B | null> =>
      graphQLRequest(GET_TEMPLATE_COMMUNICATION_B2B_BY_SLUG, {
        slug
      })
  );
}

export function useLastTemplateB2B() {
  return useQuery(
    [`content-b2b`],
    (): Promise<AllTemplateCommunicationB2Bs | null> =>
      graphQLRequest(GET_LAST_TEMPLATE_COMMUNICATION_B2B, {})
  );
}
