import { apiPublicPythonClient } from 'services/axios';

export async function signUp(
  email: string,
  code: string | null
): Promise<void> {
  await apiPublicPythonClient.post(`/auth/sign-up`, {
    email,
    code
  });
}
