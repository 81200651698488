import { SVGProps } from 'react';

export default function AutomobileIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.0779 114.912C36.0454 114.912 42.5043 108.453 42.5043 100.485C42.5043 92.5178 36.0454 86.0588 28.0779 86.0588C20.1103 86.0588 13.6514 92.5178 13.6514 100.485C13.6514 108.453 20.1103 114.912 28.0779 114.912Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.0778 111.406C34.1093 111.406 38.9988 106.517 38.9988 100.485C38.9988 94.4539 34.1093 89.5644 28.0778 89.5644C22.0464 89.5644 17.1569 94.4539 17.1569 100.485C17.1569 106.517 22.0464 111.406 28.0778 111.406Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.0779 101.901C28.8598 101.901 29.4936 101.267 29.4936 100.485C29.4936 99.7035 28.8598 99.0697 28.0779 99.0697C27.2961 99.0697 26.6622 99.7035 26.6622 100.485C26.6622 101.267 27.2961 101.901 28.0779 101.901Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.5498 103.182C28.415 102.777 27.8757 102.845 27.7408 103.249L26.8645 106.081L25.7858 109.654C25.7184 109.856 25.8533 110.125 26.0555 110.125C26.6622 110.26 27.3364 110.328 27.9431 110.328C28.9543 110.328 29.9655 110.193 30.9093 109.923C31.1115 109.856 31.2463 109.586 31.1789 109.384L29.7632 105.946L28.5498 103.182Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M30.3699 102.103C29.9654 101.968 29.6284 102.373 29.8306 102.71L31.2463 105.339L32.999 108.642C33.1339 108.845 33.3361 108.912 33.5383 108.777C34.0776 108.44 34.5495 108.036 35.0214 107.564C35.763 106.822 36.3697 106.013 36.8416 105.204C36.9764 105.002 36.8416 104.732 36.6394 104.665L33.2013 103.249L30.3699 102.103Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M30.8418 100.013C30.4373 100.148 30.5048 100.688 30.9092 100.822L33.7406 101.699L37.3135 102.777C37.5158 102.845 37.7854 102.71 37.7854 102.508C37.9202 101.901 37.9877 101.227 37.9877 100.62C37.9877 99.6089 37.8528 98.5977 37.5832 97.654C37.5158 97.4517 37.2461 97.3169 37.0439 97.3843L33.6058 98.8L30.8418 100.013Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M29.6958 98.2607C29.561 98.6652 29.9655 99.0023 30.3025 98.8L32.9316 97.3843L36.2349 95.6316C36.4371 95.4968 36.5046 95.2945 36.3697 95.0923C36.0327 94.553 35.6282 94.0811 35.1563 93.6092C34.4147 92.8676 33.6058 92.2609 32.7968 91.789C32.5946 91.6542 32.3249 91.789 32.2575 91.9913L30.8418 95.4293L29.6958 98.2607Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M27.606 97.7889C27.7408 98.1934 28.2801 98.126 28.415 97.7215L29.2913 94.8901L30.37 91.3172C30.4374 91.115 30.3026 90.8453 30.1003 90.8453C29.4936 90.7105 28.8195 90.6431 28.2127 90.6431C27.2015 90.6431 26.1903 90.7779 25.2465 91.0475C25.0443 91.115 24.9095 91.3846 24.9769 91.5869L26.3926 95.0249L27.606 97.7889Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.7857 98.9348C26.1902 99.0696 26.5273 98.6651 26.325 98.3281L24.9767 95.6989L23.224 92.3283C23.0892 92.126 22.8869 92.0586 22.6847 92.1934C22.1454 92.5305 21.6735 92.935 21.2016 93.4069C20.46 94.1484 19.8533 94.9574 19.3814 95.7663C19.2466 95.9686 19.3814 96.2382 19.5837 96.3057L23.0217 97.7213L25.7857 98.9348Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.3813 101.025C25.7858 100.89 25.7184 100.351 25.3139 100.216L22.4825 99.3393L18.9096 98.2607C18.7074 98.1933 18.4377 98.3281 18.4377 98.5304C18.3029 99.1371 18.2355 99.8112 18.2355 100.418C18.2355 101.429 18.3703 102.44 18.64 103.384C18.7074 103.586 18.977 103.721 19.1793 103.654L22.6174 102.238L25.3813 101.025Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M26.5273 102.777C26.6621 102.373 26.2576 102.036 25.9206 102.238L23.2914 103.654L19.9882 105.407C19.7859 105.541 19.7185 105.744 19.8533 105.946C20.1904 106.485 20.5949 106.957 21.0668 107.429C21.8083 108.171 22.6173 108.777 23.4263 109.249C23.6285 109.384 23.8982 109.249 23.9656 109.047L25.3813 105.609L26.5273 102.777Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.266 114.912C131.233 114.912 137.692 108.453 137.692 100.485C137.692 92.5178 131.233 86.0588 123.266 86.0588C115.298 86.0588 108.839 92.5178 108.839 100.485C108.839 108.453 115.298 114.912 123.266 114.912Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.266 111.406C129.297 111.406 134.187 106.517 134.187 100.485C134.187 94.4538 129.297 89.5643 123.266 89.5643C117.234 89.5643 112.345 94.4538 112.345 100.485C112.345 106.517 117.234 111.406 123.266 111.406Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.266 101.901C124.048 101.901 124.681 101.267 124.681 100.485C124.681 99.7035 124.048 99.0697 123.266 99.0697C122.484 99.0697 121.85 99.7035 121.85 100.485C121.85 101.267 122.484 101.901 123.266 101.901Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.737 103.182C123.603 102.777 123.063 102.845 122.928 103.249L122.052 106.081L120.973 109.653C120.906 109.856 121.041 110.125 121.243 110.125C121.85 110.26 122.524 110.328 123.131 110.328C124.142 110.328 125.153 110.193 126.097 109.923C126.299 109.856 126.434 109.586 126.367 109.384L124.951 105.946L123.737 103.182Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M125.558 102.103C125.153 101.968 124.816 102.373 125.018 102.71L126.434 105.339L128.187 108.642C128.322 108.845 128.524 108.912 128.726 108.777C129.266 108.44 129.737 108.036 130.209 107.564C130.951 106.822 131.558 106.013 132.029 105.204C132.164 105.002 132.029 104.732 131.827 104.665L128.389 103.249L125.558 102.103Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M126.03 100.013C125.625 100.148 125.693 100.687 126.097 100.822L128.928 101.699L132.501 102.777C132.704 102.845 132.973 102.71 132.973 102.508C133.108 101.901 133.176 101.227 133.176 100.62C133.176 99.6089 133.041 98.5977 132.771 97.6539C132.704 97.4516 132.434 97.3168 132.232 97.3842L128.794 98.7999L126.03 100.013Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M124.884 98.2607C124.749 98.6651 125.153 99.0022 125.49 98.8L128.119 97.3843L131.423 95.6315C131.625 95.4967 131.692 95.2945 131.557 95.0922C131.22 94.5529 130.816 94.081 130.344 93.6091C129.602 92.8676 128.794 92.2608 127.985 91.789C127.782 91.6541 127.513 91.789 127.445 91.9912L126.03 95.4293L124.884 98.2607Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M122.794 97.7888C122.929 98.1933 123.468 98.1259 123.603 97.7214L124.479 94.8901L125.558 91.3171C125.625 91.1149 125.49 90.8452 125.288 90.8452C124.681 90.7104 124.007 90.643 123.4 90.643C122.389 90.643 121.378 90.7778 120.434 91.0475C120.232 91.1149 120.097 91.3846 120.165 91.5868L121.58 95.0249L122.794 97.7888Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M120.974 98.9348C121.378 99.0696 121.715 98.6652 121.513 98.3281L120.097 95.699L118.344 92.3957C118.21 92.1935 118.007 92.1261 117.805 92.2609C117.266 92.5979 116.794 93.0024 116.322 93.4743C115.581 94.2159 114.974 95.0248 114.502 95.8338C114.367 96.036 114.502 96.3057 114.704 96.3731L118.142 97.7888L120.974 98.9348Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M120.569 101.025C120.974 100.89 120.906 100.351 120.502 100.216L117.67 99.3393L114.097 98.2607C113.895 98.1933 113.626 98.3281 113.626 98.5304C113.491 99.1371 113.423 99.8112 113.423 100.418C113.423 101.429 113.558 102.44 113.828 103.384C113.895 103.586 114.165 103.721 114.367 103.654L117.805 102.238L120.569 101.025Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M121.715 102.777C121.85 102.373 121.445 102.036 121.108 102.238L118.479 103.654L115.176 105.407C114.974 105.541 114.906 105.744 115.041 105.946C115.378 106.485 115.783 106.957 116.255 107.429C116.996 108.171 117.805 108.777 118.614 109.249C118.816 109.384 119.086 109.249 119.153 109.047L120.569 105.609L121.715 102.777Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M141.535 88.1487C139.58 85.7218 139.175 85.587 139.377 81.7444C139.58 78.9131 136.883 75.7446 135.535 74.2615C135.063 73.7896 134.591 73.3177 134.052 72.9807C132.771 72.0369 129.94 70.0819 128.457 69.4752C126.502 68.6662 125.895 67.1157 123.131 65.9697C121.176 65.1607 118.547 64.554 118.479 64.554L99.9406 50.869C99.9406 50.869 88.4803 42.6446 85.5815 41.4312C82.6827 40.2177 80.3907 39.4088 76.8852 38.8694C73.3797 38.3301 64.6159 37.9257 61.3801 37.9257C58.1442 37.9257 41.8976 38.1953 35.763 39.0717C29.6283 39.9481 24.9768 40.2851 24.5723 40.8244C24.1678 41.3637 24.1004 41.6334 24.5723 42.0379C24.9768 42.3749 25.6509 42.9143 25.7184 42.9143C25.5835 43.1165 22.2803 47.7006 20.932 49.9253C19.5837 52.2173 16.2131 59.4306 15.5389 61.3856C14.8648 63.2731 14.6625 64.8236 14.5951 64.8911C14.5277 64.9585 12.8424 65.7674 12.9772 66.6438C13.1794 66.9809 14.1232 67.1831 14.1906 67.1831L13.921 75.8795C13.921 76.2165 13.7862 76.4862 13.5839 76.6884L12.1008 78.2389C11.8312 78.5086 11.6963 78.7782 11.5615 79.1153C11.0222 80.4636 9.60653 84.5084 9.20205 89.4296C8.66274 95.4968 8.9324 99.9461 9.4717 100.89C9.74136 101.429 10.0784 101.766 10.3481 102.036C10.6851 102.238 11.157 101.968 11.0896 101.564C11.0896 101.227 11.0896 100.89 11.0896 100.553C11.0896 89.9689 20.7298 81.6096 31.7856 83.9017C37.5157 85.1151 43.3807 90.7778 44.6615 96.508C45.2683 99.2719 45.2683 101.901 44.729 104.328C44.5941 104.935 44.9986 105.541 45.6727 105.609L74.7954 106.148H104.727H105.536C106.412 106.148 107.019 105.339 106.817 104.463C106.21 101.968 106.21 99.2719 106.817 96.508C108.232 90.2385 113.154 85.3173 119.423 83.9691C130.546 81.4748 140.389 89.834 140.389 100.553C140.389 101.901 140.254 103.182 139.917 104.463C139.849 104.732 140.052 105.002 140.321 104.935C140.321 104.935 142.141 104.935 142.344 104.193C142.546 103.452 143.557 90.5756 141.535 88.1487Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.617 77.0253C30.9626 77.0253 32.8641 75.1239 32.8641 72.7783C32.8641 70.4327 30.9626 68.5312 28.617 68.5312C26.2715 68.5312 24.37 70.4327 24.37 72.7783C24.37 75.1239 26.2715 77.0253 28.617 77.0253Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.7184 42.9817C25.7184 42.9817 26.9318 42.3749 27.2689 43.7906C25.0442 47.1613 19.4489 57.0037 18.5051 60.1721"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5723 40.8243C24.5723 40.8243 26.2576 40.285 26.9992 40.8243C27.7407 41.3636 27.7407 42.3748 27.6733 42.8467C27.6059 43.3186 27.2688 43.7905 27.2688 43.7905C27.471 42.577 27.2014 41.9029 26.6621 41.5658C25.9879 41.1614 24.3026 41.5658 24.3026 41.5658"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M16.1456 59.9697L23.9656 60.509C24.4375 60.5764 24.9094 60.2394 25.1116 59.8349C26.1228 57.7451 28.9542 51.8801 30.9092 48.9813C33.3361 45.4758 39.1336 38.6671 39.1336 38.6671"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M23.9656 60.5764L25.1116 60.7787C26.325 60.9809 27.4037 61.5876 28.1452 62.5314L54.9084 94.9573C56.1892 96.5078 58.0768 97.519 60.0992 97.7212L95.8958 101.496C97.4463 101.631 98.9968 101.227 100.278 100.283L101.424 99.474"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M118.479 64.4865L110.053 63.4753C110.053 63.4753 92.7273 47.9702 84.2332 44.7344C75.7391 41.4985 76.4132 41.7682 63.6047 41.1615C50.7961 40.5547 48.4366 41.0266 46.0772 43.1839C43.7177 45.3411 40.8189 52.824 40.6167 57.3407C40.4144 61.8574 40.6167 64.6213 43.5829 70.0818C46.5491 75.5423 57.0656 90.3059 60.0992 92.3283C63.1328 94.2833 65.9641 94.8226 69.6045 95.3619C73.2448 95.9012 106.21 100.081 106.21 100.081"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M44.5267 95.9685C44.5267 95.9685 52.8186 96.6426 53.8298 96.7101C54.841 96.7775 55.8522 96.7101 56.1219 96.1707"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M8.86487 94.6877C8.86487 94.6877 9.13452 95.4293 11.8985 95.2945"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.9647 103.182C48.2344 103.182 102.165 105.002 102.165 105.002"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M110.053 63.4753L112.817 65.9696C112.817 65.9696 123.198 66.9808 126.367 67.992"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M128.457 69.4076C128.457 69.4076 126.367 68.3964 126.367 69.2054C126.367 70.0144 129.131 73.3176 130.277 74.3962C131.49 75.4749 136.479 78.8455 138.906 79.1826"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M141.535 88.1487C141.535 88.1487 135.4 79.5197 119.558 79.7894"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M140.321 100.013C140.321 100.013 141.602 100.418 142.681 100.013"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.1422 71.6936C17.2207 69.9083 16.8322 68.4411 16.2743 68.4165C15.7163 68.392 15.2004 69.8193 15.1218 71.6047C15.0432 73.39 15.4318 74.8572 15.9897 74.8817C16.5476 74.9063 17.0636 73.4789 17.1422 71.6936Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M16.2805 68.3965C16.2805 68.3965 22.2129 69.5425 22.1455 71.7672C22.0781 73.9918 16.0109 74.8682 16.0109 74.8682"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6542 64.4384C17.981 62.8331 17.8319 61.4475 17.3211 61.3435C16.8103 61.2395 16.1313 62.4566 15.8045 64.0618C15.4777 65.6671 15.6268 67.0527 16.1376 67.1567C16.6483 67.2607 17.3273 66.0436 17.6542 64.4384Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.3591 61.3181C17.3591 61.3181 22.6173 63.273 22.2128 65.228C21.8084 67.183 16.1456 67.183 16.1456 67.183"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M40.8864 55.3856C40.8864 55.3856 52.3466 56.0598 52.3466 60.0371C52.3466 64.0145 41.2234 64.5539 41.2234 64.5539"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.8301 60.509C41.7627 61.6551 42.8414 62.5314 44.1222 62.3966L49.7175 61.7899C51.5377 61.5876 51.8748 59.2956 50.122 58.6889L46.8187 57.6102C42.4369 56.1271 41.8976 58.2844 41.8301 60.509Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.965 59.0261C41.965 59.0261 47.5603 58.7564 51.2006 60.0373"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.8301 60.5091C41.8301 60.5091 44.7289 60.3069 46.6838 60.5091C48.6388 60.7114 50.7961 61.2507 50.7961 61.2507"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M112.817 66.0371L52.3467 59.7002"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M104.659 58.8911C104.659 58.8911 107.76 71.6323 106.614 80.1938C105.468 88.6879 101.356 99.474 101.356 99.474"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M106.143 83.0926C106.143 83.0926 58.6836 72.1042 49.1108 71.6323"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M107.221 82.4859L107.019 83.4971C106.951 83.9016 107.289 84.2387 107.693 84.1038L110.187 83.2275C110.524 83.0926 110.524 82.6207 110.187 82.5533L108.232 81.9466C107.76 81.7444 107.289 82.014 107.221 82.4859Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M98.9968 56.1946C99.2664 56.1272 99.6709 56.0598 100.143 56.1272C100.682 56.1946 101.356 56.4642 101.896 57.0035C102.907 58.0147 104.053 59.7675 103.648 61.318C103.244 62.9359 101.896 63.5427 100.817 63.6101C99.7383 63.6775 97.7833 63.273 97.244 62.9359C96.7047 62.5989 96.5025 62.2618 96.5699 61.5877C96.6373 60.9135 96.9069 56.8013 98.9968 56.1946Z"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M98.2552 53.7003L98.3901 56.5317"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M52.0095 58.8911L98.9293 63.4752"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.6949 40.8917L54.5713 59.1608"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M55.5825 40.8917L56.5937 59.363"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8069 57.4754L74.1212 45.0714"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M69.8741 53.2284L76.9526 44.8018"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7059 58.6214L67.7843 50.1273"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M65.4248 51.3409L67.7169 48.577"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M73.0426 56.7339L75.3347 54.0374"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M79.7166 60.3069L86.7276 51.8802"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M83.222 58.6214L90.233 50.1273"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M84.1659 60.4417L86.458 57.6778"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.83 48.6443L50.122 45.9478"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M45.066 54.3071L52.1445 45.8804"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M48.5042 55.2509L50.7962 52.5543"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M96.7721 59.9023C96.7721 59.9023 101.491 59.9697 103.648 61.3854"
        stroke="#1D1D1D"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
