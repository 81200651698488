import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { AskQuestionSkeleton } from 'components/skeleton';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';
import { askMemberQuestion } from 'services/question';

import Toast from 'features/shared/toasts/Toast';

import MemberQuestionForm, {
  QuestionFormInput
} from './components/MemberQuestionForm';

const AskQuestion = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const {
    data: member,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (formData: QuestionFormInput) =>
      askMemberQuestion({
        source: formData.source,
        content: formData.question
      }),
    onError: (error: any) => {
      const message =
        error?.response?.status === 403
          ? 'account.ask.error.cannotAskQuestion'
          : 'account.ask.error';

      toast.custom(
        <Toast type="error" title={t('general.error')} message={t(message)} />,
        { position: 'top-right', duration: 3000 }
      );
    },
    onSuccess: (memberQuestion) => {
      navigate(`/questions/${memberQuestion.id}`);
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info']
      });
      toast.custom(
        <Toast
          type="success"
          title={t('general.success')}
          message={t('account.ask.success')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
    }
  });

  const handleQuestionFormSubmit = (data: QuestionFormInput) => {
    mutate(data);
  };

  if (isLoading) return <AskQuestionSkeleton />;
  if (!isSuccess) return <GenericErrorCard />;
  if (!member.can_ask_question) {
    return (
      <div className="mt-4">
        <p>{t('account.ask.error.cannotAskQuestion')}</p>
      </div>
    );
  }

  return (
    <MemberQuestionForm
      canSelectSource={member.is_active_cse_member}
      isLoading={isPending}
      onSubmit={handleQuestionFormSubmit}
    />
  );
};

export default AskQuestion;
