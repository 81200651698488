import { find, sortBy } from 'lodash';

import { Answer } from 'types/answer';
import {
  ApiConsultation,
  Consultation,
  ConsultationStatus,
  PaginationConsultation
} from 'types/consultation';
import { Lawyer } from 'types/lawyer';

export function formatConsultationFromApi(
  apiConsultation: ApiConsultation,
  lawyerId: Lawyer['id']
): Consultation {
  const isConsultationCompleted =
    apiConsultation.status === ConsultationStatus.COMPLETED;

  // TODO - logic to do on the back
  const latestAppointment = apiConsultation.appointments.length
    ? sortBy(apiConsultation.appointments, 'appointmentDate').reverse()[0]
    : null;

  // TODO - logic to do on the back
  const isConsultationVoted = isConsultationCompleted
    ? !!apiConsultation.appointments[0].vote
    : false;

  // TODO - logic to do on the back
  const linkedAnswerId =
    (
      find(apiConsultation.linkedQuestion?.answers, {
        lawyer: { id: lawyerId }
      }) as Answer
    )?.id ?? null;

  return {
    latestAppointment,
    isConsultationCompleted,
    isConsultationVoted,
    linkedAnswerId,
    ...apiConsultation
  };
}

export function formatJsonLdConsultationFromApi(dataLd: any) {
  const consultations = dataLd['hydra:member'];

  const pagination: PaginationConsultation | any = {
    first: 0,
    last: 0,
    next: 0,
    totalItems: dataLd['hydra:totalItems']
  };

  // pagination
  ['first', 'last', 'next'].forEach((item: string) => {
    const urlParams = new URLSearchParams(
      dataLd['hydra:view'][`hydra:${item}`]
    );

    pagination[item] = Number(urlParams.get('page'));
  });

  return {
    consultations,
    pagination
  };
}
