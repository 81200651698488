import { StarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { formatDateToIntl } from 'utils/date';

import { Answer } from 'types/answer';

import AnsweredDateIndicator from './AnsweredDateIndicator';

export default function QuestionMiniature({ answer }: { answer: Answer }) {
  const { answerId } = useParams();

  const isSelected = answerId === answer.id.toString();

  const question = answer.question!;
  const isRated = Boolean(answer.answerVotes.length);

  return (
    <div
      className={classNames(
        'pt-6 pl-8 pr-6 relative',
        isSelected
          ? 'bg-purple-100 border-l-4 border-purple-900'
          : 'border-l-4 border-white bg-white'
      )}
    >
      <div>
        <div className="flex justify-end pb-2">
          <span className="text-gray-700  text-sm flex-shrink-0">
            {formatDateToIntl(question.createdDate)}
          </span>
        </div>
        <div>
          <div className="pb-2">
            <span className="font-medium break-words line-clamp-1">
              {question.title}
            </span>
          </div>
          <div className="pb-4">
            <span className="text-gray-700 text-sm break-words line-clamp-2">
              {question.content}
            </span>
          </div>
          <AnsweredDateIndicator
            answerCreatedDate={answer.publishedDate || answer.createdDate}
          />
          {isRated && (
            <Trans
              parent="div"
              ns="lawyer"
              className="flex items-center text-sm font-semibold mt-1"
              i18nKey="account.questions.rating.label"
              components={{
                Icon: (
                  <StarIcon
                    className="w-4 h-4 mr-2"
                    strokeWidth={1}
                    stroke="transparent"
                    fill="#FF993A"
                  />
                )
              }}
              values={{ value: answer.answerVotes[0].note }}
            />
          )}
        </div>
        <div className="px-4 pt-6">
          <div className="border-b border-gray-500" />
        </div>
      </div>
    </div>
  );
}
