import { useQuery } from 'react-query';

import { Lawyer } from 'types/lawyer';

import { getLawyerAvailabiltySlots } from './api';

export function useLawyerAvailabilitySlots({
  lawyerId,
  enabled
}: {
  lawyerId: Lawyer['id'];
  enabled: boolean;
}) {
  return useQuery(
    ['lawyer-availability-slots', lawyerId],
    () => getLawyerAvailabiltySlots(lawyerId),
    {
      enabled
    }
  );
}
