import { Section, Title } from 'components';
import HomeFooter from 'components/HomeFooter';
import { ThematicList } from 'containers/thematic';
import { useTranslation } from 'react-i18next';

import { useCustomer } from 'hooks';

import { PageLayout } from 'features/customer/account/content';

const GuaranteesPage = () => {
  const { t } = useTranslation('customer');
  const { id: customerId } = useCustomer();

  return (
    <PageLayout>
      <Section color="white" gutterBottom>
        <Title gutterBottom variant="h1">
          {t('account.guarantees.title')}
        </Title>
        <ThematicList customerId={customerId} />
      </Section>
      <Section color="white">
        <HomeFooter />
      </Section>
    </PageLayout>
  );
};

export default GuaranteesPage;
