import { Tag, Title } from 'components';
import { PropsWithChildren } from 'react';

import { Paragraph } from 'features/shared/body';

import { formatDateToIntl } from 'utils/date';

import { DatoImage } from 'types/cms';

import { Card, CardContent, CardImage } from './card';

interface CardProps {
  className?: string;
  image: DatoImage['responsiveImage'];
  imgHeight?: string;
  title: string;
  text?: string;
  tags?: string[];
  publishedAt?: string;
}

const FullCard = ({
  title,
  className,
  text,
  image,
  publishedAt,
  tags,
  imgHeight = 'h-2/3'
}: PropsWithChildren<CardProps>) => (
  <Card compact className={className} transparent>
    <CardImage imageAttributes={image} className={imgHeight} />
    <CardContent>
      {tags &&
        tags.length > 0 &&
        tags.map((tag) => <Tag variant="none" text={tag} className="mb-1" />)}
      <Title className="md:line-clamp-2 text-gray-800 text-base" variant="h3">
        {title}
      </Title>
      {text && (
        <Paragraph className="!text-black line-clamp-1 mt-2" text={text} />
      )}
    </CardContent>
    {publishedAt && (
      <CardContent className="text-sm font-medium -mt-1.5 text-gray-700">
        {publishedAt}
      </CardContent>
    )}
  </Card>
);

export default FullCard;
