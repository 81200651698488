import { useMutation, useQuery } from 'react-query';

import { Lawyer } from 'types/lawyer';
import { QuestionAssignation } from 'types/question-assignation';

import { acceptQuestionAssignation, getQuestionAssignations } from './api';

export function useQuestionAssignationsQuery({
  lawyerId
}: {
  lawyerId: Lawyer['id'];
}) {
  return useQuery(['question-assignations'], () =>
    getQuestionAssignations(lawyerId)
  );
}

// MUTATIONS
export function useAcceptQuestionAssignationMutation() {
  return useMutation(
    ({
      questionAssignationId,
      lawyerId
    }: {
      questionAssignationId: QuestionAssignation['id'];
      lawyerId: Lawyer['id'];
    }) => acceptQuestionAssignation(questionAssignationId, lawyerId)
  );
}
