import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { BnpParisbasIcon, LockIcon, StripeIcon } from 'features/shared/icons';
import { LoadingSpinner } from 'features/shared/loading';
import { Toast } from 'features/shared/toasts';

import { useOptions } from './payment.constants';

export enum FormType {
  Card = 'cardForm',
  Element = 'paymentElement'
}

export enum PaymentType {
  Subscription = 'subscription',
  OneTime = 'oneTime'
}

interface StripeFormProps {
  paymentType: PaymentType;
  stripePromise: Promise<Stripe | null>;
  intentClientSecret: string;
  handlePaymentSuccess: () => void;
}

export default function StripeForm({
  paymentType,
  stripePromise,
  intentClientSecret,
  handlePaymentSuccess
}: StripeFormProps) {
  if (!intentClientSecret.length) {
    return <LoadingSpinner purple large />;
  }

  return (
    <Elements stripe={stripePromise}>
      <CardForm
        paymentType={paymentType}
        handlePaymentSuccess={handlePaymentSuccess}
        clientSecret={intentClientSecret}
      />
    </Elements>
  );
}

interface CardFormProps {
  paymentType: StripeFormProps['paymentType'];
  clientSecret: string;
  handlePaymentSuccess: () => void;
}

function CardForm({
  paymentType,
  clientSecret,
  handlePaymentSuccess
}: CardFormProps) {
  const { t } = useTranslation('customer');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const { error: createPaymentMethodError } =
      await stripe.createPaymentMethod({
        type: 'card',
        card
      });

    if (createPaymentMethodError) {
      throw Error('Payment Method error.');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (clientSecret.startsWith('pi_')) {
        // if no trial - storage card + payment
        const { paymentIntent, error: confirmCardPaymentError } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card
            }
          });

        if (confirmCardPaymentError) {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('funnel.payment.error')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        } else if (paymentIntent?.status === 'succeeded') {
          handlePaymentSuccess();
        }
      } else if (clientSecret.startsWith('seti_')) {
        // if trial - storage card only
        const { setupIntent, error: confirmCardSetupError } =
          await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card
            }
          });

        if (confirmCardSetupError) {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('funnel.payment.error')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        } else if (setupIntent?.status === 'succeeded') {
          handlePaymentSuccess();
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        <div>
          <div className="pb-4">
            <label className="input-label">
              {t('card.number')}
              <CardNumberElement
                options={{ ...options, showIcon: true }}
                className="input-text selection:bg-white"
              />
            </label>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex-1">
              <label className="input-label">
                {t('card.expirationDate')}
                <CardExpiryElement
                  options={options}
                  className="input-text bg-white"
                />
              </label>
            </div>
            <div className="flex-1">
              <label className="input-label">
                {t('card.cvc')}
                <CardCvcElement
                  className="input-text bg-white"
                  options={options}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <div className="flex gap-1">
            <LockIcon />
            <span>{t('securedby')}</span>
          </div>
          <div className="flex gap-1">
            <BnpParisbasIcon className="w-24" />
            <StripeIcon className="w-12 h-12 text-black" />
          </div>
        </div>
        <div className="text-center">
          <div className="mb-2">
            <Button
              submit
              variant="primary"
              label={t('general.pay')}
              size="large"
              fullWidth
              isLoading={isLoading}
              disabled={!stripe || !elements}
            />
          </div>
          <span className="text-gray-700">
            {t('payment.cgu.confirm', { ctaLabel: t('general.pay') })}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="/cgu.pdf"
              className="underline"
            >
              {t('payment.cgu')}
            </a>
          </span>
        </div>
      </div>
    </form>
  );
}
