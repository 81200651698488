import { SVGProps } from 'react';

export default function TripIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="152" height="152" fill="white" />
      <path
        d="M127.54 18L127.53 25.01"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M135.99 22.26L134.03 26.54"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M142.11 28.19L139.31 30.63"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M117.25 22.26L119.21 26.54"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M111.12 28.19L113.92 30.63"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M62.95 117.72H9.93997C8.43997 117.72 7.21997 116.5 7.21997 115V43.5C7.21997 42 8.43997 40.78 9.93997 40.78H141.84C143.34 40.78 144.56 42 144.56 43.5V114.99C144.56 116.49 143.34 117.71 141.84 117.71H87.32"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.14 105.55C84.446 105.55 91.99 98.006 91.99 88.7C91.99 79.394 84.446 71.85 75.14 71.85C65.834 71.85 58.29 79.394 58.29 88.7C58.29 98.006 65.834 105.55 75.14 105.55Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.1401 101.99C82.48 101.99 88.4301 96.0398 88.4301 88.7C88.4301 81.3601 82.48 75.41 75.1401 75.41C67.8002 75.41 61.8501 81.3601 61.8501 88.7C61.8501 96.0398 67.8002 101.99 75.1401 101.99Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.1401 117.03C90.7863 117.03 103.47 104.346 103.47 88.7C103.47 73.0538 90.7863 60.37 75.1401 60.37C59.4938 60.37 46.8101 73.0538 46.8101 88.7C46.8101 104.346 59.4938 117.03 75.1401 117.03Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.1399 120.16C92.5148 120.16 106.6 106.075 106.6 88.7C106.6 71.3251 92.5148 57.24 75.1399 57.24C57.765 57.24 43.6799 71.3251 43.6799 88.7C43.6799 106.075 57.765 120.16 75.1399 120.16Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.1401 118.76C91.7418 118.76 105.2 105.302 105.2 88.7C105.2 72.0983 91.7418 58.64 75.1401 58.64C58.5384 58.64 45.0801 72.0983 45.0801 88.7C45.0801 105.302 58.5384 118.76 75.1401 118.76Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M92.9299 62.75H144.57"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.21997 62.75H57.35"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M92.4299 114.99H144.57"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.21997 114.99H57.85"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M83.0301 58.24H98.8201V43.56H76.2901L66.6301 58.24"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M113.26 81.77C115.447 81.77 117.22 79.997 117.22 77.81C117.22 75.6229 115.447 73.85 113.26 73.85C111.073 73.85 109.3 75.6229 109.3 77.81C109.3 79.997 111.073 81.77 113.26 81.77Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M113.26 80.88C114.955 80.88 116.33 79.5055 116.33 77.81C116.33 76.1145 114.955 74.74 113.26 74.74C111.564 74.74 110.19 76.1145 110.19 77.81C110.19 79.5055 111.564 80.88 113.26 80.88Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M113.26 79.42C114.149 79.42 114.87 78.6992 114.87 77.81C114.87 76.9208 114.149 76.2 113.26 76.2C112.371 76.2 111.65 76.9208 111.65 77.81C111.65 78.6992 112.371 79.42 113.26 79.42Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M3.93 59.59C3.38 59.31 3 58.74 3 58.08C3 57.42 3.4 56.82 3.97 56.55"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M3.92993 56.58L7.23993 54.63"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.23993 61.54L3.92993 59.59"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M5.12007 59.14C5.69997 59.14 6.17007 58.6699 6.17007 58.09C6.17007 57.5101 5.69997 57.04 5.12007 57.04C4.54017 57.04 4.07007 57.5101 4.07007 58.09C4.07007 58.6699 4.54017 59.14 5.12007 59.14Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M147.87 59.59C148.42 59.31 148.8 58.74 148.8 58.08C148.8 57.4 148.4 56.82 147.83 56.55"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M147.87 56.58L144.57 54.63"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M144.57 61.54L147.87 59.59"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M146.69 59.14C147.27 59.14 147.74 58.6699 147.74 58.09C147.74 57.5101 147.27 57.04 146.69 57.04C146.11 57.04 145.64 57.5101 145.64 58.09C145.64 58.6699 146.11 59.14 146.69 59.14Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.23999 54.63H64.47L73.59 40.78"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.3201 35.81L45.0801 36.68H17.4001C16.8101 36.68 16.3301 37.16 16.3301 37.75V40.78H59.3401V35.51H48.9001C48.3601 35.51 47.8201 35.61 47.3201 35.81V35.81Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.54 36.83V36.44C17.54 35.93 17.95 35.52 18.46 35.52H30.53C31.06 35.52 31.49 35.95 31.49 36.48V36.83"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M21.24 32.64H27.79C28.32 32.64 28.76 33.07 28.76 33.61V35.51H20.28V33.61C20.28 33.08 20.71 32.64 21.25 32.64H21.24Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M116.39 40.78V39.59C116.39 38.95 116.91 38.42 117.56 38.42H137.45C138.09 38.42 138.62 38.94 138.62 39.59V40.78"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M118.03 38.42V33.98C118.03 33.21 118.65 32.59 119.42 32.59H135.59C136.36 32.59 136.98 33.21 136.98 33.98V38.42"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M124.16 32.59V38.42"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M129.16 32.59V38.42"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.7999 69.5C77.6611 69.5 79.1699 67.9912 79.1699 66.13C79.1699 64.2688 77.6611 62.76 75.7999 62.76C73.9387 62.76 72.4299 64.2688 72.4299 66.13C72.4299 67.9912 73.9387 69.5 75.7999 69.5Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.8 67.91C76.7831 67.91 77.58 67.113 77.58 66.13C77.58 65.1469 76.7831 64.35 75.8 64.35C74.817 64.35 74.02 65.1469 74.02 66.13C74.02 67.113 74.817 67.91 75.8 67.91Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M130.99 43.56H105.2V58.25H130.99V43.56Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M127.1 45.24H109.08C108.108 45.24 107.32 46.028 107.32 47V54.8C107.32 55.772 108.108 56.56 109.08 56.56H127.1C128.072 56.56 128.86 55.772 128.86 54.8V47C128.86 46.028 128.072 45.24 127.1 45.24Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M9.59009 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M12.24 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M14.8901 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.54 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M20.1899 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M22.8501 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.5 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1501 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M30.8 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M33.45 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M36.1001 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M38.75 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.4001 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M44.05 93.55V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M44.05 62.75V83.75"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M46.7 101.99V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M46.7 62.75V75.41"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M49.3501 106.73V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M49.3501 62.75V70.68"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M52 110.02V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M52 62.75V67.39"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.6501 112.58V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.6501 62.75V64.83"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M94.4102 113.58V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M94.4102 62.75V63.83"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M97.0601 111.27V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M97.0601 62.75V66.13"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.71 108.36V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.71 62.75V69.05"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.36 104.49V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.36 62.75V72.92"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M105.01 98.41V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M105.01 62.75V78.8"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M107.66 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M110.31 80.31V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M110.31 62.75V75.17"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M112.96 81.77V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M112.96 62.75V73.87"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M115.61 80.99V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M115.61 62.75V74.63"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M118.26 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M120.91 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.56 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M126.21 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M128.86 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M131.51 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M134.16 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M136.81 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M139.46 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M142.11 62.75V114.99"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
