import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { InputErrorMessage } from 'features/shared/forms';

export interface RateAnswerFormProps {
  isLoading: boolean;
  register: UseFormRegister<RateAnswerInput>;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  errors: FieldErrors<RateAnswerInput>;
}

export type RateAnswerInput = {
  note: 0 | 1 | 2 | 3 | 4 | 5;
  comment?: string;
};

const RateAnswerForm = ({
  register,
  onSubmit,
  errors,
  isLoading
}: RateAnswerFormProps) => {
  const { t } = useTranslation('customer');

  return (
    <form onSubmit={onSubmit}>
      <p className="text-md text-left font-medium ">
        {t('account.question.vote.unsatisfied.description')}
      </p>
      {errors.root?.alreadyRate && (
        <p className="mt-2 text-red-800 mb-4">
          {errors.root.alreadyRate.message}
        </p>
      )}
      {errors.root?.invalid && (
        <p className="mt-2 text-red-800 mb-4">{errors.root.invalid.message}</p>
      )}
      <div className="mt-4 w-full">
        <textarea
          rows={3}
          {...register('comment')}
          className="w-full placeholder:text-gray-700 text-sm md:text-base block focus:border-purple-900 focus:drop-shadow-input focus:ring-0 focus:ring-offset-0 focus:shadow-purple-900 focus:outline-none py-3 px-4 rounded"
        />
        {errors.comment && <InputErrorMessage error={errors.comment} />}
      </div>
      <div className="mt-2 flex justify-end">
        <Button
          submit
          isLoading={isLoading}
          variant="primary"
          size="small"
          label={t('general.send')}
        />
      </div>
    </form>
  );
};

export default RateAnswerForm;
