import { useMemo } from 'react';

import { KeyValuePair } from 'types/core';

export default function useCreateHref({
  path,
  params
}: {
  path: string;
  params: KeyValuePair;
}) {
  const searchParams = useMemo(
    () => new URLSearchParams({ ...params }),
    [params]
  );
  return { href: `${path}?${searchParams.toString()}` };
}
