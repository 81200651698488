import { SVGProps } from 'react';

export default function ContactIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="152" height="152" fill="white" />
      <g clipPath="url(#clip0_1343_2016)">
        <path
          d="M127.585 108.459H24.1983C22.9341 108.459 21.9092 109.478 21.9092 110.736V125.726C21.9092 126.984 22.9341 128.003 24.1983 128.003H127.585C128.849 128.003 129.874 126.984 129.874 125.726V110.736C129.874 109.478 128.849 108.459 127.585 108.459Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M127.812 103.531H23.9712V108.449H127.812V103.531Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M25.3921 128.023H44.7413V131.802C44.7413 132.352 44.2874 132.804 43.7349 132.804H26.3985C25.846 132.804 25.3921 132.352 25.3921 131.802V128.023Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M107.298 128.023H126.648V131.802C126.648 132.352 126.194 132.804 125.641 132.804H108.315C107.762 132.804 107.308 132.352 107.308 131.802V128.023H107.298Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M61.0515 49.2351L59.6208 34.1961C59.532 33.2635 58.7525 32.5567 57.8151 32.5567H53.444C52.5066 32.5567 51.7272 33.2635 51.6383 34.1961L47.6225 76.319C47.6225 76.319 47.4054 78.2922 47.1193 79.6567C45.9352 85.2521 43.3303 90.1113 39.8374 93.5766"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M90.9189 49.3921L92.3497 34.3139C92.4385 33.3813 93.218 32.6745 94.1553 32.6745H98.5264C99.4638 32.6745 100.243 33.3813 100.332 34.3139L104.348 76.4368C104.348 76.4368 104.565 78.41 104.851 79.7745C106.035 85.3699 108.64 90.2291 112.133 93.6944"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M99.0394 64.9809C99.0394 76.2994 88.6691 85.4779 75.8815 85.4779C63.0939 85.4779 52.7236 76.2994 52.7236 64.9809C52.7236 58.6786 55.9403 53.0341 61.0021 49.2743C61.0119 49.2645 61.0317 49.2547 61.0415 49.2449C65.0574 46.2704 70.2376 44.4838 75.8815 44.4838C81.5254 44.4838 86.8635 46.3293 90.909 49.3921C95.8819 53.1519 99.0394 58.7473 99.0394 64.9907V64.9809Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M52.7236 64.7551V67.5529C52.7236 78.8714 63.0939 88.0499 75.8815 88.0499C88.6691 88.0499 99.0394 78.8714 99.0394 67.5529V64.9809"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M54.1445 78.2725C56.1969 81.7378 59.3346 84.6239 63.163 86.5675"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M92.3594 85.4387C95.0333 83.0729 97.0659 80.1181 98.171 76.8295"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M75.9607 73.3937C81.9822 73.3937 86.8637 68.9899 86.8637 63.5575C86.8637 58.1251 81.9822 53.7213 75.9607 53.7213C69.9391 53.7213 65.0576 58.1251 65.0576 63.5575C65.0576 68.9899 69.9391 73.3937 75.9607 73.3937Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M65.0576 63.42V64.3624C65.0576 69.791 69.9418 74.1987 75.9607 74.1987C81.9795 74.1987 86.8637 69.8008 86.8637 64.3624V63.6655"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M75.9603 71.6464C80.9138 71.6464 84.9294 68.0249 84.9294 63.5575C84.9294 59.0901 80.9138 55.4686 75.9603 55.4686C71.0068 55.4686 66.9912 59.0901 66.9912 63.5575C66.9912 68.0249 71.0068 71.6464 75.9603 71.6464Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M60.1538 40.567H91.7578"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M23.9712 103.531L48.7177 64.4802"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M127.812 103.531L103.263 64.7944"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M71.7177 80.9819C73.3962 80.9819 74.7568 79.7821 74.7568 78.302C74.7568 76.8219 73.3962 75.6221 71.7177 75.6221C70.0393 75.6221 68.6787 76.8219 68.6787 78.302C68.6787 79.7821 70.0393 80.9819 71.7177 80.9819Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M68.6787 78.302V79.264C68.6787 80.7463 70.0404 81.944 71.7177 81.944C73.3951 81.944 74.7568 80.7463 74.7568 79.264V78.302"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M80.7856 80.9819C82.464 80.9819 83.8247 79.7821 83.8247 78.302C83.8247 76.8219 82.464 75.6221 80.7856 75.6221C79.1072 75.6221 77.7466 76.8219 77.7466 78.302C77.7466 79.7821 79.1072 80.9819 80.7856 80.9819Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M77.7466 78.302V79.264C77.7466 80.7463 79.1082 81.944 80.7856 81.944C82.463 81.944 83.8247 80.7463 83.8247 79.264V78.302"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M63.8735 76.8393C65.5519 76.8393 66.9126 75.6395 66.9126 74.1594C66.9126 72.6793 65.5519 71.4795 63.8735 71.4795C62.1951 71.4795 60.8345 72.6793 60.8345 74.1594C60.8345 75.6395 62.1951 76.8393 63.8735 76.8393Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M60.8247 74.1594V75.1214C60.8247 76.6038 62.1864 77.8014 63.8637 77.8014C65.5411 77.8014 66.9028 76.6038 66.9028 75.1214V74.1594"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M88.4717 76.8393C90.1501 76.8393 91.5107 75.6395 91.5107 74.1594C91.5107 72.6793 90.1501 71.4795 88.4717 71.4795C86.7932 71.4795 85.4326 72.6793 85.4326 74.1594C85.4326 75.6395 86.7932 76.8393 88.4717 76.8393Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M85.4326 74.1594V75.1214C85.4326 76.6038 86.7943 77.8014 88.4717 77.8014C90.149 77.8014 91.5107 76.6038 91.5107 75.1214V74.1594"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M59.4629 69.4278C61.1413 69.4278 62.5019 68.2279 62.5019 66.7479C62.5019 65.2678 61.1413 64.0679 59.4629 64.0679C57.7845 64.0679 56.4238 65.2678 56.4238 66.7479C56.4238 68.2279 57.7845 69.4278 59.4629 69.4278Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M56.4238 66.7479V67.7099C56.4238 69.1922 57.7855 70.3898 59.4629 70.3898C61.1403 70.3898 62.5019 69.1922 62.5019 67.7099V66.7479"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M92.5566 69.4278C94.235 69.4278 95.5957 68.2279 95.5957 66.7479C95.5957 65.2678 94.235 64.0679 92.5566 64.0679C90.8782 64.0679 89.5176 65.2678 89.5176 66.7479C89.5176 68.2279 90.8782 69.4278 92.5566 69.4278Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M92.6058 67.3369C92.6058 67.543 92.6453 67.6019 92.8032 67.6019C92.9018 67.6019 93.0104 67.5921 93.0104 67.5921V67.6117L92.9808 67.7884C92.9808 67.7884 92.6453 67.7786 92.4578 67.7786C92.2704 67.7786 91.9645 67.8277 91.9645 67.8277L91.9842 67.651C92.2802 67.6314 92.3394 67.5528 92.3394 67.1994V66.3061C92.3394 66.1098 92.2605 66.0803 92.1223 66.0803C92.0434 66.0803 91.9151 66.1 91.9151 66.1H91.9053L91.925 65.9233C91.925 65.9233 92.1618 65.8938 92.3592 65.8644C92.7538 65.8055 92.7538 65.7957 92.7538 65.7957L92.7736 65.8644C92.6749 65.9527 92.6354 66.0116 92.6256 66.1981C92.6058 66.6104 92.6058 67.1405 92.6058 67.3467V67.3369Z"
          fill="black"
        />
        <path
          d="M89.5176 66.7479V67.7099C89.5176 69.1922 90.8792 70.3898 92.5566 70.3898C94.234 70.3898 95.5957 69.1922 95.5957 67.7099V66.7479"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M59.1469 61.5353C60.8254 61.5353 62.186 60.3354 62.186 58.8553C62.186 57.3753 60.8254 56.1754 59.1469 56.1754C57.4685 56.1754 56.1079 57.3753 56.1079 58.8553C56.1079 60.3354 57.4685 61.5353 59.1469 61.5353Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M56.1079 58.8553V59.8174C56.1079 61.2997 57.4696 62.4973 59.1469 62.4973C60.8243 62.4973 62.186 61.2997 62.186 59.8174V58.8553"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M92.626 61.5353C94.3044 61.5353 95.665 60.3354 95.665 58.8553C95.665 57.3753 94.3044 56.1754 92.626 56.1754C90.9475 56.1754 89.5869 57.3753 89.5869 58.8553C89.5869 60.3354 90.9475 61.5353 92.626 61.5353Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M89.5869 58.8553V59.8174C89.5869 61.2997 90.9486 62.4973 92.626 62.4973C94.3033 62.4973 95.665 61.2997 95.665 59.8174V58.8553"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M63.7944 55.2429C65.4728 55.2429 66.8334 54.043 66.8334 52.5629C66.8334 51.0828 65.4728 49.883 63.7944 49.883C62.116 49.883 60.7554 51.0828 60.7554 52.5629C60.7554 54.043 62.116 55.2429 63.7944 55.2429Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M60.7456 52.5629V53.525C60.7456 55.0073 62.1073 56.2049 63.7846 56.2049C65.462 56.2049 66.8237 55.0073 66.8237 53.525V52.5629"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M88.3833 55.2429C90.0617 55.2429 91.4223 54.043 91.4223 52.5629C91.4223 51.0828 90.0617 49.883 88.3833 49.883C86.7049 49.883 85.3442 51.0828 85.3442 52.5629C85.3442 54.043 86.7049 55.2429 88.3833 55.2429Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M85.3442 52.5629V53.525C85.3442 55.0073 86.7059 56.2049 88.3833 56.2049C90.0607 56.2049 91.4223 55.0073 91.4223 53.525V52.5629"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M71.6387 51.5714C73.3171 51.5714 74.6777 50.3716 74.6777 48.8915C74.6777 47.4114 73.3171 46.2115 71.6387 46.2115C69.9602 46.2115 68.5996 47.4114 68.5996 48.8915C68.5996 50.3716 69.9602 51.5714 71.6387 51.5714Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M68.5996 48.8915V49.8535C68.5996 51.3358 69.9613 52.5334 71.6387 52.5334C73.316 52.5334 74.6777 51.3358 74.6777 49.8535V48.8915"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M80.687 51.5714C82.3654 51.5714 83.726 50.3716 83.726 48.8915C83.726 47.4114 82.3654 46.2115 80.687 46.2115C79.0086 46.2115 77.6479 47.4114 77.6479 48.8915C77.6479 50.3716 79.0086 51.5714 80.687 51.5714Z"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M77.6479 48.8915V49.8535C77.6479 51.3358 79.0096 52.5334 80.687 52.5334C82.3644 52.5334 83.726 51.3358 83.726 49.8535V48.8915"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M91.876 59.837L92.1917 59.4934C92.6357 58.9928 92.8331 58.659 92.8331 58.443C92.8331 58.2565 92.7245 58.1485 92.5371 58.1485C92.3496 58.1485 92.172 58.286 92.0536 58.5019H92.0141L91.9747 58.3252C92.1029 58.07 92.3891 57.8933 92.6456 57.8933C92.9712 57.8933 93.1883 58.0798 93.1883 58.3645C93.1883 58.6001 92.991 58.8848 92.3496 59.6112L92.3693 59.6407H92.9712C93.2574 59.6407 93.3363 59.5818 93.4152 59.2873L93.5731 59.2676C93.5731 59.2676 93.4942 59.6014 93.4547 59.8861L93.4054 59.9155C93.4054 59.9155 92.4779 59.8861 92.1424 59.8861L91.9253 59.9352L91.8858 59.8664L91.876 59.837Z"
          fill="black"
        />
        <path
          d="M87.7815 53.3679C87.89 53.3875 87.9985 53.4071 88.0972 53.4071C88.4919 53.4071 88.7978 53.1813 88.7978 52.8868C88.7978 52.6218 88.5708 52.4942 88.0972 52.4942L88.0775 52.4745L88.0577 52.3371L88.3143 52.2782C88.5215 52.1801 88.6695 51.9935 88.6695 51.8168C88.6695 51.6401 88.5313 51.542 88.3636 51.542C88.2156 51.542 88.0281 51.6205 87.9097 51.7383H87.8703L87.8308 51.5714C87.8308 51.5714 88.186 51.2867 88.482 51.2867C88.7879 51.2867 88.9951 51.4634 88.9951 51.7285C88.9951 51.9346 88.8274 52.1506 88.561 52.288V52.3077C88.9162 52.3862 89.0938 52.5531 89.0938 52.7985C89.0938 53.2108 88.5215 53.6918 88.0183 53.6918C87.8407 53.6918 87.7716 53.6133 87.7617 53.4071L87.7815 53.3875V53.3679Z"
          fill="black"
        />
        <path
          d="M79.7296 49.0486L80.815 47.5663C80.815 47.5663 80.8248 47.5663 80.8347 47.5663L80.963 47.6055V48.9897H81.3281L81.3478 49.0191L81.3182 49.2351C81.3182 49.2351 81.1702 49.2351 80.963 49.2253C80.963 49.5394 80.9926 49.8928 80.9926 49.8928L80.9728 49.9223L80.6966 49.9713L80.6768 49.9321C80.6768 49.9321 80.6966 49.5296 80.6966 49.2253C80.3907 49.2253 80.0256 49.2057 79.7197 49.2057L79.7395 49.0584L79.7296 49.0486ZM80.0157 48.9799H80.6867V48.0964H80.6472L80.0157 48.9799Z"
          fill="black"
        />
        <path
          d="M71.2442 50.0204C71.0962 50.0204 71.0074 49.9615 70.9976 49.7357L71.0173 49.7161C71.1258 49.7358 71.2344 49.7554 71.333 49.7554C71.7474 49.7554 72.0632 49.5394 72.0632 49.2351C72.0632 48.9799 71.8659 48.8424 71.2344 48.7443L71.1949 48.705C71.2344 48.2436 71.264 47.743 71.264 47.743L71.2936 47.7135L72.0435 47.6841C72.2013 47.6841 72.2507 47.6448 72.2901 47.527L72.4184 47.5074L72.3197 47.9688L72.3 47.9884C72.3 47.9884 71.8955 47.9589 71.4317 47.9589L71.4021 48.4498C71.9349 48.5676 72.3493 48.7345 72.3493 49.1075C72.3493 49.5885 71.6981 50.0204 71.2245 50.0204H71.2442Z"
          fill="black"
        />
        <path
          d="M63.3308 52.4451H63.3505C63.3505 52.4451 63.6663 52.2488 63.7847 52.2488C64.2386 52.2488 64.4655 52.5924 64.4655 52.8967C64.4655 53.309 64.1498 53.6526 63.686 53.6526C63.2519 53.6526 62.9756 53.2992 62.9756 52.828C62.9756 51.65 64.2879 51.2573 64.2879 51.2573L64.3175 51.277L64.357 51.3948C64.357 51.3948 63.538 51.6893 63.3308 52.4549V52.4451ZM63.3012 52.6218C63.3012 52.6807 63.2913 52.7396 63.2913 52.7985C63.2913 53.1912 63.4689 53.4562 63.7649 53.4562C64.0215 53.4562 64.1794 53.2795 64.1794 52.985C64.1794 52.72 63.982 52.4746 63.7057 52.4746C63.5281 52.4746 63.3703 52.5727 63.3111 52.6218H63.3012Z"
          fill="black"
        />
        <path
          d="M58.8513 60.2395L58.6145 60.1707L58.575 60.0824L59.5617 58.1583L58.8809 58.1878C58.6145 58.1976 58.5651 58.2761 58.5158 58.551L58.3579 58.5706C58.3579 58.5706 58.4171 58.1878 58.4368 57.8933L58.4566 57.8737C58.4566 57.8737 58.6934 57.8933 58.9401 57.8933H59.7689L59.8577 58.0013C59.4038 58.9142 59.1275 59.5523 58.9697 59.9057L58.871 60.2395H58.8414H58.8513Z"
          fill="black"
        />
        <path
          d="M59.226 66.6497C58.9892 66.5221 58.7918 66.365 58.7918 66.0803C58.7918 65.7466 59.0878 65.4423 59.5023 65.4423C59.8081 65.4423 60.114 65.619 60.114 65.9724C60.114 66.2767 59.8871 66.4534 59.7193 66.5417C59.966 66.6791 60.1929 66.8362 60.1929 67.1405C60.1929 67.5234 59.8476 67.8375 59.3838 67.8375C59.0286 67.8375 58.7129 67.651 58.7129 67.3074C58.7129 66.9344 59.0484 66.738 59.226 66.6595V66.6497ZM59.9265 67.2485C59.9265 66.9835 59.6503 66.8558 59.3641 66.7184C59.2358 66.7773 59.0089 66.9049 59.0089 67.1994C59.0089 67.435 59.2062 67.6608 59.4924 67.6608C59.7292 67.6608 59.9265 67.4841 59.9265 67.2485ZM59.5812 66.4632C59.6897 66.4043 59.818 66.2767 59.818 66.0509C59.818 65.776 59.6503 65.619 59.4332 65.619C59.2161 65.619 59.0681 65.7858 59.0681 65.9625C59.0681 66.208 59.3246 66.3258 59.5812 66.4632Z"
          fill="black"
        />
        <path
          d="M64.2977 74.0907H64.2878C64.2878 74.0907 63.9721 74.287 63.8537 74.287C63.3998 74.287 63.1729 73.9434 63.1729 73.6391C63.1729 73.2268 63.4886 72.8832 63.9622 72.8832C64.3964 72.8832 64.6726 73.2366 64.6726 73.7078C64.6726 74.8858 63.3603 75.2785 63.3603 75.2785L63.3307 75.2589L63.2913 75.1411C63.2913 75.1411 64.1102 74.8564 64.3076 74.0907H64.2977ZM64.3372 73.9042C64.3372 73.8453 64.347 73.7864 64.347 73.7275C64.347 73.3348 64.1694 73.0698 63.8734 73.0698C63.6169 73.0698 63.459 73.2465 63.459 73.541C63.459 73.8158 63.6465 74.0514 63.9326 74.0514C64.1102 74.0514 64.2681 73.9532 64.3273 73.9042H64.3372Z"
          fill="black"
        />
        <path
          d="M70.8198 78.3707C70.8198 77.7621 71.2244 77.3105 71.7769 77.3105C72.2703 77.3105 72.6255 77.7032 72.6255 78.2628C72.6255 78.891 72.2209 79.3426 71.6684 79.3426C71.1553 79.3426 70.8198 78.9499 70.8198 78.3707ZM72.3985 78.3707C72.3985 77.9388 72.1025 77.6247 71.6881 77.6247C71.2737 77.6247 71.0369 77.8995 71.0369 78.3118C71.0369 78.7438 71.323 79.0383 71.7375 79.0383C72.1519 79.0383 72.3985 78.7634 72.3985 78.3707Z"
          fill="black"
        />
        <path
          d="M80.5979 78.1351L80.5782 78.2627L80.5585 78.2823C80.3611 78.2136 80.144 78.1645 80.144 78.1645C80.144 78.1645 80.144 78.0173 80.1835 77.8995L80.223 77.8798C80.223 77.8798 80.4401 77.9976 80.6177 78.1253L80.5979 78.1351ZM80.5782 78.351L80.6966 78.4099V78.4394C80.5782 78.6063 80.4697 78.8026 80.4697 78.8026C80.4697 78.8026 80.3315 78.7535 80.223 78.6848V78.6455C80.223 78.6455 80.3907 78.4688 80.5782 78.351ZM80.8051 78.086L80.667 78.1056L80.6473 78.086C80.6473 77.87 80.6275 77.6541 80.6275 77.6541C80.6275 77.6541 80.7657 77.605 80.8939 77.605L80.9235 77.6344C80.9235 77.6344 80.8841 77.87 80.8051 78.086ZM80.7755 78.4296L80.8742 78.3412H80.9038C81.0222 78.5081 81.1702 78.675 81.1702 78.675C81.1702 78.675 81.0814 78.7928 80.9827 78.8713H80.9433C80.9433 78.8713 80.8347 78.6455 80.7657 78.4394L80.7755 78.4296ZM80.9137 78.2627L80.8545 78.1449V78.1154C81.0617 78.0565 81.2689 77.9584 81.2689 77.9584C81.2689 77.9584 81.3577 78.0762 81.3972 78.194L81.3774 78.2332C81.3774 78.2332 81.1308 78.2725 80.9137 78.2627Z"
          fill="black"
        />
        <path
          d="M88.324 73.9434L88.3043 74.071L88.2845 74.0907C88.0872 74.022 87.8701 73.9729 87.8701 73.9729C87.8701 73.9729 87.8701 73.8256 87.9096 73.7078L87.9491 73.6882C87.9491 73.6882 88.1661 73.806 88.3437 73.9336L88.324 73.9434ZM88.3043 74.1594L88.4227 74.2183V74.2477C88.3043 74.4146 88.1957 74.611 88.1957 74.611C88.1957 74.611 88.0576 74.5619 87.9491 74.4932V74.4539C87.9491 74.4539 88.1168 74.2772 88.3043 74.1594ZM88.5312 73.8943L88.3931 73.914L88.3733 73.8943C88.3733 73.6784 88.3536 73.4624 88.3536 73.4624C88.3536 73.4624 88.4917 73.4133 88.62 73.4133L88.6496 73.4428C88.6496 73.4428 88.6101 73.6784 88.5312 73.8943ZM88.5016 74.2379L88.6003 74.1496H88.6299C88.7483 74.3165 88.8963 74.4833 88.8963 74.4833C88.8963 74.4833 88.8075 74.6011 88.7088 74.6797H88.6693C88.6693 74.6797 88.5608 74.4539 88.4917 74.2477L88.5016 74.2379ZM88.6397 74.071L88.5805 73.9532V73.9238C88.7878 73.8649 88.995 73.7667 88.995 73.7667C88.995 73.7667 89.0838 73.8845 89.1232 74.0023L89.1035 74.0416C89.1035 74.0416 88.8568 74.0809 88.6397 74.071Z"
          fill="black"
        />
        <path
          d="M7.21731 57.1767C7.21731 57.1767 6.36875 36.3459 19.7385 33.1948C33.1083 30.0436 41.663 48.3123 41.663 48.3123"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M59.6011 34.0488H77.8748"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M41.9297 48.116V35.8649C41.9297 34.8636 42.7486 34.0488 43.7551 34.0488H51.6487"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M51.5004 35.5605H44.4554C43.8831 35.5605 43.4292 36.0219 43.4292 36.5815V46.3097C43.4292 46.614 43.3207 46.9085 43.0937 47.1245C38.18 51.8168 24.2971 56.676 16.2357 57.2159C8.17438 57.7558 7.21729 57.1865 7.21729 57.1865C7.21729 57.1865 7.53303 63.4593 8.98348 65.4717C8.98348 65.4717 9.96031 66.257 18.1993 65.5698C26.3396 64.8925 38.8904 61.3978 47.1195 53.7507C47.3069 53.574 47.3661 53.3089 47.2773 53.0635C46.7643 51.7481 45.1165 47.527 44.6823 46.5748C44.1988 45.5342 43.4884 45.2691 43.4884 45.2691"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M79.375 35.5605H59.7495"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M9.4375 65.619C9.4375 65.619 10.0295 68.937 17.3607 68.5639C31.7073 67.8375 41.7816 61.5549 44.3864 59.6701C48.1556 56.9411 47.2676 53.5544 47.2676 53.5544"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M144.783 57.1767C144.783 57.1767 145.632 36.3361 132.262 33.1948C118.892 30.0535 110.337 48.3123 110.337 48.3123"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M92.3992 34.0488H74.1255"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M110.061 48.116V35.8649C110.061 34.8636 109.242 34.0488 108.236 34.0488H100.293"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M142.79 43.9243C142.79 43.9243 140.629 35.9631 136.811 31.0253C131.147 27.0791 102.414 19.481 76.7896 19.1964H75.2109C49.5862 19.481 20.8437 27.0791 15.1899 31.0253C11.3713 35.9533 9.21045 43.9243 9.21045 43.9243"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M100.46 35.5606H107.535C108.107 35.5606 108.561 36.022 108.561 36.5815V46.3098C108.561 46.6141 108.67 46.9086 108.897 47.1245C113.811 51.8169 127.693 56.6761 135.755 57.216C143.816 57.7559 144.773 57.1865 144.773 57.1865C144.773 57.1865 144.457 63.4593 143.007 65.4717C143.007 65.4717 142.03 66.2571 133.791 65.5699C125.651 64.8926 113.1 61.3979 104.871 53.7507C104.684 53.574 104.624 53.309 104.713 53.0636C105.226 51.7482 106.874 47.527 107.308 46.5748C107.792 45.5343 108.502 45.2692 108.502 45.2692"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M72.6255 35.5606H92.2312"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M142.563 65.619C142.563 65.619 141.971 68.937 134.64 68.5639C120.293 67.8375 110.219 61.5549 107.614 59.6701C103.845 56.9411 104.733 53.5544 104.733 53.5544"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M24.1982 110.275H127.782"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M52.2005 44.7097L51.1743 55.4686"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M58.7424 40.4002L58.5352 38.3093"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M59.5021 48.0277L58.8706 41.4015"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M33.2856 35.5606H39.0184"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M40.2715 39.2713V42.4911"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M10.4336 46.5748C10.7789 45.2103 11.223 43.8262 11.7854 42.4813"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M9.28906 54.379C9.368 52.828 9.56534 50.7665 9.99949 48.5381"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M44.5935 49.2842C44.3172 48.597 44.1791 48.2633 44.0508 47.9884"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M45.9255 52.6415C45.6097 51.8365 45.2447 50.9039 44.9092 50.0695"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M11.7656 67.0326C13.147 67.0816 15.2585 67.0326 18.4949 66.7675"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M19.6792 66.6792L21.9881 66.4141"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M23.3296 112.945V123.164"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M24.5928 125.795H39.5117"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M36.8281 131.871H43.5278"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M99.8584 44.7097L100.885 55.4686"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M93.3164 40.4002L93.5137 38.3093"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M92.5566 48.0277L93.1881 41.4015"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M118.773 35.5606H113.041"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M111.788 39.2713V42.4911"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M141.625 46.5748C141.28 45.2103 140.836 43.8262 140.273 42.4813"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M142.77 54.379C142.691 52.828 142.494 50.7665 142.06 48.5381"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M107.466 49.2842C107.742 48.597 107.88 48.2633 108.009 47.9884"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M106.134 52.6415C106.45 51.8365 106.815 50.9039 107.15 50.0695"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M140.293 67.0326C138.912 67.0816 136.8 67.0326 133.564 66.7675"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M132.38 66.6792L130.071 66.4141"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M128.729 112.945V123.164"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M127.466 125.795H112.547"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M115.231 131.871H108.531"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M25.3921 104.738V107.251"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M26.3491 104.738H30.2466"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M26.3491 107.084H38.7224"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M126.5 104.738V107.251"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M125.532 104.738H121.635"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
        <path
          d="M125.532 107.084H113.159"
          stroke="black"
          strokeWidth="0.4"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1343_2016">
          <rect
            width="138"
            height="114"
            fill="white"
            transform="translate(7 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
