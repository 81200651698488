import classNames from 'classnames';
import { Badge } from 'components';
import { isBefore } from 'date-fns';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IMenuItem } from 'types/account';

interface LinkNavProps {
  item: IMenuItem;
  translation: 'customer' | 'lawyer';
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const LinkNav = ({ item, translation, onClick = () => {} }: LinkNavProps) => {
  const { t } = useTranslation(translation);
  const Icon = item.icon;

  return (
    <Link key={item.name} to={item.href} onClick={onClick}>
      <div
        className={classNames('flex py-3 text-base items-center', {
          'font-medium': item.current,
          'font-normal': !item.current
        })}
        aria-current={item.current ? 'page' : undefined}
        key={item.name}
      >
        {Icon && <Icon fill="white" className="w-5" />}
        <span className="ml-3 flex gap-2 items-center hover:font-medium">
          {isBefore(new Date(), item.newUntil) ? (
            <Badge content={t(`account.sidebar.new.label`)}>
              {t(`account.sidebar.${item.name}` as any)}
            </Badge>
          ) : (
            t(`account.sidebar.${item.name}` as any)
          )}
        </span>
      </div>
    </Link>
  );
};

export default LinkNav;
