import { TabPanel, TabPanels } from '@headlessui/react';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Section, Tabs } from 'components';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { QuestionsListSkeleton } from 'components/skeleton';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useUnguardedSearchParams } from 'hooks';

import { PageLayout } from 'features/customer/account/content';
import { MemberQuestionsList } from 'features/questions';

const TABS_MAP = [
  { name: 'account.questions.tabs.personal.title', id: 'personal' },
  { name: 'account.questions.tabs.cse.title', id: 'cse' }
];

const MemberQuestionsPage = () => {
  const { t } = useTranslation('customer');
  const [, setSearchParams] = useSearchParams();
  const defaultPage = useUnguardedSearchParams({ param: 'page' }) || '1';
  const type = useUnguardedSearchParams({ param: 'type' }) || 'personal';
  const { data: member, isLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  return (
    <PageLayout>
      <Section>
        {isLoading ? (
          <QuestionsListSkeleton />
        ) : (
          <Tabs
            className={!member?.is_active_cse_member ? 'hidden' : ''}
            defaultIndex={TABS_MAP.findIndex((item) => item.id === type)}
            items={TABS_MAP.map((item) => ({
              ...item,
              onClick: () => {
                setSearchParams({ page: defaultPage, type: item.id });
              },
              name: t(item.name as any)
            }))}
          >
            <TabPanels className={!member?.is_active_cse_member ? '' : 'mt-8'}>
              <TabPanel>
                <ErrorBoundary fallback={<GenericErrorCard />}>
                  <Fade>
                    <MemberQuestionsList defaultPage={Number(defaultPage)} />
                  </Fade>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel>
                <ErrorBoundary fallback={<GenericErrorCard />}>
                  <Fade>
                    <MemberQuestionsList
                      source="cse"
                      defaultPage={Number(defaultPage)}
                    />
                  </Fade>
                </ErrorBoundary>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Section>
    </PageLayout>
  );
};

export default MemberQuestionsPage;
