import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { profileSchema } from 'schemas';
import { AuthenticatedMemberInfo } from 'services/member';

import { useUpdateAccountOptimisticMutation } from 'api/customer/customers';
import { useUpdateCustomerOptimisticMutation } from 'api/customer/mutations.index';

import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { DisabledTextInputGroup, TextInputGroup } from 'features/shared/forms';
import { Toast } from 'features/shared/toasts';

import { ProfileFormInputs } from 'types/inputs';

interface MemberEditProfileProps {
  onFinish: () => void;
  defaultValues: AuthenticatedMemberInfo;
}

const MemberEditProfile = ({
  defaultValues,
  onFinish
}: MemberEditProfileProps) => {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const [withAccountInput, setWithAccountInput] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
    reset
  } = useForm<ProfileFormInputs>({
    resolver: yupResolver(profileSchema(tForm)),
    defaultValues: {
      firstName: defaultValues.first_name || undefined,
      lastName: defaultValues.last_name || undefined,
      phoneCellphone: defaultValues.phone_cellphone || ''
    }
  });
  const { mutate, isPending } = useUpdateCustomerOptimisticMutation({
    prevCustomer: {
      id: defaultValues.id,
      email: defaultValues.email,
      firstName: defaultValues.first_name || undefined,
      lastName: defaultValues.last_name || undefined,
      phoneCellphone: defaultValues.phone_cellphone || ''
    }
  });
  const { mutate: accountMutation } = useUpdateAccountOptimisticMutation(
    defaultValues.id
  );

  const onSubmit = async (data: ProfileFormInputs) => {
    if (data.secondaryEmail) {
      accountMutation(
        { secondaryEmail: data.secondaryEmail || null },
        {
          onSuccess: onFinish,
          onError: (error: any) => {
            const { data: errorData } = error.response;
            if (
              errorData.violations[0].code ===
              'email_already_used_by_active_account'
            ) {
              setError('secondaryEmail' as any, {
                message: 'Adresse email déjà utilisée',
                type: 'focus'
              });
            }

            setError('secondaryEmail' as any, {
              message: errorData.violations[0].message,
              type: 'focus'
            });
          }
        }
      );
    }

    await mutate(
      {
        firstName: data.firstName || undefined,
        lastName: data.lastName || undefined,
        phoneCellphone: data.phoneCellphone || ''
      },
      {
        onSuccess: () => {
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.profile.update.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );

          onFinish();
        }
      }
    );
  };

  const handleResetForm = () => {
    reset();
    onFinish();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div className="grid grid-cols-6 gap-3 sm:gap-6">
        <div className="col-span-6 sm:col-span-3">
          <TextInputGroup
            name="firstName"
            label={tForm('label.firstName')}
            register={register}
            placeholder={tForm('placeholder.firstName')}
            error={errors.firstName}
            disabled={isPending}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInputGroup
            name="lastName"
            label={tForm('label.lastName')}
            register={register}
            placeholder={tForm('placeholder.lastName')}
            error={errors.lastName}
            disabled={isPending}
          />
        </div>
      </div>
      <TextInputGroup
        name="phoneCellphone"
        label={tForm('label.phone')}
        register={register}
        placeholder="+33601010101"
        error={errors.phoneCellphone}
        disabled={isPending}
      />
      <DisabledTextInputGroup
        disabled
        name="email"
        defaultValue={defaultValues.email}
        label={tForm('label.email')}
      />
      {withAccountInput ? (
        <div>
          <TextInputGroup
            name="secondaryEmail"
            label={tForm('label.email.perso')}
            register={register}
            error={errors.secondaryEmail}
            disabled={isPending}
          />
          <Alert className="mt-2">{tForm('description.email.perso')}</Alert>
        </div>
      ) : (
        <Button
          className="pl-0 pt-0 text-purple-900"
          size="small"
          variant="transparent"
          label="Ajouter une adresse personnelle"
          onClick={() => setWithAccountInput(true)}
        />
      )}
      <div className="flex justify-end">
        <Button
          onClick={handleResetForm}
          variant="transparent"
          size="small"
          label={t('general.cancel')}
          disabled={isPending}
        />
        <div className="ml-3">
          <Button
            submit
            disabled={!isDirty}
            variant="secondary"
            size="small"
            label={t('general.update')}
            isLoading={isPending}
          />
        </div>
      </div>
    </form>
  );
};

export default MemberEditProfile;
