import { useQuery } from '@tanstack/react-query';
import { MemberProfileSkeleton } from 'components/skeleton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { Button } from 'features/shared/buttons';

import MemberEditProfile from './MemberEditProfile';
import MemberProfileInformations from './components/MemberProfileInformations';

const MemberProfile = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation('customer');
  const {
    data: member,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (isLoading || !isSuccess) return <MemberProfileSkeleton />;

  return !isEdit ? (
    <>
      <MemberProfileInformations member={member} />
      <div className="pt-5">
        <div className="flex justify-end gap-4">
          <Link
            to="/account/password-new"
            className="text-sm font-medium bg-transparent text-black border border-gray-700 hover:bg-black hover:text-white py-2 px-4 rounded"
          >
            {t('password.update.cta')}
          </Link>
          <Button
            onClick={() => setIsEdit(true)}
            variant="secondary"
            size="small"
            label={t('account.profile.button.update')}
          />
        </div>
      </div>
    </>
  ) : (
    <MemberEditProfile
      defaultValues={member}
      onFinish={() => setIsEdit(false)}
    />
  );
};

export default MemberProfile;
