import { DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuth } from 'context';

import { IMenuItem } from 'types/account';

import LinkNav from './LinkNav';

interface MobileNavProps {
  open: boolean;
  close?: any;
  accountTabs: IMenuItem[];
  translation: 'customer' | 'lawyer';
  buttonLink?: string;
}

export default function MobileNav({
  open,
  close,
  accountTabs,
  translation,
  buttonLink
}: MobileNavProps) {
  const { t } = useTranslation(translation);
  const { logout, roles } = useAuth();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (open) return html.classList.add('overflow-hidden');

    return html.removeAttribute('class');
  }, [open]);

  return (
    <div
      id="mobile-nav"
      className="block md:hidden z-50 top-0 w-full fixed bg-white"
    >
      <div className="rounded-t-base px-4 sm:px-6 lg:px-8 flex items-center justify-between py-4">
        <Link to="/account/home">
          <EkieLogo className="md:ml-3" />
        </Link>
        <div className="flex items-center">
          {buttonLink && (
            <Link
              to={buttonLink}
              key="ask"
              onClick={close}
              className="font-bold text-center mr-4 bg-purple-900 hover:bg-purple-900 text-white text-sm sm:text-base rounded p-2"
            >
              {t('account.sidebar.ask')}
            </Link>
          )}
          <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2">
            {open ? (
              <XIcon className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <MenuIcon className="block h-6 w-6" aria-hidden="true" />
            )}
          </DisclosureButton>
        </div>
      </div>
      <DisclosurePanel className="w-full flex flex-col space-y-1 h-screen z-50 fixed px-4 sm:px-6 lg:px-8 rounded-b-base overflow-hidden bg-white shadow-sm md:hidden pb-4">
        {accountTabs.map((item) => (
          <LinkNav
            translation={translation}
            item={item}
            key={item.name}
            onClick={close}
          />
        ))}
        {process.env.REACT_APP_EKIE_ADMIN_AVAILIBILITY === '1' &&
          roles.includes('ROLE_CUSTOMER_ADMIN') && (
            <Link to="/admin/collaborators">
              <div className="block p-2 text-base font-medium">
                <span>{t('account.sidebar.admin')}</span>
              </div>
            </Link>
          )}
        <div className="block p-2 text-base font-medium">
          <Link to="#" onClick={logout}>
            {t('navbar.cta.logout')}
          </Link>
        </div>
      </DisclosurePanel>
    </div>
  );
}
