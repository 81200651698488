import { useQuery } from '@tanstack/react-query';
import { AnswerSectionSkeleton } from 'components/skeleton';
import { getMemberQuestionAnswer } from 'services/answer';
import { MemberQuestion } from 'services/question';

import AnswerCard from './AnswerCard';

interface QuestionAnswerProps {
  answerId: MemberQuestion['answer_id'];
}

const QuestionAnswer = ({ answerId }: QuestionAnswerProps) => {
  const {
    data: answer,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-question-answer', answerId],
    queryFn: () => getMemberQuestionAnswer(answerId as number)
  });

  if (isLoading) return <AnswerSectionSkeleton />;
  if (!isSuccess) return <div />;

  return (
    <section aria-labelledby="answer">
      <AnswerCard answer={answer} />
    </section>
  );
};

export default QuestionAnswer;
