import { Disclosure } from '@headlessui/react';
import {
  BadgeCheckIcon,
  ChatAltIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  PhoneIcon
} from '@heroicons/react/outline';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { DesktopNav, MobileNav } from 'components';
import { Link, useLocation } from 'react-router-dom';

import { HouseIcon } from 'features/shared/icons';

import { IMenuItem, PageName } from 'types/account';

import { CustomerProfile } from '../sidebar';

export default function AccountNav() {
  const { pathname } = useLocation();

  const accountTabs: IMenuItem[] = [
    {
      name: PageName.Home,
      icon: HouseIcon,
      href: '/account/home',
      current: pathname.includes(PageName.Home),
      newUntil: new Date('01-05-2023')
    },
    {
      name: PageName.Questions,
      icon: ChatAltIcon,
      href: '/account/questions',
      current: pathname.includes(PageName.Questions),
      newUntil: new Date('05-01-2023')
    },
    {
      name: PageName.Consultations,
      icon: PhoneIcon,
      href: '/account/consultations',
      current: pathname.includes(PageName.Consultations),
      newUntil: new Date('05-01-2023')
    },
    {
      name: PageName.Guarantees,
      icon: BadgeCheckIcon,
      href: '/account/guarantees',
      current: pathname.includes(PageName.Guarantees),
      newUntil: new Date('09-01-2023')
    },
    {
      name: PageName.Resources,
      icon: DocumentTextIcon,
      href: '/account/resources',
      current: pathname.includes(PageName.Resources),
      newUntil: new Date('05-29-2024')
    },
    {
      name: PageName.Contact,
      icon: InformationCircleIcon,
      href: '/account/contact',
      current: pathname.includes(PageName.Contact),
      newUntil: new Date('05-29-2024')
    }
  ];

  const accountMobileTabs: IMenuItem[] = [
    {
      name: PageName.Profile,
      href: '/account/profile',
      current: pathname.includes(PageName.Profile),
      newUntil: new Date('05-01-2023')
    }
  ];

  return (
    <>
      <div className="bg-white hidden md:flex flex-col justify-items-center items-center">
        <div className="hidden md:block md:fixed h-full pt-10 pb-4 z-50">
          <div className="flex flex-col justify-between h-full">
            <div className="w-full md:w-auto md:max-w-lg">
              <div className="md:pb-12">
                <Link to="/account/home">
                  <EkieLogo />
                </Link>
              </div>
              <div className="flex flex-col justify-between">
                <DesktopNav
                  buttonLink="/account/ask"
                  accountTabs={accountTabs}
                />
              </div>
            </div>
            <CustomerProfile />
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <Disclosure as="nav">
          {({ open, close }) => (
            <MobileNav
              open={open}
              close={close}
              translation="customer"
              buttonLink="/account/ask"
              accountTabs={[...accountTabs, ...accountMobileTabs]}
            />
          )}
        </Disclosure>
      </div>
    </>
  );
}
