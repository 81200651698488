import { SVGProps } from 'react';

export default function HabitationIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M100.756 91.5821L95.162 85.3004M112.306 104.553L108.687 100.488M121.829 115.247L116.636 109.415M113.904 115.247L115.963 117.559M108.711 109.416L112.906 114.126M97.3693 96.6786L102.608 102.562M92.1042 90.7667L93.3391 92.1531M89.9896 88.392L91.3873 89.9616M115.067 92.7242L113.797 91.2982M121.64 100.106L118.443 96.5158M119.406 104.162L120.64 105.549M116.249 100.618L117.632 102.171M112.742 96.6775L115.068 99.2897M108.203 91.5809L109.221 92.7242M101.836 84.4308L103.383 86.1677M112.306 89.6224L110.423 87.5077L108.541 85.3931M92.3191 102.561L89.9919 99.9478M103.507 115.126L98.3362 109.318M99.6807 117.394L100.263 118.049M96.3957 113.705L98.9253 116.546M92.3191 109.128L95.0591 112.205"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M119.381 80.6468C119.381 74.8779 112.111 70.2003 103.141 70.2003H103.138C94.1686 70.2003 86.8969 74.8769 86.8969 80.6468C86.8969 80.7324 86.9883 113.607 86.9883 113.607H119.288C119.288 113.607 119.396 80.8501 119.381 80.6468ZM117.753 90.215H104.616V82.1321H117.753V90.215ZM104.616 92.5154H117.753V100.908H104.616V92.5154ZM117.041 80.4397C117.041 80.4834 117.041 80.5233 117.041 80.5612H108.658L112.137 73.9704C115.122 75.4683 117.041 77.7395 117.041 80.4397ZM104.002 72.0552C106.494 72.1437 108.829 72.6116 110.813 73.3849L107.024 80.5622H103.994L104.001 72.0562L104.002 72.0552ZM102.505 72.0494L102.498 80.5622H99.387L95.6509 73.485C97.6381 72.6417 99.9822 72.1243 102.505 72.0494ZM94.3485 74.1114L97.7529 80.5612H89.4569C89.6174 77.972 91.484 75.6687 94.3485 74.1114ZM101.66 82.1321V90.215H88.5241V82.1321H101.66V82.1321ZM88.5241 92.5154H101.66V100.908H88.5241V92.5154ZM88.5241 103.208H101.66V112.037H88.5241V103.208ZM104.617 112.037V103.208H117.754V112.037H104.617Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M121.717 113.607H84.8794V117.765H121.717V113.607Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M135.722 64.7458H73.0576V127.851H135.722V64.7458Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M73.0577 64.7458L44.1819 30.3112L14.5708 64.7458V127.851H73.0752V64.7458H73.0577Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5708 64.7458H10.6003L43.9981 24.4089L78.4142 64.7458"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.6204 28.6946H113.017L141 64.7458H135.722"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.8425 127.782V94.6983H54.8406C54.7754 88.692 49.8868 83.8423 43.8649 83.8423C37.8431 83.8423 32.9544 88.692 32.8893 94.6983H32.8873V127.727"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.8423 52.7341H32.8881V74.6884H54.8423V52.7341Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.33 54.2478H34.4017V73.1761H53.33V54.2478Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M35.3579 107.325C36.0654 107.325 36.6389 106.751 36.6389 106.044C36.6389 105.336 36.0654 104.763 35.3579 104.763C34.6504 104.763 34.0769 105.336 34.0769 106.044C34.0769 106.751 34.6504 107.325 35.3579 107.325Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M36.2166 105.755C36.2467 105.845 36.2632 105.942 36.2632 106.043"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M35.3579 105.138C35.5077 105.138 35.6478 105.174 35.7723 105.238C35.8802 105.294 35.9756 105.37 36.0524 105.463"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M50.4274 31.9443H115.539"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.5818 35.6414H118.409"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M56.7362 39.3375H121.279"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.8907 43.0347H124.149"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M63.0342 46.7318H127.018"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M66.1985 50.4288H129.887"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M69.3527 54.1252H132.756"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M72.5071 57.8224H135.627"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.6616 61.5195H138.496"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M50.5916 57.9169L52.2509 59.8243"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M48.4471 55.3745L49.6202 56.7226"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.6406 66.1323L45.039 72.3389"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M35.5448 61.422L37.4989 63.6698"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M37.1536 55.3745L48.7226 68.6767"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
