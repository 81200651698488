export interface Lawyer {
  id: number;
  slug: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  takeDownDate: null;
  isPublished: boolean;
  calendar: Calendar;
  publicationDate: null;
  isEligibleForAnswerHelper: boolean;
  isIdentityVerified: boolean;
  isImportedFromTrustedSource: boolean;
  officeAddress: OfficeAddress;
  email: string;
  avatarUrl: string | null;
  toqueCode: null;
  sermentedDate: Date;
  practiceFromDate: null;
  offersFirstFreeConsultation: boolean;
  offersSplitPayment: boolean;
  offersAideJuridictionnelle: boolean;
  about: null;
  stripeConnectId: string | null;
  servicePhoneNumber: null;
  firstName: string;
  lastName: string;
  company: null;
  phoneLandline: null;
  phoneCellphone: string;
  isTakeDown: boolean;
}

export interface Calendar {
  id: string;
}

export interface OfficeAddress {
  id: number;
  type: number;
  streetLine1: string;
  streetLine2: null;
  city: City;
}

export interface City {
  id: number;
  zipcode: any[];
  inseeCode: string;
  name: string;
  canonicalName: string;
}

export interface ApiLawyerAvailabilitySlot {
  id: string;
  startAt: Date;
  appointmentOptions: { phone: boolean; video: boolean } | null;
}

export enum BookingOption {
  BookingVideo = 'video',
  BookingPhone = 'phone'
}

export enum BookingConsultationOption {
  BookingVideo = 'video_call',
  BookingPhone = 'phone_call'
}

export interface ConsultationSlot {
  id: string;
  startAt: Date;
  dayTime: string;
  option: BookingOption;
}

export interface LawyerAvailabilityDaySlots {
  day: string;
  slots: ConsultationSlot[];
}
