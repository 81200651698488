import { ExclamationIcon } from '@heroicons/react/solid';
import { Title } from 'components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { SetState } from 'types/core';

import { useCreateUnauthenticatedNpsVoteMutation } from '../../../../api/customer/nps-votes';
import { Toast } from '../../../shared/toasts';

interface ModalNpsVoteConfirmationProps {
  accountId: string;
  npsVoteNote: string;
  source: string;
  isNpsVoteConfirmationOpen: boolean;
  setIsNpsVoteConfirmationOpen: SetState<boolean>;
  setReturnMessage: SetState<string>;
}

export default function ModalNpsVoteConfirmation({
  accountId,
  npsVoteNote,
  source,
  isNpsVoteConfirmationOpen,
  setIsNpsVoteConfirmationOpen,
  setReturnMessage
}: ModalNpsVoteConfirmationProps) {
  const { t } = useTranslation('customer');

  const { mutate, isLoading } = useCreateUnauthenticatedNpsVoteMutation();

  const handleModalClose = () => {
    setIsNpsVoteConfirmationOpen(false);
    setReturnMessage(
      t('account.nps.vote.confirmation.message.no', { npsVoteNote })
    );
  };

  const handleNpsVoteConfirmation = () =>
    mutate(
      { note: npsVoteNote, accountId, source },
      {
        onSuccess: () => {
          setReturnMessage(
            t('account.nps.vote.confirmation.message.yes', { npsVoteNote })
          );
          toast.custom(
            <Toast
              type="success"
              title={t('general.thank')}
              message={t('account.nps.vote.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        },
        onError: () => {
          setReturnMessage(
            t('account.nps.vote.confirmation.message.yes.error', {
              npsVoteNote
            })
          );
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('account.nps.vote.error')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        },
        onSettled: () => {
          setIsNpsVoteConfirmationOpen(false);
        }
      }
    );

  return (
    <LayoutModal
      isModalOpen={isNpsVoteConfirmationOpen}
      handleModalClose={handleModalClose}
    >
      <div>
        <div className="flex flex-col items-center">
          <ExclamationIcon
            className="h-6 w-6 text-red-800"
            aria-hidden="true"
          />
          <Title variant="h3">
            {t('account.nps.vote.ask.confirmation.title')}
          </Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium ">
            {t('account.nps.vote.ask.confirmation.body', { npsVoteNote })}
          </p>
        </div>
      </div>

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="flex justify-end">
          <Button
            onClick={handleModalClose}
            variant="secondary"
            size="small"
            label={t('general.no')}
            disabled={isLoading}
          />
          <div className="ml-3">
            <Button
              submit
              variant="primary"
              size="small"
              onClick={handleNpsVoteConfirmation}
              label={t('general.yes')}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
