export default function SearchIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M11.6875 17.375C14.8286 17.375 17.375 14.8286 17.375 11.6875C17.375 8.54638 14.8286 6 11.6875 6C8.54638 6 6 8.54638 6 11.6875C6 14.8286 8.54638 17.375 11.6875 17.375Z"
        stroke="#1D1D1D"
        strokeWidth="1.625"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7094 15.7094L19 19"
        stroke="#1D1D1D"
        strokeWidth="1.625"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
