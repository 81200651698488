import { SVGProps } from 'react';

export default function CovidIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.5 111.796C95.5458 111.796 111.796 95.5458 111.796 75.5C111.796 55.4541 95.5458 39.2037 75.5 39.2037C55.4541 39.2037 39.2037 55.4541 39.2037 75.5C39.2037 95.5458 55.4541 111.796 75.5 111.796Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.23 44.8305V30.7706"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.2302 30.7704C77.1919 30.7704 78.7822 29.1801 78.7822 27.2185C78.7822 25.2568 77.1919 23.6666 75.2302 23.6666C73.2686 23.6666 71.6783 25.2568 71.6783 27.2185C71.6783 29.1801 73.2686 30.7704 75.2302 30.7704Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.23 25.0028C75.8016 25.0028 76.3283 25.2212 76.7201 25.5809"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M77.1056 26.0367C77.324 26.3772 77.446 26.7882 77.446 27.225C77.446 27.7388 77.2662 28.2206 76.9707 28.5931"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M96.9976 53.6235L106.94 43.6807"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M109.452 44.7211C111.414 44.7211 113.004 43.1309 113.004 41.1692C113.004 39.2076 111.414 37.6173 109.452 37.6173C107.49 37.6173 105.9 39.2076 105.9 41.1692C105.9 43.1309 107.49 44.7211 109.452 44.7211Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M111.019 39.6021C111.424 40.0068 111.642 40.5334 111.668 41.0665"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M111.61 41.6573C111.52 42.0556 111.321 42.4281 111.013 42.7364C110.646 43.1025 110.184 43.3145 109.709 43.3723"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M106.17 75.2299H120.236"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.781 78.7821C125.743 78.7821 127.333 77.1919 127.333 75.2302C127.333 73.2685 125.743 71.6783 123.781 71.6783C121.82 71.6783 120.229 73.2685 120.229 75.2302C120.229 77.1919 121.82 78.7821 123.781 78.7821Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M126.004 75.2299C126.004 75.8016 125.785 76.3282 125.426 76.72"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M124.97 77.1056C124.629 77.324 124.218 77.446 123.781 77.446C123.268 77.446 122.786 77.2662 122.413 76.9707"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M97.3832 96.9975L107.326 106.94"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M109.837 113.004C111.799 113.004 113.389 111.414 113.389 109.452C113.389 107.49 111.799 105.9 109.837 105.9C107.876 105.9 106.286 107.49 106.286 109.452C106.286 111.414 107.876 113.004 109.837 113.004Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M111.405 111.019C111 111.424 110.473 111.642 109.94 111.668"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M109.349 111.61C108.951 111.52 108.578 111.321 108.27 111.013C107.904 110.646 107.692 110.184 107.634 109.709"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.7759 106.17V120.236"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.776 127.333C77.7377 127.333 79.3279 125.743 79.3279 123.781C79.3279 121.82 77.7377 120.229 75.776 120.229C73.8144 120.229 72.2241 121.82 72.2241 123.781C72.2241 125.743 73.8144 127.333 75.776 127.333Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M75.7759 126.004C75.2043 126.004 74.6776 125.785 74.2858 125.426"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M73.9007 124.97C73.6823 124.629 73.5603 124.218 73.5603 123.781C73.5603 123.268 73.7401 122.786 74.0356 122.413"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.0087 97.3832L44.0659 107.326"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.5548 113.389C43.5165 113.389 45.1067 111.799 45.1067 109.837C45.1067 107.876 43.5165 106.285 41.5548 106.285C39.5932 106.285 38.0029 107.876 38.0029 109.837C38.0029 111.799 39.5932 113.389 41.5548 113.389Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.9875 111.405C39.5828 111 39.3644 110.473 39.3387 109.94"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.3903 109.349C39.4802 108.951 39.6793 108.578 39.9876 108.27C40.3537 107.904 40.8162 107.692 41.2915 107.634"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M44.8306 75.7759H30.7708"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M27.2185 79.3281C29.1802 79.3281 30.7704 77.7379 30.7704 75.7762C30.7704 73.8145 29.1802 72.2243 27.2185 72.2243C25.2569 72.2243 23.6666 73.8145 23.6666 75.7762C23.6666 77.7379 25.2569 79.3281 27.2185 79.3281Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.0026 75.776C25.0026 75.2043 25.2209 74.6776 25.5806 74.2858"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M26.0365 73.9005C26.3769 73.6821 26.788 73.5601 27.2247 73.5601C27.7386 73.5601 28.2203 73.7399 28.5928 74.0354"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.6235 54.0087L43.6808 44.0659"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.1692 45.1067C43.1309 45.1067 44.7211 43.5164 44.7211 41.5548C44.7211 39.5931 43.1309 38.0029 41.1692 38.0029C39.2076 38.0029 37.6173 39.5931 37.6173 41.5548C37.6173 43.5164 39.2076 45.1067 41.1692 45.1067Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.6022 39.9874C40.0068 39.5828 40.5335 39.3644 41.0666 39.3387"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.6575 39.3902C42.0557 39.4801 42.4282 39.6792 42.7365 39.9875C43.1026 40.3536 43.3146 40.8161 43.3724 41.2914"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M90.78 42.5692L98.3913 26.4989"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M100.787 26.8393C103.767 26.8393 106.182 24.4237 106.182 21.444C106.182 18.4643 103.767 16.0487 100.787 16.0487C97.8072 16.0487 95.3916 18.4643 95.3916 21.444C95.3916 24.4237 97.8072 26.8393 100.787 26.8393Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M102.463 17.9052C102.701 18.0143 102.919 18.1492 103.125 18.297"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M103.999 19.2024C104.191 19.4786 104.345 19.774 104.461 20.0888C104.814 21.0394 104.795 22.1313 104.326 23.1204"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M109.587 63.0201L126.338 57.0403"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M131.605 60.5537C134.584 60.5537 137 58.1381 137 55.1584C137 52.1786 134.584 49.7631 131.605 49.7631C128.625 49.7631 126.209 52.1786 126.209 55.1584C126.209 58.1381 128.625 60.5537 131.605 60.5537Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M135.292 53.8417C135.381 54.0857 135.439 54.3362 135.478 54.5867"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M135.458 55.8457C135.401 56.1733 135.298 56.4944 135.157 56.7963C134.733 57.7212 133.949 58.4791 132.921 58.8452"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M108.431 90.7802L124.501 98.3914"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M129.556 106.183C132.536 106.183 134.951 103.767 134.951 100.787C134.951 97.8075 132.536 95.3919 129.556 95.3919C126.576 95.3919 124.161 97.8075 124.161 100.787C124.161 103.767 126.576 106.183 129.556 106.183Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M133.095 102.464C132.986 102.701 132.851 102.92 132.703 103.125"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M131.797 103.999C131.521 104.191 131.226 104.345 130.911 104.461C129.96 104.814 128.869 104.795 127.879 104.326"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M87.9862 109.587L93.966 126.338"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M95.8481 137C98.8278 137 101.243 134.584 101.243 131.605C101.243 128.625 98.8278 126.209 95.8481 126.209C92.8683 126.209 90.4528 128.625 90.4528 131.605C90.4528 134.584 92.8683 137 95.8481 137Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M97.1649 135.292C96.9208 135.381 96.6703 135.439 96.4198 135.478"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M95.1605 135.459C94.833 135.401 94.5118 135.298 94.2099 135.157C93.285 134.733 92.5271 133.949 92.161 132.922"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M60.2262 108.431L52.6085 124.501"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M50.2127 134.951C53.1924 134.951 55.608 132.536 55.608 129.556C55.608 126.576 53.1924 124.161 50.2127 124.161C47.2329 124.161 44.8174 126.576 44.8174 129.556C44.8174 132.536 47.2329 134.951 50.2127 134.951Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M48.5428 133.095C48.3051 132.986 48.0868 132.851 47.8812 132.703"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.0078 131.798C46.8151 131.522 46.6609 131.226 46.5453 130.911C46.1921 129.961 46.2113 128.869 46.6802 127.88"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.4135 87.9863L24.6624 93.9661"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3953 101.243C22.375 101.243 24.7906 98.8279 24.7906 95.8481C24.7906 92.8684 22.375 90.4528 19.3953 90.4528C16.4156 90.4528 14 92.8684 14 95.8481C14 98.8279 16.4156 101.243 19.3953 101.243Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M15.715 97.1649C15.625 96.9208 15.5672 96.6703 15.5287 96.4198"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M15.5416 95.1608C15.5994 94.8333 15.7022 94.5121 15.8435 94.2102C16.2674 93.2853 17.051 92.5274 18.0787 92.1613"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M42.5691 60.2261L26.4988 52.6085"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4439 55.6079C24.4236 55.6079 26.8392 53.1924 26.8392 50.2126C26.8392 47.2329 24.4236 44.8173 21.4439 44.8173C18.4641 44.8173 16.0486 47.2329 16.0486 50.2126C16.0486 53.1924 18.4641 55.6079 21.4439 55.6079Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.905 48.5428C18.0142 48.3051 18.1491 48.0868 18.2968 47.8812"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M19.2026 47.0077C19.4788 46.815 19.7743 46.6608 20.089 46.5452C21.0396 46.192 22.1315 46.2112 23.1207 46.6801"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M63.0199 41.4134L57.0402 24.6623"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M55.1582 24.7906C58.138 24.7906 60.5535 22.375 60.5535 19.3953C60.5535 16.4156 58.138 14 55.1582 14C52.1785 14 49.7629 16.4156 49.7629 19.3953C49.7629 22.375 52.1785 24.7906 55.1582 24.7906Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M53.8416 15.7149C54.0856 15.625 54.3361 15.5672 54.5866 15.5286"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M55.8457 15.5416C56.1733 15.5994 56.4944 15.7022 56.7963 15.8435C57.7212 16.2674 58.4791 17.051 58.8452 18.0787"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M84.8199 62.1981C88.7077 62.1981 91.8594 59.0464 91.8594 55.1585C91.8594 51.2707 88.7077 48.119 84.8199 48.119C80.932 48.119 77.7803 51.2707 77.7803 55.1585C77.7803 59.0464 80.932 62.1981 84.8199 62.1981Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M87.0038 50.5403C87.3121 50.6881 87.6011 50.8615 87.8645 51.0542"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M89.0074 52.2362C89.2579 52.5959 89.4571 52.9813 89.6112 53.3923C90.0672 54.6384 90.048 56.0579 89.4378 57.3489"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M65.5056 97.4857C69.3935 97.4857 72.5452 94.334 72.5452 90.4461C72.5452 86.5583 69.3935 83.4066 65.5056 83.4066C61.6178 83.4066 58.4661 86.5583 58.4661 90.4461C58.4661 94.334 61.6178 97.4857 65.5056 97.4857Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M64.6773 95.4881C64.3433 95.4303 64.0157 95.3468 63.7074 95.2312"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M62.2879 94.4092C61.9475 94.133 61.6456 93.8182 61.3951 93.465C60.6115 92.3988 60.239 91.0242 60.4766 89.6176"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M64.6773 63.2768C67.125 63.2768 69.1092 61.2926 69.1092 58.8449C69.1092 56.3973 67.125 54.4131 64.6773 54.4131C62.2297 54.4131 60.2455 56.3973 60.2455 58.8449C60.2455 61.2926 62.2297 63.2768 64.6773 63.2768Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M66.6364 60.7974C66.129 61.3048 65.4738 61.5745 64.8123 61.6067"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M64.0673 61.5427C63.5727 61.4335 63.1039 61.183 62.7185 60.7976C62.2625 60.3416 61.9991 59.7635 61.9285 59.1726"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.021 89.8616C101.469 89.8616 103.453 87.8774 103.453 85.4297C103.453 82.9821 101.469 80.9979 99.021 80.9979C96.5733 80.9979 94.5891 82.9821 94.5891 85.4297C94.5891 87.8774 96.5733 89.8616 99.021 89.8616Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.307 83.8627C101.712 84.4536 101.853 85.1473 101.764 85.8089"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.564 86.5221C101.365 86.9845 101.031 87.402 100.582 87.7103C100.049 88.0765 99.4321 88.2306 98.8347 88.1856"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M99.021 74.498C101.469 74.498 103.453 72.5138 103.453 70.0661C103.453 67.6185 101.469 65.6343 99.021 65.6343C96.5733 65.6343 94.5891 67.6185 94.5891 70.0661C94.5891 72.5138 96.5733 74.498 99.021 74.498Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.307 68.5054C101.712 69.0963 101.853 69.79 101.764 70.4515"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.564 71.1644C101.365 71.6269 101.031 72.0444 100.582 72.3527C100.049 72.7188 99.4321 72.8729 98.8347 72.828"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M80.9146 80.2079C83.3623 80.2079 85.3465 78.2237 85.3465 75.7761C85.3465 73.3284 83.3623 71.3442 80.9146 71.3442C78.467 71.3442 76.4828 73.3284 76.4828 75.7761C76.4828 78.2237 78.467 80.2079 80.9146 80.2079Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M78.3841 76.8875C78.0951 76.2324 78.0822 75.5258 78.2942 74.89"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M78.6219 74.2282C78.9046 73.8107 79.3092 73.4574 79.8038 73.2391C80.3947 72.9821 81.0241 72.9436 81.6086 73.0913"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M83.2138 99.9202C85.6615 99.9202 87.6457 97.936 87.6457 95.4883C87.6457 93.0407 85.6615 91.0565 83.2138 91.0565C80.7662 91.0565 78.782 93.0407 78.782 95.4883C78.782 97.936 80.7662 99.9202 83.2138 99.9202Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M80.677 96.5994C80.3879 95.9443 80.3751 95.2377 80.587 94.6019"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M80.9144 93.9401C81.197 93.5226 81.6017 93.1694 82.0963 92.951C82.6872 92.6941 83.3166 92.6555 83.9011 92.8033"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M58.8449 77.9921C61.2926 77.9921 63.2768 76.0079 63.2768 73.5603C63.2768 71.1126 61.2926 69.1284 58.8449 69.1284C56.3973 69.1284 54.4131 71.1126 54.4131 73.5603C54.4131 76.0079 56.3973 77.9921 58.8449 77.9921Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M56.3084 74.6716C56.0194 74.0165 56.0065 73.3099 56.2185 72.6741"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M56.5458 72.0123C56.8284 71.5948 57.233 71.2416 57.7276 71.0232C58.3185 70.7663 58.948 70.7277 59.5325 70.8755"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
