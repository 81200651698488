import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { enCustomer, enForm, enLawyer } from './en';
import { frCustomer, frForm, frLawyer } from './fr';

export const defaultNS = 'fr';

export const resources = {
  fr: {
    customer: frCustomer,
    lawyer: frLawyer,
    form: frForm
  },
  // Force french for testing environment
  ...(!process.env.REACT_APP_TEST_MODE && {
    en: { customer: enCustomer, lawyer: enLawyer, form: enForm }
  })
} as const;

export const availableLanguages = Object.keys(resources);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV !== 'production',
    // Default lang - commented as we leverage LanguageDecector
    // lng: 'en',
    // Fallback in case
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    fallbackLng: 'fr',
    resources
  });

export default i18n;
