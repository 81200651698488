import { Fade } from 'components/transition';
import { Article } from 'containers/articles';
import { useParams } from 'react-router-dom';

import { PageLayout } from 'features/customer/account/content';

const ArticlePage = () => {
  const { slug = '' } = useParams();

  return (
    <PageLayout previousHref="/account/resources">
      <Fade className="flex flex-col xl:flex-row gap-2 bg-gray-200">
        <Article slug={slug} />
      </Fade>
    </PageLayout>
  );
};

export default ArticlePage;
