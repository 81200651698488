import { handleLegacyStringData } from 'utils/api';

import { ApiCustomer, Customer } from 'types/customer';

// FROM API TO SPA
export function formatCustomerFromApi(apiRes: ApiCustomer): Customer {
  const customer: Customer = {
    id: apiRes.id,
    isB2b: apiRes.isB2b,
    firstName: handleLegacyStringData(apiRes.firstName),
    lastName: handleLegacyStringData(apiRes.lastName),
    email: apiRes.email,
    secondaryEmail: apiRes.secondaryEmail,
    phoneCellphone: apiRes.phone,
    onboardingStatus: apiRes.onboardingStatus
  };

  return customer;
}
