import { Variant } from 'types/icon';

export default function ConsultationsIcon({ variant }: { variant: Variant }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className="w-6 h-6"
    >
      {variant === Variant.Active ? (
        <path
          d="M19.125 14.3438L15.9375 12.9688C15.375 12.75 14.6875 12.9062 14.3125 13.4062L13.25 14.6562C11.625 13.75 10.25 12.375 9.34375 10.75L10.625 9.71875C11.0938 9.3125 11.25 8.625 11 8.0625L9.65625 4.875C9.375 4.25 8.6875 3.90625 8.03125 4.0625L5.09375 4.75C4.4375 4.875 4 5.4375 4 6.125C4 13.7812 10.2188 20 17.875 20C18.5625 20 19.125 19.5625 19.2812 18.9062L19.9375 15.9688C20.0938 15.3125 19.75 14.625 19.125 14.3438ZM18.2812 18.6875C18.25 18.875 18.0938 19.0312 17.875 19.0312C10.7812 19.0312 5 13.2188 5 6.125C5 5.90625 5.125 5.75 5.3125 5.71875L8.25 5.03125C8.28125 5.03125 8.3125 5 8.34375 5C8.5 5 8.65625 5.125 8.75 5.28125L10.0938 8.4375C10.1562 8.625 10.125 8.8125 9.96875 8.9375L8.40625 10.2188C8.21875 10.375 8.15625 10.625 8.28125 10.8438C9.3125 12.9375 11.0625 14.6875 13.1875 15.7188C13.375 15.8438 13.625 15.7812 13.7812 15.5938L15.0625 14.0312C15.1875 13.875 15.375 13.8438 15.5625 13.9062L18.7188 15.25C18.9062 15.3438 19.0312 15.5312 18.9688 15.75L18.2812 18.6875Z"
          fill="#AA97FF"
        />
      ) : (
        <path
          d="M19.125 14.3438L15.9375 12.9688C15.375 12.75 14.6875 12.9062 14.3125 13.4062L13.25 14.6562C11.625 13.75 10.25 12.375 9.34375 10.75L10.625 9.71875C11.0938 9.3125 11.25 8.625 11 8.0625L9.65625 4.875C9.375 4.25 8.6875 3.90625 8.03125 4.0625L5.09375 4.75C4.4375 4.875 4 5.4375 4 6.125C4 13.7812 10.2188 20 17.875 20C18.5625 20 19.125 19.5625 19.2812 18.9062L19.9375 15.9688C20.0938 15.3125 19.75 14.625 19.125 14.3438ZM18.2812 18.6875C18.25 18.875 18.0938 19.0312 17.875 19.0312C10.7812 19.0312 5 13.2188 5 6.125C5 5.90625 5.125 5.75 5.3125 5.71875L8.25 5.03125C8.28125 5.03125 8.3125 5 8.34375 5C8.5 5 8.65625 5.125 8.75 5.28125L10.0938 8.4375C10.1562 8.625 10.125 8.8125 9.96875 8.9375L8.40625 10.2188C8.21875 10.375 8.15625 10.625 8.28125 10.8438C9.3125 12.9375 11.0625 14.6875 13.1875 15.7188C13.375 15.8438 13.625 15.7812 13.7812 15.5938L15.0625 14.0312C15.1875 13.875 15.375 13.8438 15.5625 13.9062L18.7188 15.25C18.9062 15.3438 19.0312 15.5312 18.9688 15.75L18.2812 18.6875Z"
          fill="#1D1D1D"
        />
      )}
    </svg>
  );
}
