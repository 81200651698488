import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { SetState } from 'types/core';

interface ModalRescheduledConfirmationProps {
  isOpen: boolean;
  setIsOpen: SetState<boolean>;
  handleConfirm: () => void;
}

export default function ModalRescheduledConfirmation({
  isOpen,
  setIsOpen,
  handleConfirm
}: ModalRescheduledConfirmationProps) {
  const { t } = useTranslation('customer');
  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <LayoutModal isModalOpen={isOpen} handleModalClose={handleModalClose}>
      <div>
        <div className="text-left">
          <Title variant="h3">
            {t('account.consultations.reschedule.modal.title')}
          </Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium">
            {t('account.consultations.reschedule.modal.text')}
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              size="medium"
              label={t('account.consultations.reschedule.modal.keep')}
              onClick={handleModalClose}
            />
            <Button
              submit
              variant="tertiary"
              size="medium"
              label={t('account.consultations.reschedule.modal.continue')}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
