import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  usePastConsultationsQuery,
  useScheduledConsultationsQuery,
  useToCompleteConsultationsQuery
} from 'api/lawyer/consultations';

import { useAuth } from 'context';

interface GlobalContextType {
  onForceRefetchScheduled: () => void;
  onForceRefetchToComplete: () => void;
  onForceRefetchPast: () => void;
  onRefetchScheduled: (value: number) => void;
  onRefetchToComplete: (value: number) => void;
  onRefetchPast: (value: number) => void;
  scheduledPage: number;
  toCompletePage: number;
  pastPage: number;
  scheduled: any;
  toComplete: any;
  past: any;
}

const PaginationContext = createContext<GlobalContextType>(
  null as unknown as GlobalContextType
);

export function usePagination() {
  return useContext(PaginationContext);
}

export const PaginationContextProvider: React.FC<PaginationProviderProps> = (
  props
) => {
  const [pastPage, setPastPage] = useState(1);
  const [toCompletePage, setToCompletePage] = useState(1);
  const [scheduledPage, setScheduledPage] = useState(1);
  const contextRef = useRef<HTMLDivElement>(null);

  const { children } = props;

  const { lawyerId } = useAuth();

  const scheduled = useScheduledConsultationsQuery({
    lawyerId: lawyerId as number,
    page: scheduledPage
  });

  const toComplete = useToCompleteConsultationsQuery({
    lawyerId: lawyerId as number,
    page: toCompletePage
  });

  const past = usePastConsultationsQuery({
    lawyerId: lawyerId as number,
    page: pastPage
  });

  useEffect(() => {
    contextRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [scheduledPage, toCompletePage, pastPage]);

  const onRefetchScheduled = useCallback(
    async (count) => {
      setScheduledPage(count);
    },
    [setScheduledPage]
  );

  const onRefetchToComplete = useCallback(
    async (count) => {
      setToCompletePage(count);
    },
    [setToCompletePage]
  );

  const onRefetchPast = useCallback(
    (count) => {
      setPastPage(count);
    },
    [setPastPage]
  );

  const onForceRefetchScheduled = useCallback(async () => {
    scheduled.refetch();
  }, [scheduled]);

  const onForceRefetchToComplete = useCallback(async () => {
    toComplete.refetch();
  }, [toComplete]);

  const onForceRefetchPast = useCallback(async () => {
    past.refetch();
  }, [past]);

  return (
    <PaginationContext.Provider
      // eslint-disable-next-line
      value={{
        onForceRefetchScheduled,
        onForceRefetchToComplete,
        onForceRefetchPast,
        onRefetchScheduled,
        onRefetchToComplete,
        onRefetchPast,
        scheduledPage,
        toCompletePage,
        pastPage,
        scheduled,
        toComplete,
        past
      }}
    >
      <div ref={contextRef}>{children}</div>
    </PaginationContext.Provider>
  );
};

export type PaginationProviderProps = {
  children: ReactNode;
};
