import { LayoutModal } from 'features/shared/layout';

import { Consultation, ConsultationsFilter } from 'types/consultation';
import { SetState } from 'types/core';

import { CommonConsultationCard } from '../consultations/card';

interface ModalDisplayConsultationProps {
  currentConsultation: Consultation | undefined;
  isModalDisplayConsultationOpen: boolean;
  setIsModalDisplayConsultationOpen: SetState<boolean>;
}

export default function ModalDisplayConsultation({
  currentConsultation,
  isModalDisplayConsultationOpen,
  setIsModalDisplayConsultationOpen
}: ModalDisplayConsultationProps) {
  const handleModalCloseConsultation = () => {
    setIsModalDisplayConsultationOpen(false);
  };

  return (
    <LayoutModal
      isClosable={false}
      isModalOpen={isModalDisplayConsultationOpen}
      handleModalClose={handleModalCloseConsultation}
      withDefaultPadding={false}
    >
      {currentConsultation && (
        <CommonConsultationCard
          handleCloseConsultation={handleModalCloseConsultation}
          key={currentConsultation?.id}
          status={ConsultationsFilter.TOCOMPLETE}
          consultation={currentConsultation}
          isShowMode
        />
      )}
    </LayoutModal>
  );
}
