import { find } from 'lodash';

import { Restrictions, Subscription } from 'types/subscription';

import { formatProductFromApi } from '../products/formatting';

// FROM API TO SPA
export function formatSubscriptionFromApi(apiRes: any): Subscription {
  const hasAnActiveSubscription = find(apiRes, { active: true }) !== undefined;
  if (hasAnActiveSubscription) {
    const activeSubscriptionFromApi: any = find(apiRes, {
      active: true
    });

    const { id, startDate, endDate, product, active, revokable, status } =
      activeSubscriptionFromApi;
    const { skuCode } = formatProductFromApi(product);

    return {
      isOfferedByPartner: Boolean(product.offeredByPartner),
      status: status.value,
      active,
      revokable,
      id,
      startDate,
      endDate,
      product: formatProductFromApi(product),
      restrictions: extractRestrictions(product.restrictions),
      legalProtection:
        skuCode === process.env.REACT_APP_PRODUCT_PREMIUM_PLUS_SKU_CODE
    };
  }

  const sortedArrayOfSubByEndDate = apiRes.sort((a: any, b: any) =>
    a.endDate < b.endDate ? 1 : -1
  );

  const [inactiveSubscriptionFromApi] = sortedArrayOfSubByEndDate;
  const { id, startDate, endDate, product, active, revokable, status } =
    inactiveSubscriptionFromApi;

  const restrictions = extractRestrictions(product.restrictions);

  return {
    isOfferedByPartner: Boolean(product.offeredByPartner),
    status: status.value,
    active,
    revokable,
    id,
    startDate,
    endDate,
    product,
    restrictions,
    legalProtection: false
  };
}

type ApiRestriction = {
  resourceType: string;
  restriction: string;
  value: number | string[];
};

function convertLegacyRestrictionsToArrayIfNeeded(
  restrictions: Record<string, ApiRestriction> | ApiRestriction[] | null
): ApiRestriction[] {
  // Maintain compatibility with legacy data: Restrictions were previously stored as a dictionary rather than a list.
  // This conversion allows support for both formats until old data is fully migrated.
  if (!restrictions) {
    return [];
  }

  if (Array.isArray(restrictions)) {
    return restrictions;
  }

  return Object.values(restrictions);
}

function extractRestrictions(
  restrictions: Record<string, ApiRestriction> | ApiRestriction[] | null
): Restrictions {
  const restrictionsArray =
    convertLegacyRestrictionsToArrayIfNeeded(restrictions);

  const findRestriction = (resourceType: string, restriction: string) =>
    restrictionsArray.find(
      (r) => r.resourceType === resourceType && r.restriction === restriction
    )?.value;

  return {
    questions: {
      maxNb: (findRestriction('question', 'max_nb') as number) ?? null
    },
    consultations: {
      maxNb: (findRestriction('consultation', 'max_nb') as number) ?? null
    },
    thematicsForbidden:
      (findRestriction('question', 'themes_forbidden') as string[]) ?? []
  };
}

// FROM SPA TO API
