/* eslint-disable global-require */
import * as Sentry from '@sentry/react';
import ScrollToTop from 'components/ScrollToTop';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { ErrorPage } from 'pages/error';

import {
  AuthProvider,
  ReactQueryProvider,
  TrackingProvider
} from 'context/index';

import ReactQueryProviderV2 from './ReactQueryProviderV2';

function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <ErrorBoundary
        onError={(error) => Sentry.captureException(error)}
        FallbackComponent={ErrorPage}
      >
        <ReactQueryProviderV2>
          <ReactQueryProvider>
            <TrackingProvider>
              <AuthProvider>
                <ScrollToTop />
                {children}
                <Toaster />
              </AuthProvider>
            </TrackingProvider>
          </ReactQueryProvider>
        </ReactQueryProviderV2>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default AppProviders;
