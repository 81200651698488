import { Card } from 'components';
import { Fade } from 'components/transition';

const QuestionSectionSkeleton = () => (
  <Fade>
    <Card className="animate-pulse flex flex-row">
      <div className="h-3 w-1/3 mb-3 bg-gray-300 rounded" />
      <div className="flex gap-4 items-center mb-3">
        <div className="h-16 w-16 bg-gray-300 rounded-full" />
        <div className="h-12 w-1/3 bg-gray-300 rounded" />
      </div>
      <div className="h-52 w-full mb-3 bg-gray-300 rounded" />
    </Card>
  </Fade>
);

export default QuestionSectionSkeleton;
