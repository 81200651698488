import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

import { AccountContentLayout } from 'features/lawyer/account/content';
import { LinkedButton } from 'features/shared/buttons';
import { WhatsappIcon } from 'features/shared/icons';

export default function LawyerCarePage() {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('lawyer');

  return (
    <div className="min-h-screen">
      <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
        <AccountContentLayout title={t('account.sidebar.lawyercare')}>
          <div className="flex flex-col justify-around lg:flex-row gap-4">
            <div className="w-full">
              <LinkedButton
                label={tForm('label.email')}
                href="mailto:service-lawyercare@ekie.co"
                variant="primary"
                size="large"
                fullWidth
                icon={<MailIcon className="h-5 w-5" />}
              />
            </div>
            <div className="w-full">
              <LinkedButton
                label={t('account.contact.phone')}
                href="sms:+33644644109"
                variant="primary"
                size="large"
                fullWidth
                icon={<PhoneIcon className="h-5 w-5" />}
              />
            </div>
            <div className="w-full">
              <LinkedButton
                isExternal
                label={t('account.contact.whatsapp')}
                href="https://api.whatsapp.com/send?phone=33644644109"
                variant="primary"
                size="large"
                fullWidth
                icon={<WhatsappIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </AccountContentLayout>
      </div>
    </div>
  );
}
