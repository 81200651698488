export default function TimerIcon() {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.0613 110.953C78.7464 110.953 94.7043 94.9954 94.7043 75.3103C94.7043 55.6252 78.7464 39.6673 59.0613 39.6673C39.3762 39.6673 23.4183 55.6252 23.4183 75.3103C23.4183 94.9954 39.3762 110.953 59.0613 110.953Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M59.2877 104.624C75.336 104.624 88.3458 91.614 88.3458 75.5656C88.3458 59.5172 75.336 46.5075 59.2877 46.5075C43.2393 46.5075 30.2296 59.5172 30.2296 75.5656C30.2296 91.614 43.2393 104.624 59.2877 104.624Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M64.3514 69.9545C69.4416 69.9545 73.5681 65.8281 73.5681 60.7378C73.5681 55.6476 69.4416 51.5211 64.3514 51.5211C59.2611 51.5211 55.1347 55.6476 55.1347 60.7378C55.1347 65.8281 59.2611 69.9545 64.3514 69.9545Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M77.0895 42.944C77.2763 42.944 77.4632 42.944 77.6527 42.944C81.2067 42.9434 84.6469 41.6903 87.3686 39.4047C90.0903 37.1191 91.9193 33.9474 92.5343 30.447C93.1493 26.9465 92.5109 23.3413 90.7312 20.2649C88.9516 17.1885 86.1446 14.8377 82.8035 13.6258C79.4625 12.4138 75.8012 12.4182 72.463 13.6381C69.1249 14.8581 66.3235 17.2155 64.5512 20.2962C62.779 23.3768 62.1491 26.9835 62.7725 30.4825C63.3959 33.9815 65.2325 37.1488 67.9596 39.4279"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M67.67 32.9588C66.6168 30.9046 66.223 28.5754 66.5425 26.2891C66.8619 24.0029 67.879 21.8708 69.4549 20.184C71.0308 18.4971 73.0888 17.3375 75.3481 16.8635C77.6074 16.3894 79.9579 16.624 82.079 17.5352C84.2 18.4464 85.9883 19.9898 87.1997 21.9549C88.4112 23.92 88.9868 26.211 88.8481 28.5153C88.7094 30.8196 87.8631 33.025 86.4247 34.8306C84.9863 36.6361 83.0258 37.9539 80.8108 38.6041"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M78.329 39.0358C78.6924 39.0152 79.0544 38.9757 79.4137 38.9173V38.9173"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M75.9682 54.3846L60.0035 74.4234C59.7794 74.3251 59.5375 74.2741 59.2929 74.2734C58.9673 74.2736 58.6482 74.3644 58.3713 74.5355C58.0944 74.7067 57.8705 74.9515 57.7247 75.2426C57.5789 75.5336 57.5169 75.8595 57.5456 76.1838C57.5744 76.5081 57.6927 76.818 57.8875 77.0789L48.0497 89.4196L49.3261 90.4223L58.7349 77.6947C59.0846 77.8094 59.4615 77.8112 59.8122 77.6997C60.1629 77.5882 60.4697 77.3691 60.6889 77.0736C60.9082 76.778 61.0289 76.421 61.0338 76.053C61.0388 75.685 60.9279 75.3248 60.7167 75.0234L75.9682 54.3846Z"
        fill="#1D1D1D"
      />
      <path
        d="M59.4535 52.9318L59.9194 54.3851"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5388 52.5292L67.8753 54.0509"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M55.9031 57.0585L57.3485 58.0037"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M55.4031 62.9383L57.1038 62.4012"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M58.1928 67.5943L59.4538 66.1568"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M62.9829 68.0833L62.5643 69.7814"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5385 67.2937L69.331 68.4943"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M71.3336 63.875L72.5868 64.8807"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M72.2231 60.1272H73.5473"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M70.8387 56.6694L71.9603 55.5347"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M64.3513 51.5238V53.2903"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M64.5884 61.957C65.093 61.957 65.502 61.548 65.502 61.0435C65.502 60.5389 65.093 60.1299 64.5884 60.1299C64.0839 60.1299 63.6749 60.5389 63.6749 61.0435C63.6749 61.548 64.0839 61.957 64.5884 61.957Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1566 52.1239L64.167 60.1278H65.1566V52.1239Z"
        fill="#1D1D1D"
      />
      <path
        d="M71.9705 14.678C71.9705 14.678 60.7931 19.0285 64.5461 33.2509"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M78.1868 39.5885C78.1868 39.5885 80.9662 40.0728 84.3536 37.7461"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M83.1073 28.988L70.9033 25.1236C70.5264 25.0043 70.1241 25.2131 70.0047 25.59L67.2447 34.3065C67.1254 34.6834 67.3342 35.0857 67.7111 35.205L79.9151 39.0694C80.2921 39.1887 80.6944 38.9799 80.8137 38.603L83.5737 29.8865C83.6931 29.5096 83.4843 29.1073 83.1073 28.988Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M78.4607 38.6146L78.3291 39.0358L77.0894 42.9443L76.6578 44.3077"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M67.5647 40.6888L67.9595 39.4386L69.1545 35.6669"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M71.2995 25.2554L68.1779 35.3597"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M72.6759 25.6923L69.557 35.7965"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M74.2367 26.1871L71.1152 36.2913"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M75.7974 26.6793L72.6758 36.7835"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M77.3581 27.174L74.2366 37.2783"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M78.9187 27.6688L75.7971 37.7731"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M80.5926 28.1978L77.4711 38.302"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M82.1532 28.6926L79.0316 38.7968"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M40.9964 47.1707C40.9964 47.1707 26.2055 55.3294 25.2527 75.5656"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M38.5882 102.889C38.5882 102.889 59.814 119.625 83.4873 99.7601"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M67.07 42.3597C67.07 42.3597 72.4787 43.6099 75.9687 45.884"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M31.5507 66.8753L34.8907 67.5938"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M41.5754 52.5292L43.2077 55.5357"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M53.087 47.1707L53.5371 50.3476"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M69.1547 48.2261L68.2069 50.9878"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M81.2721 56.5611L79.416 58.3908"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M84.3533 69.7809L87.5854 68.9334"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M83.0584 84.7139L86.4511 85.9062"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M75.4734 95.1334L77.5611 98.161"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M64.167 100.526L64.6618 104.127"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M50.9765 99.7601L49.5841 102.963"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M39.097 91.5698L36.6117 93.7391"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M33.9775 80.0529L30.6875 80.7425"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6257 64.9935L61.5484 58.9219"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M46.9418 86.7141L69.3308 79.1791"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M53.4686 90.4804L59.9196 87.9484"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M56.6926 98.8918L64.1675 96.3598"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M67.5963 93.2858L83.0583 87.9484"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M36.6117 82.0689L80.3423 66.8753"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M50.2815 82.7847L83.4874 70.4467"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6317 30.6846L36.3875 34.4782C35.9663 34.6721 35.7819 35.1707 35.9758 35.592L38.3908 40.8402C38.5846 41.2614 39.0833 41.4458 39.5045 41.252L47.7487 37.4583C48.1699 37.2645 48.3543 36.7659 48.1604 36.3446L45.7454 31.0964C45.5516 30.6751 45.0529 30.4908 44.6317 30.6846Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M42.3913 43.7971L40.7147 40.6887L46.3893 38.0777L48.0475 41.4019"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M36.3881 34.4747L39.5044 41.2469"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M37.6513 33.8931L40.7676 40.6652"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M38.7252 33.3983L41.8415 40.1704"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M39.7989 32.9035L42.9152 39.6756"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M41.1517 32.2798L44.2706 39.0545"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M42.3912 31.7113L45.5102 38.4835"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M43.5676 31.1691L46.6865 37.9413"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M44.623 30.6849L47.7393 37.457"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M102.784 58.4123L98.6712 50.3227C98.461 49.9094 97.9556 49.7447 97.5422 49.9548L92.3924 52.5732C91.979 52.7833 91.8143 53.2888 92.0245 53.7021L96.1374 61.7917C96.3476 62.205 96.8531 62.3698 97.2664 62.1596L102.416 59.5413C102.83 59.3311 102.994 58.8257 102.784 58.4123Z"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M89.5905 56.6849L92.6305 54.8899L95.4625 60.4566L92.2041 62.2437"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M98.6734 50.3211L92.0276 53.7006"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M99.3025 51.5606L92.6567 54.9401"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M99.8395 52.616L93.1936 55.9955"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M100.374 53.6687L93.7278 57.0482"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M101.05 54.9978L94.4043 58.3773"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M101.669 56.2137L95.0227 59.5932"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M102.255 57.3691L95.6096 60.7459"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M102.782 58.4034L96.136 61.7829"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M50.4815 7.67996L52.3581 21.1216"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M31.5744 15.4097L38.8992 26.8352"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M18.6074 27.3161L28.2299 36.8887"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M8.98014 42.3597L21.0031 48.6581"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M3.12688 60.5405L16.3474 63.6067"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M90.7253 120.321L85.8508 107.656"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M107.39 108.512L97.6677 99.0416"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M117.323 93.9758L105.782 86.8325"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M123.292 77.1423L110.156 73.7312"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
      <path
        d="M124.874 58.1086L111.303 58.1165"
        stroke="#1D1D1D"
        strokeWidth="0.340535"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
