import { ModalSubscriptionCancel } from 'containers/subscription';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomer } from 'hooks';

import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';

import { formatDateToIntl } from 'utils/date';

import { Subscription } from 'types/subscription';

export default function SubscriptionStatus({
  subscription
}: {
  subscription: Subscription;
}) {
  const { t } = useTranslation('customer');
  const { isB2b } = useCustomer();
  const { id: subscriptionId, active, revokable } = subscription;
  const [isModalCancelSubscriptionOpen, setIsModalCancelSubscriptionOpen] =
    useState<boolean>(false);

  return (
    <>
      <Alert
        type={active ? 'success' : 'warning'}
        title={t(
          active
            ? 'account.subscription.active'
            : 'account.subscription.inactive'
        )}
      >
        <div>
          <p>
            {active &&
              t('account.subscription.active.paragraph', {
                productName: subscription.product.name,
                endDate: formatDateToIntl(new Date(subscription.endDate))
              })}
            {!active &&
              isB2b &&
              t('account.subscription.inactive.b2b.paragraph', {
                endDate: formatDateToIntl(new Date(subscription.endDate))
              })}
            {!active && !isB2b && t('account.subscription.inactive.paragraph')}
          </p>
          <div className="flex justify-end items-center gap-4 pt-2">
            {revokable && (
              <Button
                size="small"
                variant="transparent"
                onClick={() => setIsModalCancelSubscriptionOpen(true)}
                className="cursor-pointer text-gray-700 hover:underline"
              >
                {t('account.subscription.cancel.cta')}
              </Button>
            )}
          </div>
        </div>
      </Alert>

      <ModalSubscriptionCancel
        subscriptionId={subscriptionId}
        isModalSubscriptionCancelOpen={isModalCancelSubscriptionOpen}
        setIsModalSubscriptionCancelOpen={setIsModalCancelSubscriptionOpen}
      />
    </>
  );
}
