import { ErrorBoundary } from '@sentry/react';
import { Section, Title } from 'components';
import FeatureErrorCard from 'components/card/FeatureErrorCard';
import { CustomerSubscription } from 'containers/subscription';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';
import { MemberProfile } from 'features/member';

function MemberPage() {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Section gutterBottom>
        <Title gutterBottom variant="h2">
          {t('account.profile.title')}
        </Title>
        <div className="flex w-full flex-col space-y-2 max-w-[720px]">
          <ErrorBoundary
            fallback={<FeatureErrorCard title={t('account.profile.error')} />}
          >
            <MemberProfile />
          </ErrorBoundary>
        </div>
      </Section>
      <Section>
        <Title gutterBottom variant="h2">
          {t('account.subscription.section')}
        </Title>
        <CustomerSubscription />
      </Section>
    </PageLayout>
  );
}

export default MemberPage;
