import { useQuery } from 'react-query';

import userReviewData from './userReviewData.json';

export interface UserReviewResponse {
  userReview: UserReview | null;
}

export interface UserReview {
  id: string;
  content: {
    en: string;
    fr: string;
  };
  fullName: string;
  note: number;
}

export function useGetRandomUserReview() {
  const random = Math.floor(Math.random() * 50);

  return useQuery([`random-user-review`], (): UserReviewResponse | null => ({
    userReview: userReviewData[random]
  }));
}
