import { useMutation, useQuery } from '@tanstack/react-query';

import {
  Appointment,
  NewAppointmentVote,
  NewUnauthenticatedAppointmentVote
} from 'types/consultation';

import {
  createAppointmentVote,
  createUnauthenticatedAppointmentVote,
  getAppointmentVoteLink
} from './api';

export function useAppointmentLinkQuery({
  voteLinkId
}: {
  voteLinkId: string;
}) {
  return useQuery({
    queryKey: ['appointment-vote-link', voteLinkId],
    queryFn: () => getAppointmentVoteLink(voteLinkId)
  });
}

export function useCreateAppointmentVoteMutation() {
  return useMutation({
    mutationFn: ({
      appointmentId,
      newAppointmentVote
    }: {
      appointmentId: Appointment['id'];
      newAppointmentVote: NewAppointmentVote;
    }) => createAppointmentVote(appointmentId, newAppointmentVote)
  });
}

export function useCreateUnauthenticatedAppointmentVoteMutation() {
  return useMutation({
    mutationFn: ({
      appointmentId,
      newAppointmentVote
    }: {
      appointmentId: Appointment['id'];
      newAppointmentVote: NewUnauthenticatedAppointmentVote;
    }) =>
      createUnauthenticatedAppointmentVote(appointmentId, newAppointmentVote)
  });
}
