import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { EyesIcon, FileUploadedIcon } from 'features/shared/icons';

import { Consultation } from 'types/consultation';

export interface ConsultationCardDocumentSectionProps {
  className?: string;
  document: NonNullable<Consultation['document']>;
}

function ConsultationCardDocumentSection({
  document,
  className = ''
}: ConsultationCardDocumentSectionProps) {
  const { t } = useTranslation('lawyer');

  return (
    <section
      id="document-section"
      className={classNames('flex flex-col gap-4 items-start', className)}
    >
      <div className="flex gap-4 items-center pl-1">
        <FileUploadedIcon className="w-4 h-4" />
        <span className="font-bold text-sm">
          {t('account.consultations.card.documentAttached')}
        </span>
      </div>
      <div className="pl-9 w-full">
        <div className="flex justify-between items-center border-gray-400 border-2 rounded-lg p-2">
          <span>{document.name}</span>
          <a
            rel="noreferrer"
            target="_blank"
            href={document.url}
            className="hover:underline cursor-pointer fill-black"
          >
            <EyesIcon fill="#9022FF" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default ConsultationCardDocumentSection;
