import { useQuery } from '@tanstack/react-query';
import { Section } from 'components';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getMemberQuestions, isActiveMemberQuestion } from 'services/question';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

const LastMemberQuestionReminder = () => {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ['member-questions-list', 'personal'],
    queryFn: () => getMemberQuestions({ source: 'personal', pageSize: 1 })
  });

  if (!data?.items.length || isLoading || !isSuccess) return <div />;

  const [lastQuestion] = data.items;

  if (!isActiveMemberQuestion(lastQuestion)) return <div />;

  return (
    <Fade>
      <Section gutterBottom className="!py-3" color="white">
        <div className="flex justify-between">
          {t('account.home.reminder.question', {
            askedAt: formatDateToIntl(
              new Date(lastQuestion.created_at),
              'long',
              locale
            )
          })}
          <NavLink
            className="text-purple-900 underline underline-offset-4	"
            to={`/questions/${lastQuestion.id}`}
          >
            Voir la question
          </NavLink>
        </div>
      </Section>
    </Fade>
  );
};

export default LastMemberQuestionReminder;
