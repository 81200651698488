import { Title } from 'components';

import { Question } from 'types/question';

interface QuestionTitleProps {
  question: Question;
}

export default function QuestionTitle({ question }: QuestionTitleProps) {
  return (
    <div className="break-words truncate line-clamp-1">
      <Title variant="h2">{question.title}</Title>
    </div>
  );
}
