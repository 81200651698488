import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { BalanceIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';

import { SetState } from 'types/core';

interface ModalAnswerSuccessProps {
  isOpen: boolean;
  setIsOpen: SetState<boolean>;
}

export default function ModalAnswerSuccess({
  isOpen,
  setIsOpen
}: ModalAnswerSuccessProps) {
  const { t } = useTranslation('lawyer');

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <LayoutModal isModalOpen={isOpen} handleModalClose={handleModalClose}>
      <div>
        <div className="flex flex-col items-center">
          <div className="pb-2">
            <BalanceIcon />
          </div>
          <Title variant="h3">
            {t('account.questions.answered.modal.title')}
          </Title>
        </div>
        <div className="text-center">
          <p className="text-md mt-2 font-medium ">
            {t('account.questions.answered.modal.p')}
          </p>
        </div>
      </div>
    </LayoutModal>
  );
}
