import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';

import { CameraIcon, CrossIcon, FilterIcon } from 'features/shared/icons';

import { BookingOption, LawyerAvailabilityDaySlots } from 'types/lawyer';

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { duration: 0 }
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0 }
  }
};

interface FilterDropdownProps {
  setFilteredSlots?: any;
  lawyerAvailabiltySlots: LawyerAvailabilityDaySlots[];
}

function FilterDropdown({
  lawyerAvailabiltySlots,
  setFilteredSlots
}: FilterDropdownProps) {
  const { t } = useTranslation('customer');
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [currentOption, setCurrentOption] = useState<BookingOption>();

  useClickOutside(modalRef, () => setIsOpen(false));

  const onFilterSlot = (value: BookingOption) => {
    const newAvailability = lawyerAvailabiltySlots?.map((object) => {
      const newSlots = object.slots.filter(
        (item: any) => item?.option === value
      );
      return {
        ...object,
        slots: newSlots
      };
    });

    // Filter if empty slots
    const availabilityWithoutEmpty = newAvailability?.filter(
      (item) => item.slots && item.slots.length > 0
    );
    setFilteredSlots(availabilityWithoutEmpty);
  };

  const onSelectOption = (value: BookingOption) => {
    onFilterSlot(value);
    setCurrentOption(value);
    setIsOpen(false);
  };

  const onClearOption = () => {
    setCurrentOption(undefined);
    setIsOpen(false);
    setFilteredSlots(lawyerAvailabiltySlots);
  };

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      className="w-fit decoration-purple-600 relative z-10"
      ref={modalRef}
    >
      {!currentOption ? (
        <motion.button
          type="button"
          whileTap={{ scale: 0.97 }}
          onClick={() => setIsOpen(!isOpen)}
          className={clsx(
            'relative p-3 bg-purple-300 rounded w-auto flex flex-row items-center justify-center h-10',
            { 'border border-purple-400 !rounded-b-none ': isOpen }
          )}
        >
          <div className="w-6 h-6 mr-2">
            <FilterIcon />
          </div>
          <div
            className={clsx(
              'font-sans font-medium text-purple-900 tracking-wide text-[13px] leading-[18px] uppercase'
            )}
          >
            {t('account.consultations.booking.scheduling.filter.default')}
          </div>
        </motion.button>
      ) : (
        <motion.div className="border border-purple-900 rounded flex flex-row justify-center items-center px-[18px] py-[8px] gap-x-4">
          <p className="font-sans text-xs text-purple-900 font-bold tracking-[0.08em] uppercase">
            {currentOption === BookingOption.BookingVideo
              ? t('account.consultations.booking.scheduling.filter.visio')
              : t('account.consultations.booking.scheduling.filter.call')}
          </p>
          <button
            type="button"
            className="w-6 h-6 stroke-purple-900 hover:opacity-60"
            onClick={onClearOption}
          >
            <CrossIcon />
          </button>
        </motion.div>
      )}
      <motion.ul
        className="flex flex-col bg-white absolute z-10 left-0 top-10 w-full border border-[#F0F0F0] rounded-b shadow-lg"
        variants={{
          open: {
            clipPath: 'inset(0% 0% 0% 0%)',
            transition: {
              type: 'spring',
              duration: 0.1
            }
          },
          closed: {
            clipPath: 'inset(10% 50% 90% 50%)',
            transition: {
              type: 'spring',
              duration: 0.1
            }
          }
        }}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      >
        <motion.li
          className="cursor-pointer text-black font-sans text-base block py-2 px-4 border-b border-[#F0F0F0] hover:bg-gray-400 whitespace-pre"
          variants={itemVariants}
          onClick={() => onSelectOption(BookingOption.BookingPhone)}
        >
          {t('account.consultations.booking.scheduling.filter.call')}
        </motion.li>
        <motion.li
          className="cursor-pointer text-black font-sans text-base flex py-2 px-4 justify-between items-center hover:bg-gray-400"
          variants={itemVariants}
          onClick={() => onSelectOption(BookingOption.BookingVideo)}
        >
          <p> {t('account.consultations.booking.scheduling.filter.visio')}</p>
          <div className="w-5 h-5 mr-2 fill-purple-600">
            <CameraIcon />
          </div>
        </motion.li>
      </motion.ul>
    </motion.nav>
  );
}

export { FilterDropdown };
