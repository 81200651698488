import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useLawyerAccount } from 'hooks';

import {
  ModalQuestionAccepted,
  QuestionAssignationCtas,
  QuestionCard,
  QuestionTitle
} from 'features/lawyer/account/questions/assignations';
import { BackQuestions } from 'features/lawyer/account/questions/nav';

export default function AssignationsPage() {
  const queryClient = useQueryClient();
  const [isModalQuestionAccepted, setIsModalQuestionAccepted] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { questionAssignations } = useLawyerAccount();

  const correspondingQuestionAssignation = useMemo(() => {
    const correspondingEntity = questionAssignations.find(
      (questionAssignation) =>
        questionAssignation.id.toString() === searchParams.get('id')
    );

    return correspondingEntity;
  }, [questionAssignations, searchParams]);

  const handleAssignationAccept = () => {
    queryClient.invalidateQueries(['question-assignations']);
    queryClient.invalidateQueries(['questions-to-answer']);
    setIsModalQuestionAccepted(true);
  };

  if (!correspondingQuestionAssignation)
    return (
      <ModalQuestionAccepted
        isModalQuestionAccepted={isModalQuestionAccepted}
        setIsModalQuestionAccepted={setIsModalQuestionAccepted}
      />
    );

  return (
    <div className="overflow-y-auto max-h-screen">
      <div className="z-40 sticky top-0 bg-gray-paper">
        <div className="pt-6 lg:pt-10 pb-6 px-6 border-b border-gray-500">
          <div className="block pb-4 lg:hidden lg:pb-0">
            <BackQuestions />
          </div>
          <QuestionTitle question={correspondingQuestionAssignation.question} />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 p-6">
          <div>
            <QuestionCard
              question={correspondingQuestionAssignation.question}
            />
          </div>
          <div>
            <QuestionAssignationCtas
              questionAssignation={correspondingQuestionAssignation}
              handleAssignationAccept={handleAssignationAccept}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
