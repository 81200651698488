import jwtDecode from 'jwt-decode';
import { apiPrivatePythonClient } from 'services/axios';

import { setNewAuthStorage } from 'context/auth/auth.utils';

import { AuthLocalStorage } from 'types/auth';

interface AuthRefreshTokenResponse {
  access_token: string;
  refresh_token: string;
}

export const getAccessTokenByRefreshToken =
  async (): Promise<AuthLocalStorage> => {
    try {
      const { data } =
        await apiPrivatePythonClient.post<AuthRefreshTokenResponse>(
          '/auth/token/refresh',
          {
            refresh_token: window.localStorage.getItem('refreshToken')
          }
        );

      const { sub } = jwtDecode<{ sub: string }>(data.access_token);

      const authenticationData = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        email: sub
      };
      setNewAuthStorage(authenticationData);

      return authenticationData;
    } catch (error) {
      return Promise.reject(error);
    }
  };
