import { TFunctionTranslationFormValidation } from 'schemas';
import * as yup from 'yup';

export const answerRateSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object().shape({
    note: yup
      .number()
      .min(0, t('schema.note.min'))
      .max(5, t('schema.note.max'))
      .required(t('schema.note.required')),
    comment: yup.string().when('note', {
      is: (note: number) => note >= 0 && note <= 3,
      then: (schema) =>
        schema
          .required(t('schema.comment.required'))
          .min(20, t('schema.comment.min'))
          .max(120, t('schema.comment.max')),
      otherwise: (schema) => schema.nullable()
    })
  });
