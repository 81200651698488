import classNames from 'classnames';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { formatDateToIntl } from 'utils/date';

import { AnswerStatus } from 'types/answer';
import { Question } from 'types/question';

import { ToAnswerDateIndicator } from '../shared';

export default function QuestionToAnswerCard({
  question
}: {
  question: Question;
}) {
  const [searchParams] = useSearchParams();

  const isSelected = useMemo(
    () => searchParams.get('id') === question.id,
    [question.id, searchParams]
  );

  const publishedAnswer = question?.answers.find(
    (a) => a.status === AnswerStatus.PUBLISHED
  );

  return (
    <div
      className={classNames(
        'pt-6 pl-8 pr-6 relative',
        isSelected
          ? 'bg-purple-100 border-l-4 border-purple-900'
          : 'border-l-4 border-white bg-white'
      )}
    >
      <div>
        <div className="flex justify-end pb-2">
          <span className="text-gray-700 text-sm flex-shrink-0">
            {formatDateToIntl(question.createdDate)}
          </span>
        </div>
        <div>
          <div className="pb-2">
            <span className="font-medium break-words line-clamp-1">
              {question.title}
            </span>
          </div>
          <div className="pb-4">
            <span className="text-gray-700 text-sm break-words line-clamp-2">
              {question.content}
            </span>
          </div>
          <ToAnswerDateIndicator
            answerDeadLine={
              publishedAnswer?.additionalRequest?.answerDeadLine ||
              question.answerDeadLine
            }
          />
        </div>
        <div className="px-4 pt-6">
          <div className="border-b border-gray-500" />
        </div>
      </div>
    </div>
  );
}
