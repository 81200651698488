import { isAfter, subMonths } from 'date-fns';

import { apiPrivatePythonClient } from './axios';

const LIMIT_DATE = subMonths(new Date(), 2);

export type AskMemberQuestionPayload = {
  source: QuestionSource;
  content: string;
};

export type QuestionStatus =
  | 'awaiting_process'
  | 'awaiting_answer'
  | 'answered'
  | 'reviewed'
  | 'archived';

export type QuestionSource = 'personal' | 'cse';

export type MemberQuestion = {
  id: number;
  content: string;
  created_at: string;
  assigned_at: string | null;
  member_full_name: string;
  status: QuestionStatus;
  can_share_question: boolean;
  additional_request_id: string | null;
} & (
  | {
      can_rate_answer: true;
      can_ask_consultation: boolean;
      answer_id: number;
      lawyer_id: number;
    }
  | {
      can_rate_answer: boolean;
      can_ask_consultation: true;
      answer_id: number;
      lawyer_id: number;
    }
  | {
      can_rate_answer: false;
      can_ask_consultation: false;
      answer_id: number | null;
      lawyer_id: number | null;
    }
);

export type QuestionSharedMember = {
  member_id: number;
  member_name: string;
  member_email: string;
  shared: boolean;
};

export type MemberQuestionAdditionalRequest = {
  id: string;
  request: string;
  request_answer: string | null;
  created_at: string;
  answered_at: string | null;
  lawyer_full_name: string | null;
  lawyer_avatar_url: string | null;
};

export type GetMemberQuestionsPayload = {
  source: QuestionSource;
  page?: number;
  pageSize?: number;
};

export type GetMemberQuestionsResponse = {
  total: number;
  items: MemberQuestion[];
};

export type GetQuestionSharedMembersResponse = {
  shares: QuestionSharedMember[];
};

export const DEFAULT_QUESTIONS_PAGE_SIZE = 10;

export async function getMemberQuestions({
  source,
  page,
  pageSize = DEFAULT_QUESTIONS_PAGE_SIZE
}: GetMemberQuestionsPayload): Promise<GetMemberQuestionsResponse> {
  const { data } = await apiPrivatePythonClient.get<GetMemberQuestionsResponse>(
    `/member/questions`,
    {
      params: {
        source,
        page,
        page_size: pageSize,
        order_by: '-created_at'
      }
    }
  );

  return data;
}

export async function getMemberQuestion(
  questionId: number
): Promise<MemberQuestion> {
  const { data } = await apiPrivatePythonClient.get(
    `/member/questions/${questionId}`
  );

  return data;
}

export async function getMemberQuestionAdditionalRequest(
  additionRequestId: string
): Promise<MemberQuestionAdditionalRequest> {
  const { data } = await apiPrivatePythonClient.get(
    `/member/additional-requests/${additionRequestId}`
  );

  return data;
}

export async function askMemberQuestion(
  data: AskMemberQuestionPayload
): Promise<MemberQuestion> {
  const { data: response } = await apiPrivatePythonClient.post(
    '/member/questions',
    data
  );

  return response;
}

export function isActiveMemberQuestion(question: MemberQuestion): boolean {
  return (
    ['awaiting_process', 'awaiting_answer', 'answered'].includes(
      question.status
    ) && isAfter(new Date(question.created_at), LIMIT_DATE)
  );
}

export async function getQuestionSharedMembers(
  questionId: number
): Promise<GetQuestionSharedMembersResponse> {
  const res = await apiPrivatePythonClient.get(
    `/member/questions/${questionId}/shares`
  );

  return res.data;
}

export async function shareQuestion(
  questionId: number,
  questionSharedMembers: QuestionSharedMember[]
): Promise<void> {
  return apiPrivatePythonClient.put(`/member/questions/${questionId}/shares`, {
    shares: questionSharedMembers.map((sharedMember) => ({
      ...sharedMember,
      shared: true
    }))
  });
}
