import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { MemberProfileSkeleton } from 'components/skeleton';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { authNewPasswordSchema } from 'schemas';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useUpdateCustomerPasswordMutation } from 'api/customer/mutations.index';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { Toast } from 'features/shared/toasts';

interface NewPasswordInput {
  password: string;
  passwordConfirmation: string;
}

interface MemberUpdatePasswordProps {
  submitLabel?: string;
  onSuccess: () => void;
}

const MemberUpdatePassword = ({
  submitLabel = 'password.update.cta',
  onSuccess
}: MemberUpdatePasswordProps) => {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('customer');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NewPasswordInput>({
    resolver: yupResolver(authNewPasswordSchema(tForm))
  });
  const {
    data: member,
    isLoading: isAuthenticatedMemberLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const { mutate, isPending } = useUpdateCustomerPasswordMutation();

  const onSubmit = (data: NewPasswordInput) => {
    isSuccess &&
      mutate(
        { newPassword: data.password, customerId: member.id },
        {
          onSuccess: () => {
            onSuccess();
            toast.custom(
              <Toast
                type="success"
                title={t('general.success')}
                message={t('account.profile.update.success')}
              />,
              { position: 'bottom-left', duration: 3000 }
            );
          },
          onError: () => {
            toast.custom(
              <Toast
                type="error"
                title={t('general.error')}
                message={t('error.occurred')}
              />,
              { position: 'bottom-left', duration: 3000 }
            );
          }
        }
      );
  };

  if (isAuthenticatedMemberLoading || !isSuccess)
    return <MemberProfileSkeleton />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4 mt-3">
        <div>
          <TextInputGroup
            name="password"
            label={`${tForm('label.password')}`}
            type="password"
            register={register}
            error={errors.password}
            placeholder="**********"
          />
        </div>
        <div>
          <TextInputGroup
            name="passwordConfirmation"
            label={`${tForm('label.confirmPassword')}`}
            type="password"
            register={register}
            error={errors.passwordConfirmation}
            placeholder="**********"
          />
        </div>
        <div>
          <Button
            submit
            fullWidth
            isLoading={isPending}
            size="medium"
            variant="tertiary"
            label={t(submitLabel as any)}
          />
        </div>
      </div>
    </form>
  );
};

export default MemberUpdatePassword;
