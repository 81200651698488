import { useMemo } from 'react';

export const useOptions = () =>
  useMemo(
    () => ({
      style: {
        base: {
          fontFamily: 'Satoshi, system-ui, sans-serif',
          fontSize: '16px',
          color: '#1D1D1D',
          '::placeholder': {
            color: '#939393'
          }
        },
        invalid: {
          color: '#df1b41'
        }
      }
    }),
    []
  );
