import { Section } from 'components';
import AdministratorList from 'containers/administrators/AdministratorList';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';

const AdministratorsPage = () => {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Section title={t('admin.settings.title')}>
        <AdministratorList />
      </Section>
    </PageLayout>
  );
};

export default AdministratorsPage;
