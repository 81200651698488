import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useCancelAppointmentConsultationMutation } from 'api/lawyer/consultations';

import { usePagination } from 'pages/lawyer/context';

import { Button } from 'features/shared/buttons';
import { BalanceIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';

import { Appointment, ConsultationCancelIntent } from 'types/consultation';
import { SetState } from 'types/core';

export interface ConsultationCancelIntentInput {
  intent: ConsultationCancelIntent;
}

interface ModalCancelAppointmentConsultationProps {
  appointmentId: Appointment['id'] | undefined;
  isOpen: boolean;
  setIsOpen: SetState<boolean>;
}

export default function ModalCancelAppointmentConsultation({
  appointmentId,
  isOpen,
  setIsOpen
}: ModalCancelAppointmentConsultationProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('lawyer');
  const { onForceRefetchScheduled, onForceRefetchToComplete } = usePagination();

  const { mutateAsync, isLoading: isCancelAppointmentLoading } =
    useCancelAppointmentConsultationMutation();

  const handleModalQuestionAcceptedClose = () => {
    setIsOpen(false);
  };

  const onSubmit = () => {
    mutateAsync(
      {
        appointmentId: appointmentId?.toString() ?? ''
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['consultations']);
          onForceRefetchScheduled();
          onForceRefetchToComplete();
        },
        onSettled: () => {
          setIsOpen(false);
        }
      }
    );
  };

  return (
    <LayoutModal
      isModalOpen={isOpen}
      handleModalClose={handleModalQuestionAcceptedClose}
    >
      <div>
        <div className="flex flex-col items-center">
          <BalanceIcon />
          <Title variant="h3">
            {t('account.consultations.cancellation.title')}
          </Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium text-center">
            {t('account.consultations.cancellation.appointment.p')}
          </p>
          <div>
            <div className="mt-10">
              <div className="flex gap-4 justify-end">
                <Button
                  variant="secondary"
                  size="medium"
                  label={t('general.back')}
                  onClick={() => setIsOpen(false)}
                />
                <Button
                  onClick={onSubmit}
                  variant="custom"
                  customClassNames="bg-red-800 text-white"
                  size="medium"
                  label={t('account.consultations.cancellation.confirm')}
                  isLoading={isCancelAppointmentLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
