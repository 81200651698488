import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { Link } from 'react-router-dom';

import NavbarContainer from './NavbarContainer';

export default function NavbarDefault() {
  return (
    <NavbarContainer>
      <div className="flex py-4 items-center">
        <div className="flex w-full items-center justify-between gap-4">
          <div className="my-2 flex justify-center w-20">
            <Link to="/">
              <EkieLogo />
            </Link>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}
