import { privateAxios } from 'context/auth/auth.utils';

import type { Lawyer } from 'types/lawyer';
import type { ApiQuestion, LawyerQuestion } from 'types/question';

import { formatQuestionFromApi } from './formatting';

export async function getQuestionsToAnswer(
  lawyerId: Lawyer['id']
): Promise<LawyerQuestion[] | []> {
  const res = await privateAxios.get(
    `/questions?itemsPerPage=100&order[createdDate]=asc&questionAssignations.lawyer.id=${lawyerId}&questionsToAnswer=${lawyerId}`
  );

  const questions = res.data.map((apiQuestion: ApiQuestion) =>
    formatQuestionFromApi(apiQuestion)
  );

  return questions;
}
