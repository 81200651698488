import { privateAxios } from 'context/auth/auth.utils';

import { Customer } from 'types/customer';
import { Subscription } from 'types/subscription';

import { formatSubscriptionFromApi } from './formatting';

export async function getSubscription(
  customerId: Customer['id']
): Promise<Subscription | null> {
  const res = await privateAxios.get(
    `/subscriptions?customer.id=${customerId}`
  );
  const { data } = res;
  if (!data.length) {
    return null;
  }
  const subscription = formatSubscriptionFromApi(data);
  return subscription;
}

export async function deleteSubscription(subscriptionId: Subscription['id']) {
  const res = await privateAxios.delete(`/subscriptions/${subscriptionId}`);
  const { data } = res;
  return data;
}

export async function getStripeSessionToken(
  customerId: Customer['id']
): Promise<string> {
  const res = await privateAxios.get(
    `/subscription/get-stripe-session-token/${customerId}`
  );
  return res.data;
}
