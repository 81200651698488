import { useTranslation } from 'react-i18next';

import FullCalendar, { AllowFunc } from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { useLocale } from 'hooks';

import { CalendarEvent } from 'types/calendar-event';
import { BookingOption } from 'types/lawyer';

interface AvailabilityCalendarProps {
  calendarEvents: CalendarEvent[];
  bookingOptionValue: BookingOption;
  handleSelectAllow: AllowFunc;
  handleDateSelect: (selectInfo: any) => void;
  renderEventContent: (eventInfo: any) => JSX.Element;
  handleAvailability: (clickInfo: any) => void;
}

export default function AvailabilityCalendar({
  calendarEvents,
  bookingOptionValue,
  handleDateSelect,
  handleSelectAllow,
  handleAvailability,
  renderEventContent
}: AvailabilityCalendarProps) {
  const { locale } = useLocale();
  const { t } = useTranslation('lawyer');

  return (
    <FullCalendar
      height="60vh"
      allDaySlot={false}
      eventColor={
        bookingOptionValue === BookingOption.BookingVideo
          ? '#c9befb'
          : '#98D5B6'
      }
      slotMinTime="08:00"
      slotMaxTime="21:00"
      slotLabelInterval={30}
      defaultTimedEventDuration="00:30"
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit'
      }}
      timeZone="local"
      firstDay={1}
      locale={locale}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridDay,timeGridWeek,dayGridMonth'
      }}
      buttonText={{
        today: t('general.today'),
        day: t('general.day'),
        week: t('general.week'),
        month: t('general.month')
      }}
      events={calendarEvents.map((event) => ({
        ...event,
        option: event.option,
        title:
          event.title === 'availability' ? t('availability') : t('consultation')
      }))}
      initialView="timeGridWeek"
      selectOverlap={false}
      selectMirror
      dayMaxEvents
      weekends={false}
      selectable
      nowIndicator
      selectAllow={handleSelectAllow}
      select={handleDateSelect}
      eventContent={renderEventContent}
      eventClick={handleAvailability}
      eventOverlap={false}
      displayEventTime
    />
  );
}
