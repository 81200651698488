import { useTranslation } from 'react-i18next';
import { AuthenticatedMemberInfo } from 'services/member';

import { SectionLine } from 'features/customer/account/dashboard';

interface MemberProfileInformationsProps {
  member: AuthenticatedMemberInfo;
}

const MemberProfileInformations = ({
  member
}: MemberProfileInformationsProps) => {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('customer');

  return (
    <div className="flex flex-col space-y-6">
      <SectionLine
        title={tForm('label.lastName')}
        value={
          !member.first_name || !member.last_name
            ? t('general.undefined')
            : `${member.first_name} ${member.last_name}`
        }
      />
      <SectionLine
        title={tForm('label.phone')}
        value={
          !member.phone_cellphone
            ? t('general.undefined')
            : member.phone_cellphone
        }
      />
      <SectionLine
        title={t('account.profile.email.label')}
        value={member.email}
      />
      <SectionLine
        title={t('account.profile.personalEmail.label')}
        value={member.secondary_email || t('general.undefined')}
      />
    </div>
  );
};

export default MemberProfileInformations;
