import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { QuestionAnswer } from 'services/answer';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

import SanitizedContent from '../customer/question/SanitizedContent';

interface AnswerCardProps {
  answer: Omit<QuestionAnswer, 'question_id' | 'additional_request_id'>;
}

export default function AnswerCard({ answer }: AnswerCardProps) {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();

  return (
    <Card>
      <CardContent>
        <Title gutterBottom variant="h3">
          {t('account.question.answer.title')}
        </Title>
        <div className="my-6 flex items-center">
          <LawyerInfo fullName={answer.lawyer_full_name} />
        </div>
        <div>
          <SanitizedContent content={answer.content} />
          <div className="mt-2 text-right text-sm font-medium text-gray-700">
            {formatDateToIntl(new Date(answer.published_at), 'long', locale)}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export function LawyerInfo({
  fullName,
  avatarUrl
}: {
  fullName: QuestionAnswer['lawyer_full_name'];
  avatarUrl?: string | null;
}) {
  const { t } = useTranslation('customer');

  return (
    <>
      <div className="mr-4 flex-shrink-0">
        {!fullName && (
          <span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        )}
        {fullName && avatarUrl && (
          <img
            className="h-16 w-16 rounded-full"
            src={avatarUrl}
            alt={fullName}
          />
        )}
        {fullName && !avatarUrl && (
          <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
            <span className="text-lg font-medium leading-none text-white">
              {capitalize(fullName)[0]}
            </span>
          </span>
        )}
      </div>
      <Title variant="h3">
        {fullName || t('account.question.answer.ekielegalteam')}
      </Title>
    </>
  );
}
