import { Pagination } from 'components/pagination';
import { useState } from 'react';

import {
  Appointment,
  Consultation,
  ConsultationsFilter,
  PaginationConsultation
} from 'types/consultation';

import { CommonConsultationCard } from '../card';
import {
  ModalCancelAppointmentConsultation,
  ModalCancelConsultation
} from '../modal';

export default function ScheduledConsultations({
  onRefetch,
  consultations,
  pagination
}: {
  onRefetch(page: number): void;
  consultations: Consultation[];
  pagination: PaginationConsultation;
}) {
  const [consultationId, setConsultationId] = useState<Consultation['id']>();
  const [appointmentId, setAppointmentId] = useState<Appointment['id']>();

  const [isModalCancelConsultationOpen, setIsModalCancelConsultationOpen] =
    useState<boolean>(false);

  const [
    isModalCancelAppointmentConsultationOpen,
    setIsModalCancelAppointmentConsultationOpen
  ] = useState<boolean>(false);

  const handleCancelConsultation = (id: Consultation['id']) => {
    setConsultationId(id);
    setIsModalCancelConsultationOpen(true);
  };

  const handleCancelAppointmentConsultation = (id: Appointment['id']) => {
    setAppointmentId(id);
    setIsModalCancelAppointmentConsultationOpen(true);
  };

  return (
    <div className="flex flex-col gap-6">
      {consultations.map((consultation) => (
        <CommonConsultationCard
          status={ConsultationsFilter.SCHEDULED}
          key={consultation.id}
          consultation={consultation}
          handleCancelConsultation={handleCancelConsultation}
          handleCancelAppointmentConsultation={
            handleCancelAppointmentConsultation
          }
        />
      ))}
      {consultationId && (
        <ModalCancelConsultation
          consultationId={consultationId}
          isModalCancelConsultationOpen={isModalCancelConsultationOpen}
          setIsModalCancelConsultationOpen={setIsModalCancelConsultationOpen}
        />
      )}

      {appointmentId && (
        <ModalCancelAppointmentConsultation
          appointmentId={appointmentId}
          isOpen={isModalCancelAppointmentConsultationOpen}
          setIsOpen={setIsModalCancelAppointmentConsultationOpen}
        />
      )}
      <Pagination onRefetch={onRefetch} pageCount={pagination.last} />
    </div>
  );
}
