import { privateAxios } from 'context/auth/auth.utils';

import {
  ApiConsultation,
  ApiConsultationConfirmation,
  Consultation,
  ConsultationFile
} from 'types/consultation';
import { Customer } from 'types/customer';
import { Question } from 'types/question';

import { BookingConsultationOption, Lawyer } from '../../../types/lawyer';
import { formatConsultationFromApi } from './formatting';

export async function createConsultation(
  questionId: Question['id'],
  availabilitySlotId: string,
  appointmentType: BookingConsultationOption,
  comment?: string,
  consultationFile?: ConsultationFile
): Promise<ApiConsultationConfirmation> {
  const formData = new FormData();
  formData.append('questionId', questionId);
  formData.append('availabilitySlotId', availabilitySlotId);
  formData.append('appointmentType', appointmentType);
  if (comment) {
    formData.append('comment', comment);
  }
  if (consultationFile?.content) {
    formData.append(
      'document',
      new Blob([consultationFile.content]),
      consultationFile.name
    );
  }

  const res = await privateAxios.post(
    `/v2/questions/${questionId}/consultations`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  const consultation = res.data;
  return consultation;
}

export async function scheduleConsultation(
  consultationId: Consultation['id'],
  availabilitySlotId: string,
  appointmentType: BookingConsultationOption
): Promise<ApiConsultationConfirmation> {
  const res = await privateAxios.post(
    `/consultations/${consultationId}/schedule-appointment`,
    {
      availabilitySlotId,
      appointmentTypeRequested: appointmentType
    }
  );
  const appointment = res.data;
  return appointment;
}

export async function rescheduleConsultation(
  appointmentId: string,
  availabilitySlotId: string,
  appointmentType: BookingConsultationOption
): Promise<ApiConsultationConfirmation> {
  const res = await privateAxios.post(`/consultations/reschedule-appointment`, {
    appointmentId,
    availabilitySlotId,
    appointmentTypeRequested: appointmentType
  });
  const appointment = res.data;
  return appointment;
}

export async function getConsultations(
  customerId: Customer['id']
): Promise<Consultation[] | []> {
  const res = await privateAxios.get(
    `/consultations?customer.id=${customerId}`
  );
  const consultation = formatConsultationFromApi(res.data as ApiConsultation[]);

  return consultation;
}

export async function sendNotificationLawyerNoAvailability(
  lawyerId: Lawyer['id']
): Promise<string> {
  const res = await privateAxios.post(
    `/customers/notify-lawyer/${lawyerId}/no-availabilities`
  );

  return res.data;
}
