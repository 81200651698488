import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HomeFooter = () => {
  const { t } = useTranslation('customer');

  return (
    <div className="font-medium flex flex-col lg:flex-row gap-2 lg:gap-5">
      <div className="flex gap-5 flex-1">
        <img
          alt="ask question"
          className="h-24 w-28"
          src="/images/illu_step_5.png"
        />
        <div className="flex flex-col gap-1 justify-center lg:h-24">
          <Title variant="h3">{t('account.home.footer.title')}</Title>
          <p className="text-gray-800">
            {t('account.home.footer.description')}
          </p>
        </div>
      </div>
      <div className="flex items-center self-end lg:self-auto min-w-[175px]">
        <Link
          className="bg-purple-900 hover:bg-purple-800 text-white rounded px-4 py-2"
          to="/account/ask"
        >
          {t('account.home.footer.cta')}
        </Link>
      </div>
    </div>
  );
};

export default HomeFooter;
