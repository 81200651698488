import { Section } from 'components';
import { Fade } from 'components/transition';

const MemberProfileSkeleton = () => (
  <Fade>
    <Section className="grid gap-4">
      <div className="animate-pulse h-12 w-full bg-gray-300 rounded" />
      <div className="animate-pulse h-12 w-full bg-gray-300 rounded" />
      <div className="animate-pulse h-12 w-full bg-gray-300 rounded" />
      <div className="animate-pulse h-12 w-full bg-gray-300 rounded" />
      <div className="flex justify-end gap-4">
        <div className="animate-pulse h-10 w-52 bg-gray-300 rounded" />
        <div className="animate-pulse h-10 w-52 bg-gray-300 rounded" />
      </div>
    </Section>
  </Fade>
);

export default MemberProfileSkeleton;
