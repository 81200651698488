import { AxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { useNavigate } from 'react-router-dom';

import DefaultErrorPage from './DefaultErrorPage';
import NotFoundPage from './NotFoundPage';

export default function ErrorPage({
  error,
  resetErrorBoundary
}: FallbackProps) {
  const { reset } = useQueryErrorResetBoundary();
  const navigate = useNavigate();
  const isNotFoundError = (error as AxiosError)?.response?.status === 404;

  const handleResetError = ({ reload }: { reload: boolean }) => {
    if (reload) {
      return window.location.replace('/');
    }
    reset();
    resetErrorBoundary();
    return navigate(-1);
  };

  if (isNotFoundError) {
    return <NotFoundPage handleResetError={handleResetError} />;
  }

  return <DefaultErrorPage handleResetError={handleResetError} />;
}
