import { uniq } from 'lodash';

import { getDateAsDdMmYyyy, getDateAsHhMm } from 'utils/date';

import {
  ApiLawyerAvailabilitySlot,
  BookingOption,
  LawyerAvailabilityDaySlots
} from 'types/lawyer';

export function formatLawyerAvailabilitySlotsFromApi(
  apiRes: ApiLawyerAvailabilitySlot[]
): LawyerAvailabilityDaySlots[] {
  const arrayOfDays = apiRes.map((slot) => getDateAsDdMmYyyy(slot.startAt));
  return uniq(arrayOfDays).map((day) => ({
    day,
    slots: apiRes
      .filter((slot) => getDateAsDdMmYyyy(slot.startAt) === day)
      .map((slot) => ({
        id: slot.id,
        startAt: slot.startAt,
        option: slot?.appointmentOptions?.video
          ? BookingOption.BookingVideo
          : BookingOption.BookingPhone,
        dayTime: getDateAsHhMm(slot.startAt)
      }))
  }));
}
