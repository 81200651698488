export const THEMATICS = [
  {
    id: 'estate',
    title: 'account.guarantees.estate.title',
    description: 'account.guarantees.estate.description',
    imageSource: '/images/illu_house.png',
    samples: [
      'account.guarantees.estate.sample1',
      'account.guarantees.estate.sample2',
      'account.guarantees.estate.sample3',
      'account.guarantees.estate.sample4',
      'account.guarantees.estate.sample5',
      'account.guarantees.estate.sample6',
      'account.guarantees.estate.sample7',
      'account.guarantees.estate.sample8',
      'account.guarantees.estate.sample9',
      'account.guarantees.estate.sample10',
      'account.guarantees.estate.sample11'
    ]
  },
  {
    id: 'succession',
    title: 'account.guarantees.succession.title',
    description: 'account.guarantees.succession.description',
    imageSource: '/images/illu_contract.png',
    samples: [
      'account.guarantees.succession.sample1',
      'account.guarantees.succession.sample2',
      'account.guarantees.succession.sample3',
      'account.guarantees.succession.sample4',
      'account.guarantees.succession.sample5',
      'account.guarantees.succession.sample6',
      'account.guarantees.succession.sample7',
      'account.guarantees.succession.sample8'
    ]
  },
  {
    id: 'family',
    title: 'account.guarantees.family.title',
    description: 'account.guarantees.family.description',
    imageSource: '/images/illu_family.png',
    samples: [
      'account.guarantees.family.sample1',
      'account.guarantees.family.sample2',
      'account.guarantees.family.sample3',
      'account.guarantees.family.sample4',
      'account.guarantees.family.sample5',
      'account.guarantees.family.sample6',
      'account.guarantees.family.sample7'
    ]
  },
  {
    id: 'penal',
    title: 'account.guarantees.penal.title',
    description: 'account.guarantees.penal.description',
    imageSource: '/images/illu_balance.png',
    samples: [
      'account.guarantees.penal.sample1',
      'account.guarantees.penal.sample2',
      'account.guarantees.penal.sample3',
      'account.guarantees.penal.sample4',
      'account.guarantees.penal.sample5',
      'account.guarantees.penal.sample6',
      'account.guarantees.penal.sample7',
      'account.guarantees.penal.sample8',
      'account.guarantees.penal.sample9'
    ]
  },
  {
    id: 'consumption',
    title: 'account.guarantees.consumption.title',
    description: 'account.guarantees.consumption.description',
    imageSource: '/images/illu_consumption.svg',
    samples: [
      'account.guarantees.consumption.sample1',
      'account.guarantees.consumption.sample2',
      'account.guarantees.consumption.sample3',
      'account.guarantees.consumption.sample4',
      'account.guarantees.consumption.sample5',
      'account.guarantees.consumption.sample6',
      'account.guarantees.consumption.sample7'
    ]
  },
  {
    id: 'society',
    title: 'account.guarantees.society.title',
    description: 'account.guarantees.society.description',
    imageSource: '/images/illu_money.png',
    samples: [
      'account.guarantees.society.sample1',
      'account.guarantees.society.sample2',
      'account.guarantees.society.sample3',
      'account.guarantees.society.sample4',
      'account.guarantees.society.sample5',
      'account.guarantees.society.sample6',
      'account.guarantees.society.sample7'
    ]
  },
  {
    id: 'residencePermit',
    title: 'account.guarantees.residencePermit.title',
    description: 'account.guarantees.residencePermit.description',
    imageSource: '/images/illu_residence_permit.svg',
    samples: [
      'account.guarantees.residencePermit.sample1',
      'account.guarantees.residencePermit.sample2',
      'account.guarantees.residencePermit.sample3',
      'account.guarantees.residencePermit.sample4',
      'account.guarantees.residencePermit.sample5',
      'account.guarantees.residencePermit.sample6',
      'account.guarantees.residencePermit.sample7'
    ]
  },
  {
    id: 'travel',
    title: 'account.guarantees.travel.title',
    description: 'account.guarantees.travel.description',
    imageSource: '/images/illu_car.png',
    samples: [
      'account.guarantees.travel.sample1',
      'account.guarantees.travel.sample2',
      'account.guarantees.travel.sample3',
      'account.guarantees.travel.sample4',
      'account.guarantees.travel.sample5',
      'account.guarantees.travel.sample6'
    ]
  },
  {
    id: 'taxation',
    title: 'account.guarantees.taxation.title',
    description: 'account.guarantees.taxation.description',
    imageSource: '/images/illu_dollar.png',
    samples: [
      'account.guarantees.taxation.sample1',
      'account.guarantees.taxation.sample2',
      'account.guarantees.taxation.sample3',
      'account.guarantees.taxation.sample4',
      'account.guarantees.taxation.sample5',
      'account.guarantees.taxation.sample6',
      'account.guarantees.taxation.sample7',
      'account.guarantees.taxation.sample8'
    ]
  },
  {
    id: 'labor',
    title: 'account.guarantees.labor.title',
    description: 'account.guarantees.labor.description',
    imageSource: '/images/illu_book.png',
    samples: [
      'account.guarantees.labor.sample1',
      'account.guarantees.labor.sample2',
      'account.guarantees.labor.sample3',
      'account.guarantees.labor.sample4',
      'account.guarantees.labor.sample5',
      'account.guarantees.labor.sample6'
    ]
  },
  {
    id: 'other',
    title: 'account.guarantees.other.title',
    description: 'account.guarantees.other.description',
    imageSource: '/images/illu_book.png',
    samples: [
      'account.guarantees.other.sample1',
      'account.guarantees.other.sample2',
      'account.guarantees.other.sample3',
      'account.guarantees.other.sample4'
    ]
  }
];

export const LABOR_LABEL_THEMATIC_SLUG = 'travail';
