import { Divider, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { Trans, useTranslation } from 'react-i18next';
import { MemberQuestion } from 'services/question';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

import SanitizedContent from '../../customer/question/SanitizedContent';
import { STATUS_LABELS } from './MemberQuestionOverview';

interface MemberQuestionCardProps {
  question: MemberQuestion;
}

const MemberQuestionCard = ({ question }: MemberQuestionCardProps) => {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();

  const questionDate = formatDateToIntl(
    new Date(question.created_at),
    'long',
    locale
  );
  const emptyTitlePlaceholder = `${t('account.question.of')} ${questionDate}`;

  return (
    <Card>
      <CardContent>
        <Title variant="h2" className="truncate">
          {emptyTitlePlaceholder}
        </Title>
        <div className="mt-4 max-w-2xl">
          <Trans
            parent="div"
            ns="customer"
            className="truncate mb-1 text-gray-700"
            i18nKey="account.questions.status.label"
            components={{ 1: <span className="font-medium text-gray-800" /> }}
            values={{
              status: t(STATUS_LABELS[question.status])
            }}
          />
        </div>
        <Divider />
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <p className="text-sm font-medium text-gray-700">
              {t('account.question.askedthe')}
            </p>
            <p className="mt-1 text-sm">{questionDate}</p>
          </div>
          <div className="sm:col-span-2">
            <p className="text-sm font-medium text-gray-700">
              {t('account.question.title')}
            </p>
            <div className="text-sm mt-1">
              <SanitizedContent content={question.content} />
            </div>
          </div>
          <div className="sm:col-span-1">
            <p className="text-sm font-medium text-gray-700">
              {t('account.question.by')}
            </p>
            <p className="mt-1 text-sm">{question.member_full_name}</p>
          </div>
        </dl>
      </CardContent>
    </Card>
  );
};

export default MemberQuestionCard;
