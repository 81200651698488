import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useQuery } from 'react-query';

import { getAccount } from 'api/shared/account/api';

import { logoutUser } from 'context/auth/auth.utils';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { Lawyer } from 'types/lawyer';

export interface AuthContext {
  lawyerId: number | null;
  lawyer: Lawyer | null;
  userId: number | null | undefined;
  roles: string[];
  isAuthenticated: boolean;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  logout: () => void;
}

const AuthCtx = createContext<AuthContext | undefined>(undefined);
interface AuthProviderProps {
  children: React.ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [roles, setRoles] = useState<string[]>([]);
  const [lawyerId, setLawyerId] = useState<number | null>(null);
  const [lawyer, setLawyer] = useState<Lawyer | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(
    window.localStorage.getItem('email') as string
  );

  const account = useQuery(
    ['account', email],
    () => getAccount(email as string),
    {
      enabled: !!email,
      onSuccess: (a) => {
        setRoles(a.roles);
        setUserId(a.id);
        setLawyerId(a.lawyerId);
        setLawyer(a.lawyer);
        setIsAuthenticated(true);
      }
    }
  );

  const logout = useCallback(() => {
    logoutUser();
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      userId,
      lawyerId,
      lawyer,
      roles,
      setEmail,
      logout
    }),
    [isAuthenticated, setEmail, logout]
  );

  if (account.isLoading) return <LayoutLoadingSkeleton />;

  return <AuthCtx.Provider value={value}>{children}</AuthCtx.Provider>;
}

const useAuth = () => {
  const context = useContext(AuthCtx);
  if (context === undefined) {
    throw new Error('useAuth must be used within a Provider');
  }
  return context;
};

export { AuthProvider, AuthCtx, useAuth };
