import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { useLawyer } from 'hooks';

import { useAuth } from 'context';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { AvatarIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { AdditionalRequest } from 'types/answer';

interface AdditionalRequestAnswerCardProps {
  additionalRequest: AdditionalRequest;
}

export default function AdditionalRequestAnswerCard({
  additionalRequest
}: AdditionalRequestAnswerCardProps) {
  const { t } = useTranslation('lawyer');
  const { lawyerId } = useAuth();
  const { firstName, lastName } = useLawyer({ lawyerId: lawyerId as number });

  const hasFullName = firstName && lastName;

  const getFormattedDate = (publishedDate: Date) => {
    const day = formatDateToIntl(publishedDate);
    const time = getDateAsHhMm(publishedDate);
    return `${day} ${t('general.at')} ${time}`;
  };

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow">
      <div className="pb-7 border-gray-500">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-400">
              <AvatarIcon />
            </span>
            <Title variant="h3">
              {hasFullName
                ? `${firstName} ${lastName}`
                : t('general.ekieClient')}
            </Title>
          </div>
          {additionalRequest.answeredAt && (
            <span className="text-gray-700 text-sm">
              {getFormattedDate(additionalRequest.answeredAt)}
            </span>
          )}
        </div>
      </div>
      <div className="pt-6 border-t border-gray-500">
        <SanitizedContent content={additionalRequest.answer || ''} />
      </div>
    </div>
  );
}
