import { ApiProduct, Product } from 'types/product';

export function formatProductFromApi(apiProduct: ApiProduct): Product {
  const { id, name, skuCode, payable, isPremium, offeredByPartner } =
    apiProduct;

  const product = {
    id,
    skuCode,
    name,
    isPayable: payable,
    isPremium,
    offeredByPartner
  };

  return product;
}
