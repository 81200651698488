import { ErrorBoundary } from '@sentry/react';
import { Section, Title } from 'components';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';
import { MemberQuestionsConsultationAvailable } from 'features/questions';
import { Paragraph } from 'features/shared/body';

const MemberQuestionsConsultationAvailablePage = () => {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Fade>
        <Section>
          <Title
            gutterBottom
            variant="h1"
            text={t('account.question.consultation.cta')}
          />
          <Paragraph gutterBottom weight="normal">
            {t('account.consultations.choice.cta')}
          </Paragraph>
          <ErrorBoundary fallback={<GenericErrorCard />}>
            <MemberQuestionsConsultationAvailable />
          </ErrorBoundary>
        </Section>
      </Fade>
    </PageLayout>
  );
};

export default MemberQuestionsConsultationAvailablePage;
