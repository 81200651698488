import { privateAxios, privateAxiosJsonLd } from 'context/auth/auth.utils';

import {
  ApiConsultation,
  Consultation,
  ConsultationCancelIntent,
  ConsultationStatus,
  ConsultationsPast,
  PaginationConsultation
} from 'types/consultation';
import { Lawyer } from 'types/lawyer';

import {
  formatConsultationFromApi,
  formatJsonLdConsultationFromApi
} from './formatting';

const today = new Date().toISOString();

export async function getScheduledConsultations(
  lawyerId: Lawyer['id'],
  page: number,
  isScheduledAt: 'after' | 'before'
): Promise<{
  consultations: Consultation[] | [];
  pagination: PaginationConsultation;
}> {
  const res = await privateAxiosJsonLd.get(
    `/consultations?appointments.lawyer.id=${lawyerId}&page=${page}&status[]=${ConsultationStatus.SCHEDULED}&status[]=${ConsultationStatus.SCHEDULED_APPOINTMENT}&appointments.appointmentDate[${isScheduledAt}]=${today}&order[appointments.appointmentDate]=asc`
  );

  const data = formatJsonLdConsultationFromApi(res.data);

  const consultations = data.consultations.map(
    (apiConsultation: ApiConsultation) =>
      formatConsultationFromApi(apiConsultation, lawyerId)
  );

  return {
    consultations,
    pagination: data.pagination
  };
}

export async function getPastConsultations(
  lawyerId: Lawyer['id'],
  page: number
): Promise<{
  consultations: ConsultationsPast[] | [];
  pagination: PaginationConsultation;
}> {
  const res = await privateAxiosJsonLd.get(
    `/consultations?appointments.lawyer.id=${lawyerId}&status[]=${ConsultationStatus.CANCELLED}&status[]=${ConsultationStatus.COMPLETED}&page=${page}`
  );
  const data = formatJsonLdConsultationFromApi(res.data);

  const consultations = data.consultations.map(
    (apiConsultation: ApiConsultation) =>
      formatConsultationFromApi(apiConsultation, lawyerId)
  );

  return {
    consultations: consultations.filter(
      (consultation: Consultation) =>
        consultation.status === ConsultationStatus.CANCELLED ||
        consultation.status === ConsultationStatus.COMPLETED ||
        consultation.status === ConsultationStatus.TO_RESCHEDULE
    ),
    pagination: data.pagination
  };
}

export async function cancelConsultation(
  consultationId: Consultation['id'],
  intent: ConsultationCancelIntent
) {
  await privateAxios.put(`/consultations/${consultationId}/cancel`, {
    intent
  });
}

export async function cancelAppointmentConsultation(appointmentId: string) {
  const res = await privateAxios.post(`/consultations/cancel-appointment`, {
    appointmentId
  });
  const appointment = res.data;
  return appointment;
}

export async function completeConsultation(consultationId: Consultation['id']) {
  await privateAxios.put(`/consultations/${consultationId}/complete`, {});
}
