import { SVGProps } from 'react';

export default function CheckIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.25 5C13.25 5.21094 13.1562 5.39844 13.0156 5.53906L7.01562 11.5391C6.875 11.6797 6.6875 11.75 6.5 11.75C6.28906 11.75 6.10156 11.6797 5.96094 11.5391L2.96094 8.53906C2.82031 8.39844 2.75 8.21094 2.75 8C2.75 7.57812 3.07812 7.25 3.5 7.25C3.6875 7.25 3.875 7.34375 4.01562 7.48438L6.5 9.94531L11.9609 4.48438C12.1016 4.34375 12.2891 4.25 12.5 4.25C12.8984 4.25 13.25 4.57812 13.25 5Z"
        fill="current"
      />
    </svg>
  );
}
