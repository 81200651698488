import { Card, Divider } from 'components';
import { Fade } from 'components/transition';

const QuestionsListSkeleton = () => (
  <Fade className="max-w-[720px]">
    <div className="animate-pulse h-7 w-1/3 mb-6 bg-gray-300 rounded" />
    <div className="flex flex-col gap-8">
      <Card className="animate-pulse flex flex-row">
        <div className="h-14 w-full bg-gray-300 rounded" />
        <Divider />
        <div className="h-3 w-1/3 bg-gray-300 rounded" />
        <div className="h-3 w-1/3 mb-3 bg-gray-300 rounded" />
      </Card>
      <Card className="animate-pulse flex flex-row">
        <div className="h-14 w-full bg-gray-300 rounded" />
        <Divider />
        <div className="h-3 w-1/3 bg-gray-300 rounded" />
        <div className="h-3 w-1/3 mb-3 bg-gray-300 rounded" />
      </Card>
      <Card className="animate-pulse flex flex-row">
        <div className="h-14 w-full bg-gray-300 rounded" />
        <Divider />
        <div className="h-3 w-1/3 bg-gray-300 rounded" />
        <div className="h-3 w-1/3 mb-3 bg-gray-300 rounded" />
      </Card>
    </div>
  </Fade>
);

export default QuestionsListSkeleton;
