import { useQuery } from 'react-query';

import { Lawyer } from 'types/lawyer';

import { getQuestionsToAnswer } from './api';

// QUERIES
export function useQuestionsToAnswerQuery({
  lawyerId
}: {
  lawyerId: Lawyer['id'];
}) {
  return useQuery(['questions-to-answer'], () =>
    getQuestionsToAnswer(lawyerId)
  );
}

// MUTATIONS
