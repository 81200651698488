import type { ApiQuestion, LawyerQuestion } from 'types/question';

export function formatQuestionFromApi(
  apiQuestion: ApiQuestion
): LawyerQuestion {
  const hasLinkedConsultation = Boolean(
    apiQuestion.linkedConsultations?.length ?? false
  );

  return {
    id: apiQuestion.id.toString(),
    answerDeadLine: apiQuestion.answerDeadLine,
    isPrivate: apiQuestion.isPrivate,
    status: apiQuestion.status,
    content: apiQuestion.content,
    anonymizedContent: apiQuestion.anonymizedContent,
    consumerTag: apiQuestion.consumerTag,
    answers: apiQuestion.answers,
    createdDate: apiQuestion.createdDate,
    title: apiQuestion.title,
    anonymizedTitle: apiQuestion.anonymizedTitle,
    qualifiedDate: apiQuestion.qualifiedDate,
    isAnswered: apiQuestion.isAnswered,
    source: apiQuestion.source,
    isAnsweredByLawyer: apiQuestion.isAnsweredByLawyer,
    answerHelper: apiQuestion.answerHelper,
    hasLinkedConsultation,
    linkedConsultation: hasLinkedConsultation
      ? apiQuestion.linkedConsultations[0]
      : null,
    customer: {
      ...apiQuestion.customer,
      isPremium: apiQuestion.subscription!.product.isPremium
    }
  };
}
