interface RatingValueProps {
  value?: number;
  max: number;
}

const RatingValue = ({ value, max }: RatingValueProps) => (
  <div className="text-3xl font-bold flex items-baseline">
    {value ? (
      <>
        <span>{value}</span>
        <div className="text-gray-700 text-base ml-0.5">{`/${max}`}</div>
      </>
    ) : (
      <span>-</span>
    )}
  </div>
);

export default RatingValue;
