import { useQuery } from 'react-query';

import { Lawyer } from 'types/lawyer';

import { getCalendarEvents } from './api';

export function useCalendarEvents({
  lawyerId,
  calendarId
}: {
  lawyerId: Lawyer['id'];
  calendarId: string;
}) {
  return useQuery(['calendar-events'], () =>
    getCalendarEvents(lawyerId, calendarId)
  );
}
