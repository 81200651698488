import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';

import Title from './Title';

const GOOGLE_RATING_LINK = 'https://go.ekie.co/avis-ekie';

interface GoogleRatingProps {
  onClick?: () => void;
}

function GoogleRating({ onClick = () => {} }: GoogleRatingProps) {
  const { t } = useTranslation('customer');

  return (
    <div>
      <Title variant="h3">{t('account.question.vote.satisfied.title')}</Title>
      <div>
        <p className="text-md mt-2 text-left font-medium ">
          {t('account.question.vote.satisfied.description')}
        </p>
        <div className="mt-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={GOOGLE_RATING_LINK}
          >
            <Button
              variant="primary"
              size="large"
              fullWidth
              label={t('general.googleRating.cta')}
              onClick={onClick}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default GoogleRating;
