import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Consultation, ConsultationFile } from 'types/consultation';
import { Customer } from 'types/customer';
import { BookingConsultationOption, Lawyer } from 'types/lawyer';
import { Question } from 'types/question';

import {
  createConsultation,
  getConsultations,
  rescheduleConsultation,
  scheduleConsultation,
  sendNotificationLawyerNoAvailability
} from './api';

export function useConsultationsQuery({
  customerId
}: {
  customerId: Customer['id'];
}) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['consultations'],
    queryFn: async () => {
      const consultations = await getConsultations(customerId);
      for (const consultation of consultations) {
        queryClient.setQueryData(
          ['consultation', consultation.id],
          consultation
        );
      }

      return consultations;
    }
  });
}

// MUTATIONS
export function useCreateConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      questionId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone,
      comment = undefined,
      consultationFile = undefined
    }: {
      questionId: Question['id'];
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
      comment?: string;
      consultationFile?: ConsultationFile;
    }) =>
      createConsultation(
        questionId,
        availabilitySlotId,
        appointmentType,
        comment,
        consultationFile
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });
}

export function useScheduleConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      consultationId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone
    }: {
      consultationId: Consultation['id'];
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
    }) =>
      scheduleConsultation(consultationId, availabilitySlotId, appointmentType),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });
}
export function useRescheduleConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      appointmentId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone
    }: {
      appointmentId: string;
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
    }) =>
      rescheduleConsultation(
        appointmentId,
        availabilitySlotId,
        appointmentType
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });
}

export function useSendNotificationToLawyerMutation() {
  return useMutation({
    mutationFn: ({ lawyerId }: { lawyerId: Lawyer['id'] }) =>
      sendNotificationLawyerNoAvailability(lawyerId)
  });
}
