import { GoogleRating } from 'components';
import { capitalize } from 'lodash';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QuestionAnswer, getMemberQuestionAnswer } from 'services/answer';
import { createAdditionalRequest } from 'services/customer/additional-request';
import {
  PublicRateAnswerPayload,
  isPositiveRating,
  rateAnswer
} from 'services/rating';

import AdditionalRequestAnswerForm from 'features/answer/AdditionalRequestAnswerForm';
import { Toast } from 'features/shared/toasts';

import {
  RateAnswerChoice,
  RateAnswerComment,
  RateAnswerUnsatisfiedChoice
} from './components';

interface AnswerVoteProps {
  questionId: QuestionAnswer['id'];
  answerId: QuestionAnswer['id'];
}

export type VotingStatus =
  | 'non-voted'
  | 'satisfied'
  | 'unsatisfied-choice'
  | 'additional-request'
  | 'unsatisfied';

export interface RateAnswerInput {
  note: 0 | 1 | 2 | 3 | 4 | 5;
  comment?: string;
}

const RateAnswer = ({ questionId, answerId }: AnswerVoteProps) => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: (data: PublicRateAnswerPayload) => rateAnswer(data),
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('account.rateAnswer.form.error')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
    }
  });
  const [answerVotingStatus, setAnswerVotingStatus] =
    useState<VotingStatus>('non-voted');
  const [note, setNote] = useState<RateAnswerInput['note']>();
  const { data: answer } = useQuery(['member-question-answer', answerId], () =>
    getMemberQuestionAnswer(answerId as number)
  );

  const {
    mutate: createAdditionalRequestMutate,
    isLoading: isCreateAdditionalRequestLoading
  } = useMutation(
    (content: string) => createAdditionalRequest(answerId, content),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['member-question', questionId]);
        toast.custom(
          <Toast
            type="success"
            title={t('general.success')}
            message={t('account.vote.success')}
          />,
          { position: 'bottom-left', duration: 3000 }
        );
      },
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('account.question.additionalRequest.form.success')}
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  const onSubmitRateAnswer = (data: RateAnswerInput) => {
    mutate(
      {
        ratingLinkId: answer?.rating_link_id as string,
        ...data
      },
      {
        onSuccess: () => {
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.vote.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );

          if (isPositiveRating(data.note)) {
            return setAnswerVotingStatus('satisfied');
          }

          return queryClient.invalidateQueries(['member-question', questionId]);
        }
      }
    );
  };

  const onNoteClick = (value: RateAnswerInput['note']) => {
    console.log('ddd');
    if (isPositiveRating(value)) {
      return onSubmitRateAnswer({ note: value });
    }

    setNote(value);
    return setAnswerVotingStatus(
      !answer?.additional_request_id ? 'unsatisfied-choice' : 'unsatisfied'
    );
  };

  const onSubmitComment = (comment: string) => {
    note && onSubmitRateAnswer({ note, comment });
  };

  return (
    <>
      {answerVotingStatus === 'non-voted' && (
        <RateAnswerChoice isLoading={isLoading} onClick={onNoteClick} />
      )}
      {answerVotingStatus === 'unsatisfied-choice' && (
        <RateAnswerUnsatisfiedChoice
          onConfirmClick={() => setAnswerVotingStatus('unsatisfied')}
          onNextStepClick={() => setAnswerVotingStatus('additional-request')}
          description={t(
            'account.question.vote.choiceUnsatisfied.description',
            {
              fullName: answer?.lawyer_full_name
                ? capitalize(answer.lawyer_full_name)
                : t('account.question.answer.ekielegalteam')
            }
          )}
        />
      )}
      {answerVotingStatus === 'additional-request' && (
        <AdditionalRequestAnswerForm
          isLoading={isCreateAdditionalRequestLoading}
          onSubmitAdditionalRequestForm={createAdditionalRequestMutate}
        />
      )}
      {answerVotingStatus === 'unsatisfied' && (
        <RateAnswerComment
          isLoading={isLoading}
          onSubmitComment={onSubmitComment}
        />
      )}
      {answerVotingStatus === 'satisfied' && <GoogleRating />}
    </>
  );
};

export default RateAnswer;
