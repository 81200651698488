interface SectionLineProps {
  title: string;
  value: string;
}

export default function SectionLine({ title, value }: SectionLineProps) {
  return (
    <div className="border-b border-gray-600">
      <div className="flex items-baseline justify-between gap-12 py-2 text-sm md:text-base">
        <span className="font-medium text-gray-700">{title}</span>
        <span>{value}</span>
      </div>
    </div>
  );
}
