import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface CardProps {
  className?: string;
  compact?: boolean;
  transparent?: boolean;
}

const Card = ({
  className,
  children,
  transparent = false,
  compact = false
}: PropsWithChildren<CardProps>) => (
  <div
    className={classNames(
      'rounded-lg flex gap-2 flex-col',
      compact ? 'p-3' : 'py-6 px-4',
      transparent ? '!p-2 hover:bg-gray-50' : 'bg-white border border-gray-300',
      className
    )}
  >
    {children}
  </div>
);

export default Card;
