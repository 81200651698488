import { Disclosure } from '@headlessui/react';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { DesktopNav, MobileNav } from 'components';
import { Link, useLocation } from 'react-router-dom';

import {
  ConsultationsIcon,
  DashboardIcon,
  LawyerCareIcon,
  PaymentsIcons,
  QuestionsIcon
} from 'features/shared/icons';

import { IMenuItem, PageName } from 'types/account';

import { LawyerProfile } from '../profile';

export default function AccountNav() {
  const { pathname } = useLocation();

  const accountTabs: IMenuItem[] = [
    ...(process.env.REACT_APP_EKIE_DASHBOARD_AVAILIBILITY === '1'
      ? [
          {
            name: PageName.Dashboard,
            href: '/account/dashboard',
            icon: DashboardIcon,
            current: pathname.includes(PageName.Dashboard),
            newUntil: new Date('01-05-2023')
          }
        ]
      : []),
    {
      name: PageName.Calendar,
      href: '/account/calendar',
      icon: DashboardIcon,
      current: pathname.includes(PageName.Calendar),
      newUntil: new Date('01-05-2023')
    },
    {
      name: PageName.Questions,
      href: '/account/questions',
      icon: QuestionsIcon,
      current: pathname.includes(PageName.Questions),
      newUntil: new Date('01-05-2023')
    },
    {
      name: PageName.Consultations,
      href: '/account/consultations',
      icon: ConsultationsIcon,
      current: pathname.includes(PageName.Consultations),
      newUntil: new Date('01-05-2023')
    },
    {
      name: PageName.Payments,
      href: '/account/payments',
      icon: PaymentsIcons,
      current: pathname.includes(PageName.Payments),
      newUntil: new Date('01-05-2023')
    },
    {
      name: PageName.LawyerCare,
      href: '/account/lawyercare',
      icon: LawyerCareIcon,
      current: pathname.includes(PageName.LawyerCare),
      newUntil: new Date('01-05-2023')
    }
  ];

  const accountMobileTabs: IMenuItem[] = [
    {
      name: PageName.Profile,
      href: '/account/profile',
      current: pathname.includes(PageName.Profile),
      newUntil: new Date('05-01-2023')
    }
  ];

  return (
    <>
      <div className="bg-white hidden md:flex flex-col justify-items-start items-start">
        <div
          className="hidden md:block md:fixed h-full pt-12 z-50"
          style={{ maxWidth: '250px', width: '100%' }}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="w-full md:w-auto md:max-w-lg">
              <div className="md:pb-12">
                <Link to="/account/dashboard">
                  <EkieLogo className="md:ml-3" />
                </Link>
              </div>
              <div className="flex flex-col justify-between">
                <DesktopNav translation="lawyer" accountTabs={accountTabs} />
              </div>
            </div>
            <LawyerProfile />
          </div>
        </div>
      </div>
      <div className="block md:hidden relative">
        <Disclosure as="nav" className="">
          {({ open }) => (
            <div id="mobile-nav" className="block md:hidden relative">
              <MobileNav
                translation="lawyer"
                open={open}
                accountTabs={[...accountTabs, ...accountMobileTabs]}
              />
            </div>
          )}
        </Disclosure>
      </div>
    </>
  );
}
