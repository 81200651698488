import { Variant } from 'types/icon';

export default function PaymentsIcon({ variant }: { variant: Variant }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className="w-6 h-6"
    >
      {variant === Variant.Active ? (
        <path
          d="M19.5 19H4.5C4.21875 19 4 19.25 4 19.5C4 19.7812 4.21875 20 4.5 20H19.5C19.75 20 20 19.7812 20 19.5C20 19.25 19.75 19 19.5 19ZM18.75 18C19 18 19.25 17.7812 19.25 17.5C19.25 17.25 19 17 18.75 17H5.25C4.96875 17 4.75 17.25 4.75 17.5C4.75 17.7812 4.96875 18 5.25 18H18.75ZM19.5312 7.0625L12.2812 4.0625C12.1875 4.03125 12.0938 4 12 4C11.875 4 11.7812 4.03125 11.6875 4.0625L4.4375 7.0625C4.15625 7.1875 4 7.46875 4 7.75V9.25C4 9.6875 4.3125 10 4.75 10H6V15C5.71875 15 5.5 15.25 5.5 15.5C5.5 15.7812 5.71875 16 6 16H18C18.25 16 18.5 15.7812 18.5 15.5C18.5 15.25 18.25 15 18 15V10H19.25C19.6562 10 20 9.6875 20 9.25V7.75C20 7.46875 19.8125 7.1875 19.5312 7.0625ZM9.5 15H7V10H9.5V15ZM13.5 15H10.5V10H13.5V15ZM11.25 8.25C11.25 7.84375 11.5625 7.5 12 7.5C12.4062 7.5 12.75 7.84375 12.75 8.25C12.75 8.6875 12.4062 9 12 9C11.5625 9 11.25 8.6875 11.25 8.25ZM17 15H14.5V10H17V15ZM19 9H13.5625C13.6562 8.78125 13.75 8.53125 13.75 8.25C13.75 7.3125 12.9375 6.5 12 6.5C11.0312 6.5 10.25 7.3125 10.25 8.25C10.25 8.53125 10.3125 8.78125 10.4062 9H5V7.9375L12 5.03125L19 7.9375V9Z"
          fill="#AA97FF"
        />
      ) : (
        <path
          d="M19.5 19H4.5C4.21875 19 4 19.25 4 19.5C4 19.7812 4.21875 20 4.5 20H19.5C19.75 20 20 19.7812 20 19.5C20 19.25 19.75 19 19.5 19ZM18.75 18C19 18 19.25 17.7812 19.25 17.5C19.25 17.25 19 17 18.75 17H5.25C4.96875 17 4.75 17.25 4.75 17.5C4.75 17.7812 4.96875 18 5.25 18H18.75ZM19.5312 7.0625L12.2812 4.0625C12.1875 4.03125 12.0938 4 12 4C11.875 4 11.7812 4.03125 11.6875 4.0625L4.4375 7.0625C4.15625 7.1875 4 7.46875 4 7.75V9.25C4 9.6875 4.3125 10 4.75 10H6V15C5.71875 15 5.5 15.25 5.5 15.5C5.5 15.7812 5.71875 16 6 16H18C18.25 16 18.5 15.7812 18.5 15.5C18.5 15.25 18.25 15 18 15V10H19.25C19.6562 10 20 9.6875 20 9.25V7.75C20 7.46875 19.8125 7.1875 19.5312 7.0625ZM9.5 15H7V10H9.5V15ZM13.5 15H10.5V10H13.5V15ZM11.25 8.25C11.25 7.84375 11.5625 7.5 12 7.5C12.4062 7.5 12.75 7.84375 12.75 8.25C12.75 8.6875 12.4062 9 12 9C11.5625 9 11.25 8.6875 11.25 8.25ZM17 15H14.5V10H17V15ZM19 9H13.5625C13.6562 8.78125 13.75 8.53125 13.75 8.25C13.75 7.3125 12.9375 6.5 12 6.5C11.0312 6.5 10.25 7.3125 10.25 8.25C10.25 8.53125 10.3125 8.78125 10.4062 9H5V7.9375L12 5.03125L19 7.9375V9Z"
          fill="#1D1D1D"
        />
      )}
    </svg>
  );
}
