import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import { Title } from 'components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { putAnswerHelperVote } from 'api/lawyer/answers/api';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

interface AnswerHelperVoteProps {
  answerHelperId: string;
  defaultValue?: boolean;
}

type AnswerHelperVoteInput = {
  answerHelperId: string;
  upVote: boolean;
  comment?: string;
};

const AnswerHelperVote = ({
  answerHelperId,
  defaultValue
}: AnswerHelperVoteProps) => {
  const queryClient = useQueryClient();
  const { t: tCustomer } = useTranslation('customer');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation('lawyer');
  const { register, handleSubmit, setValue, watch } =
    useForm<AnswerHelperVoteInput>({
      defaultValues: {
        answerHelperId,
        upVote: defaultValue
      }
    });
  const hasComment = watch('comment');
  const hasRate = watch('upVote');
  const currentAnswerHelperId = watch('answerHelperId');

  const { mutate, isLoading } = useMutation(
    ({ answerHelperId: id, ...rest }: AnswerHelperVoteInput) =>
      putAnswerHelperVote(id, rest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['questions-to-answer']);
        hasComment &&
          toast.custom(
            <Toast
              type="success"
              title={tCustomer('general.success')}
              message={tCustomer('admin.collaborators.form.upload.success')}
            />,
            { position: 'top-right', duration: 3000 }
          );
        hasComment && setIsModalOpen(false);
      },
      onError: () => {
        hasComment &&
          toast.custom(
            <Toast
              type="error"
              title={tCustomer('general.error')}
              message={tCustomer('admin.collaborators.form.upload.error')}
            />,
            { position: 'top-right', duration: 3000 }
          );
      }
    }
  );

  useEffect(() => {
    if (answerHelperId !== currentAnswerHelperId) {
      setValue('answerHelperId', answerHelperId);
      setValue('upVote', defaultValue as any);
      setValue('comment', undefined);
    }
  }, [answerHelperId, setValue, defaultValue, currentAnswerHelperId]);

  const onSubmitVote = (value: boolean) => () => {
    if (hasRate === undefined) {
      mutate({ answerHelperId, upVote: value });
      setValue('upVote', value);
      setIsModalOpen(true);
    }
  };

  const onSubmitHelperVote = (data: AnswerHelperVoteInput) => {
    mutate(data);
  };

  return (
    <div className="flex justify-end">
      <input type="hidden" {...register('upVote')} />
      <ThumbDownIcon
        className="w-6 cursor-pointer"
        fill={hasRate === false ? 'black' : 'white'}
        onClick={onSubmitVote(false)}
      />
      <ThumbUpIcon
        {...register('comment')}
        className="w-6 ml-2 cursor-pointer"
        fill={hasRate === true ? 'black' : 'white'}
        onClick={onSubmitVote(true)}
      />
      <LayoutModal
        isModalOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      >
        <form onSubmit={handleSubmit(onSubmitHelperVote)}>
          <Title gutterBottom variant="h3">
            {t('account.lawyer.answerHelper.form.title')}
          </Title>
          <Paragraph gutterBottom className="text-gray-700 text-sm">
            {t('account.lawyer.answerHelper.form.description')}
          </Paragraph>
          <textarea
            rows={3}
            {...register('comment')}
            className="w-full mb-4 placeholder:text-gray-700 text-sm md:text-base block focus:border-purple-900 focus:drop-shadow-input focus:ring-0 focus:ring-offset-0 focus:shadow-purple-900 focus:outline-none py-3 px-4 rounded"
          />
          <Button
            isLoading={isLoading}
            fullWidth
            submit
            size="medium"
            variant="tertiary"
            label={t('account.lawyer.answerHelper.form.submit')}
          />
        </form>
      </LayoutModal>
    </div>
  );
};

export default AnswerHelperVote;
