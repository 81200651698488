export default function LayoutOnboarding({
  leftChild,
  rightChild
}: {
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
}) {
  return (
    <div className="lg:grid lg:grid-cols-12 px-6 lg:px-8 lg:h-screen">
      <div className="h-full lg:col-span-6">{leftChild}</div>
      <div className="hidden lg:block lg:col-span-6">{rightChild}</div>
    </div>
  );
}
