import { SVGProps } from 'react';

export default function Icon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="15.9995" cy="16.0001" r="16.0001" fill="#F7F7F7" />
      <path
        d="M15.9731 12.9997C13.9879 12.9997 12.3941 14.6214 12.3941 16.5787C12.3941 18.5639 13.9879 20.1577 15.9731 20.1577C17.9304 20.1577 19.5521 18.5639 19.5521 16.6066C19.5521 14.6214 17.9304 12.9997 15.9731 12.9997ZM15.9731 19.2629C14.4912 19.2629 13.2889 18.0606 13.2889 16.5787C13.2889 15.1247 14.4912 13.8944 15.9731 13.8944C17.4271 13.8944 18.6573 15.1247 18.6573 16.6066C18.6573 18.0606 17.4271 19.2629 15.9731 19.2629ZM23.914 16.1033C22.4041 12.6642 19.3843 10.3155 15.9731 10.3155C12.5339 10.3155 9.51418 12.6642 8.00429 16.1033C7.94837 16.2431 7.92041 16.4389 7.92041 16.5787C7.92041 16.7185 7.94837 16.9422 8.00429 17.082C9.51418 20.5211 12.5339 22.8419 15.9731 22.8419C19.3843 22.8419 22.4041 20.5211 23.914 17.082C23.9699 16.9422 24.0258 16.7185 24.0258 16.5787C24.0258 16.4389 23.9699 16.2431 23.914 16.1033ZM23.1031 16.7185C21.6771 19.934 18.937 21.9471 15.9731 21.9471C12.9813 21.9471 10.2412 19.9619 8.84312 16.7185C8.81516 16.6905 8.81516 16.6066 8.7872 16.6066C8.7872 16.5787 8.7872 16.4948 8.7872 16.4668C10.2412 13.2234 12.9813 11.2102 15.9731 11.2102C18.937 11.2102 21.6771 13.2234 23.1031 16.4668C23.1031 16.4948 23.1031 16.5787 23.1031 16.5787C23.1031 16.6066 23.1031 16.6905 23.1031 16.7185Z"
        fill="current"
      />
    </svg>
  );
}
