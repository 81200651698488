import classNames from 'classnames';
import { Title } from 'components';
import { PropsWithChildren } from 'react';

interface SectionProps {
  title?: string;
  className?: string;
  color?: string;
  gutterBottom?: boolean;
}

const Section = ({
  children,
  title,
  className,
  color = 'gray-100',
  gutterBottom = false
}: PropsWithChildren<SectionProps>) => (
  <div
    className={classNames(
      `rounded-2xl bg-${color} px-3 py-4 md:p-5`,
      className,
      gutterBottom && 'mb-4'
    )}
  >
    {title && <Title variant="h2">{title}</Title>}
    {children}
  </div>
);

export default Section;
