import { SVGProps } from 'react';

export default function MagicLinkIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      strokeWidth={2}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05352 0.799805C8.92495 0.799805 8.83923 0.885519 8.83923 1.01409V3.58552C8.83923 3.71409 8.92495 3.7998 9.05352 3.7998C9.18209 3.7998 9.2678 3.71409 9.2678 3.58552V1.01409C9.2678 0.906948 9.16066 0.799805 9.05352 0.799805Z"
        fill="white"
      />
      <path
        d="M9.05352 8.2998C8.92495 8.2998 8.83923 8.38552 8.83923 8.51409V10.8712C8.83923 10.9998 8.92495 11.0855 9.05352 11.0855C9.18209 11.0855 9.2678 10.9998 9.2678 10.8712V8.51409C9.2678 8.40695 9.16066 8.2998 9.05352 8.2998Z"
        fill="white"
      />
      <path
        d="M14.4107 6.15723H11.8393C11.7107 6.15723 11.625 6.24294 11.625 6.37151C11.625 6.50008 11.7107 6.5858 11.8393 6.5858H14.4107C14.5393 6.5858 14.625 6.50008 14.625 6.37151C14.625 6.24294 14.5179 6.15723 14.4107 6.15723Z"
        fill="white"
      />
      <path
        d="M4.76787 6.5858H7.12502C7.25359 6.5858 7.3393 6.50008 7.3393 6.37151C7.3393 6.24294 7.25359 6.15723 7.12502 6.15723H4.76787C4.6393 6.15723 4.55359 6.24294 4.55359 6.37151C4.55359 6.50008 4.6393 6.5858 4.76787 6.5858Z"
        fill="white"
      />
      <path
        d="M12.825 2.25666L11.0465 4.0138C10.9607 4.09952 10.9607 4.22809 11.0465 4.3138C11.0893 4.35666 11.1536 4.37809 11.1965 4.37809C11.2393 4.37809 11.3036 4.35666 11.3465 4.3138L13.125 2.53523C13.2107 2.44952 13.2107 2.32094 13.125 2.23523C13.0393 2.14952 12.9107 2.17094 12.825 2.25666Z"
        fill="white"
      />
      <path
        d="M7.68214 7.61408L0.439286 14.8355C0.353571 14.9212 0.353571 15.0498 0.439286 15.1355C0.482143 15.1784 0.546429 15.1998 0.589286 15.1998C0.632143 15.1998 0.696429 15.1784 0.739286 15.1355L7.98214 7.89265C8.06786 7.80694 8.06786 7.67837 7.98214 7.59265C7.89643 7.50694 7.76786 7.52837 7.68214 7.61408Z"
        fill="white"
      />
      <path
        d="M11.175 7.63557C11.0892 7.54986 10.9607 7.54986 10.875 7.63557C10.7892 7.72129 10.7892 7.84986 10.875 7.93558L12.6535 9.71415C12.6964 9.757 12.7607 9.77843 12.8035 9.77843C12.8464 9.77843 12.9107 9.757 12.9535 9.71415C13.0392 9.62843 13.0392 9.49986 12.9535 9.41415L11.175 7.63557Z"
        fill="white"
      />
      <path
        d="M6.20352 2.25667C6.11781 2.17095 5.98923 2.17095 5.90352 2.25667C5.8178 2.34238 5.8178 2.47095 5.90352 2.55667L7.68209 4.33524C7.72495 4.3781 7.78923 4.39953 7.83209 4.39953C7.87495 4.39953 7.93923 4.3781 7.98209 4.33524C8.06781 4.24953 8.06781 4.12095 7.98209 4.03524L6.20352 2.25667Z"
        fill="white"
      />
    </svg>
  );
}
