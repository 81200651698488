import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import {
  QuestionRejectInput,
  refuseQuestionAssignation
} from 'api/lawyer/question-assignations/api';

import { useAuth } from 'context';

import { Button } from 'features/shared/buttons';
import { SelectInputGroup } from 'features/shared/forms';

import { QuestionAssignation } from 'types/question-assignation';

interface QuestionRejectProps {
  questionAssignation: QuestionAssignation;
}

type RejectFormInput = Pick<QuestionRejectInput, 'reason' | 'message'>;

const REASON_OPTIONS = [
  {
    label: 'options.reason.wrongLawDomain',
    value: 'wrong_law_domain'
  },
  { label: 'options.reason.multipleThemes', value: 'multiple_themes' },
  { label: 'options.reason.noQuestion', value: 'no_question' },
  { label: 'options.reason.other', value: 'other' }
];

const QuestionReject = ({ questionAssignation }: QuestionRejectProps) => {
  const { t } = useTranslation('lawyer');
  const queryClient = useQueryClient();
  const { t: tForm } = useTranslation('form');
  const { lawyerId } = useAuth();
  const {
    register,
    watch,
    handleSubmit,
    unregister,
    formState: { errors }
  } = useForm<RejectFormInput>({
    defaultValues: {
      reason: REASON_OPTIONS[0].value
    }
  });
  const reasonWatch = watch('reason');

  const { mutate: rejectQuestionMutate, isLoading } = useMutation(
    (data: QuestionRejectInput) => refuseQuestionAssignation(data)
  );

  useEffect(() => {
    if (reasonWatch === 'other') {
      register('message');
      return;
    }
    unregister('message');
  }, [register, unregister, reasonWatch]);

  const onSubmit = (data: RejectFormInput) =>
    rejectQuestionMutate(
      {
        lawyerId: lawyerId as number,
        questionAssignationId: questionAssignation.id,
        reason: data.reason,
        message: data.message
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['question-assignations']);
        }
      }
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4 mt-8"
    >
      <SelectInputGroup
        options={REASON_OPTIONS.map((option) => ({
          ...option,
          label: tForm(option.label as any)
        }))}
        name="reason"
        label={tForm('label.reason')}
        error={errors.reason}
        register={register}
      />
      {reasonWatch === 'other' && (
        <>
          <label className="input-label">{tForm('label.message')}</label>
          <textarea
            rows={3}
            placeholder={tForm('placeholder.message')}
            {...register('message')}
          />
        </>
      )}
      <div className="flex gap-6">
        <Button
          isLoading={isLoading}
          fullWidth
          submit
          size="medium"
          variant="primary"
          label={t('general.refuse')}
        />
      </div>
    </form>
  );
};

export default QuestionReject;
