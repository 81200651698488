export default function FilterIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7L20 7"
        stroke="#9C3AFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 7L8 7"
        stroke="#9C3AFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17 17L20 17"
        stroke="#9C3AFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 17L12 17"
        stroke="#9C3AFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="10"
        cy="7"
        r="2"
        transform="rotate(90 10 7)"
        stroke="#9C3AFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="15"
        cy="17"
        r="2"
        transform="rotate(90 15 17)"
        stroke="#9C3AFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
