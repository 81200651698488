import {
  AutomobileIllustration,
  BalanceIllustration,
  BookIllustration,
  CarRentalIllustration,
  ConsommationIllustration,
  ContactIllustration,
  ContractsIllustration,
  CovidIllustration,
  DocumentsIllustration,
  ElderCareIllustration,
  FamilyIllustration,
  FineIllustration,
  HabitationIllustration,
  HousePurchaseIllustration,
  InvestingIllustration,
  MagnifierIllustration,
  MobilityIllustration,
  OnlineIllustration,
  ResidencePermitIllustration,
  SuccessionIllustration,
  SunIllustration,
  TaxesIllustration,
  TripIllustration,
  UmbrellaIllustration,
  ViolationIllustration,
  WeddingIllustration
} from 'features/shared/illustrations';

export default function IllustrationSwitcher({
  illustrationId
}: {
  illustrationId: string;
}) {
  switch (illustrationId) {
    case 'magnifier':
      return <MagnifierIllustration className="w-20 h-20" />;
    case 'social':
      return <BookIllustration className="w-20 h-20" />;
    case 'succession':
      return <SuccessionIllustration className="w-20 h-20" />;
    case 'balance':
      return <BalanceIllustration className="w-20 h-20" />;
    case 'sun':
      return <SunIllustration className="w-20 h-20" />;
    case 'automobile':
      return <AutomobileIllustration className="w-20 h-20" />;
    case 'umbrella':
      return <UmbrellaIllustration className="w-20 h-20" />;
    case 'house':
      return <HabitationIllustration className="w-20 h-20" />;
    case 'covid':
      return <CovidIllustration className="w-20 h-20" />;
    case 'violation':
      return <ViolationIllustration className="w-20 h-20" />;
    case 'housePurchase':
      return <HousePurchaseIllustration className="w-20 h-20" />;
    case 'tax':
      return <TaxesIllustration className="w-20 h-20" />;
    case 'elderCare':
      return <ElderCareIllustration className="w-20 h-20" />;
    case 'carRental':
      return <CarRentalIllustration className="w-20 h-20" />;
    case 'residencePermit':
      return <ResidencePermitIllustration className="w-20 h-20" />;
    case 'trip':
      return <TripIllustration className="w-20 h-20" />;
    case 'fine':
      return <FineIllustration className="w-20 h-20" />;
    case 'investing':
      return <InvestingIllustration className="w-20 h-20" />;
    case 'consommation':
      return <ConsommationIllustration className="w-20 h-20" />;
    case 'family':
      return <FamilyIllustration className="w-20 h-20" />;
    case 'contact':
      return <ContactIllustration className="w-20 h-20" />;
    case 'wedding':
      return <WeddingIllustration className="w-20 h-20" />;
    case 'online':
      return <OnlineIllustration className="w-20 h-20" />;
    case 'contracts':
      return <ContractsIllustration className="w-20 h-20" />;
    case 'documents':
      return <DocumentsIllustration className="w-20 h-20" />;
    case 'mobility':
      return <MobilityIllustration className="w-20 h-20" />;
    default:
      return null;
  }
}
