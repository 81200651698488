/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { MDXEditorMethods } from '@mdxeditor/editor';
import TranslatedAnswer from 'containers/answer/TranslatedAnswer';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { additionalRequestAnswerSchema } from 'schemas';
import { createAdditionalRequestAnswer } from 'services/customer/additional-request';

import { useLawyer } from 'hooks';

import CreateAdditionalRequestForm from 'pages/lawyer/account/questions/to-answer/CreateAdditionalRequestForm';

import { useAuth } from 'context';

import {
  ModalAnswerConfirmation,
  ModalAnswerSuccess
} from 'features/lawyer/account/questions/to-answer/modals';
import ModalAnswerTemplateConfirmation from 'features/lawyer/account/questions/to-answer/modals/ModalAnswerTemplateConfirmation';
import { answerContentDefaultValue } from 'features/lawyer/account/questions/to-answer/to-answer.constants';
import { LayoutModal } from 'features/shared/layout';

interface CreateAdditionalRequestAnswerProps {
  answerId: number;
  additionalRequestId: string;
}

export interface CreateAdditionalRequestInput {
  content: string;
}

export default function CreateAdditionalRequestAnswer({
  answerId,
  additionalRequestId
}: CreateAdditionalRequestAnswerProps) {
  const { t: tForm } = useTranslation('form');
  const queryClient = useQueryClient();
  const ref = useRef<MDXEditorMethods>(null);

  const { lawyerId } = useAuth();
  const { firstName, lastName } = useLawyer({ lawyerId: lawyerId as number });

  const [isTranslateAnswerOpen, setIsTranslateAnswerOpen] = useState(false);
  const [isModalAnswerConfirmationOpen, setIsModalAnswerConfirmationOpen] =
    useState(false);
  const [isModalTemplateConfirmationOpen, setIsModalTemplateConfirmationOpen] =
    useState(false);
  const [isModalAnswerSuccessOpen, setIsModalAnswerSuccessOpen] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mutate: createAnswerMutate,
    isLoading,
    isSuccess
  } = useMutation(
    (formData: CreateAdditionalRequestInput) =>
      createAdditionalRequestAnswer(
        answerId,
        additionalRequestId,
        formData.content
      ),
    {
      onSuccess: async () => {
        searchParams.delete('id');
        setSearchParams(searchParams);
        queryClient.invalidateQueries(['questions-to-answer']);
        queryClient.invalidateQueries(['answers']);
        setIsModalAnswerConfirmationOpen(false);
        setIsModalAnswerSuccessOpen(true);
      },
      onSettled: () => {
        setIsModalAnswerConfirmationOpen(false);
      }
    }
  );

  const contentDefaultValue = answerContentDefaultValue({
    t: tForm,
    lawyerFullName: `${firstName} ${lastName}`,
    customerCanAskConsultationForFree: true
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors, isDirty }
  } = useForm<CreateAdditionalRequestInput>({
    defaultValues: {
      content: contentDefaultValue
    },
    resolver: yupResolver(additionalRequestAnswerSchema(tForm))
  });

  const handleConfirm = async () => {
    const values = getValues();
    createAnswerMutate(values);
  };

  const onChangeDefaultContent = () => {
    setValue('content', contentDefaultValue);
    ref.current?.setMarkdown(contentDefaultValue);
    setIsModalTemplateConfirmationOpen(false);
  };

  const onChangeTemplateConfirmation = () => {
    if (isDirty) setIsModalTemplateConfirmationOpen(true);
    else onChangeDefaultContent();
  };

  const onSubmit = () => setIsModalAnswerConfirmationOpen(true);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CreateAdditionalRequestForm
        isLoading={isLoading}
        onChangeTemplateConfirmation={onChangeTemplateConfirmation}
        onTranslateClick={() => setIsTranslateAnswerOpen(true)}
        control={control}
        errors={errors}
        ref={ref}
      />
      {!isSuccess ? (
        <ModalAnswerConfirmation
          handleConfirm={handleConfirm}
          setIsOpen={setIsModalAnswerConfirmationOpen}
          isOpen={isModalAnswerConfirmationOpen}
          isLoading={isLoading}
        />
      ) : (
        <ModalAnswerSuccess
          isOpen={isModalAnswerSuccessOpen}
          setIsOpen={setIsModalAnswerSuccessOpen}
        />
      )}
      <ModalAnswerTemplateConfirmation
        handleConfirm={onChangeDefaultContent}
        isOpen={isModalTemplateConfirmationOpen}
        isFormalNotice={false}
        setIsOpen={setIsModalTemplateConfirmationOpen}
      />
      <LayoutModal
        isModalOpen={isTranslateAnswerOpen}
        handleModalClose={() => setIsTranslateAnswerOpen(false)}
      >
        <TranslatedAnswer answer={watch('content')} />
      </LayoutModal>
    </form>
  );
}
