import DOMpurify from 'dompurify';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export default function SanitizedContent({ content }: { content: string }) {
  return (
    <ReactMarkdown
      className="prose"
      rehypePlugins={[rehypeRaw as any]}
      remarkPlugins={[remarkGfm]}
    >
      {DOMpurify.sanitize(content)}
    </ReactMarkdown>
  );
}
