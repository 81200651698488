import { apiPublicPythonClient } from './axios';

export interface PublicRateAnswerPayload {
  ratingLinkId: string;
  note: 0 | 1 | 2 | 3 | 4 | 5;
  comment?: string;
}

export async function rateAnswer({
  ratingLinkId,
  note,
  comment
}: PublicRateAnswerPayload) {
  const { data } = await apiPublicPythonClient.post(
    `/public/answer-ratings/link/${ratingLinkId}`,
    {
      note,
      comment
    }
  );

  return data;
}

export function isPositiveRating(note: PublicRateAnswerPayload['note']) {
  return note === 4 || note === 5;
}
