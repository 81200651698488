import { Title } from 'components';

import { Question } from 'types/question';

interface QuestionTitleProps {
  question: Question;
}

export default function QuestionTitle({ question }: QuestionTitleProps) {
  const hasConsumerTag = question?.consumerTag;

  if (!hasConsumerTag) return null;

  return (
    <div>
      <Title variant="h2">{question.title}</Title>
    </div>
  );
}
