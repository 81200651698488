import clsx from 'clsx';

import { NavbarMinimal } from '../navbar';

interface LayoutContentProps {
  children: React.ReactNode;
  className?: string;
  withLang?: boolean;
}

export default function LayoutContent({
  children,
  className = '',
  withLang = false
}: LayoutContentProps) {
  return (
    <div
      className={clsx(`min-h-screen w-full bg-gray-paper pb-12 ${className}`)}
    >
      <NavbarMinimal withLang={withLang} />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <main>
          <div className="flex flex-col items-center gap-2">{children}</div>
        </main>
      </div>
    </div>
  );
}
