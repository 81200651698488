import { Title } from 'components';
import { ReactNode } from 'react';

interface AccountContentLayoutProps {
  title: string;
  children: ReactNode;
}

export default function AccountContentLayout({
  title,
  children
}: AccountContentLayoutProps) {
  return (
    <>
      <div className="px-4 sm:px-12 lg:px-24">
        <div className="mb-4 md:mb-6">
          <Title variant="h1">{title}</Title>
        </div>
      </div>
      <div className="mb-6 border-b border-gray-600" />
      <div className="px-4 sm:px-12 lg:px-24">{children}</div>
    </>
  );
}
