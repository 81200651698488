import { ConsultationSlot, LawyerAvailabilityDaySlots } from 'types/lawyer';

import Accordion from './accordion/Accordion';

export default function VisioLawyerAvailabilitySlots({
  onSetSlot,
  currentSlot,
  daySlots
}: {
  onSetSlot: (slot: any) => void;
  currentSlot: ConsultationSlot;
  daySlots: LawyerAvailabilityDaySlots[];
}) {
  return (
    <div>
      {daySlots && daySlots?.length > 0 && (
        <Accordion defaultId={1}>
          {daySlots.map((item: any, itemKey: number) => (
            <div className="mb-4" key={item?.day}>
              <Accordion.Item
                data={item}
                id={itemKey + 1}
                onSetSlot={onSetSlot}
                currentSlot={currentSlot}
              />
            </div>
          ))}
        </Accordion>
      )}
    </div>
  );
}
