import { SVGProps } from 'react';

export default function QuestionIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" {...props}>
      <path
        d="M18 4H6C4.875 4 4 4.90625 4 6V14.9688C4 16.125 4.875 17 6 17H9V19.5938C9 19.9062 9.34375 20.0625 9.59375 19.875L13.5 17H18C19.0938 17 20 16.0938 20 15V6C20 4.90625 19.0938 4 18 4ZM11.7812 14C11.3438 14 11 13.6562 11 13.1875C11 12.75 11.3438 12.375 11.7812 12.375C12.25 12.375 12.625 12.75 12.625 13.1875C12.625 13.6562 12.25 14 11.7812 14ZM13.5938 10.375L12.4062 11.0938V11.1562C12.4062 11.4688 12.125 11.7812 11.7812 11.7812C11.4375 11.7812 11.1562 11.4688 11.1562 11.1562V10.7188C11.1562 10.5 11.25 10.2812 11.4688 10.1562L12.9375 9.28125C13.125 9.21875 13.25 9.03125 13.25 8.8125C13.25 8.5 12.9688 8.25 12.6562 8.25H11.3125C11 8.25 10.7188 8.5 10.7188 8.8125C10.7188 9.15625 10.4375 9.4375 10.0938 9.4375C9.78125 9.4375 9.5 9.15625 9.5 8.8125C9.5 7.8125 10.2812 7 11.3125 7H12.6562C13.6875 7 14.5 7.8125 14.5 8.8125C14.5 9.4375 14.1562 10.0312 13.5938 10.375Z"
        fill="#545454"
      />
    </svg>
  );
}
