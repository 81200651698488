import {
  useCreateResourceVoteMutation,
  useGetResourceVoteQuery
} from '../../api/customer/resource';
import ResourceVoteForm from './component/ResourceVoteForm';

type ResourceVoteProps = {
  resourceId: string;
};

const ResourceVote = ({ resourceId }: ResourceVoteProps) => {
  const { mutate } = useCreateResourceVoteMutation();
  const { data: resource, isLoading } = useGetResourceVoteQuery(resourceId);

  const onSubmitVote = (value: boolean) => {
    mutate({
      resourceType: 'practice_sheet',
      resourceId,
      vote: value
    });
  };

  if (isLoading) return <div />;

  return (
    <ResourceVoteForm
      defaultValue={resource?.voteType}
      onSubmitVote={onSubmitVote}
    />
  );
};

export default ResourceVote;
