import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { LayoutCard, LayoutDefault } from 'features/shared/layout';

export interface DefaultErrorPageProps {
  handleResetError: ({ reload }: { reload: boolean }) => void;
}

export default function DefaultErrorPage({
  handleResetError
}: DefaultErrorPageProps) {
  const { t } = useTranslation('customer');
  return (
    <LayoutDefault minimal>
      <LayoutCard
        title={t('error.default.title')}
        body={
          <div className="flex flex-col gap-4">
            <p className="text-md text-center font-medium ">
              {t('error.default.description')}
            </p>
            <div className="flex items-center justify-between gap-2">
              <Button
                variant="primary"
                size="medium"
                label={t('general.back')}
                fullWidth
                onClick={() => handleResetError({ reload: true })}
              />
              <a className="w-full" href="mailto:service-client@ekie.co">
                <Button
                  variant="secondary"
                  size="medium"
                  label={t('general.contactus')}
                  fullWidth
                />
              </a>
            </div>
          </div>
        }
      />
    </LayoutDefault>
  );
}
