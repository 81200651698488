import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { AvatarIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { AnswerStatus } from 'types/answer';
import { Question } from 'types/question';

import { ToAnswerDateIndicator } from '../shared';

interface QuestionCardProps {
  question: Question;
}

export default function QuestionCard({ question }: QuestionCardProps) {
  const { t } = useTranslation('lawyer');

  const hasFullName = useMemo((): boolean => {
    if (question.customer?.firstName && question.customer?.lastName)
      return true;
    return false;
  }, [question]);

  const customerName = useMemo(() => {
    if (hasFullName) {
      return `${question.customer!.firstName} ${question.customer!.lastName}`;
    }
    return t('general.ekieClient');
  }, [hasFullName, question.customer, t]);

  const formattedCreatedDateLabel: string = useMemo(() => {
    const day = formatDateToIntl(question.createdDate);
    const time = getDateAsHhMm(question.createdDate);
    return `${day} à ${time}`;
  }, [question.createdDate]);

  const publishedAnswer = question?.answers.find(
    (a) => a.status === AnswerStatus.PUBLISHED
  );

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow-base">
      <div className="mb-6 border-b border-gray-500">
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-4 items-center">
            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-400">
              <AvatarIcon />
            </span>
            <h3 className="text-lg font-sans font-bold">{customerName}</h3>
          </div>
          <span className="text-gray-700 text-sm">
            {formattedCreatedDateLabel}
          </span>
        </div>
      </div>
      <div className="border-gray-500 border-b pb-6">
        <SanitizedContent content={question.content} />
      </div>

      <div className="pt-6">
        <ToAnswerDateIndicator
          answerDeadLine={
            publishedAnswer?.additionalRequest?.answerDeadLine ||
            question.answerDeadLine
          }
        />
      </div>
    </div>
  );
}
