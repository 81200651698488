import { Link, To } from 'react-router-dom';

import { Button } from 'features/shared/buttons';

interface EmptyProps {
  to: To;
  imgSrc: string;
  imgAlt: string;
  linkLabel: string;
}

const Empty = ({ to, linkLabel, imgSrc, imgAlt }: EmptyProps) => (
  <div className="flex flex-col justify-center items-center w-full py-14 relative">
    <div className="relative items-center h-[280px] w-[297px]">
      <img
        src={imgSrc}
        className="h-full w-full mix-blend-multiply"
        alt={imgAlt}
      />
    </div>

    <Link to={to}>
      <Button variant="tertiary" size="large" label={linkLabel} />
    </Link>
  </div>
);

export default Empty;
