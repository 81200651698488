import RequestResetPassword from 'features/login/RequestResetPassword';
import { LayoutDefault } from 'features/shared/layout';

const RequestResetPasswordPage = () => (
  <LayoutDefault minimal>
    <RequestResetPassword />
  </LayoutDefault>
);

export default RequestResetPasswordPage;
