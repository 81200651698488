import { ConsultationVoteUnsatisfiedForm } from 'features/customer/consultation/forms';
import { LayoutModal } from 'features/shared/layout';

interface ConsultationVoteModalUnsatisfiedProps {
  isUnsatisfiedModalOpen: boolean;
  handleSubmitUnsatisfiedVote: (reason: string) => void;
  isMutationLoading: boolean;
}

export default function ConsultationVoteModalUnsatisfied({
  isUnsatisfiedModalOpen,
  handleSubmitUnsatisfiedVote,
  isMutationLoading
}: ConsultationVoteModalUnsatisfiedProps) {
  return (
    <LayoutModal
      isModalOpen={isUnsatisfiedModalOpen}
      handleModalClose={() => handleSubmitUnsatisfiedVote('other')}
    >
      <ConsultationVoteUnsatisfiedForm
        handleSubmitUnsatisfiedVote={handleSubmitUnsatisfiedVote}
        isMutationLoading={isMutationLoading}
      />
    </LayoutModal>
  );
}
