import { Navigate } from 'react-router-dom';

import { useUnguardedSearchParams } from 'hooks';

import { PasswordLogin, RequestMagicLink } from 'features/login';
import AutoSignUp from 'features/login/AutoSignUp';
import { LayoutDefault } from 'features/shared/layout';

export default function LoginPage() {
  const isPasswordMethod =
    useUnguardedSearchParams({ param: 'password' }) === 'true';

  const isPartnerLogin =
    useUnguardedSearchParams({ param: 'partner' }) === 'true';
  const isAutoSignupMethod = useUnguardedSearchParams({ param: 'email' });

  return (
    <LayoutDefault minimal>
      {isAutoSignupMethod && <AutoSignUp />}
      {isPasswordMethod && <PasswordLogin />}
      {isPartnerLogin && <Navigate to="/signup" replace />}

      {!isPasswordMethod && !isPartnerLogin && !isAutoSignupMethod && (
        <RequestMagicLink />
      )}
    </LayoutDefault>
  );
}
