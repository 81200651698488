import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { MemberQuestionAdditionalRequest } from 'services/question';

import { useLocale } from 'hooks';

import SanitizedContent from 'features/customer/question/SanitizedContent';

import { formatDateToIntl } from 'utils/date';

import { LawyerInfo } from './AnswerCard';

interface AdditionalRequestAnswerCardProps {
  additionalRequest: MemberQuestionAdditionalRequest;
}

const AdditionalRequestAnswerCard = ({
  additionalRequest
}: AdditionalRequestAnswerCardProps) => {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();

  return (
    <Card>
      <CardContent>
        <Title gutterBottom variant="h3">
          {t('account.question.answer.title')}
        </Title>
        <div className="my-6 flex items-center">
          <LawyerInfo
            fullName={additionalRequest.lawyer_full_name}
            avatarUrl={additionalRequest.lawyer_avatar_url}
          />
        </div>
        <div>
          {additionalRequest.request_answer && (
            <SanitizedContent content={additionalRequest.request_answer} />
          )}
          {additionalRequest.answered_at && (
            <div className="mt-2 text-right text-sm font-medium text-gray-700">
              {formatDateToIntl(
                new Date(additionalRequest.answered_at),
                'long',
                locale
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AdditionalRequestAnswerCard;
