import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useUnguardedSearchParams } from 'hooks';

import { LayoutCard, LayoutDefault } from 'features/shared/layout';

import { ModalNpsVoteConfirmation } from '../../features/customer/vote/nps-vote';

export default function NpsVotePage() {
  const params = useParams();
  const { t } = useTranslation('customer');
  const { accountId } = params;
  const npsVoteNote = useUnguardedSearchParams({ param: 'note' }) as string;
  const source = useUnguardedSearchParams({ param: 'source' }) ?? 'web';
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [returnMessage, setReturnMessage] = useState<string>('');

  return (
    <LayoutDefault minimal>
      <div>
        <LayoutCard
          title={t('account.nps.vote.confirmation.title')}
          body={
            <div className="w-full">
              <p className="text-base font-medium text-gray-800 pb-8">
                {returnMessage}
              </p>
            </div>
          }
        />
      </div>
      <ModalNpsVoteConfirmation
        accountId={accountId as string}
        npsVoteNote={npsVoteNote}
        source={source}
        isNpsVoteConfirmationOpen={isModalOpen}
        setIsNpsVoteConfirmationOpen={setIsModalOpen}
        setReturnMessage={setReturnMessage}
      />
    </LayoutDefault>
  );
}
