import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/calendar.css';

import { useCreateAvailabilitySlotMutation } from 'api/lawyer/mutations.index';
import { useCalendarEvents } from 'api/lawyer/queries.index';

import { useLawyer, useLawyerAccount, useLocale } from 'hooks';

import { useAuth } from 'context';

import { AccountContentLayout } from 'features/lawyer/account/content';
import { AvailabilityCalendar } from 'features/lawyer/account/dashboard';
import ModalDisplayConsultation from 'features/lawyer/account/dashboard/ModalDisplayConsultation';
import ModalModifyAvailability from 'features/lawyer/account/dashboard/ModalModifyAvailability';
import { Alert } from 'features/shared/alerts';
import { CameraIcon } from 'features/shared/icons';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { CalendarEvent } from 'types/calendar-event';
import { Consultation } from 'types/consultation';
import { BookingOption } from 'types/lawyer';

import { TFunction } from 'i18next';

const radioInputValues = (t: TFunction) => [
  {
    id: 1,
    value: BookingOption.BookingPhone,
    label: t('account.consultations.booking.scheduling.filter.phone')
  },
  {
    id: 2,
    value: BookingOption.BookingVideo,
    label: t('account.consultations.booking.scheduling.filter.video')
  }
];

function renderEventContent(eventInfo: any) {
  const { event, timeText } = eventInfo;
  const { extendedProps, title } = event;

  const isConsultation = title === 'Consultation';

  const onCreateTimeText = () => {
    if (
      !extendedProps.startDate ||
      !extendedProps.endDate ||
      !extendedProps.locale
    )
      return '';
    const customStartTimeText = new Date(
      extendedProps.startDate
    ).toLocaleString(extendedProps.locale, {
      hour: 'numeric',
      minute: 'numeric'
    });

    const customEndTimeText = new Date(extendedProps.endDate).toLocaleString(
      extendedProps.locale,
      {
        hour: 'numeric',
        minute: 'numeric'
      }
    );
    return `${customStartTimeText} - ${customEndTimeText}`;
  };

  const isVideo = extendedProps.option === BookingOption.BookingVideo;

  const cleanTimeText = timeText.trim(' ');

  return (
    <button
      type="button"
      className="group overflow-hidden flex relative w-full h-full"
    >
      <div className="absolute top-1 right-2">
        {isVideo && (
          <div className="w-5 h-5 fill-white">
            <CameraIcon />
          </div>
        )}
      </div>
      <div
        className={clsx('flex flex-col items-start text-white text-sm', {
          'text-[10px] p-0': isConsultation,
          'p-0.5': !isConsultation
        })}
      >
        <span className="mb-2 font-bold text-xs">
          {cleanTimeText ? timeText : onCreateTimeText()}
        </span>
        <br />
        <i>{title}</i>
      </div>
    </button>
  );
}

export default function CalendarPage() {
  const { locale } = useLocale();
  const { t } = useTranslation('lawyer');

  const [bookingOptionValue, setBookingOptionValue] = useState(
    BookingOption.BookingPhone
  );

  const [isModalModifyAvailabilityOpen, setIsModalModifyAvailabilityOpen] =
    useState<boolean>(false);

  const [isModalDisplayConsultationOpen, setIsModalDisplayConsultationOpen] =
    useState<boolean>(false);
  const [selectedStartStr, setSelectedStartStr] = useState<Date>();
  const [selectedEndStr, setSelectedEndStr] = useState<Date>();
  const [selectedOption, setSelectedOption] = useState<BookingOption>();

  const [currentConsultation, setCurrentConsultation] =
    useState<Consultation>();

  const { lawyerId } = useAuth();
  const {
    calendar: { id: calendarId }
  } = useLawyer({
    lawyerId: lawyerId as number
  });
  const calendarEvents = useCalendarEvents({
    lawyerId: lawyerId as number,
    calendarId
  });

  const { consultations } = useLawyerAccount();

  // FORMAT CALENDAR EVENT TO ADD CONSULTATION DATA -- DOUBLE CHECK
  const formattedEvent: CalendarEvent[] = useMemo(() => {
    if (!consultations || !calendarEvents?.data) return [];

    const consultationEvent = calendarEvents?.data?.filter(
      (event: CalendarEvent) => event.title === 'consultation'
    );
    const availablityEvent = calendarEvents?.data?.filter(
      (event: CalendarEvent) => event.title !== 'consultation'
    );

    const completeAvailablityEvent: any = availablityEvent?.map(
      (event: CalendarEvent) => ({
        ...event,
        startDate: event.start,
        endDate: event.end,
        option: event.option,
        locale
      })
    );

    const completeConsultationEvent: any = consultationEvent?.map(
      (event: CalendarEvent) => {
        const current: Consultation | undefined =
          consultations.scheduled.consultations.find(
            (scheduled) => scheduled.id.toString() === event.resourceId
          );
        if (current) {
          const { latestAppointment } = current;
          const { firstName, lastName, phoneCellphone } = current.customer;
          return {
            ...event,
            firstName,
            lastName,
            appointmentDate: latestAppointment?.appointmentDate,
            phoneCellphone,
            consultation: current,
            description: t('account.lawyer.calendar.consultation', {
              customerName: `${firstName} ${lastName} `,
              customerPhone: phoneCellphone
            })
          };
        }
        return { ...event };
      }
    );

    const completeAndFutureEvent = completeConsultationEvent.filter(
      (event: any) => new Date(event.appointmentDate) > new Date()
    );

    return [...completeAvailablityEvent, ...completeAndFutureEvent];
  }, [t, locale, calendarEvents, consultations]);

  const { mutate: createAvailabilitySlotMutate } =
    useCreateAvailabilitySlotMutation();

  const handleDateSelect = (selectInfo: any) => {
    if (selectInfo.allDay) return;
    const calendarApi = selectInfo.view.calendar;

    const appointmentOptions =
      bookingOptionValue === BookingOption.BookingVideo
        ? ['phone', 'video']
        : ['phone'];
    createAvailabilitySlotMutate(
      {
        lawyerId: lawyerId as number,
        startDate: selectInfo.startStr,
        endDate: selectInfo.endStr,
        appointmentOptions
      },
      {
        onSettled: () => {
          calendarApi.unselect();
        }
      }
    );
  };

  const handleAvailability = (clickInfo: any) => {
    const { startStr, endStr, title, extendedProps } = clickInfo.event;
    if (title === 'Consultation') {
      setCurrentConsultation(extendedProps.consultation);
      setIsModalDisplayConsultationOpen(true);
      return;
    }
    setSelectedStartStr(startStr);
    setSelectedEndStr(endStr);
    setSelectedOption(extendedProps.option);
    setIsModalModifyAvailabilityOpen(true);
  };

  const handleDatediff = (first: any, second: any) =>
    Math.round((second - first) / (1000 * 60 * 60 * 24));

  const handleSelectAllow = (info: any) => {
    if (handleDatediff(info.end, info.start) !== 0) {
      return false;
    }
    if (new Date() > new Date(info.startStr)) return false;
    return true;
  };

  if (!calendarEvents.isSuccess) return <LayoutLoadingSkeleton />;

  const onChangeOption = (e: any) => {
    setBookingOptionValue(e.target.value);
  };

  return (
    <>
      <div className="min-h-screen">
        <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
          <AccountContentLayout title={t('account.calendar.title')}>
            <div>
              <div className="pb-6">
                <Alert title={t('account.dashboard.alert.title')}>
                  <p>{t('account.dashboard.alert.description')}</p>
                </Alert>
              </div>
              <div className="w-full mb-4">
                <h3 className="text-black font-sans mb-3 font-bold">
                  {t('account.consultations.booking.scheduling.type')}
                </h3>

                {radioInputValues(t).map((item: any) => (
                  <div key={item.id} className="flex flex-row gap-3">
                    <input
                      onClick={onChangeOption}
                      type="radio"
                      id={item.value}
                      className="hidden peer"
                      value={item.value}
                      required
                      name="bookingOption"
                    />
                    <label
                      htmlFor={item.value}
                      className="relative flex justify-center py-0.5 items-center cursor-pointer"
                    >
                      <div
                        className={clsx(
                          'flex justify-center items-center bg-white border-[8E8E8E] border-[1.5px] w-[18px] h-[18px] rounded-full p-0.5',
                          { 'border-[#98D5B6]': item.value === 'phone' },
                          { 'border-purple-900': item.value === 'video' }
                        )}
                      >
                        <div
                          className={clsx(
                            'w-full h-full rounded-full',
                            {
                              'bg-[#98D5B6]':
                                bookingOptionValue === item.value &&
                                item.value === 'phone'
                            },
                            {
                              'bg-purple-900':
                                bookingOptionValue === item.value &&
                                item.value === 'video'
                            }
                          )}
                        />
                      </div>
                      <p
                        className={clsx(
                          'ml-[11px] font-sans text-base text-black'
                        )}
                      >
                        {item.label}
                      </p>
                    </label>
                  </div>
                ))}
              </div>
              <div className="bg-white p-6 rounded">
                <AvailabilityCalendar
                  calendarEvents={formattedEvent}
                  handleDateSelect={handleDateSelect}
                  handleAvailability={handleAvailability}
                  handleSelectAllow={handleSelectAllow}
                  renderEventContent={renderEventContent}
                  bookingOptionValue={bookingOptionValue}
                />
              </div>
            </div>
          </AccountContentLayout>
        </div>
      </div>
      {/* Remove if new design is validated */}
      {selectedStartStr && selectedEndStr && selectedOption && (
        <ModalModifyAvailability
          isModalModifyAvailabilityOpen={isModalModifyAvailabilityOpen}
          setIsModalModifyAvailabilityOpen={setIsModalModifyAvailabilityOpen}
          createAvailabilitySlotMutate={createAvailabilitySlotMutate}
          setSelectedOption={setSelectedOption}
          startStr={selectedStartStr}
          endStr={selectedEndStr}
          option={selectedOption}
        />
      )}

      {currentConsultation && (
        <ModalDisplayConsultation
          isModalDisplayConsultationOpen={isModalDisplayConsultationOpen}
          setIsModalDisplayConsultationOpen={setIsModalDisplayConsultationOpen}
          currentConsultation={currentConsultation}
        />
      )}
    </>
  );
}
