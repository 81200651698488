import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDownIcon, LogoutUnfilledIcon } from 'features/shared/icons';

import { IMenuItem } from 'types/account';

import LinkNav from './LinkNav';

interface ProfileNavProps {
  label: string;
  accountTabs: IMenuItem[];
  logout: MouseEventHandler<HTMLButtonElement>;
}

export function ProfileNav({ label, accountTabs, logout }: ProfileNavProps) {
  const { t } = useTranslation('customer');

  return (
    <Menu as="div" className="relative py-4 mb-4 inline-block text-left w-48">
      <MenuButton className="inline-flex w-full flex-row items-center justify-between rounded-md bg-gray-paper px-4 py-2 text-sm font-medium text-white">
        {({ open }) => (
          <>
            <div className="flex flex-col font-sans font-bold text-base text-black first-letter:uppercase truncate overflow-hidden whitespace-nowrap">
              {label}
            </div>
            {open ? (
              <XIcon strokeWidth={1.5} className="w-6 text-black" />
            ) : (
              <ChevronDownIcon />
            )}
          </>
        )}
      </MenuButton>
      <MenuItems
        className={classNames(
          accountTabs.length > 1 ? '-top-40' : '-top-28',
          'absolute p-2 px-4 left-0 mt-2 w-[196px] divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        )}
      >
        <MenuItem>
          <button
            type="button"
            onClick={logout}
            className="flex py-3 -ml-[2px] text-base stroke-black items-center"
          >
            <LogoutUnfilledIcon />
            <span className="ml-2 flex gap-2 items-center hover:font-bold">
              {t(`account.sidebar.logout` as any)}
            </span>
          </button>
        </MenuItem>
        {accountTabs.map((item) => (
          <MenuItem>
            <LinkNav translation="customer" item={item} key={item.name} />
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
