import ResetPassword from 'features/login/ResetPassword';
import { LayoutDefault } from 'features/shared/layout';

const ResetPasswordPage = () => (
  <LayoutDefault minimal>
    <ResetPassword />
  </LayoutDefault>
);

export default ResetPasswordPage;
