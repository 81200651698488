import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { Title } from 'components';
import { LABOR_LABEL_THEMATIC_SLUG, THEMATICS } from 'constants/thematics';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getConsumerCategorieBySlug } from 'services/consumerCategorie';

import { useSubscriptionQuery } from 'api/customer/subscriptions';

import { Paragraph } from 'features/shared/body';

interface ThematicListProps {
  customerId: number;
}

const ThematicList = ({ customerId }: ThematicListProps) => {
  const { t } = useTranslation('customer');
  const { data: subscriptionData } = useSubscriptionQuery({ customerId });
  const { data: consumerCategorieData } = useQuery(
    ['consumerCategorieBySlug', LABOR_LABEL_THEMATIC_SLUG],
    () => getConsumerCategorieBySlug(LABOR_LABEL_THEMATIC_SLUG)
  );

  const thematicsForbidden = subscriptionData?.restrictions.thematicsForbidden;
  const consumerTagsIds =
    consumerCategorieData?.consumerTags.map((consumerTag) =>
      consumerTag.replace('/consumer-tags/', '')
    ) || [];

  const hasLaborLawForbidden = thematicsForbidden?.every((value) =>
    consumerTagsIds.includes(value)
  );

  return (
    <div className="flex gap-6 flex-col max-w-4xl mt-12">
      {THEMATICS.map(
        (thematic) =>
          (thematic.id !== 'labor' ||
            (thematic.id === 'labor' && !hasLaborLawForbidden)) && (
            <div>
              <Title variant="h2">{t(thematic.title as any)}</Title>
              <Paragraph className="prose -mt-2 text-gray-550">
                {t(thematic.description as any)}
              </Paragraph>
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex gap-6 items-center justify-between rounded-lg py-6 text-left font-semibold">
                      <span>
                        Quelques exemples de questions que vous pourriez vous
                        poser
                      </span>
                      {open ? (
                        <MinusIcon className="h-5 w-5" />
                      ) : (
                        <PlusIcon className="h-5 w-5" />
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel className="prose">
                      <ul className="-mt-4 list-decimal">
                        {thematic.samples.map((sample) => (
                          <li>{t(sample as any)}</li>
                        ))}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          )
      )}
    </div>
  );
};

export default ThematicList;
