import { privateAxios, publicAxios } from 'context/auth/auth.utils';

import {
  Appointment,
  NewAppointmentVote,
  NewUnauthenticatedAppointmentVote
} from 'types/consultation';

export async function createAppointmentVote(
  appointmentId: Appointment['id'],
  newAppointmentVote: NewAppointmentVote
) {
  const res = await privateAxios.post(
    `/appointments/${appointmentId}/appointment-votes`,
    newAppointmentVote
  );
  return res.data;
}

export async function createUnauthenticatedAppointmentVote(
  appointmentId: Appointment['id'],
  newAppointmentVote: NewUnauthenticatedAppointmentVote
) {
  const res = await publicAxios.post(
    `/appointments/${appointmentId}/appointment-votes`,
    newAppointmentVote
  );
  return res.data;
}

export async function getAppointmentVoteLink(
  voteLinkId: string
): Promise<{ id: string; appointmentId: number; customerId: number }> {
  const res = await publicAxios.get(`/appointment-vote-links/${voteLinkId}`);
  return res.data;
}
