import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { requestPasswordReset } from 'services/authentication/password';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { LayoutCard } from 'features/shared/layout';

import { requestResetPasswordSchema } from './validation';

export interface RequestResetPasswordFormInputs {
  email: string;
}

const RequestResetPassword = () => {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const handleError = useErrorHandler();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (formData: { email: string }) =>
      requestPasswordReset(formData.email)
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RequestResetPasswordFormInputs>({
    resolver: yupResolver(requestResetPasswordSchema(tForm))
  });

  const onSubmit: SubmitHandler<RequestResetPasswordFormInputs> = async (
    formData
  ) => {
    mutate(formData, {
      onError: (error: any) => handleError(error)
    });
  };

  return (
    <LayoutCard
      title={t('password.reset.request.title')}
      body={
        !isSuccess ? (
          <div className="w-full">
            <div className="pb-4">
              <p>{t('password.request.p')}</p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex w-full flex-col gap-4"
            >
              <TextInputGroup
                autofocus
                type="email"
                fullWidth
                placeholder={tForm('placeholder.email')}
                label={tForm('label.email')}
                name="email"
                register={register}
                error={errors.email}
              />
              <Button
                isLoading={isPending}
                fullWidth
                submit
                size="medium"
                variant="primary"
                label={t('general.continue')}
              />
            </form>
          </div>
        ) : (
          <div className="w-full">
            <p>{t('general.emailSent.p')}</p>
          </div>
        )
      }
    />
  );
};

export default RequestResetPassword;
