import { Variant } from 'types/icon';

export default function LawyerCareIcon({ variant }: { variant: Variant }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className="w-6 h-6"
    >
      {variant === Variant.Active ? (
        <path
          d="M17.9688 17.3125C19.2188 15.875 20 14.0312 20 12C20 10 19.2188 8.125 17.9688 6.71875L18.3438 6.375C18.5312 6.1875 18.5312 5.84375 18.3438 5.65625C18.1562 5.46875 17.8125 5.46875 17.625 5.65625L17.2812 6.03125C15.875 4.78125 14 4 12 4C9.96875 4 8.09375 4.78125 6.6875 6.03125L6.34375 5.65625C6.15625 5.46875 5.8125 5.46875 5.625 5.65625C5.4375 5.84375 5.4375 6.1875 5.625 6.375L6 6.71875C4.75 8.125 4 10 4 12C4 14.0312 4.75 15.875 6 17.3125L5.625 17.6562C5.4375 17.8438 5.4375 18.1875 5.625 18.375C5.71875 18.4688 5.84375 18.5 6 18.5C6.125 18.5 6.25 18.4688 6.34375 18.375L6.6875 18C8.09375 19.25 9.96875 20 12 20C14 20 15.875 19.25 17.2812 18L17.625 18.375C17.7188 18.4688 17.8438 18.5 18 18.5C18.125 18.5 18.25 18.4688 18.3438 18.375C18.5312 18.1875 18.5312 17.8438 18.3438 17.6562L17.9688 17.3125ZM19 12C19 13.7812 18.3438 15.375 17.2812 16.5938L14.4375 13.75C14.7812 13.25 15 12.6562 15 12C15 11.375 14.7812 10.7812 14.4375 10.2812L17.2812 7.4375C18.3438 8.65625 19 10.25 19 12ZM10 12C10 10.9062 10.875 10 12 10C13.0938 10 14 10.9062 14 12C14 13.125 13.0938 14 12 14C10.875 14 10 13.125 10 12ZM12 5C13.75 5 15.3438 5.65625 16.5625 6.71875L13.7188 9.5625C13.2188 9.21875 12.625 9 12 9C11.3438 9 10.75 9.21875 10.25 9.5625L7.40625 6.71875C8.625 5.65625 10.2188 5 12 5ZM5 12C5 10.25 5.625 8.65625 6.6875 7.4375L9.53125 10.2812C9.1875 10.7812 9 11.375 9 12C9 12.6562 9.1875 13.25 9.53125 13.75L6.6875 16.5938C5.625 15.375 5 13.75 5 12ZM12 19C10.2188 19 8.625 18.375 7.40625 17.3125L10.25 14.4688C10.75 14.8125 11.3438 15 12 15C12.625 15 13.2188 14.8125 13.7188 14.4688L16.5625 17.3125C15.3438 18.375 13.75 19 12 19Z"
          fill="#AA97FF"
        />
      ) : (
        <path
          d="M17.9688 17.3125C19.2188 15.875 20 14.0312 20 12C20 10 19.2188 8.125 17.9688 6.71875L18.3438 6.375C18.5312 6.1875 18.5312 5.84375 18.3438 5.65625C18.1562 5.46875 17.8125 5.46875 17.625 5.65625L17.2812 6.03125C15.875 4.78125 14 4 12 4C9.96875 4 8.09375 4.78125 6.6875 6.03125L6.34375 5.65625C6.15625 5.46875 5.8125 5.46875 5.625 5.65625C5.4375 5.84375 5.4375 6.1875 5.625 6.375L6 6.71875C4.75 8.125 4 10 4 12C4 14.0312 4.75 15.875 6 17.3125L5.625 17.6562C5.4375 17.8438 5.4375 18.1875 5.625 18.375C5.71875 18.4688 5.84375 18.5 6 18.5C6.125 18.5 6.25 18.4688 6.34375 18.375L6.6875 18C8.09375 19.25 9.96875 20 12 20C14 20 15.875 19.25 17.2812 18L17.625 18.375C17.7188 18.4688 17.8438 18.5 18 18.5C18.125 18.5 18.25 18.4688 18.3438 18.375C18.5312 18.1875 18.5312 17.8438 18.3438 17.6562L17.9688 17.3125ZM19 12C19 13.7812 18.3438 15.375 17.2812 16.5938L14.4375 13.75C14.7812 13.25 15 12.6562 15 12C15 11.375 14.7812 10.7812 14.4375 10.2812L17.2812 7.4375C18.3438 8.65625 19 10.25 19 12ZM10 12C10 10.9062 10.875 10 12 10C13.0938 10 14 10.9062 14 12C14 13.125 13.0938 14 12 14C10.875 14 10 13.125 10 12ZM12 5C13.75 5 15.3438 5.65625 16.5625 6.71875L13.7188 9.5625C13.2188 9.21875 12.625 9 12 9C11.3438 9 10.75 9.21875 10.25 9.5625L7.40625 6.71875C8.625 5.65625 10.2188 5 12 5ZM5 12C5 10.25 5.625 8.65625 6.6875 7.4375L9.53125 10.2812C9.1875 10.7812 9 11.375 9 12C9 12.6562 9.1875 13.25 9.53125 13.75L6.6875 16.5938C5.625 15.375 5 13.75 5 12ZM12 19C10.2188 19 8.625 18.375 7.40625 17.3125L10.25 14.4688C10.75 14.8125 11.3438 15 12 15C12.625 15 13.2188 14.8125 13.7188 14.4688L16.5625 17.3125C15.3438 18.375 13.75 19 12 19Z"
          fill="#1D1D1D"
        />
      )}
    </svg>
  );
}
