export default function ArrowLeftIcon() {
  return (
    <svg className="w-6 h-6">
      <path
        d="M18.5 12.5H5m0 0 6-6m-6 6 6 6"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        stroke="#1D1D1D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
