import { slice } from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { Videos } from 'types/cms';

import { graphQLRequest } from './dato';

export const YOUTUBE_VIDEOS_BY_PAGE = 10;
const EKIE_VIDEOS_IDS = [
  '8BIPmGnw5Os',
  '6PM6oal5eTY',
  't7luYruu3-E',
  'ZMOLL5ZZ_eU',
  'OH6QmO-0Czw',
  'Rv3hfVjww9s',
  'VghmV8mben4',
  '_FfE73NQ3Q0',
  'RlVKHOriyxk',
  'K2mvaYUWgLc',
  'GEB9xvr2Ms8',
  '1W_QTZa43mA',
  '2dEvKHUVrP0',
  'qwmB4p0kqkA',
  'mXpDlfIgqB8',
  '2Jv9s2G2ANk',
  'yXAHvPCPeFI',
  '2_uEU2BiunE',
  'kYKoAfurQF8',
  'FC3xuBPMRHs',
  'gLBw0Kd-WiQ',
  'h_nOYmaHmQM',
  'MMVEOIi6LRg',
  'GyJJ22aCQS4',
  'HEYsqCQV5QY',
  'OtAKVW_IGZo',
  'wrSly2rRtmk',
  'fflbVVtGDAI',
  '_PfeO_kkqS4',
  'YoiqJ1sKRPg',
  'tFV4XaOsHA0',
  'kfYPp6SECpI',
  'c5JbA-2gjPM',
  'm7UF0mziSeU',
  'Eygw15xOpTM',
  'VHuhGgRqxdM'
];

const GET_PAGINATED_VIDEOS_QUERY = `
  query GetVideos($skip: IntType, $first: IntType = 8) {
    allVideos(
      first: $first,
      skip: $skip
    ) {
      id
      video {
        thumbnailUrl
        title
        providerUid
      }
    }
  }
`;

type YoutubeChannelResponse = {
  total: number;
  nextPageParam?: number;
  videoIds: string[];
};

export async function getYoutubeVideoIds({
  pageParam = 0
}: {
  pageParam?: number;
}): Promise<YoutubeChannelResponse> {
  const nextCursor = pageParam + YOUTUBE_VIDEOS_BY_PAGE;

  return {
    total: EKIE_VIDEOS_IDS.length,
    nextPageParam: nextCursor < EKIE_VIDEOS_IDS.length ? nextCursor : undefined,
    videoIds: slice(EKIE_VIDEOS_IDS, pageParam, nextCursor)
  };
}

export const useGetVideos = () =>
  useInfiniteQuery(
    ['videos'],
    ({ pageParam = 0 }): Promise<Videos | null> =>
      graphQLRequest(GET_PAGINATED_VIDEOS_QUERY, {
        skip: pageParam,
        first: YOUTUBE_VIDEOS_BY_PAGE
      }),
    {
      getNextPageParam: (_, pages) => {
        const nextCursor =
          pages.length === 1
            ? YOUTUBE_VIDEOS_BY_PAGE
            : pages.length * YOUTUBE_VIDEOS_BY_PAGE;

        return nextCursor;
      }
    }
  );
