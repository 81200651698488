import { Section, Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from 'features/customer/account/content';
import { MemberUpdatePassword } from 'features/member';

const UpdatePasswordPage = () => {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();

  return (
    <PageLayout>
      <Section gutterBottom>
        <Title gutterBottom variant="h2">
          {t('password.update.title')}
        </Title>
        <div className="flex w-full flex-col space-y-2 max-w-[720px]">
          <p>{t('password.update.indications.p')}</p>
          <MemberUpdatePassword
            onSuccess={() => navigate('/account/profile')}
          />
        </div>
      </Section>
    </PageLayout>
  );
};

export default UpdatePasswordPage;
