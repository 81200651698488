import { apiPrivatePythonClient } from './axios';

export type QuestionAnswer = {
  id: number;
  question_id: number;
  published_at: string;
  content: string;
  lawyer_full_name: string | null;
  lawyer_avatar_url: string | null;
  additional_request_id: string | null;
  rating_link_id: string | null;
};

export async function getMemberQuestionAnswer(
  answerId: QuestionAnswer['id']
): Promise<QuestionAnswer> {
  const { data } = await apiPrivatePythonClient.get(
    `/member/answers/${answerId}`
  );

  return data;
}
