import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { VoteButton } from 'features/customer/vote';
import { LoadingSpinner } from 'features/shared/loading';

export interface AnswerVoteFormProps {
  isLoading: boolean;
  onClick: (note: 0 | 1 | 2 | 3 | 4 | 5) => void;
}

const RateAnswerChoice = ({ isLoading, onClick }: AnswerVoteFormProps) => {
  const { t } = useTranslation('customer');

  return (
    <>
      <Title variant="h3">{t('account.question.vote.initial.title')}</Title>
      <div className="mt-2">
        {isLoading ? (
          <LoadingSpinner purple />
        ) : (
          <>
            <p className="text-md text-left font-medium">
              <span>{t('account.question.vote.initial.paragraph.1')}</span>{' '}
              <span>{t('account.question.vote.initial.paragraph.2')}</span>
            </p>
            <div className="py-3">
              <div className="grid grid-rows-vote max-w-fit">
                <div className="grid grid-cols-5 gap-4 w-full">
                  <VoteButton vote={1} handleClick={() => onClick(1)} />
                  <VoteButton vote={2} handleClick={() => onClick(2)} />
                  <VoteButton vote={3} handleClick={() => onClick(3)} />
                  <VoteButton vote={4} handleClick={() => onClick(4)} />
                  <VoteButton vote={5} handleClick={() => onClick(5)} />
                </div>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-700">
              {t('account.question.vote.initial.description')}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default RateAnswerChoice;
