import QuestionReject from 'containers/question/QuestionReject';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAcceptQuestionAssignationMutation } from 'api/lawyer/question-assignations';

import { useAuth } from 'context';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { QuestionAssignation } from 'types/question-assignation';

export default function QuestionAssignationCtas({
  questionAssignation,
  handleAssignationAccept
}: {
  questionAssignation: QuestionAssignation;
  handleAssignationAccept: () => void;
}) {
  const { t } = useTranslation('lawyer');
  const [isOpen, setIsOpen] = useState(false);

  const { lawyerId } = useAuth();
  const { mutate: acceptMutate, isLoading: acceptIsLoading } =
    useAcceptQuestionAssignationMutation();

  const handleAcceptClick = () => {
    acceptMutate(
      {
        lawyerId: lawyerId as number,
        questionAssignationId: questionAssignation.id
      },
      {
        onSuccess: () => {
          handleAssignationAccept();
        }
      }
    );
  };

  return (
    <div className="flex justify-end">
      <div className="flex gap-6">
        <Button
          variant="secondary"
          size="large"
          label={t('general.refuse')}
          onClick={() => setIsOpen(true)}
        />
        <Button
          isLoading={acceptIsLoading}
          variant="tertiary"
          size="large"
          label={t('general.accept')}
          onClick={() => handleAcceptClick()}
        />
        <LayoutModal
          handleModalClose={() => setIsOpen(false)}
          isModalOpen={isOpen}
        >
          <QuestionReject questionAssignation={questionAssignation} />
        </LayoutModal>
      </div>
    </div>
  );
}
