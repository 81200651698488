/* eslint-disable jsx-a11y/no-autofocus */
import clsx from 'clsx';
import { HTMLProps, useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

import { EyesIcon } from 'features/shared/icons';

import InputErrorMessage from './InputErrorMessage';

interface TextInputGroupProps {
  type?: string;
  autofocus?: boolean;
  name: string;
  label: string;
  error: FieldError | undefined;
  register: UseFormRegister<any>;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  maxLength?: number;
  additionalInputProps?: HTMLProps<HTMLInputElement>;
}

export default function TextInputGroup({
  type = 'text',
  autofocus = false,
  name,
  label,
  register,
  error,
  placeholder = '',
  defaultValue = '',
  disabled = false,
  fullWidth = false,
  maxLength = undefined,
  additionalInputProps = {}
}: TextInputGroupProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onShowPassword = () => setShowPassword(!showPassword);

  return (
    <div id="input-group" className={clsx({ 'w-full': fullWidth })}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div className="relative mt-1">
        <input
          maxLength={maxLength}
          type={showPassword ? 'text' : type}
          id={name}
          disabled={disabled}
          className={clsx({
            'input-text h-12': true,
            'focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900':
              !error,
            'text-red-800 border-red-800 bg-red-200': error,
            'text-gray-700': disabled
          })}
          onClick={(e) => type === 'date' && e.preventDefault()}
          placeholder={!error ? placeholder : ''}
          defaultValue={defaultValue}
          autoFocus={autofocus}
          {...register(name)}
          {...additionalInputProps}
        />
        {type === 'password' && (
          <div
            className={clsx(
              'absolute right-4 top-1/2 -translate-y-1/2 flex justify-center items-center',
              {
                'fill-purple-900': showPassword,
                'fill-black': !showPassword
              }
            )}
          >
            <button
              type="button"
              aria-label="Voir le mot de passe"
              onClick={onShowPassword}
            >
              <EyesIcon />
            </button>
          </div>
        )}
      </div>
      {error && <InputErrorMessage error={error} />}
    </div>
  );
}
