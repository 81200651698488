import { Locale } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';

import i18n from 'i18next';

export default function useLocale(): {
  locale: 'fr' | 'en';
  dateLocale: Locale;
} {
  const locale =
    i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'fr';

  const dateLocale = locale.includes('fr') ? fr : enGB;

  return { locale, dateLocale };
}
