import { ProfileNav } from 'components/ProfileNav';
import { useLocation } from 'react-router-dom';

import { useLawyer } from 'hooks';

import { useAuth } from 'context';

import GearIcon from 'features/shared/icons/GearIcon';

import { IMenuItem, PageName } from 'types/account';

export default function LawyerProfile() {
  const { pathname } = useLocation();
  const { logout, lawyerId } = useAuth();
  const { firstName, lastName } = useLawyer({
    lawyerId: lawyerId as number
  });
  const accountTabs: IMenuItem[] = [
    {
      name: PageName.Profile,
      href: '/account/profile',
      current: pathname.includes(PageName.Profile),
      newUntil: new Date('05-01-2023'),
      icon: GearIcon
    }
  ];

  return (
    <ProfileNav
      logout={logout}
      label={firstName && lastName && `${firstName} ${lastName[0]}`}
      accountTabs={accountTabs}
    />
  );
}
