import { addYears, format } from 'date-fns';

import { privateAxios } from 'context/auth/auth.utils';

import { ApiCalendarEvent, CalendarEvent } from 'types/calendar-event';
import { Lawyer } from 'types/lawyer';

import { formatCalendarEventFromApi } from './formatting';

export async function getCalendarEvents(
  lawyerId: Lawyer['id'],
  calendarId: string
): Promise<CalendarEvent[] | []> {
  const currentDate = new Date();
  const yyyyMmDd = format(currentDate, 'yyyy-MM-dd');
  const nextYearDate = addYears(currentDate, 1);
  const nextYearYyyyMmDd = format(nextYearDate, 'yyyy-MM-dd');
  const res = await privateAxios.get(
    `/fc-load-events?filters[lawyer-id]=${lawyerId}&filters[calendar-id]=${calendarId}&start=${yyyyMmDd}&end=${nextYearYyyyMmDd}`
  );

  const events: CalendarEvent[] = res.data.map(
    (apiCalendarEvent: ApiCalendarEvent) =>
      formatCalendarEventFromApi(apiCalendarEvent)
  );

  // Retrive only availability events
  // at some point - the consultations could be in the calendar
  // as well
  const availabilityEvents = events.filter((calendarEvent) =>
    ['availability', 'consultation'].includes(calendarEvent.title)
  );

  return availabilityEvents;
}
