import jwtDecode from 'jwt-decode';
import { apiPublicPythonClient } from 'services/axios';

import { setNewAuthStorage } from 'context/auth/auth.utils';

import { AuthLocalStorage } from 'types/auth';

export interface AuthLoginResponse {
  access_token: string;
  refresh_token: string;
}

export type AuthLoginPayload =
  | { email: string; password: string; token?: null }
  | { token: string; email?: null; password?: null };

export const loginByPasswordOrMagicLink = async ({
  email,
  password,
  token
}: AuthLoginPayload): Promise<AuthLocalStorage> => {
  const { data } = await apiPublicPythonClient.post<AuthLoginResponse>(
    `/auth/login`,
    { email, password, magic_link_token: token },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  const { sub } = jwtDecode<{ sub: string }>(data.access_token);

  const authenticationData = {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    email: sub
  };

  setNewAuthStorage(authenticationData);

  return authenticationData;
};
