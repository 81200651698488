import clsx from 'clsx';
import { motion } from 'framer-motion';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStep } from 'pages/customer/consultation/ConsultationBookingPage';

import { Button } from 'features/shared/buttons';
import { CameraIcon } from 'features/shared/icons';

import { SetState } from 'types/core';
import { BookingOption } from 'types/lawyer';

export default function ConsultationBookingConfirm({
  setBookingOption,
  setBookingStep,
  bookingOption
}: {
  setBookingStep: SetState<BookingStep>;
  setBookingOption: SetState<BookingOption | undefined>;
  bookingOption: BookingOption | undefined;
}) {
  const { t } = useTranslation('customer');
  const [currentBookingOption, setCurrentBookingOption] =
    useState<SetStateAction<BookingOption | undefined>>();

  useEffect(() => {
    if (bookingOption) setCurrentBookingOption(bookingOption);
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    setCurrentBookingOption(currentBookingOption);
    setBookingOption(currentBookingOption as BookingOption);
    setBookingStep(BookingStep.Payment);
  };

  const onStepBack = () => {
    setBookingStep(BookingStep.Scheduling);
  };

  const onSelectBookingType = (value: any) => {
    setCurrentBookingOption(value);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="w-full flex flex-col gap-y-4 mt-6 max-w-[720px]">
        <div className="p-8 pb-14 w-full h-auto bg-white rounded shadow-xl">
          <div className="flex flex-col w-full">
            <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-6">
              {t('account.consultations.booking.confirm.title')}
            </h2>
            <div className="flex flex-col w-full  border border-[#CCCCCC] rounded">
              <motion.button
                type="button"
                onClick={() => onSelectBookingType(BookingOption.BookingVideo)}
                className={clsx(
                  'flex flex-row justify-center items-center cursor-pointer text-[#353535] rounded border-b border-[#CCCCCC] relative py-5 pl-4 pr-[22px] w-full hover:bg-gray-200 fill-purple-700',
                  {
                    'bg-purple-700 !text-white fill-white hover:!bg-purple-700':
                      currentBookingOption === BookingOption.BookingVideo
                  }
                )}
              >
                <p className="font-sans font-medium text-base text-left first-letter:uppercase flex-grow">
                  {t('account.consultations.booking.scheduling.filter.visio')}
                </p>

                <div className="w-5 h-5">
                  <CameraIcon />
                </div>
              </motion.button>
              <motion.button
                type="button"
                onClick={() => onSelectBookingType(BookingOption.BookingPhone)}
                className={clsx(
                  'flex flex-row justify-center items-center cursor-pointer relative text-[#353535] py-5 pl-4 pr-[22px] w-full  hover:bg-gray-200 fill-purple-700',
                  {
                    'bg-purple-700 !text-white fill-white hover:!bg-purple-700':
                      currentBookingOption === BookingOption.BookingPhone
                  }
                )}
              >
                <p className="font-sans font-medium text-base text-left first-letter:uppercase flex-grow">
                  {t('account.consultations.booking.scheduling.filter.call')}
                </p>
              </motion.button>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <p className="text-gray-700 text-sm text-center">
            {t('account.consultations.booking.scheduling.agreement')}
          </p>
        </div>

        <div className="flex w-full gap-x-[14px]">
          <Button
            variant="secondary"
            size="large"
            label={t('account.consultations.booking.confirm.back')}
            onClick={onStepBack}
          />
          <Button
            variant="primary"
            size="large"
            fullWidth
            submit
            label={t('general.continue')}
          />
        </div>
      </div>
    </form>
  );
}
