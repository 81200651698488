export default function CameraIcon({ color = 'current' }: { color?: string }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.06329 2.87442C4.20999 2.87442 3.43088 3.35672 2.98568 4.06163C2.57757 4.80364 2.57757 5.73114 2.98568 6.43605C3.43088 7.17806 4.20999 7.62326 5.06329 7.62326C5.8795 7.62326 6.65861 7.17806 7.10381 6.43605C7.51191 5.73114 7.51191 4.80364 7.10381 4.06163C6.65861 3.35672 5.8795 2.87442 5.06329 2.87442ZM13.3738 9.99768H5.06329C2.42917 9.99768 0.314453 7.88296 0.314453 5.24884C0.314453 2.65182 2.42917 0.5 5.06329 0.5C6.84411 0.5 8.40232 1.50171 9.21853 2.94862C9.99763 1.50171 11.5558 0.5 13.3738 0.5C15.9708 0.5 18.1226 2.65182 18.1226 5.24884C18.1226 7.88296 15.9708 9.99768 13.3738 9.99768ZM13.3738 2.87442C12.5205 2.87442 11.7413 3.35672 11.2961 4.06163C10.888 4.80364 10.888 5.73114 11.2961 6.43605C11.7413 7.17806 12.5205 7.62326 13.3738 7.62326C14.19 7.62326 14.9691 7.17806 15.4143 6.43605C15.8224 5.73114 15.8224 4.80364 15.4143 4.06163C14.9691 3.35672 14.19 2.87442 13.3738 2.87442ZM21.0164 10.1461C21.4245 10.3316 21.6842 10.7397 21.6842 11.1849V18.3082C21.6842 18.7905 21.4245 19.1986 21.0164 19.3841C20.6083 19.5696 20.126 19.5325 19.755 19.2728L17.3806 17.4919L16.9354 17.1209V12.3721L19.755 10.2574C20.126 9.99768 20.6083 9.92348 21.0164 10.1461ZM13.3738 11.1849C14.6723 11.1849 15.7482 12.2608 15.7482 13.5593V17.1209C15.7482 18.4566 14.6723 19.4954 13.3738 19.4954H5.06329C3.72768 19.4954 2.68887 18.4566 2.68887 17.1209V13.5593H1.50166C0.833857 13.5593 0.314453 13.0399 0.314453 12.3721C0.314453 11.7414 0.833857 11.1849 1.50166 11.1849H13.3738Z"
        fill="current"
      />
    </svg>
  );
}
