import { FullCard } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { Link } from 'react-router-dom';
import { useGetLastArticles } from 'services/dato';

interface LastArticlesListProps {
  withLaborLaw?: boolean;
}

const LastArticlesList = ({ withLaborLaw = false }: LastArticlesListProps) => {
  const { data, isLoading } = useGetLastArticles({
    withLaborLaw
  });

  if (isLoading) {
    return <ArticleListSkeleton />;
  }

  return (
    <div className="grid sm:grid-cols-4 xl:grid-cols-3 gap-5 mb-10">
      {data?.allArticles.map((article) => (
        <Link
          key={article.id}
          to={`/account/resources/${article.slug}`}
          className="sm:col-span-2 xl:col-span-1"
        >
          <FullCard
            imgHeight="h-48"
            title={article.title}
            image={article.featuredMedia?.responsiveImage}
            tags={[article.tags[0]?.name]}
          />
        </Link>
      ))}
    </div>
  );
};

export default LastArticlesList;
