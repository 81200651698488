import { DocumentTextIcon } from '@heroicons/react/outline';
import { CreateAdditionalRequestInput } from 'containers/additional-request/CreateAdditionalRequestAnswer';
import { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AnswerTextArea } from 'features/lawyer/account/questions/to-answer/answer-text-area';
import { Button } from 'features/shared/buttons';

interface CreateAdditionalRequestFormProps {
  onChangeTemplateConfirmation(): void;
  onTranslateClick(): void;
  control: any;
  isLoading: boolean;
  errors?: FieldErrors<CreateAdditionalRequestInput>;
}

const CreateAdditionalRequestForm = forwardRef(
  (
    {
      onChangeTemplateConfirmation,
      isLoading,
      onTranslateClick,
      control,
      errors
    }: CreateAdditionalRequestFormProps,
    ref
  ) => {
    const { t } = useTranslation('lawyer');

    return (
      <div className="bg-white px-6 py-5 sm:p-6 lg:px-10 lg:pt-10 lg:pb-6 rounded shadow-base">
        <div className="mb-6 flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <h3 className="text-lg font-sans font-bold">Votre réponse</h3>
        </div>
        <div className="pb-6">
          <AnswerTextArea
            name="content"
            ref={ref}
            control={control}
            error={errors?.content}
          />
          <button
            type="button"
            onClick={onTranslateClick}
            className="text-sm text-purple-900 mt-2 font-medium font-sans hover:opacity-70 flex items-center"
          >
            {t('account.lawyer.question.answer.textarea.translate')}
          </button>
          <button
            type="button"
            aria-label="template"
            className="font-sant text-sm underline text-gray-900 transition-all flex items-center mt-1.5"
            onClick={onChangeTemplateConfirmation}
          >
            <DocumentTextIcon
              className="h-5 w-5 text-purple-500"
              aria-hidden="true"
            />
            <span className="ml-0.5">
              {t('account.lawyer.question.answer.proposal.template')}
            </span>
          </button>
        </div>

        <div className="flex flex-col">
          <Button
            submit
            isLoading={isLoading}
            variant="custom"
            size="large"
            label={t('account.lawyer.question.answer.formal_notice.send')}
            customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium lg:w-full xl:w-auto"
          />
        </div>
      </div>
    );
  }
);

export default CreateAdditionalRequestForm;
