import { Section } from 'components';

const AskQuestionSkeleton = () => (
  <Section>
    <div className="animate-pulse h-7 w-1/3 mb-6 bg-gray-300 rounded" />
    <div className="animate-pulse h-7 w-1/3 mb-6 bg-gray-300 rounded" />
    <div className="animate-pulse h-72 w-full mb-6 bg-gray-300 rounded" />
    <div className="animate-pulse h-12 w-full bg-gray-300 rounded" />
  </Section>
);

export default AskQuestionSkeleton;
