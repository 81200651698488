interface NumericValueProps {
  value: number;
  prefix?: string;
}

const NumericValue = ({ value, prefix }: NumericValueProps) => (
  <div className="text-3xl font-bold">
    {value}
    {prefix}
  </div>
);

export default NumericValue;
