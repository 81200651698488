import { Title } from 'components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Collaborator,
  sendBulkEmailInvitationToCollaborator
} from 'services/admin/collaborators';

import { useCustomerAccount } from 'hooks';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

interface PartnerCollaboratorDeleteProps {
  collaboratorsEmails: Collaborator['principalEmail'][];
  onSuccess?: Function;
}

const PartnerCollaboratorBulkSendInvitation = ({
  collaboratorsEmails,
  onSuccess = () => {}
}: PartnerCollaboratorDeleteProps) => {
  const { t } = useTranslation('customer');
  const { subscription } = useCustomerAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isLoading, isSuccess, isIdle, isError, reset } = useMutation(
    () =>
      sendBulkEmailInvitationToCollaborator(
        subscription.product.offeredByPartner.id,
        collaboratorsEmails
      ),
    {
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('admin.collaborators.form.add.error.generic')}
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  const closeModale = () => {
    setIsModalOpen(false);
    reset();
    isSuccess && onSuccess();
  };

  return (
    <div>
      <Button
        className="!text-purple-800 !py-0 !px-4 !font-bold"
        variant="transparent"
        size="medium"
        onClick={() => setIsModalOpen(true)}
      >
        {t('admin.collaborators.table.bulkActions.sendInvitation')}
      </Button>
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModale}>
        <Title variant="h3" text="Envoyer une invitation Ekie" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.form.sendBulkInvitationEmail.success')}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                variant="tertiary"
                size="medium"
                label={t('general.close')}
                onClick={closeModale}
              />
            </div>
          </div>
        )}
        {(isIdle || isLoading || isError) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.sendBulkInvitationEmail.text', {
                count: collaboratorsEmails.length
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModale}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isLoading}
                variant="custom"
                size="medium"
                label={t(
                  'admin.collaborators.modal.sendInvitationEmail.submit'
                )}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default PartnerCollaboratorBulkSendInvitation;
