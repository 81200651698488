import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import {
  LoginMagicLinkPage,
  LoginPage,
  RequestResetPasswordPage,
  ResetPasswordPage,
  SignUpPage
} from 'pages/authentication';
import {
  ConsultationAppointmentVotePage,
  NpsVotePage,
  PrivateContentPage
} from 'pages/customer';
import { NotFoundPage } from 'pages/error';
import { AnswerRatingPage } from 'pages/rating';

import { PUBLIC_DEFAULT_REDIRECT_URL } from './constants';

export const RedirectToRateAnswer = () => {
  const { ratingLinkId } = useParams();

  return <Navigate to={`/rate-answer/${ratingLinkId}`} replace />;
};

const PublicRouter = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="authenticate" element={<LoginMagicLinkPage />} />
    <Route path="rate-answer/:ratingLinkId" element={<AnswerRatingPage />} />
    <Route
      path="consultations/vote/link/:voteLinkId"
      element={<ConsultationAppointmentVotePage />}
    />
    <Route path="nps/vote/:accountId" element={<NpsVotePage />} />
    <Route path="/signup" element={<SignUpPage />} />
    <Route path="password/request" element={<RequestResetPasswordPage />} />
    <Route path="password/new" element={<ResetPasswordPage />} />
    <Route path="content/:slug" element={<PrivateContentPage />} />
    <Route path="*" element={<NotFoundPage />} />
    <Route
      path=""
      element={<Navigate to={PUBLIC_DEFAULT_REDIRECT_URL} replace />}
    />
    <Route
      path="account/*"
      element={<Navigate to={PUBLIC_DEFAULT_REDIRECT_URL} replace />}
    />
    <Route
      path="onboarding/email"
      element={<Navigate to="/signup" replace />}
    />
    <Route
      path="questions/vote/link/:ratingLinkId"
      element={<RedirectToRateAnswer />}
    />
  </Routes>
);

export default PublicRouter;
