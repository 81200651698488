import { ProfileCompletionForm } from 'features/customer/onboarding';
import { LayoutOnboarding } from 'features/shared/layout';

export default function ProfileCompletionPage() {
  return (
    <LayoutOnboarding
      leftChild={
        <div className="h-full py-6">
          <div className="h-full container mx-auto max-w-md">
            <div className="h-full flex items-center lg:justify-center flex-col gap-6">
              <div className="w-full grid place-items-center my-4">
                <div className="w-full grid grid-cols-4 gap-4">
                  <div className="border-2 border-b rounded-lg border-gray-800" />
                  <div className="border-2 border-b rounded-lg border-gray-400" />
                  <div className="border-2 border-b rounded-lg border-gray-400" />
                  <div className="border-2 border-b rounded-lg border-gray-400" />
                </div>
              </div>
              <div className="row-span-2 lg:row-span-3">
                <div className="h-full flex flex-col items-center gap-6">
                  <div className="w-full text-center">
                    <h3 className="text-4xl font-serif italic mb-2">
                      Bienvenue !
                    </h3>
                    <p className="text-gray-800">
                      Pour assurer une bonne communication avec l'avocat, nous
                      avons besoin que vous complétiez vos informations
                      personnelles.
                    </p>
                  </div>
                  <div className="w-full">
                    <ProfileCompletionForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      rightChild={
        <div className="h-full flex justify-end">
          <img
            src="/images/onboarding-kyc.webp"
            className="max-h-screen py-6"
            alt="People in a metro"
          />
        </div>
      }
    />
  );
}
