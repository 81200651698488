/* eslint-disable jsx-a11y/no-autofocus */
import clsx from 'clsx';
import { HTMLProps } from 'react';

interface TextInputGroupProps {
  type?: string;
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  additionalInputProps?: HTMLProps<HTMLInputElement>;
}

export default function DisabledTextInputGroup({
  name,
  label,
  type = 'text',
  defaultValue = '',
  disabled = false,
  fullWidth = false,
  additionalInputProps = {}
}: TextInputGroupProps) {
  return (
    <div id="input-group" className={clsx({ 'w-full': fullWidth })}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div className="relative mt-1">
        <input
          type={type}
          id={name}
          disabled={disabled}
          className={clsx({
            'input-text focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900':
              true,
            'text-gray-700': disabled
          })}
          defaultValue={defaultValue}
          {...additionalInputProps}
        />
      </div>
    </div>
  );
}
