interface ClockIconProps {
  fill?: string;
}

export default function ClockIcon({ fill = '#1D1D1D' }: ClockIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.47526 1.91667C4.96224 1.91667 2.14193 4.76172 2.14193 8.25C2.14193 11.763 4.96224 14.5833 8.47526 14.5833C11.9635 14.5833 14.7839 11.763 14.7839 8.25C14.8086 4.76172 11.9883 1.91667 8.47526 1.91667ZM10.5534 10.2044C10.4297 10.3529 10.2565 10.4271 10.0586 10.4271C9.9349 10.4271 9.8112 10.4023 9.6875 10.3281L8.10417 9.14062C7.98047 9.01693 7.90625 8.84375 7.90625 8.64583V5.67708C7.90625 5.35547 8.15365 5.08333 8.5 5.08333C8.82161 5.08333 9.09375 5.35547 9.09375 5.67708V8.34896L10.4297 9.36328C10.7018 9.5612 10.7513 9.93229 10.5534 10.2044Z"
        fill={fill}
      />
    </svg>
  );
}
