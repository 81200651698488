import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { YOUTUBE_VIDEOS_BY_PAGE, useGetVideos } from 'services/youtubeChannel';

const LastEkieYoutubeVideos = () => {
  const { data, fetchNextPage, hasNextPage = false } = useGetVideos();

  if (!data) return <div />;

  return (
    <InfiniteScroll
      loader=""
      dataLength={data.pages.length * YOUTUBE_VIDEOS_BY_PAGE}
      next={fetchNextPage}
      hasMore={hasNextPage}
    >
      <div className="grid sm:grid-cols-4 xl:grid-cols-3 gap-5 mb-10">
        {data?.pages.map((page) =>
          page?.allVideos.map(({ video }) => (
            <Link key={video.id} to={`/account/videos/${video.providerUid}`}>
              <img
                className="aspect-video object-cover"
                alt="thumbnail video youtube"
                src={video.thumbnailUrl}
              />
            </Link>
          ))
        )}
      </div>
    </InfiniteScroll>
  );
};

export default LastEkieYoutubeVideos;
