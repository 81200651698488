import { Navigate, Route, Routes } from 'react-router-dom';

import { useLawyerQuery } from 'api/lawyer/lawyers';

import { NotFoundPage } from 'pages/error';
import {
  AccountPage,
  AnsweredPage,
  AssignationsPage,
  CalendarPage,
  ConsultationsPage,
  DashboardPage,
  LawyerCarePage,
  PaymentsPage,
  ProfilePage,
  QuestionsPage,
  ToAnswerPage
} from 'pages/lawyer';
import { PaginationContextProvider } from 'pages/lawyer/context';

import { useAuth } from 'context';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { LAWYER_DEFAULT_REDIRECT_URL } from './constants';

const LawyerRouter = () => {
  const { lawyerId } = useAuth();
  const lawyer = useLawyerQuery({ lawyerId: lawyerId as number });

  if (!lawyer.isSuccess) return <LayoutLoadingSkeleton />;

  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to={LAWYER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="login"
        element={<Navigate to={LAWYER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="authenticate"
        element={<Navigate to={LAWYER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="account"
        element={
          <PaginationContextProvider>
            <AccountPage />
          </PaginationContextProvider>
        }
      >
        <Route path="calendar" element={<CalendarPage />} />
        <Route
          path="dashboard"
          element={
            process.env.REACT_APP_EKIE_DASHBOARD_AVAILIBILITY === '1' ? (
              <DashboardPage />
            ) : (
              <Navigate replace to="/account/calendar" />
            )
          }
        />
        <Route path="profile" element={<ProfilePage />} />
        <Route
          path="home"
          element={<Navigate replace to={LAWYER_DEFAULT_REDIRECT_URL} />}
        />
        <Route path="questions" element={<QuestionsPage />}>
          <Route path="question-assignations" element={<AssignationsPage />} />
          <Route path="questions-to-answer" element={<ToAnswerPage />} />
          <Route
            path="questions-answered/:answerId"
            element={<AnsweredPage />}
          />
        </Route>
        <Route path="consultations" element={<ConsultationsPage />} />
        <Route path="payments" element={<PaymentsPage />} />
        <Route path="lawyercare" element={<LawyerCarePage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default LawyerRouter;
