import AlphabetCard from 'components/alphabet/AlphabetCard';
import { AlphabetSkeleton } from 'components/skeleton';
import { useGetAlphabet } from 'services/dato';

const Alphabet = () => {
  const { data, isLoading } = useGetAlphabet();

  if (isLoading) {
    return <AlphabetSkeleton />;
  }

  return (
    <div className="flex flex-wrap sm:gap-2">
      {data?.alphabet.map((alphabetSheet) => (
        <AlphabetCard
          alphabetSheet={alphabetSheet}
          className="w-1/2 sm:w-1/3 lg:max-w-[228px]"
        />
      ))}
    </div>
  );
};

export default Alphabet;
