import {
  ApiQuestionAssignation,
  QuestionAssignation
} from 'types/question-assignation';

export const formatQuestionAssignationFromApi = (
  data: ApiQuestionAssignation
): QuestionAssignation => {
  const { id: questionAssignationId, question } = data;
  const {
    id: questionId,
    title,
    anonymizedTitle,
    content,
    anonymizedContent,
    createdDate,
    consumerTag,
    answerDeadLine,
    customer
  } = question;
  return {
    id: questionAssignationId,
    question: {
      id: questionId,
      title,
      anonymizedTitle,
      content,
      anonymizedContent,
      createdDate,
      consumerTag,
      answerDeadLine,
      customer
    }
  };
};
