import { useErrorHandler } from 'react-error-boundary';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { signUp } from 'services/authentication/signup';
import { useEffectOnce } from 'usehooks-ts';

import { useSafedGuardedSearchParams } from 'hooks/useSearchParams';

const AutoSignUp = () => {
  const email = useSafedGuardedSearchParams({ param: 'email' });
  const code = useSafedGuardedSearchParams({ param: 'partnerKey' });
  const handleError = useErrorHandler();
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (data: { email: string; code: string }) =>
      signUp(data.email, data.code),
    onSuccess: () => navigate('/signup?submitted=true'),
    onError: (error: any) => handleError(error)
  });

  useEffectOnce(() => mutate({ email, code }));

  return <div />;
};

export default AutoSignUp;
