import { SVGProps } from 'react';

export default function FileRemoveIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="19" cy="19" r="19" fill="#FBFAFA" />
      <path
        d="M26 12L12 26"
        stroke="#656565"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 26L12 12"
        stroke="#656565"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
