import { ConsumerTag } from 'types/question';

interface QuestionTagProps {
  name: ConsumerTag['name'];
}

export default function QuestionTag({ name }: QuestionTagProps) {
  return (
    <span className="px-2 py-1 bg-[#F5F3FF] border border-white rounded shadow-sm font-serif uppercase text-xs tracking-wider md:truncate md:mr-3">
      {name}
    </span>
  );
}
