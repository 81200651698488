import { Title } from 'components';

import { QuestionAssignation } from 'types/question-assignation';

export default function QuestionTitle({
  question
}: {
  question: QuestionAssignation['question'];
}) {
  return (
    <div>
      <Title variant="h2">{question.anonymizedTitle}</Title>
    </div>
  );
}
