import { Card } from 'components';

const AlphabetSheetSkeleton = () => (
  <>
    <Card
      compact
      className="flex rounded-2xl xl:min-h-screen flex-row lg:flex-col justify-between w-full xl:w-72"
    >
      <div className="flex flex-row xl:flex-col">
        <div className="animate-pulse h-28 w-28 md:h-52 md:w-64 xl:h-64 xl:w-full bg-gray-300 rounded" />
        <div className="animate-pulse h-6 w-36 xl:w-full ml-6 xl:ml-0 mt-0 xl:mt-6 self-end xl:self-start bg-gray-300 rounded" />
      </div>
      <div className="animate-pulse hidden xl:block h-32 w-full bg-gray-300 rounded" />
    </Card>
    <Card className="flex-1">
      <div className="animate-pulse h-12 w-full bg-gray-300 rounded" />
      <div className="animate-pulse h-96 w-full bg-gray-300 rounded" />
    </Card>
  </>
);

export default AlphabetSheetSkeleton;
