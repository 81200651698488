/* eslint-disable no-underscore-dangle */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import { useLocale } from 'hooks';

interface CookiesSettings {
  googleAnalytics: boolean;
  segment: boolean;
  hotJar: boolean;
}

interface TrackingProviderProps {
  children: JSX.Element | null;
}

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const { locale } = useLocale();

  const isAxeptioLoaded = useRef(false);
  const isHotJarLoaded = useRef(false);

  const loadAxeptioScript = () => {
    const axeptioId = process.env.REACT_APP_AXEPTIO_CLIENT_ID as string;
    const script = document.createElement('script');

    script.innerHTML = `
    window.axeptioSettings = {
      clientId: "${axeptioId}",
      cookiesVersion: "ekie app-${locale}",
      jsonCookieName: 'axeptio_cookies'
    };
     
    (function(d, s) {
      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
      e.async = true; e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");
    `;
    document.head.appendChild(script);

    isAxeptioLoaded.current = true;
  };

  useEffect(() => {
    const axeptioWindowIsReady =
      window !== undefined && !(window as any).axeptioSettings;
    if (axeptioWindowIsReady && !isAxeptioLoaded.current) {
      loadAxeptioScript();
    }
  }, [isAxeptioLoaded]);

  const loadHotJarScript = () => {
    const hotJarId = process.env.REACT_APP_HOTJAR_ID as string;
    const script = document.createElement('script');
    script.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotJarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(script);

    isHotJarLoaded.current = false;
  };

  const enableHotJar = () => loadHotJarScript();

  useEffect(() => {
    const windowIsReady = window !== undefined;
    if (windowIsReady) {
      // @ts-ignore
      window._axcb = window._axcb || [];

      // @ts-ignore
      window._axcb.push((axeptio) => {
        axeptio.on('cookies:complete', async (choices: CookiesSettings) => {
          if (choices) {
            if (choices.hotJar) {
              enableHotJar();
            }
          }
        });
      });
    }
  });

  return children;
};

export default TrackingProvider;
