import { Pagination } from 'components/pagination';

import {
  Consultation,
  ConsultationsFilter,
  PaginationConsultation
} from 'types/consultation';

import { CommonConsultationCard } from '../card';

interface PastConsultationsProps {
  onRefetch(page: number): void;
  consultations: Consultation[];
  pagination: PaginationConsultation;
}

export default function PastConsultations({
  onRefetch,
  consultations,
  pagination
}: PastConsultationsProps) {
  return (
    <div className="flex flex-col gap-6">
      {consultations.map((consultation) => (
        <CommonConsultationCard
          status={ConsultationsFilter.PAST}
          key={consultation.id}
          consultation={consultation}
        />
      ))}
      <Pagination onRefetch={onRefetch} pageCount={pagination.last} />
    </div>
  );
}
