import { Pagination } from 'components/pagination';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useCompleteConsultationMutation } from 'api/lawyer/consultations';

import { usePagination } from 'pages/lawyer/context';

import {
  Consultation,
  ConsultationsFilter,
  PaginationConsultation
} from 'types/consultation';

import { CommonConsultationCard } from '../card';
import { ModalCancelConsultation } from '../modal';

interface ToCompleteConsultationsProps {
  onRefetch(page: number): void;
  consultations: Consultation[];
  pagination: PaginationConsultation;
}

export default function ToCompleteConsultations({
  onRefetch,
  consultations,
  pagination
}: ToCompleteConsultationsProps) {
  const queryClient = useQueryClient();
  const { mutate, isLoading: isCompleteConsultationLoading } =
    useCompleteConsultationMutation();
  const {
    onForceRefetchToComplete,
    onForceRefetchPast,
    onForceRefetchScheduled
  } = usePagination();

  const [consultationId, setConsultationId] = useState<Consultation['id']>();
  const [isCurrentConsultation, setIsCurrentConsultation] =
    useState<Consultation['id']>();

  const [isModalCancelConsultationOpen, setIsModalCancelConsultationOpen] =
    useState<boolean>(false);

  const handleCancelConsultation = (id: Consultation['id']) => {
    setConsultationId(id);
    setIsModalCancelConsultationOpen(true);
  };

  const handleCompleteConsultation = (id: Consultation['id']) => {
    setIsCurrentConsultation(id);
    mutate(
      {
        consultationId: id
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['consultations']);
          onForceRefetchScheduled();
          onForceRefetchToComplete();
          onForceRefetchPast();
        }
      }
    );
  };

  return (
    <div className="flex flex-col gap-6">
      {consultations.map((consultation) => (
        <CommonConsultationCard
          key={consultation.id}
          status={ConsultationsFilter.TOCOMPLETE}
          consultation={consultation}
          handleCancelConsultation={handleCancelConsultation}
          handleCompleteConsultation={handleCompleteConsultation}
          isCompleteConsultationLoading={
            isCompleteConsultationLoading &&
            isCurrentConsultation === consultation.id
          }
        />
      ))}
      {consultationId && (
        <ModalCancelConsultation
          consultationId={consultationId}
          isModalCancelConsultationOpen={isModalCancelConsultationOpen}
          setIsModalCancelConsultationOpen={setIsModalCancelConsultationOpen}
        />
      )}
      <Pagination onRefetch={onRefetch} pageCount={pagination.last} />
    </div>
  );
}
