import { SVGProps } from 'react';

export default function SunIllustration({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="150.5"
        cy="150.5"
        r="57.25"
        transform="rotate(21.1254 150.5 150.5)"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M175.082 85.4906L186.976 54.7084"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M216.591 172.284L247.373 184.177"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M215.181 118.304L245.753 104.772"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M181.863 212.137L195.395 242.71"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M113.092 245.931L124.985 215.149"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M56.1499 110.293L86.9321 122.187"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M56.8033 189.161L87.376 175.629"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M111.229 55.6246L124.76 86.1972"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M198.257 101.353L207.407 91.8424"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M201.467 198.017L210.978 207.167"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M219.271 147.054L232.466 146.799"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M155.321 219.803L155.576 232.998"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M93.1493 209.478L102.299 199.968"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M93.9612 90.6132L103.472 99.763"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M68.486 149.16L81.6809 148.905"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M147.656 68.7561L147.912 81.951"
        stroke="black"
        strokeWidth="0.5"
      />
    </svg>
  );
}
