import { Title } from 'components';
import { Link, To } from 'react-router-dom';

interface HeroBannerProps {
  title: string;
  to: To;
  label: string;
  imgSrc: string;
  onClick: () => void;
}

const HeroBanner = ({ imgSrc, title, to, label, onClick }: HeroBannerProps) => (
  <div className="rounded-2xl bg-black mb-2 flex">
    <div className="sm:w-1/2 h-56 sm:h-72 px-8 py-6 sm:py-8 flex justify-between flex-col">
      <Title
        className="!text-3xl sm:!text-4xl font-bold whitespace-pre-line text-white"
        variant="h1"
      >
        {title}
      </Title>
      <Link
        className="block w-fit mt-4 uppercase text-xs rounded-full text-center tracking-widest font-medium bg-white hover:bg-purple-900 px-6 py-4 focus:outline-none sm:py-3"
        to={to}
        onClick={onClick}
      >
        {label}
      </Link>
    </div>
    <div className="w-1/2 hidden sm:flex justify-end">
      <img
        className="rounded-2xl h-56 sm:h-72 object-cover xl:object-contain"
        src={imgSrc}
        alt="hero banner"
      />
    </div>
  </div>
);

export default HeroBanner;
