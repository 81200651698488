import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ResourceVoteProps = {
  defaultValue: any;
  onSubmitVote: (value: boolean) => void;
};

type ResourceVoteInput = {
  upVote: boolean;
  comment?: string;
};
const ResourceVoteForm = ({
  defaultValue,
  onSubmitVote
}: ResourceVoteProps) => {
  const { t } = useTranslation('form');
  const { register, watch, setValue } = useForm<ResourceVoteInput>({
    defaultValues: {
      upVote: defaultValue
    }
  });
  const hasRate = watch('upVote');

  const onThumbClick = (value: boolean) => {
    if (!hasRate) {
      setValue('upVote', value);
      onSubmitVote(value);
    }
  };

  return (
    <div className="flex items-center">
      <span className="flex">
        <input type="hidden" {...register('upVote')} />
        {t('value.resource-vote.agree')}
        <ThumbUpIcon
          strokeWidth={1.5}
          className="h-6 w-6 ml-2 hover:fill-gray-500 hover:stroke-purple-900 cursor-pointer"
          onClick={() => onThumbClick(true)}
          fill={hasRate !== true ? '#FFFFFF' : '#C5C5C5'}
          stroke={hasRate !== true ? '#1d1d1d' : '#9022FF'}
        />
      </span>
      <span className="flex ml-6">
        {t('value.resource-vote.disagree')}
        <ThumbDownIcon
          strokeWidth={1.5}
          className="h-6 w-6 ml-2 hover:fill-gray-500 hover:stroke-purple-900 cursor-pointer"
          onClick={() => onThumbClick(false)}
          fill={hasRate !== false ? '#FFFFFF' : '#C5C5C5'}
          stroke={hasRate !== false ? '#1d1d1d' : '#9022FF'}
        />
      </span>
    </div>
  );
};

export default ResourceVoteForm;
