import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { Title } from 'components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Collaborator,
  sendEmailInvitationToCollaborator
} from 'services/admin/collaborators';

import { useCustomerAccount } from 'hooks';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

interface PartnerCollaboratorSendInvitationProps {
  collaboratorEmail: Collaborator['principalEmail'];
  firstName: Collaborator['firstName'];
  lastName: Collaborator['lastName'];
}

const PartnerCollaboratorSendInvitation = ({
  collaboratorEmail,
  firstName,
  lastName
}: PartnerCollaboratorSendInvitationProps) => {
  const { t } = useTranslation('customer');
  const { subscription } = useCustomerAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isLoading, isSuccess, isIdle, isError, reset } = useMutation(
    () =>
      sendEmailInvitationToCollaborator(
        subscription.product.offeredByPartner.id,
        collaboratorEmail
      ),
    {
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('admin.collaborators.form.add.error.generic')}
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  const closeModale = () => {
    setIsModalOpen(false);
    reset();
  };

  return (
    <div>
      <Button
        variant="custom"
        size="small"
        customClassNames="!p-0 text-white"
        onClick={() => setIsModalOpen(true)}
        icon={
          <PaperAirplaneIcon
            className="w-5 h-6 rotate-90"
            strokeWidth={2}
            stroke="gray"
          />
        }
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModale}>
        <Title variant="h3" text="Envoyer une invitation Ekie" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.form.sendInvitationEmail.success')}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                variant="tertiary"
                size="medium"
                label={t('general.close')}
                onClick={closeModale}
              />
            </div>
          </div>
        )}
        {(isIdle || isLoading || isError) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.sendInvitationEmail.text', {
                firstName,
                lastName
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModale}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isLoading}
                variant="custom"
                size="medium"
                label={t(
                  'admin.collaborators.modal.sendInvitationEmail.submit'
                )}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default PartnerCollaboratorSendInvitation;
