import { useTranslation } from 'react-i18next';

import { useStripeUpdateAccountId } from 'api/lawyer/queries.index';

import { useLawyer, useUnguardedSearchParams } from 'hooks';

import { useAuth } from 'context';

import { AccountContentLayout } from 'features/lawyer/account/content';
import {
  StripePaymentsConfigured,
  StripePaymentsNotConfigured
} from 'features/lawyer/account/payments';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

export default function PaymentsPage() {
  const code = useUnguardedSearchParams({ param: 'code' });
  const { t } = useTranslation('lawyer');
  const { lawyerId } = useAuth();
  const { stripeConnectId } = useLawyer({ lawyerId: lawyerId as number });
  const hasStripeConfigured = !!stripeConnectId;

  const { isLoading } = useStripeUpdateAccountId({
    lawyerId: lawyerId as number,
    code
  });

  if (isLoading) return <LayoutLoadingSkeleton />;

  return (
    <div className="min-h-screen">
      <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
        <AccountContentLayout title={t('account.sidebar.payments')}>
          {hasStripeConfigured ? (
            <StripePaymentsConfigured />
          ) : (
            <StripePaymentsNotConfigured />
          )}
        </AccountContentLayout>
      </div>
    </div>
  );
}
