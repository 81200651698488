import { Customer } from './customer';
import { Lawyer } from './lawyer';

export enum PageName {
  Home = 'home',
  Questions = 'questions',
  Ask = 'ask',
  Consultations = 'consultations',
  Resources = 'resources',
  Calendar = 'calendar',
  Subscription = 'subscription',
  Guarantees = 'guarantees',
  Contact = 'contact',
  Profile = 'profile',
  Dashboard = 'dashboard',
  Payments = 'payments',
  LawyerCare = 'lawyercare',
  Collaborators = 'collaborators',
  Settings = 'settings',
  Admin = 'admin'
}

export interface IMenuItem {
  name: PageName;
  href: string;
  current: boolean;
  newUntil: Date;
  icon?: any;
  onClick?: Function;
}

export interface ApiAccount {
  id: number;
  type: string;
  lawyer: Lawyer | null;
  customer: Customer | null;
  username: string;
  email: string;
  roles: string[];
  signupDate: Date;
  lastLoginDate: Date;
  isLocked: boolean;
  lockedUntilDate: null;
  addresses: any[];
  tags: Tag[];
  marcomSubscriptions: any[];
}

export interface Tag {
  id: number;
  tag: string;
  value: string;
  account: string;
}
export interface Account {
  id: number;
  roles: string[];
  lawyerId: number | null;
  lawyer: Lawyer | null;
}
