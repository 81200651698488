import { PropsWithChildren, ReactNode } from 'react';

interface BadgeProps {
  content: ReactNode;
}

const Badge = ({ content, children }: PropsWithChildren<BadgeProps>) => (
  <div className="relative inline-flex w-fit">
    <div className="absolute leading-[0.5] bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-2/3 translate-x-2/3 rotate-0 skew-x-0 skew-y-0 scale-x-75 scale-y-75 rounded-full bg-purple-900 p-2 text-white font-bold">
      {content}
    </div>
    {children}
  </div>
);

export default Badge;
