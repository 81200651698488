import jwtDecode from 'jwt-decode';
import { apiPublicPythonClient } from 'services/axios';

import { setNewAuthStorage } from 'context/auth/auth.utils';

import { AuthLoginResponse } from './login';

export async function requestPasswordReset(email: string): Promise<any> {
  await apiPublicPythonClient.post('/auth/reset-password-request', {
    email
  });
}

export interface AuthResetPasswordPayload {
  token: string;
  password: string;
}

export async function resetPassword({
  token,
  password
}: AuthResetPasswordPayload) {
  const { data } = await apiPublicPythonClient.post<AuthLoginResponse>(
    '/auth/reset-password',
    {
      token,
      password
    }
  );

  const { sub } = jwtDecode<{ sub: string }>(data.access_token);

  const authenticationData = {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    email: sub
  };

  setNewAuthStorage(authenticationData);

  return authenticationData;
}
