import { yupResolver } from '@hookform/resolvers/yup';
import { Title } from 'components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { cancelConsultationIntentSchema } from 'schemas';

import { useCancelConsultationMutation } from 'api/lawyer/mutations.index';

import { usePagination } from 'pages/lawyer/context';

import { SelectConsultationCancelIntent } from 'features/lawyer/account/consultations/select';
import { Button } from 'features/shared/buttons';
import { BalanceIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';

import { Consultation, ConsultationCancelIntent } from 'types/consultation';
import { SetState } from 'types/core';

export interface ConsultationCancelIntentInput {
  intent: ConsultationCancelIntent;
}

interface ModalCancelAvailabilityProps {
  consultationId: Consultation['id'];
  isModalCancelConsultationOpen: boolean;
  setIsModalCancelConsultationOpen: SetState<boolean>;
}

export default function ModalCancelConsultation({
  consultationId,
  isModalCancelConsultationOpen,
  setIsModalCancelConsultationOpen
}: ModalCancelAvailabilityProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('lawyer');
  const {
    onForceRefetchScheduled,
    onForceRefetchToComplete,
    onForceRefetchPast
  } = usePagination();

  const { t: tForm } = useTranslation('form');
  const { register, handleSubmit } = useForm<ConsultationCancelIntentInput>({
    resolver: yupResolver(cancelConsultationIntentSchema(tForm))
  });

  const { mutate, isLoading } = useCancelConsultationMutation();

  const handleModalQuestionAcceptedClose = () => {
    setIsModalCancelConsultationOpen(false);
  };

  const onSubmit = (formData: ConsultationCancelIntentInput) => {
    const { intent } = formData;
    mutate(
      {
        consultationId,
        intent: Number(intent)
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['consultations']);
          onForceRefetchScheduled();
          onForceRefetchToComplete();
          onForceRefetchPast();
        },
        onSettled: () => {
          setIsModalCancelConsultationOpen(false);
        }
      }
    );
  };

  return (
    <LayoutModal
      isModalOpen={isModalCancelConsultationOpen}
      handleModalClose={handleModalQuestionAcceptedClose}
    >
      <div>
        <div className="flex flex-col items-center">
          <BalanceIcon />
          <Title variant="h3">
            {t('account.consultations.cancellation.title')}
          </Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium ">
            {t('account.consultations.cancellation.p')}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <SelectConsultationCancelIntent register={register} />
            </div>
            <div className="mt-4">
              <div className="flex gap-4 justify-end">
                <Button
                  variant="secondary"
                  size="medium"
                  label={t('general.back')}
                  onClick={() => setIsModalCancelConsultationOpen(false)}
                />
                <Button
                  submit
                  variant="custom"
                  customClassNames="bg-red-800 text-white"
                  size="medium"
                  label={t('account.consultations.cancellation.confirm')}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutModal>
  );
}
