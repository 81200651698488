export default function LogoutUnfilledIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5547 9.71094L18.3438 12.5L15.5547 15.2891"
        stroke="current"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9062 12.5H18.3438"
        stroke="current"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9062 18.3438H7.1875C7.0466 18.3438 6.91148 18.2878 6.81185 18.1882C6.71222 18.0885 6.65625 17.9534 6.65625 17.8125V7.1875C6.65625 7.0466 6.71222 6.91148 6.81185 6.81185C6.91148 6.71222 7.0466 6.65625 7.1875 6.65625H10.9062"
        stroke="current"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
