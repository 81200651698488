import { SVGProps } from 'react';

export default function FileUploadedIcon({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="23"
      height="27"
      viewBox="0 0 23 27"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.6994 7.5678L15.3812 0.298574C15.1821 0.105709 14.9145 -0.0015409 14.6364 1.67323e-05H2.09091C1.53636 1.67323e-05 1.00453 0.218835 0.612413 0.608333C0.220291 0.997831 0 1.5261 0 2.07694V24.9231C0 25.4739 0.220291 26.0022 0.612413 26.3917C1.00453 26.7812 1.53636 27 2.09091 27H20.9091C21.4636 27 21.9954 26.7812 22.3876 26.3917C22.7797 26.0022 23 25.4739 23 24.9231V8.3077C23.0016 8.03142 22.8936 7.76563 22.6994 7.5678ZM14.6364 8.3077V2.5053L20.4778 8.3077H14.6364Z"
        fill="current"
      />
    </svg>
  );
}
