interface CalendarIconProps {
  fill?: string;
}

export default function CalendarIcon({ fill = '#1D1D1D' }: CalendarIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6H16V5C16 4.46875 15.5312 4 15 4C14.4375 4 14 4.46875 14 5V6H10V5C10 4.46875 9.53125 4 9 4C8.4375 4 8 4.46875 8 5V6H6.5C5.65625 6 5 6.6875 5 7.5V9H19V7.5C19 6.6875 18.3125 6 17.5 6ZM5 18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V10H5V18.5Z"
        fill={fill}
      />
    </svg>
  );
}
