import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Section, Title } from 'components';
import { isAfter, subDays } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useLawyerAvailabilitySlots } from 'api/customer/queries.index';

import { useCustomerAccount, useGuardedSearchParams } from 'hooks';

import { LawyerNotifyAvailabilityForm } from 'features/customer/account/consultations/booking';
import ConsultationBookingConfirm from 'features/customer/account/consultations/booking/steps/confirm/ConsultationBookingConfirm';
import { ConsultationBookingForm } from 'features/customer/account/consultations/booking/steps/form';
import { ConsultationBookingPayment } from 'features/customer/account/consultations/booking/steps/payment';
import { ConsultationRescheduleBlockedBooking } from 'features/customer/account/consultations/reschedule/blocked';
import { PageLayout } from 'features/customer/account/content';
import { LoadingSpinner } from 'features/shared/loading';

import { ConsultationFile } from 'types/consultation';
import { SetState } from 'types/core';
import { BookingOption, ConsultationSlot } from 'types/lawyer';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUB_KEY as string
);

export enum BookingStep {
  Scheduling = 'scheduling',
  SchedulingConfirm = 'schedulong_confirm',
  Payment = 'payment',
  Success = 'success'
}

export default function ConsultationBookingPage() {
  const { t } = useTranslation('customer');
  const lawyerId = useGuardedSearchParams({ param: 'lawyerId' });
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointmentId');
  const [bookingStep, setBookingStep] = useState<BookingStep>(
    BookingStep.Scheduling
  );
  const [slot, setSlot] = useState<ConsultationSlot>();
  const [bookingOption, setBookingOption] = useState<BookingOption>();
  const [document, setDocument] = useState<ConsultationFile | undefined>();
  const { data, isSuccess } = useLawyerAvailabilitySlots({
    lawyerId: Number(lawyerId),
    enabled: !!lawyerId
  });
  const consultationId = searchParams.get('consultationId');
  const { consultations } = useCustomerAccount();

  const currentConsultation = consultations.find(
    (item) => item.id.toString() === consultationId
  );
  const currentAppointment = currentConsultation?.appointments?.find(
    (item) => !item.isProcessed
  );

  const appointmentDate = new Date(currentAppointment?.appointmentDate || '');
  const isRescheduleBlocked = isAfter(new Date(), subDays(appointmentDate, 1));
  if (isRescheduleBlocked && appointmentId)
    return <ConsultationRescheduleBlockedBooking />;

  if (!isSuccess) {
    return (
      <div className="h-screen grid place-items-center">
        <LoadingSpinner large purple />
      </div>
    );
  }

  const isAttorneyAvailable = data.length > 0;

  return (
    <PageLayout>
      <Section>
        <Title
          gutterBottom
          variant="h1"
          text={t('account.consultations.booking.title')}
        />
        {bookingStep === BookingStep.Scheduling && !isAttorneyAvailable && (
          <LawyerNotifyAvailabilityForm lawyerId={Number(lawyerId)} />
        )}
        {bookingStep === BookingStep.Scheduling && isAttorneyAvailable && (
          <ConsultationBookingForm
            lawyerAvailabiltySlots={data}
            slot={slot as ConsultationSlot}
            setSlot={setSlot as SetState<ConsultationSlot>}
            document={document}
            setDocument={setDocument}
            setBookingStep={setBookingStep}
            setBookingOption={setBookingOption}
          />
        )}
        {bookingStep === BookingStep.SchedulingConfirm &&
          isAttorneyAvailable && (
            <ConsultationBookingConfirm
              setBookingOption={setBookingOption}
              setBookingStep={setBookingStep}
              bookingOption={bookingOption as BookingOption}
            />
          )}
        {bookingStep === BookingStep.Payment && isAttorneyAvailable && (
          <Elements stripe={stripePromise}>
            <ConsultationBookingPayment
              document={document}
              stripePromise={stripePromise}
              slot={slot as ConsultationSlot}
              bookingOption={bookingOption as BookingOption}
              setBookingStep={setBookingStep}
            />
          </Elements>
        )}
      </Section>
    </PageLayout>
  );
}
