import { useQuery } from '@tanstack/react-query';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { getMemberQuestionAdditionalRequest } from 'services/question';

import AdditionalRequestAnswerCard from 'features/answer/AdditionalRequestAnswerCard';

import AdditionalRequestCard from './components/AdditionalRequestCard';

interface MemberQuestionAdditionalRequestProps {
  additionalRequestId: string;
}

const MemberQuestionAdditionalRequest = ({
  additionalRequestId
}: MemberQuestionAdditionalRequestProps) => {
  const {
    data: additionalRequest,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-question-additional-request', additionalRequestId],
    queryFn: () => getMemberQuestionAdditionalRequest(additionalRequestId)
  });

  if (isLoading) return <QuestionSectionSkeleton />;
  if (!isSuccess) return <div />;

  return (
    <>
      <section aria-labelledby="answer">
        <AdditionalRequestCard additionalRequest={additionalRequest} />
      </section>

      {additionalRequest.answered_at && (
        <section aria-labelledby="answer">
          <AdditionalRequestAnswerCard additionalRequest={additionalRequest} />
        </section>
      )}
    </>
  );
};

export default MemberQuestionAdditionalRequest;
