import { TabPanel, TabPanels } from '@headlessui/react';
import { Section, Tabs } from 'components';
import { Fade } from 'components/transition';
import Alphabet from 'containers/alphabet/Alphabet';
import { ArticlesList } from 'containers/articles';
import DocumentsList from 'containers/document/DocumentsList';
import LastEkieYoutubeVideo from 'containers/videos/LastEkieYoutubeVideos';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageLayout } from 'features/customer/account/content';

const TABS_MAP = [
  {
    name: 'account.resources.tabs.articles',
    id: 'articles',
    path: '/account/resources'
  },
  {
    name: 'account.resources.tabs.alphabet',
    id: 'alphabet',
    path: '/account/resources/alphabet'
  },
  {
    name: 'account.resources.tabs.videos',
    id: 'videos',
    path: '/account/resources/videos'
  },
  {
    name: 'account.resources.tabs.documents',
    id: 'documents',
    path: '/account/resources/documents'
  }
];

const ResourcesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');
  const { pathname } = useLocation();

  const defaultIndex =
    TABS_MAP.findIndex((item) => item.path === pathname) || 0;

  return (
    <PageLayout>
      <Fade>
        <Section color="white">
          <Tabs
            defaultIndex={defaultIndex}
            items={TABS_MAP.map((item) => ({
              ...item,
              onClick: () => navigate(item.path),
              name: t(item.name as any)
            }))}
          >
            <TabPanels>
              <TabPanel>
                <Fade>
                  <ArticlesList />
                </Fade>
              </TabPanel>
              <TabPanel>
                <Fade>
                  <Alphabet />
                </Fade>
              </TabPanel>
              <TabPanel>
                <Fade>
                  <LastEkieYoutubeVideo />
                </Fade>
              </TabPanel>
              <TabPanel>
                <Fade>
                  <DocumentsList />
                </Fade>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Section>
      </Fade>
    </PageLayout>
  );
};

export default ResourcesPage;
