import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Customer } from 'types/customer';
import { Subscription } from 'types/subscription';

import {
  deleteSubscription,
  getStripeSessionToken,
  getSubscription
} from './api';

// QUERIES
export function useSubscriptionQuery({
  customerId,
  enabled = true
}: {
  customerId: Customer['id'];
  enabled?: boolean;
}) {
  return useQuery(['subscription'], () => getSubscription(customerId), {
    enabled
  });
}

export function useStripeSessionTokenQuery({
  customerId
}: {
  customerId: Customer['id'];
}) {
  return useQuery(['stripe-session-token'], () =>
    getStripeSessionToken(customerId)
  );
}

// MUTATIONS
export function useCancelSubscriptionOptimisticMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ subscriptionId }: { subscriptionId: Subscription['id'] }) =>
      deleteSubscription(subscriptionId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['subscription']);

        const previousSubscription = queryClient.getQueryData<Subscription>([
          'subscription'
        ]);

        if (previousSubscription) {
          queryClient.setQueryData<Subscription>(['subscription'], {
            ...previousSubscription,
            active: false
          });
        }

        return { previousSubscription };
      },
      onError: (error, variables, context) => {
        if (context?.previousSubscription) {
          queryClient.setQueryData<Subscription>(
            ['subscription'],
            context.previousSubscription
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('subscription');
      }
    }
  );
}
