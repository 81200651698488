import { Title } from 'components';
import { useState } from 'react';

import { useLawyerAccount } from 'hooks';

import { usePagination } from 'pages/lawyer/context';

import { ConsultationsNav } from 'features/lawyer/account/consultations/nav';
import { PastConsultations } from 'features/lawyer/account/consultations/past';
import { ScheduledConsultations } from 'features/lawyer/account/consultations/scheduled';
import { ConsultationsToComplete } from 'features/lawyer/account/consultations/to-complete';

import { ConsultationsFilter } from 'types/consultation';

export default function ConsultationsPage() {
  const { consultations } = useLawyerAccount();
  const { onRefetchScheduled, onRefetchToComplete, onRefetchPast } =
    usePagination();

  const [filter, setFilter] = useState<ConsultationsFilter>(
    ConsultationsFilter.SCHEDULED
  );

  return (
    <div className="min-h-screen">
      <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
        <div className="px-4 sm:px-12 lg:px-24">
          <div className="mb-4 md:mb-6">
            <Title variant="h1">Consultations</Title>
          </div>
          <ConsultationsNav
            filter={filter}
            setFilter={setFilter}
            scheduledLength={consultations.scheduled.pagination.totalItems}
            toCompleteLength={consultations.toComplete.pagination.totalItems}
            pastLength={consultations.past.pagination.totalItems}
          />
        </div>
        <div className="mb-6 border-b border-gray-600" />
        <div className="px-4 sm:px-12 lg:px-24">
          {filter === ConsultationsFilter.SCHEDULED && (
            <ScheduledConsultations
              onRefetch={onRefetchScheduled}
              consultations={consultations.scheduled.consultations}
              pagination={consultations.scheduled.pagination}
            />
          )}
          {filter === ConsultationsFilter.TOCOMPLETE && (
            <ConsultationsToComplete
              onRefetch={onRefetchToComplete}
              consultations={consultations.toComplete.consultations}
              pagination={consultations.toComplete.pagination}
            />
          )}
          {filter === ConsultationsFilter.PAST && (
            <PastConsultations
              onRefetch={onRefetchPast}
              consultations={consultations.past.consultations}
              pagination={consultations.past.pagination}
            />
          )}
        </div>
      </div>
    </div>
  );
}
