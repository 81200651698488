import { TabPanel, TabPanels } from '@headlessui/react';
import { Divider, Section, Tabs, Title } from 'components';
import { Card, CardContent } from 'components/card';
import {
  NumericValue,
  RatingStars,
  RatingValue,
  Statistic
} from 'components/statistic';
import { camelCase, take } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { Link } from 'react-router-dom';
import {
  getLawyerCurrentMonthPerformances,
  getLawyerOverallPerformances
} from 'services/lawyers/performances';

import {
  getAnswerVotes,
  getAppointmentVotes
} from 'api/customer/answer-votes/api';

import { useAuth } from 'context';

import { PageLayout } from 'features/customer/account/content';
import { RefreshIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

const TABS_MAP = [
  { name: 'account.dashboard.currentMonth.title', id: 'currentMonth' },
  { name: 'account.dashboard.overAll.title', id: 'overAll' }
];

const SkeletonPerformances = Array(5)
  .fill(1)
  .map((_) => (
    <Card className="animate-pulse flex flex-row">
      <div className="h-3 w-2/3 mb-3 bg-gray-300 rounded" />
      <div className="h-7 w-1/4 bg-gray-300 rounded" />
    </Card>
  ));

export default function DashboardPage() {
  const { t } = useTranslation('lawyer');
  const { lawyerId } = useAuth();

  const [
    {
      data: currentMonthPerformancesData,
      isLoading: isCurrentMonthPerformancesLoading,
      refetch: refetchCurrentMonth
    },
    {
      data: overAllPerformancesData,
      isLoading: isOverAllPerformancesLoading,
      refetch: refetchOverAll
    },
    { data: answerVotesData, isLoading: isAnswerVotesLoading },
    { data: appointmentVotesData, isLoading: isAppointmentVotesLoading }
  ] = useQueries([
    {
      queryKey: ['currentMonthPerformances'],
      queryFn: () => getLawyerCurrentMonthPerformances(lawyerId as number)
    },
    {
      queryKey: ['overAllPerformances'],
      queryFn: () => getLawyerOverallPerformances(lawyerId as number)
    },
    {
      queryKey: ['answerVotes'],
      queryFn: () => getAnswerVotes()
    },
    {
      queryKey: ['appointmentVotes'],
      queryFn: () => getAppointmentVotes()
    }
  ]);

  const onRefetchClick = () => {
    refetchCurrentMonth();
    refetchOverAll();
  };

  const lastAnswerVotes = take(answerVotesData, 6);
  const lastAppointmentVotes = take(appointmentVotesData, 6);

  return (
    <PageLayout>
      <div className="mt-6 m-4 flex justify-between">
        <Title component="h1" variant="h2">
          {t('account.dashboard.title')}
        </Title>
        <div className="flex mt-2 sm:mt-0">
          <span>
            {t('account.dashboard.date', {
              date: formatDateToIntl(new Date(), 'long'),
              time: getDateAsHhMm(new Date())
            })}
          </span>
          <button
            type="button"
            onClick={onRefetchClick}
            className="w-5 h-5 ml-2 text-purple-800"
          >
            <RefreshIcon />
          </button>
        </div>
      </div>
      <Section className="!bg-white !px-5 !pt-2 !rounded-lg" gutterBottom>
        <Tabs
          title={t('account.dashboard.performances.title')}
          items={TABS_MAP.map((item) => ({
            ...item,
            name: t(item.name as any)
          }))}
        >
          <TabPanels className="mt-6">
            <TabPanel className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-4">
              {isCurrentMonthPerformancesLoading
                ? SkeletonPerformances
                : currentMonthPerformancesData.performances.map(
                    (performance: any) => (
                      <Statistic
                        key={performance.name}
                        label={t(
                          `account.dashboard.performances.${camelCase(
                            performance.name
                          )}.title` as any
                        )}
                      >
                        {performance.type === 'numeric' && (
                          <NumericValue value={performance.value.result} />
                        )}
                        {performance.type === 'percent' && (
                          <NumericValue
                            value={performance.value.result}
                            prefix="%"
                          />
                        )}
                        {performance.type === 'rating' && (
                          <RatingValue
                            value={performance.value.result}
                            max={performance.value.maxRatingValue}
                          />
                        )}
                      </Statistic>
                    )
                  )}
            </TabPanel>
            <TabPanel className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-4">
              {isOverAllPerformancesLoading
                ? SkeletonPerformances
                : overAllPerformancesData.performances.map(
                    (performance: any) => (
                      <Statistic
                        key={performance.name}
                        label={t(
                          `account.dashboard.performances.${camelCase(
                            performance.name
                          )}.title` as any
                        )}
                      >
                        {performance.type === 'numeric' && (
                          <NumericValue value={performance.value.result} />
                        )}

                        {performance.type === 'percent' && (
                          <NumericValue
                            value={performance.value.result}
                            prefix="%"
                          />
                        )}
                        {performance.type === 'rating' && (
                          <RatingValue
                            value={performance.value.result}
                            max={performance.value.maxRatingValue}
                          />
                        )}
                      </Statistic>
                    )
                  )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>
      {!isAnswerVotesLoading && !isAppointmentVotesLoading && answerVotesData && (
        <div className="grid grid-cols-2 gap-4">
          <Card>
            <Title variant="h3">
              {t('account.dashboard.lastAnswerRates.title')}
            </Title>
            {lastAnswerVotes.map((answerVote, index) => (
              <>
                {answerVote.comment && (
                  <CardContent>{answerVote.comment}</CardContent>
                )}
                <CardContent className="flex !flex-row justify-between">
                  <div className="flex gap-4 items-center">
                    <span className="text-gray-700">
                      {`${answerVote.customer.firstName} ${answerVote.customer.lastName}`}
                    </span>
                    <RatingStars value={answerVote.note} />
                  </div>
                  <Link
                    className="cursor-pointer text-purple-900 underline font-medium"
                    to={`/account/questions/questions-answered/${answerVote.answer.id}`}
                  >
                    {t('account.dashboard.lastRates.question.link')}
                  </Link>
                </CardContent>
                {lastAnswerVotes.length - 1 !== index && (
                  <Divider className="!py-0" />
                )}
              </>
            ))}
          </Card>
          <Card>
            <Title variant="h3">
              {t('account.dashboard.lastAppointmentRates.title')}
            </Title>
            {lastAppointmentVotes.map((answerVote, index) => (
              <>
                {answerVote.reason && (
                  <CardContent>{answerVote.reason}</CardContent>
                )}
                <CardContent className="flex !flex-row justify-between">
                  <div className="flex gap-4 items-center">
                    <span className="text-gray-700">
                      {`${answerVote.customer.firstName} ${answerVote.customer.lastName}`}
                    </span>
                    <RatingStars value={answerVote.note} />
                  </div>
                </CardContent>
                {lastAppointmentVotes.length - 1 !== index && (
                  <Divider className="!py-0" />
                )}
              </>
            ))}
          </Card>
        </div>
      )}
    </PageLayout>
  );
}
