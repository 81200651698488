import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLocale } from 'hooks';

import { QuestionTag } from 'features/lawyer/account/questions/shared';
import { Button } from 'features/shared/buttons';
import {
  CalendarIcon,
  CameraIcon,
  ClockIcon,
  PhoneIcon,
  QuestionIcon
} from 'features/shared/icons';

import {
  Appointment,
  Consultation,
  ConsultationStatus,
  ConsultationsFilter
} from 'types/consultation';
import { BookingConsultationOption } from 'types/lawyer';

import ConsultationDocumentCardSection from './ConsultationCardDocumentSection';

interface CommonConsultationCardProps {
  handleCompleteConsultation?: (id: Consultation['id']) => void;
  handleCancelConsultation?: (id: Consultation['id']) => void;
  handleCancelAppointmentConsultation?: (id: Appointment['id']) => void;
  handleCloseConsultation?: () => void;
  consultation: Consultation;
  status: ConsultationsFilter;
  isCompleteConsultationLoading?: boolean;
  isShowMode?: boolean;
}

export default function CommonConsultationCard({
  handleCompleteConsultation,
  handleCancelConsultation,
  handleCancelAppointmentConsultation,
  handleCloseConsultation,
  consultation,
  status,
  isCompleteConsultationLoading,
  isShowMode = false
}: CommonConsultationCardProps) {
  const { locale } = useLocale();
  const { t } = useTranslation('lawyer');

  const isPassed = ConsultationsFilter.PAST === status;
  const isScheduled = ConsultationsFilter.SCHEDULED === status;
  const isToComplete = ConsultationsFilter.TOCOMPLETE === status;

  const currentAppointment = useMemo(
    () => consultation?.appointments?.find((item) => !item.isProcessed),
    [consultation]
  );

  const triggerhandleCompleteConsultation = useCallback(() => {
    if (handleCompleteConsultation) handleCompleteConsultation(consultation.id);
  }, [handleCompleteConsultation, consultation]);

  const triggerhandleCancelConsultation = useCallback(() => {
    if (handleCancelConsultation) handleCancelConsultation(consultation.id);
  }, [handleCancelConsultation, consultation]);

  const triggerhandleCancelAppoitmentConsultation = useCallback(() => {
    if (handleCancelAppointmentConsultation)
      handleCancelAppointmentConsultation(currentAppointment?.id ?? 0);
  }, [handleCancelAppointmentConsultation, currentAppointment]);

  const triggerhandleCloseConsultation = useCallback(() => {
    if (handleCloseConsultation) handleCloseConsultation();
  }, [handleCloseConsultation]);

  const formattedAppointmentDate = useMemo(
    (): string =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'full'
      }).format(
        new Date(
          consultation?.appointments?.find(
            (appointment) => !appointment.isProcessed
          )?.appointmentDate || consultation.latestAppointment!.appointmentDate
        )
      ),
    [consultation, locale]
  );

  const formattedAppointmentTime = useMemo(
    (): string =>
      new Intl.DateTimeFormat(locale, {
        timeStyle: 'short'
      }).format(
        new Date(
          consultation?.appointments?.find(
            (appointment) => !appointment.isProcessed
          )?.appointmentDate || consultation.latestAppointment!.appointmentDate
        )
      ),
    [consultation, locale]
  );

  const formattedStatus = useMemo(() => {
    if (consultation.status === ConsultationStatus.COMPLETED) {
      return t('account.consultations.closed');
    }
    if (consultation.status === ConsultationStatus.CANCELLED) {
      return t('account.consultations.cancelled');
    }
    return t('account.consultations.closed');
  }, [consultation.status, t]);

  const formattedLatestUpdatedDate = useMemo(
    (): string =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'full'
      }).format(new Date(consultation.lastUpdatedDate)),
    [consultation.lastUpdatedDate, locale]
  );
  const [nextAppointment] = consultation.appointments.filter(
    (appointment) => !appointment.isProcessed
  );
  const isVideoConsultation =
    nextAppointment?.appointmentType === BookingConsultationOption.BookingVideo;

  return (
    <div
      className="md:max-w-lg bg-white rounded-lg"
      id={consultation?.id?.toString()}
    >
      <div
        id="top-section"
        className={clsx(
          'px-6 py-3 rounded-t-lg',
          isScheduled || isShowMode ? 'bg-purple-400' : 'bg-gray-500'
        )}
      >
        <div className="flex gap-8">
          <div className="flex gap-2 items-center">
            <CalendarIcon fill={isScheduled ? '#8C79E6' : '#545454'} />
            <span className="capitalize">{formattedAppointmentDate}</span>
          </div>
          <div className="flex gap-2 items-center">
            <ClockIcon fill={isScheduled ? '#8C79E6' : '#545454'} />
            <span className="capitalize">{formattedAppointmentTime}</span>
          </div>
        </div>
      </div>
      <div id="content-section" className="p-6">
        {consultation.linkedAnswerId ? (
          <>
            <div id="question-section" className="flex items-start">
              <div className="flex items-center gap-2">
                <QuestionIcon className="w-6 h-6" />
              </div>
              <div className="ml-3 w-full flex flex-col gap-4">
                <div className="md:flex md:flex-col md:items-start">
                  <Link
                    className="block md:hidden"
                    to={`/account/questions/questions-answered/${consultation.linkedAnswerId}`}
                  >
                    <span className="underline md:no-underline font-bold text-sm">
                      {t('account.consultations.card.associatedQuestion')}
                    </span>
                  </Link>
                  <span className="hidden md:block font-bold text-sm">
                    {t('account.consultations.card.associatedQuestion')}
                  </span>
                </div>
                <Link
                  className="hidden md:block hover:shadow-base"
                  to={`/account/questions/questions-answered/${consultation.linkedAnswerId}`}
                >
                  <div className="rounded-lg border-2 p-4 border-gray-400 break-words">
                    {consultation.caseDetail.consumerTag && (
                      <div className="flex justify-between items-center pb-2">
                        <QuestionTag
                          name={consultation.caseDetail.consumerTag.name}
                        />
                        <span />
                      </div>
                    )}
                    <div>
                      <span>{consultation.linkedQuestion.title}</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="my-4 border-b border-[#E3E3E3]" />
          </>
        ) : null}

        {consultation.document && (
          <>
            <ConsultationDocumentCardSection document={consultation.document} />
            <div className="my-4 border-b border-[#E3E3E3]" />
          </>
        )}

        <div id="contact-section" className="flex items-start">
          <div className="flex items-center gap-2">
            <PhoneIcon />
          </div>
          <div className="ml-3 w-full">
            <div className="flex flex-col items-start pb-2">
              <span className="font-bold text-sm">Contact</span>
            </div>
            <span className="text-sm text-gray-800">
              {consultation.customer.firstName} {consultation.customer.lastName}{' '}
              - {consultation.customer.phoneCellphone}
            </span>
          </div>
        </div>
        <div className="my-4 border-b border-[#E3E3E3]" />
        <div className={clsx('flex justify-end', isToComplete ? 'mb-2' : '')}>
          {/* BOTTTOM CUSTOM STYLE */}
          {isPassed && (
            <span className="text-sm text-gray-700">
              {formattedStatus} {t('general.the')} {formattedLatestUpdatedDate}
            </span>
          )}

          {isScheduled && !isShowMode && (
            <div className="flex items-center justify-end gap-x-3">
              <div className="group flex relative">
                <Button
                  size="medium"
                  variant="secondary"
                  onClick={triggerhandleCancelAppoitmentConsultation}
                  label={t('account.consultations.cancellation.confirm')}
                />
              </div>
            </div>
          )}

          {isVideoConsultation && isScheduled && (
            <a
              href={nextAppointment?.videoRoom?.hostUrl}
              className="ml-4"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                label={
                  <div className="flex items-center">
                    {t('account.consultations.join')}
                    <div className="h-4 ml-2">
                      <CameraIcon color="white" />
                    </div>
                  </div>
                }
                variant="custom"
                size="medium"
                customClassNames="bg-black hover:bg-gray-800 h-full text-white"
              />
            </a>
          )}

          {isShowMode && (
            <div className="flex items-center justify-end gap-x-3 ml-4">
              <div className="group flex relative">
                <Button
                  size="medium"
                  variant="secondary"
                  onClick={triggerhandleCloseConsultation}
                  label="Fermer"
                />
              </div>
            </div>
          )}

          {isToComplete && !isShowMode && (
            <div className="flex gap-4">
              <Button
                size="medium"
                variant="secondary"
                onClick={triggerhandleCancelConsultation}
                label={t('account.consultations.cancellation.cancel')}
              />
              <Button
                size="medium"
                variant="tertiary"
                isLoading={isCompleteConsultationLoading}
                onClick={triggerhandleCompleteConsultation}
                label={t('account.consultations.cancellation.close')}
              />
            </div>
          )}
        </div>

        {isToComplete && !isShowMode && (
          <div className="text-right">
            <span className="text-sm text-gray-700">
              {t('account.consultations.toClose.alert')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
