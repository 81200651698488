/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import { Pagination } from 'components/pagination';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Collaborator,
  getCollaboratorsByPartnerId
} from 'services/admin/collaborators';

import { useCustomerAccount } from 'hooks';

import { TextInputGroup } from 'features/shared/forms';

import { formatDateToIntl } from 'utils/date';

import PartnerCollaboratorAdd from './PartnerCollaboratorAdd';
import PartnerCollaboratorBulkAdd from './PartnerCollaboratorBulkAdd';
import PartnerCollaboratorBulkDelete from './PartnerCollaboratorBulkDelete';
import PartnerCollaboratorBulkSendInvitation from './PartnerCollaboratorBulkSendInvitation';
import PartnerCollaboratorDelete from './PartnerCollaboratorDelete';
import PartnerCollaboratorSendInvitation from './PartnerCollaboratorSendInvitation';
import PartnerCollaboratorUpdate from './PartnerCollaboratorUpdate';

const RESPONSIVE_HIDDEN_COLUMNS = ['addedAt', 'principalEmail', 'archivedAt'];

interface CollaboratorsListProps {
  archived?: boolean;
}

const CollaboratorsList = ({ archived = false }: CollaboratorsListProps) => {
  const { t } = useTranslation('customer');
  const [rowSelection, setRowSelection] = useState({});
  const {
    register,
    formState: { errors },
    watch
  } = useForm<{
    search: string | null;
  }>({
    defaultValues: { search: null }
  });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10
  });
  const { subscription } = useCustomerAccount();
  const { data } = useQuery(
    [
      'collaboratorsByPartnerId',
      watch('search'),
      archived,
      pagination.pageIndex
    ],
    () =>
      getCollaboratorsByPartnerId(
        subscription.product.offeredByPartner.id,
        watch('search'),
        archived,
        pagination.pageIndex
      )
  );

  const columns = useMemo<ColumnDef<Collaborator>[]>(
    () => [
      ...(!archived
        ? [
            {
              id: 'rowSelect',
              header: ({ table }: any) => (
                <input
                  type="checkbox"
                  id="select"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                  className="rounded text-black align-middle focus:ring-0"
                />
              ),
              cell: ({ row }: any) => (
                <input
                  type="checkbox"
                  id="rowSelect"
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onChange={row.getToggleSelectedHandler()}
                  className="rounded text-black align-middle focus:ring-0"
                />
              )
            }
          ]
        : []),
      {
        accessorKey: 'fullName',
        cell: ({ row }) => (
          <div>
            <span>{`${row.original.firstName} ${row.original.lastName}`}</span>
            <span className="text-gray-550 block md:hidden">{`${row.original.principalEmail}`}</span>
          </div>
        ),
        header: () => t('admin.collaborators.table.header.fullName')
      },
      {
        accessorKey: 'principalEmail',
        cell: ({ getValue }) => getValue(),
        header: () => t('admin.collaborators.table.header.email')
      },
      {
        accessorKey: 'type',
        cell: ({ row }) =>
          row.original.type === 'cse' ? (
            <div className="flex w-fit p-1.5 bg-blue-100 uppercase rounded-md text-xs text-blue-900">
              {t('admin.collaborators.table.row.role.cse')}
            </div>
          ) : (
            <div className="flex w-fit p-1.5 bg-gray-300 uppercase rounded-md text-xs">
              {t('admin.collaborators.table.row.role.employee')}
            </div>
          ),
        header: () => t('admin.collaborators.table.header.role')
      },
      {
        accessorKey: 'addedAt',
        cell: ({ row }) =>
          formatDateToIntl(new Date(row.original.createdAt), 'long'),
        header: () => t('admin.collaborators.table.header.addedAt')
      },
      ...(!archived
        ? [
            {
              accessorKey: 'actions',
              header: '',
              cell: ({ row }: any) => (
                <div className="flex gap-2">
                  <PartnerCollaboratorUpdate collaborator={row.original} />
                  <PartnerCollaboratorSendInvitation
                    collaboratorEmail={row.original.principalEmail}
                    firstName={row.original.firstName}
                    lastName={row.original.lastName}
                  />
                  <PartnerCollaboratorDelete
                    collaboratorId={row.original.id}
                    firstName={row.original.firstName}
                    lastName={row.original.lastName}
                  />
                </div>
              )
            }
          ]
        : [
            {
              accessorKey: 'archivedAt',
              cell: ({ row }: any) =>
                formatDateToIntl(new Date(row.original.archivedOn), 'long'),
              header: () => t('admin.collaborators.table.header.archivedAt')
            }
          ])
    ],
    [t, archived]
  );

  const table = useReactTable({
    data: data?.collaborators ?? [],
    columns,
    pageCount: data?.pagination.totalPages ?? -1,
    state: { pagination, rowSelection },
    enableRowSelection: !archived,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    enableMultiRowSelection: !archived,
    getRowId: (row) => `${row.id}|${row.principalEmail}`
  });

  const hasSelectedCollaborators = !isEmpty(rowSelection);

  return (
    <>
      <div className="flex self-end my-4 gap-4 flex-wrap">
        <div className="w-full xl:flex-1">
          <TextInputGroup
            type="string"
            label=""
            placeholder="Rechercher des utilisateurs..."
            name="search"
            register={register}
            error={errors.search}
          />
        </div>
        {!archived && (
          <div className="mt-1 w-full sm:flex-1 xl:flex-initial xl:w-[unset]">
            <PartnerCollaboratorAdd />
          </div>
        )}
        {!archived && (
          <div className="mt-1 w-full sm:flex-1 xl:flex-initial xl:w-[unset]">
            <PartnerCollaboratorBulkAdd />
          </div>
        )}
      </div>
      <table className="w-full table-auto">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={classNames(
                    'text-left text-gray-800 font-normal py-3 px-4',
                    {
                      'hidden md:table-cell':
                        RESPONSIVE_HIDDEN_COLUMNS.includes(header.id)
                    }
                  )}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className="font-medium rounded-md hover:bg-gray-200 border-y-2 border-gray-300"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={classNames('py-3 px-4', {
                    'hidden md:table-cell': RESPONSIVE_HIDDEN_COLUMNS.includes(
                      cell.column.id
                    )
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={classNames(
          'flex w-full justify-center mt-6',
          hasSelectedCollaborators && 'mb-20'
        )}
      >
        <Pagination
          onRefetch={(page) => setPagination({ pageIndex: page, pageSize: 15 })}
          pageCount={data?.pagination.totalPages || 1}
        />
      </div>
      {hasSelectedCollaborators && (
        <div className="fixed bottom-10 rounded-2xl bg-gray-100 w-[calc(100%_-_320px)] border-[3px] border-gray-400 px-3 py-4 md:px-6 md:py-6">
          <div className="flex justify-between font-bold">
            <span>{t('admin.collaborators.table.bulkActions.title')}</span>
            <div className="flex gap-6">
              <span>
                {t(
                  'admin.collaborators.table.bulkActions.selectedCollaborators',
                  { count: Object.keys(rowSelection).length }
                )}
              </span>
              <PartnerCollaboratorBulkSendInvitation
                collaboratorsEmails={Object.keys(rowSelection).map(
                  (rowIdentifier) => {
                    const [, email] = rowIdentifier.split('|');
                    return email;
                  }
                )}
                onSuccess={table.resetRowSelection}
              />
              <PartnerCollaboratorBulkDelete
                collaboratorIds={Object.keys(rowSelection).map(
                  (rowIdentifier) => {
                    const [id] = rowIdentifier.split('|');
                    return id;
                  }
                )}
                onSuccess={table.resetRowSelection}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollaboratorsList;
