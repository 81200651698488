import { Answer, ApiAnswer } from 'types/answer';

export const formatAnswerFromApi = (apiAnswer: ApiAnswer): Answer => {
  const {
    id,
    answerVotes,
    status,
    lawyer,
    question,
    content,
    createdDate,
    formalNotice,
    formalNoticeProposal,
    publishedDate
  } = apiAnswer;

  return {
    id,
    status,
    answerVotes: answerVotes ?? [],
    publishedDate,
    lawyer,
    content,
    createdDate,
    question,
    formalNotice,
    formalNoticeProposal
  };
};
