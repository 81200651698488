import { useOutletContext } from 'react-router-dom';

import { Consultation } from 'types/consultation';
import { CustomerUsageMeter } from 'types/customer';
import { Subscription } from 'types/subscription';

interface OutletContext {
  subscription: Subscription;
  consultations: Consultation[];
  futureConsultations: Consultation[];
  customerUsageMeter: CustomerUsageMeter;
}

export default function useCustomerAccount() {
  return useOutletContext<OutletContext>();
}
