import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUnguardedSearchParams } from 'hooks';

import { BookingStep } from 'pages/customer/consultation/ConsultationBookingPage';

import {
  ConsultationSlotInput,
  DocumentDropzone
} from 'features/customer/account/consultations/booking';
import { Button } from 'features/shared/buttons';

import { ConsultationFile } from 'types/consultation';
import { SetState } from 'types/core';
import {
  BookingOption,
  ConsultationSlot,
  LawyerAvailabilityDaySlots
} from 'types/lawyer';

import VisioLawyerAvailabilitySlots from '../../slots/VisioLawyerAvailabilitySlots';
import { FilterDropdown } from '../../slots/dropdown';

export default function ConsultationBookingForm({
  lawyerAvailabiltySlots,
  slot,
  document,
  setSlot,
  setDocument,
  setBookingStep,
  setBookingOption
}: {
  lawyerAvailabiltySlots: LawyerAvailabilityDaySlots[];
  slot: ConsultationSlot;
  document?: ConsultationFile;
  setSlot: SetState<ConsultationSlot>;
  setDocument: SetState<ConsultationFile | undefined>;
  setBookingStep: SetState<BookingStep>;
  setBookingOption: SetState<BookingOption | undefined>;
}) {
  const [currentSlot, setCurrentSlot] = useState<ConsultationSlot>();
  const appointmentId = useUnguardedSearchParams({ param: 'appointmentId' });

  const [filteredSlots, setFilteredSlots] = useState<
    LawyerAvailabilityDaySlots[]
  >(lawyerAvailabiltySlots);

  const { t } = useTranslation('customer');
  const { handleSubmit, setValue } = useForm<ConsultationSlotInput>();

  useEffect(() => {
    if (slot) onSetSlot(slot);
    // eslint-disable-next-line
  }, []);

  const onSetSlot = (value: ConsultationSlot) => {
    setValue('slot', JSON.stringify(value));
    setCurrentSlot(value);
  };

  const onSubmit = (formData: ConsultationSlotInput) => {
    const value = JSON.parse(formData?.slot ?? '');
    setSlot(value);
    if (value.option === BookingOption.BookingVideo)
      setBookingStep(BookingStep.SchedulingConfirm);
    else {
      setBookingStep(BookingStep.Payment);
      setBookingOption(BookingOption.BookingPhone);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-6 max-w-[720px]">
        <div className="w-full flex flex-col gap-y-4">
          <div className="p-8 w-full h-auto min-h-[428px] bg-white rounded shadow-xl">
            <div className="flex flex-col w-full">
              <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-5">
                {t('account.consultations.booking.scheduling.title')}
              </h2>
              <div className="mb-5">
                <FilterDropdown
                  lawyerAvailabiltySlots={lawyerAvailabiltySlots}
                  setFilteredSlots={setFilteredSlots}
                />
              </div>
              <VisioLawyerAvailabilitySlots
                onSetSlot={onSetSlot}
                currentSlot={currentSlot as ConsultationSlot}
                daySlots={filteredSlots}
              />
            </div>
          </div>
          {!appointmentId && (
            <div className="p-8 w-full h-auto bg-white rounded shadow-xl">
              <div className="flex flex-col w-full">
                <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-5">
                  {t('account.consultations.booking.scheduling.document.title')}
                </h2>
                <div className="mb-4">
                  <DocumentDropzone
                    label="label.uploadFile"
                    typeLabel="file.upload.3"
                    required
                    document={document}
                    setDocument={setDocument}
                    maxFile
                  />
                </div>
              </div>
            </div>
          )}
          <div className="mb-5">
            <p className="text-gray-700 text-sm text-center">
              {t('account.consultations.booking.scheduling.agreement')}
            </p>
          </div>
          <div className="flex w-full px-8">
            <Button
              variant="primary"
              size="large"
              fullWidth
              submit
              label={t('general.continue')}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
