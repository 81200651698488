/* eslint-disable jsx-a11y/no-autofocus */
import clsx from 'clsx';
import React from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

import { SelectOption } from 'types/core';

interface DefaultSelectOption extends SelectOption {
  disabled: boolean;
}

interface TextInputGroupProps {
  options: SelectOption[];
  name: string;
  label: string;
  error: FieldError | undefined;
  register: UseFormRegister<any>;
  defaultOption?: DefaultSelectOption;
  autofocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

const TextInputGroup = React.forwardRef(
  (
    {
      options,
      name,
      label,
      register,
      error,
      defaultOption = { label: '', value: '', disabled: true },
      autofocus = false,
      placeholder = '',
      disabled = false,
      fullWidth = false
    }: TextInputGroupProps,
    ref
  ) => (
    <div id="input-group" className={clsx({ 'w-full': fullWidth })}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div className="relative mt-1">
        <select
          id={name}
          disabled={disabled}
          className={clsx({
            'input-select': true,
            'focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900':
              !error,
            'text-red-800 border-red-800 bg-red-400': error,
            'text-gray-700': disabled
          })}
          placeholder={!error ? placeholder : ''}
          defaultValue={defaultOption.value}
          autoFocus={autofocus}
          {...register(name)}
        >
          <option value={defaultOption.value} disabled={defaultOption.disabled}>
            {defaultOption.label}
          </option>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      {error && <InputErrorMessage error={error} />}
    </div>
  )
);

function InputErrorMessage({ error }: { error: FieldError }) {
  return (
    <p
      className="mt-2 text-left text-sm text-red-800"
      id={`${error.ref}-error`}
    >
      {error.message}
    </p>
  );
}

export default TextInputGroup;
