import { Switch } from '@headlessui/react';
import { DocumentTextIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { AnsweredIcon } from 'features/shared/icons';
import { LoadingSpinner } from 'features/shared/loading';

import { AnswerTextArea } from '../answer-text-area';
import { AnswerInput } from '../to-answer.constants';

const ToAnswerCard = forwardRef(
  (
    {
      onChangeTemplateConfirmation,
      onChangeFormalProposal,
      onSaveDraft,
      formalNoticeProposal,
      isFormalNoticeUsed,
      isFromButtonAction,
      isSavingDraftLoading,
      onTranslateClick,
      control,
      errors
    }: {
      onChangeTemplateConfirmation(): void;
      onChangeFormalProposal(): void;
      onSaveDraft(): void;
      onTranslateClick(): void;
      control: any;
      formalNoticeProposal: boolean;
      isFormalNoticeUsed: boolean;
      isFromButtonAction: boolean;
      isSavingDraftLoading: boolean;
      errors?: FieldErrors<AnswerInput>;
    },
    ref
  ) => {
    const { t } = useTranslation('lawyer');

    return (
      <div className="bg-white px-6 py-5 sm:p-6 lg:px-10 lg:pt-10 lg:pb-6 rounded shadow-base">
        <div className="mb-6 flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <h3 className="text-lg font-sans font-bold">Votre réponse</h3>
          <div className="flex items-center justify-end">
            <div className="pr-2">
              {!isFromButtonAction && isSavingDraftLoading ? (
                <LoadingSpinner purple />
              ) : (
                <AnsweredIcon />
              )}
            </div>
            <span
              className={clsx('text-sm', {
                '-ml-2': !isFromButtonAction && isSavingDraftLoading
              })}
            >
              {t('account.lawyer.question.answer.save.automatic')}
            </span>
          </div>
        </div>
        <div className="pb-6">
          <AnswerTextArea
            ref={ref}
            control={control}
            error={errors?.answerContent}
          />

          <div className="flex justify-between">
            <button
              type="button"
              onClick={onSaveDraft}
              className="text-sm text-purple-900 mt-2 font-medium font-sans hover:opacity-70 flex items-center"
            >
              {t('account.lawyer.question.answer.textarea.draft')}
              {isSavingDraftLoading && isFromButtonAction && (
                <div className="ml-2">
                  <LoadingSpinner purple />
                </div>
              )}
            </button>

            <button
              type="button"
              onClick={onTranslateClick}
              className="text-sm text-purple-900 mt-2 font-medium font-sans hover:opacity-70 flex items-center"
            >
              {t('account.lawyer.question.answer.textarea.translate')}
            </button>
          </div>
          {!isFormalNoticeUsed && (
            <button
              type="button"
              aria-label="template"
              className="font-sant text-sm underline text-gray-900 transition-all flex items-center mt-1.5"
              onClick={onChangeTemplateConfirmation}
            >
              <DocumentTextIcon
                className="h-5 w-5 text-purple-500"
                aria-hidden="true"
              />
              <span className="ml-0.5">
                {formalNoticeProposal
                  ? t(
                      'account.lawyer.question.answer.proposal.template.formal_notice'
                    )
                  : t('account.lawyer.question.answer.proposal.template')}
              </span>
            </button>
          )}
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col justify-between items-center sm:flex-row lg:flex-col lg:items-start xl:flex-row xl:items-center">
            <div className="mb-4 sm:mb-0 lg:items-center lg:mb-4 xl:mb-0">
              <div className="flex flex-row items-center">
                <Switch
                  checked={formalNoticeProposal}
                  onChange={onChangeFormalProposal}
                  className={`${
                    !formalNoticeProposal ? 'bg-gray-500' : 'bg-purple-800'
                  }
                relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">
                    {t('account.lawyer.question.answer.formal_notice.ask')}
                  </span>
                  <span
                    aria-hidden="true"
                    className={`${
                      formalNoticeProposal ? 'translate-x-6' : 'translate-x-0'
                    }
                  pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
                <label className="font-sans text-sm text-black ml-2.5">
                  {t('account.lawyer.question.answer.formal_notice.ask')}
                </label>
              </div>
            </div>

            <Button
              submit
              variant="custom"
              size="large"
              label={t('account.lawyer.question.answer.formal_notice.send')}
              customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium lg:w-full xl:w-auto"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ToAnswerCard;
