import { apiPrivatePythonClient } from './axios';

type MemberOnboardingStatus =
  | 'not_started'
  | 'not_eligible'
  | 'pending'
  | 'completed';

export type AuthenticatedMemberInfo = {
  id: number;
  email: string;
  secondary_email: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_cellphone: string | null;
  subscription_id: string | null;
  is_active_cse_member: boolean;
  is_admin: boolean;
  onboarding_status: MemberOnboardingStatus;
  can_ask_question: boolean;
  can_book_consultation: boolean;
};

export async function getAuthenticatedMemberInfo(): Promise<AuthenticatedMemberInfo> {
  const { data } = await apiPrivatePythonClient.get(`/member/members/me`);

  return data;
}
