import { find } from 'lodash';
import { useQuery } from 'react-query';

import { AlphabetResponse, AlphabetSheetResponse } from 'types/cms';

import data from './alphabetData.json';

export const useGetAlphabet = () =>
  useQuery(['alphabet'], (): AlphabetResponse => ({ alphabet: data }));

export const useGetAlphabetSheetBySlug = ({ slug }: { slug: string }) =>
  useQuery(
    [`alphabet-${slug}`],
    (): AlphabetSheetResponse => ({ alphabetSheet: find(data, { slug }) })
  );
