import { privateAxios } from 'context/auth/auth.utils';

export async function createAdditionalRequest(
  answerId: number,
  content: string
): Promise<void> {
  return privateAxios.post(`/answers/${answerId}/additional-request`, {
    content
  });
}

export async function createAdditionalRequestAnswer(
  answerId: number,
  additionalRequestId: string,
  content: string
): Promise<void> {
  return privateAxios.post(
    `/answers/${answerId}/additional-request/${additionalRequestId}`,
    { content }
  );
}
