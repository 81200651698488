import { Title } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  Collaborator,
  deleteBulkPartnerCollaborator
} from 'services/admin/collaborators';

import { useCustomerAccount } from 'hooks';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

interface PartnerCollaboratorBulkDeleteProps {
  collaboratorIds: Collaborator['id'][];
  onSuccess?: Function;
}

const PartnerCollaboratorBulkDelete = ({
  collaboratorIds,
  onSuccess = () => {}
}: PartnerCollaboratorBulkDeleteProps) => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const { subscription } = useCustomerAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isLoading, isSuccess, isIdle, reset } = useMutation(
    () =>
      deleteBulkPartnerCollaborator(
        subscription.product.offeredByPartner.id,
        collaboratorIds
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['collaboratorsByPartnerId'],
          exact: false
        });
      }
    }
  );

  const closeModale = () => {
    setIsModalOpen(false);
    reset();
    isSuccess && onSuccess();
  };

  return (
    <div>
      <Button
        className="!text-red-800 !p-0 !font-bold"
        variant="transparent"
        size="medium"
        onClick={() => setIsModalOpen(true)}
      >
        {t('admin.collaborators.table.bulkActions.archive')}
      </Button>
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModale}>
        <Title variant="h3" text="Suppression d'un collaborateur" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.form.bulkDelete.success')}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                variant="tertiary"
                size="medium"
                label={t('general.close')}
                onClick={closeModale}
              />
            </div>
          </div>
        )}
        {(isIdle || isLoading) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.bulkDelete.text', {
                count: collaboratorIds.length
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModale}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isLoading}
                variant="custom"
                size="medium"
                label={t('admin.collaborators.modal.delete.submit')}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default PartnerCollaboratorBulkDelete;
