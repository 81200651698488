import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { useTranslation } from 'react-i18next';

import { Button } from '../buttons';
import NavbarContainer from './NavbarContainer';

interface NavbarMinimalProps {
  withLang?: boolean;
}

export default function NavbarMinimal({
  withLang = false
}: NavbarMinimalProps) {
  const { i18n } = useTranslation();

  const onSwitchLanguage = () => {
    i18n?.changeLanguage(i18n.language === 'en' ? 'fr' : 'en');
  };
  return (
    <NavbarContainer>
      <div className="flex py-4 md:py-8 items-center justify-center">
        <div className="flex justify-center w-20">
          <EkieLogo />
        </div>
        {withLang && (
          <div className="absolute right-4 md:right-8">
            <Button
              onClick={onSwitchLanguage}
              label={i18n.language === 'en' ? 'FR' : 'EN'}
              size="small"
              variant="secondary"
              customClassNames="rounded-full"
            />
          </div>
        )}
      </div>
    </NavbarContainer>
  );
}
