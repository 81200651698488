import { Title } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SanitizedContent from 'features/customer/question/SanitizedContent';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { Answer } from 'types/answer';

interface OldAnswerCardProps {
  answer: Answer;
}

export default function PreviousAnswerCard({ answer }: OldAnswerCardProps) {
  const { t } = useTranslation('lawyer');

  const formattedCreatedDateLabel: string = useMemo(() => {
    const day = formatDateToIntl(answer.createdDate);
    const time = getDateAsHhMm(answer.createdDate);
    return `${day} à ${time}`;
  }, [answer.createdDate]);

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow-base opacity-60">
      <div className="pb-7 border-b border-gray-500">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <Title variant="h3">
              {t('account.questions.toanswer.previous')}
            </Title>
          </div>
          <span className="text-gray-700 text-sm">
            {formattedCreatedDateLabel}
          </span>
        </div>
      </div>
      <div className="py-6">
        <SanitizedContent content={answer.content} />
      </div>
    </div>
  );
}
