import { ApiCalendarEvent, CalendarEvent } from 'types/calendar-event';
import { BookingOption } from 'types/lawyer';

export function formatCalendarEventFromApi(
  apiCalendarEvent: ApiCalendarEvent
): CalendarEvent {
  const { start, end, type, resourceId, appointmentOptions } = apiCalendarEvent;
  const option = appointmentOptions?.video
    ? BookingOption.BookingVideo
    : BookingOption.BookingPhone;

  const color =
    // eslint-disable-next-line
    type === 'consultation'
      ? '#1d1d1d'
      : option === BookingOption.BookingVideo
      ? '#c9befb'
      : '#98D5B6';

  return {
    start,
    end,
    title: type,
    color,
    display: type === 'consultation' ? 'auto' : 'background',
    resourceId,
    option: appointmentOptions?.video
      ? BookingOption.BookingVideo
      : BookingOption.BookingPhone
  };
}
