import { ConsultationStatus } from 'types/consultation';

export function isConsultationStatusOngoing(status: ConsultationStatus) {
  if (
    [
      ConsultationStatus.VALIDATED,
      ConsultationStatus.QUALIFIED,
      ConsultationStatus.SCHEDULED,
      ConsultationStatus.SCHEDULED_APPOINTMENT,
      ConsultationStatus.HAS_INCIDENT
    ].includes(status)
  )
    return true;
  return false;
}
