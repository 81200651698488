import { useTranslation } from 'react-i18next';

import { SearchIcon } from 'features/shared/icons';

import { SetState } from 'types/core';

interface QuestionSearchProps {
  setSearch: SetState<string>;
}

export default function QuestionsSearch({ setSearch }: QuestionSearchProps) {
  const { t } = useTranslation('lawyer');

  return (
    <div className="mt-1 relative rounded">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon />
      </div>
      <input
        type="text"
        onChange={(e) => setSearch(e.target.value)}
        className="w-full pl-12 sm:text-sm border border-gray-600 placeholder:text-gray-700 text-sm md:text-base focus:ring-0 focus:ring-offset-0 focus:outline-none py-3 px-4 rounded focus:border-purple-900 "
        placeholder={`${t('account.questions.search.label')}...`}
      />
    </div>
  );
}
