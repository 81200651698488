import { TFunctionTranslationFormValidation } from 'schemas';
import { emailSchema, passwordSchema } from 'schemas/shared';
import * as yup from 'yup';

const CODE_CHARACTERS_MIN = 4;

export const signUpSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    hasCode: yup.boolean(),
    code: yup
      .string()
      .nullable()
      .when('hasCode', {
        is: true,
        then: yup
          .string()
          .required(t('schema.code.required'))
          .min(
            CODE_CHARACTERS_MIN,
            t('schema.code.min', { min: CODE_CHARACTERS_MIN })
          )
      })
  });

export const requestMagicLinkSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t)
  });

export const loginPasswordSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    password: passwordSchema(t)
  });

export const requestResetPasswordSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup
    .object({
      email: emailSchema(t)
    })
    .required();

export const resetPasswordSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      password: passwordSchema(t),
      confirmPassword: passwordSchema(t).oneOf(
        [yup.ref('password'), null],
        t('schema.confirmPassword.valid')
      )
    })
    .required();
