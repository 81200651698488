import { useSearchParams } from 'react-router-dom';

interface UseGuardedSearchParams {
  param: string;
}

export function useSafedGuardedSearchParams({
  param
}: UseGuardedSearchParams): string {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get(param);

  if (!paramValue) {
    return '';
  }

  return paramValue;
}

export function useGuardedSearchParams({
  param
}: UseGuardedSearchParams): string {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get(param);

  if (!paramValue) {
    throw new Error(`${param} not present in URL.`);
  }

  return paramValue;
}

interface UseUnguardedSearchParams extends UseGuardedSearchParams {}

export function useUnguardedSearchParams({
  param
}: UseUnguardedSearchParams): string | null {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get(param);

  return paramValue;
}
