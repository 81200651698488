import { privateAxios } from 'context/auth/auth.utils';

import { Lawyer } from 'types/lawyer';

export async function getLawyer(lawyerId: Lawyer['id']): Promise<Lawyer> {
  const res = await privateAxios.get(`/lawyers/${lawyerId}`);
  const lawyer = res.data;
  return lawyer;
}

export async function createAvailabilitySlot(
  lawyerId: Lawyer['id'],
  startDate: Date,
  endDate: Date,
  appointmentOptions: string[]
) {
  await privateAxios.post(`/lawyers/${lawyerId}/availability`, {
    availability: {
      startDate,
      endDate
    },
    appointmentOptions
  });
  return null;
}

export async function cancelAvailabilitySlot(
  lawyerId: Lawyer['id'],
  startDate: Date,
  endDate: String
) {
  await privateAxios.post(`/lawyers/${lawyerId}/remove-availability`, {
    availability: {
      startDate,
      endDate
    }
  });
  return null;
}

export async function getStripeConfigureUrl(
  lawyerId: Lawyer['id'],
  redirectUrl: string
): Promise<{ url: string }> {
  const res = await privateAxios.get(
    `/lawyers/${lawyerId}/stripe-connect?redirectUrl=${redirectUrl}`
  );
  const { url } = res.data;
  return { url };
}

export async function updateLawyerStripeAccountId(
  lawyerId: Lawyer['id'],
  code: string
) {
  await privateAxios.put(`/lawyers/${lawyerId}/stripe-connect`, {
    stripeAuthorizationCode: code
  });
}
