import classNames from 'classnames';
import Title from 'components/Title';
import { Card, CardContent, CardImage } from 'components/card';
import { Link } from 'react-router-dom';

import { AlphabetSheet } from 'types/cms';

interface AlphabetCardProps {
  alphabetSheet: Omit<AlphabetSheet, 'text' | 'nextAlphabetSheet'>;
  direction?: 'col' | 'row';
  className?: string;
}

const AlphabetCard = ({
  alphabetSheet,
  direction = 'col',
  className
}: AlphabetCardProps) => (
  <Card compact className={className}>
    <Link key={alphabetSheet.id} to={`/account/alphabet/${alphabetSheet.slug}`}>
      <div
        className={classNames(
          'flex',
          direction === 'col' ? 'flex-col' : 'flex-row'
        )}
      >
        <CardImage
          className={classNames(
            direction === 'col' ? 'w-full h-52' : 'w-28 h-28'
          )}
          imageAttributes={alphabetSheet.image.responsiveImage}
        />
        <CardContent className="pb-2">
          <span className="my-1 text-gray-700">{alphabetSheet.letter}</span>
          <Title variant="h3" className="leading-6 md:line-clamp-3">
            {alphabetSheet.title}
          </Title>
        </CardContent>
      </div>
    </Link>
  </Card>
);

export default AlphabetCard;
