import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ArrowLeftIcon } from 'features/shared/icons';

export default function BackQuestions() {
  const { t } = useTranslation('lawyer');
  return (
    <div className="flex gap-2">
      <NavLink
        to="/account/questions"
        className="flex items-center gap-1 text-sm"
      >
        <ArrowLeftIcon />
        <span className="font-bold">{t('account.questions.back')}</span>
      </NavLink>
    </div>
  );
}
