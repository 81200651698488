import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const isProduction = process.env.NODE_ENV === 'production';

interface ReactQueryProviderProps {
  children: React.ReactNode;
}

export default function ReactQueryProvider({
  children
}: ReactQueryProviderProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry(failureCount: number, error: any) {
          if (error.status === 404) return false;
          if (failureCount < 2) return true;
          return false;
        }
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!isProduction ? <ReactQueryDevtools position="bottom-right" /> : null}
    </QueryClientProvider>
  );
}
