import { PencilAltIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Title } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { addPartnerCollaboratorSchema } from 'schemas';
import {
  AddPartnerCollaboratorInput,
  Collaborator,
  updatePartnerCollaborator
} from 'services/admin/collaborators';

import { useCustomerAccount } from 'hooks';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { LayoutModal } from 'features/shared/layout';

interface CollaboratorUpdateProps {
  collaborator: Collaborator;
}

const RADIO_CHOICES = [
  {
    value: 'cse',
    label: 'admin.collaborators.form.add.role.value1'
  },
  {
    value: 'employee',
    label: 'admin.collaborators.form.add.role.value2'
  }
];

const CollaboratorUpdate = ({ collaborator }: CollaboratorUpdateProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');
  const { subscription } = useCustomerAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    reset: resetForm,
    getValues,
    control,
    formState: { errors }
  } = useForm<AddPartnerCollaboratorInput>({
    resolver: yupResolver(addPartnerCollaboratorSchema(tForm)),
    defaultValues: {
      ...collaborator,
      email: collaborator.principalEmail
    }
  });

  const {
    mutate,
    isLoading,
    isIdle,
    isSuccess,
    reset: resetMutation
  } = useMutation(
    (formData: AddPartnerCollaboratorInput) =>
      updatePartnerCollaborator(
        subscription.product.offeredByPartner.id,
        collaborator.id,
        formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['collaboratorsByPartnerId']);
      }
    }
  );

  return (
    <div>
      <Button
        variant="custom"
        size="small"
        customClassNames="!p-0"
        onClick={() => setIsModalOpen(true)}
        icon={
          <PencilAltIcon className="w-6 h-6" strokeWidth={2} stroke="gray" />
        }
      />
      <LayoutModal
        isModalOpen={isModalOpen}
        handleModalClose={() => {
          setIsModalOpen(false);
          resetForm();
          resetMutation();
        }}
      >
        <Title variant="h3" text="Modifier ce collaborateur" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph className="text-lg font-semibold">
              {t('admin.collaborators.form.update.success', {
                firstName: getValues('firstName'),
                lastName: getValues('lastName')
              })}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                  resetForm();
                  resetMutation();
                }}
                isLoading={isLoading}
                variant="tertiary"
                size="medium"
                label={t('general.close')}
              />
            </div>
          </div>
        )}
        {(isIdle || isLoading) && (
          <form
            className="flex flex-col gap-5 my-5"
            onSubmit={handleSubmit((data: AddPartnerCollaboratorInput) =>
              mutate(data)
            )}
          >
            <div className="w-full flex gap-8">
              <TextInputGroup
                type="string"
                placeholder={tForm('placeholder.firstName')}
                label={tForm('label.firstName')}
                name="firstName"
                register={register}
                disabled
                fullWidth
                error={errors.firstName}
              />
              <TextInputGroup
                type="string"
                placeholder={tForm('placeholder.lastName')}
                label={tForm('label.lastName')}
                name="lastName"
                register={register}
                disabled
                fullWidth
                error={errors.lastName}
              />
            </div>
            <TextInputGroup
              type="string"
              placeholder={tForm('placeholder.email')}
              label={tForm('label.email')}
              name="email"
              disabled
              register={register}
              error={errors.email}
            />
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <div>
                  <label htmlFor="type" className="input-label">
                    {t('admin.collaborators.form.add.role.label')}
                  </label>
                  {RADIO_CHOICES.map((radio, index) => (
                    <div
                      className={`flex items-center border border-gray-500 rounded-md py-3 px-3 ${
                        index === 0 ? 'mb-3' : ''
                      }`}
                    >
                      <input
                        id="type-2"
                        type="radio"
                        {...field}
                        defaultChecked={field.value === radio.value}
                        value={radio.value}
                        className="w-4 h-4 bg-gray-100 border-gray-300"
                      />
                      <label
                        htmlFor="type-2"
                        className="ml-6 font-medium text-gray-900"
                      >
                        {t(radio.label as any)}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            />
            <div className="flex gap-4 justify-end mt-2">
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                submit
                isLoading={isLoading}
                variant="custom"
                size="medium"
                label={t('admin.collaborators.form.update.submit')}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </form>
        )}
      </LayoutModal>
    </div>
  );
};

export default CollaboratorUpdate;
