import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { SKIP_ONBOARDING_COMPLETION } from 'features/customer/profile/constants';

const useMemberOnboardingChecker = () => {
  const navigate = useNavigate();
  const { data: member, isSuccess } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  useEffect(() => {
    if (isSuccess) {
      if (
        member.onboarding_status === 'not_eligible' ||
        member.onboarding_status === 'completed'
      )
        return;

      const shouldStartFromProfile =
        !member?.first_name && !member?.last_name && !member?.phone_cellphone;
      const shouldStartFromSituation =
        member.onboarding_status === 'not_started';

      if (shouldStartFromProfile) {
        navigate('/onboarding/profile');
      }

      const hasSkipOnboarding =
        localStorage.getItem(SKIP_ONBOARDING_COMPLETION) === 'true';

      if (
        !shouldStartFromProfile &&
        shouldStartFromSituation &&
        !hasSkipOnboarding
      ) {
        navigate('/onboarding/situation');
      }
    }
  }, [isSuccess, member, navigate]);
};

export default useMemberOnboardingChecker;
