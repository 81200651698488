import { ExclamationIcon } from '@heroicons/react/solid';
import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { useCancelSubscriptionOptimisticMutation } from 'api/customer/subscriptions';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { SetState } from 'types/core';
import { Subscription } from 'types/subscription';

interface ModalSubscriptionCancelProps {
  subscriptionId: Subscription['id'];
  isModalSubscriptionCancelOpen: boolean;
  setIsModalSubscriptionCancelOpen: SetState<boolean>;
}

export default function ModalSubscriptionCancel({
  subscriptionId,
  isModalSubscriptionCancelOpen,
  setIsModalSubscriptionCancelOpen
}: ModalSubscriptionCancelProps) {
  const { t } = useTranslation('customer');

  const { mutate, isLoading } = useCancelSubscriptionOptimisticMutation();

  const handleModalClose = () => setIsModalSubscriptionCancelOpen(false);

  const handleCancelSubscription = () =>
    mutate(
      { subscriptionId },
      { onSettled: () => setIsModalSubscriptionCancelOpen(false) }
    );

  return (
    <LayoutModal
      isModalOpen={isModalSubscriptionCancelOpen}
      handleModalClose={handleModalClose}
    >
      <div>
        <div className="flex flex-col items-center">
          <ExclamationIcon
            className="h-6 w-6 text-red-800"
            aria-hidden="true"
          />
          <Title component="h3" variant="h2">
            {t('account.subscription.cancel.confirmation.title')}
          </Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium ">
            {t('account.subscription.cancel.confirmation.body')}
          </p>
        </div>
      </div>

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="flex justify-end">
          <Button
            onClick={handleModalClose}
            variant="secondary"
            size="small"
            label={t('general.cancel')}
            disabled={isLoading}
          />
          <div className="ml-3">
            <Button
              submit
              variant="primary"
              size="small"
              onClick={handleCancelSubscription}
              label={t('general.unsubscribe')}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
