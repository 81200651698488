import { privateAxios } from 'context/auth/auth.utils';

import { Lawyer } from 'types/lawyer';
import {
  ApiQuestionAssignation,
  QuestionAssignation
} from 'types/question-assignation';

import { formatQuestionAssignationFromApi } from './formatting';

export async function getQuestionAssignations(
  lawyerId: Lawyer['id']
): Promise<QuestionAssignation[] | []> {
  const res = await privateAxios.get(
    `/question-assignations?itemsPerPage=100&lawyer.id=${lawyerId}&question.status=20&status=pending`
  );
  const questionAssignations = res.data.map(
    (apiQuestionAssignation: ApiQuestionAssignation) =>
      formatQuestionAssignationFromApi(apiQuestionAssignation)
  );
  return questionAssignations;
}

export async function acceptQuestionAssignation(
  questionAssignationId: QuestionAssignation['id'],
  lawyerId: Lawyer['id']
) {
  await privateAxios.post(
    `/question-assignations/${questionAssignationId}/accept`,
    {
      assignationId: questionAssignationId,
      lawyerId
    }
  );
  // Only returns a 202
  return null;
}

export type QuestionRejectInput = {
  questionAssignationId: QuestionAssignation['id'];
  lawyerId: Lawyer['id'];
  reason: string;
  message?: string;
};

export async function refuseQuestionAssignation({
  questionAssignationId,
  lawyerId,
  reason,
  message
}: QuestionRejectInput) {
  await privateAxios.post(
    `/question-assignations/${questionAssignationId}/reject`,
    {
      assignationId: questionAssignationId,
      lawyerId,
      reason,
      message
    }
  );

  return null;
}
