import { PropsWithChildren, ReactNode } from 'react';

const Chip = ({
  content,
  children
}: PropsWithChildren<{ content: ReactNode }>) => (
  <span className="border border-purple-900 text-purple-900 rounded-[32px] px-3">
    {children || content}
  </span>
);

export default Chip;
