import { useTranslation } from 'react-i18next';

import { VoteButton } from 'features/customer/vote';

import { Appointment } from 'types/consultation';

function ConsultationVoteForm({
  appointmentId,
  handleConsultationVote
}: {
  appointmentId: Appointment['id'];
  handleConsultationVote: (
    note: number,
    appointmentId: Appointment['id']
  ) => void;
}) {
  const { t } = useTranslation('customer');

  return (
    <>
      <div className="my-3 border-base border-gray-500" />
      <div className="mt-4">
        <p className="text-md text-left font-medium">
          {t('account.consultation.vote.paragraph')}
        </p>
        <div className="mt-4">
          <div className="grid grid-cols-vote max-h-fit max-w-fit">
            <div className="flex gap-4 w-full">
              <VoteButton
                vote={1}
                handleClick={() => handleConsultationVote(appointmentId, 1)}
              />
              <VoteButton
                vote={2}
                handleClick={() => handleConsultationVote(appointmentId, 2)}
              />
              <VoteButton
                vote={3}
                handleClick={() => handleConsultationVote(appointmentId, 3)}
              />
              <VoteButton
                vote={4}
                handleClick={() => handleConsultationVote(appointmentId, 4)}
              />
              <VoteButton
                vote={5}
                handleClick={() => handleConsultationVote(appointmentId, 5)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConsultationVoteForm;
