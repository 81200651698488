import { PageName } from './account';

export enum Variant {
  Active = 'active',
  Inactive = 'inactive'
}

export interface IconProps {
  variant: Variant;
  name: PageName;
}
