/* eslint-disable no-return-assign */
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface QuestionTextAreaProps {
  register: UseFormRegister<any>;
  error: FieldError | undefined;
}

const MAX_QUESTION_CHARACTERS_LENGTH = 2000;

export default function QuestionTextArea({
  register,
  error
}: QuestionTextAreaProps) {
  const { t } = useTranslation('customer');
  const [questionCharLeft, setQuestionCharLeft] = useState<number>(
    MAX_QUESTION_CHARACTERS_LENGTH
  );

  return (
    <div>
      <textarea
        rows={8}
        {...register('question', {
          onChange: (e) =>
            setQuestionCharLeft(
              MAX_QUESTION_CHARACTERS_LENGTH - (e.target.value as string).length
            ),
          onBlur: (e) =>
            (e.target.placeholder = t('account.ask.textAreaPlaceholder'))
        })}
        minLength={20}
        maxLength={MAX_QUESTION_CHARACTERS_LENGTH}
        className="w-full placeholder:text-gray-700 text-sm md:text-base block focus:border-purple-900 focus:drop-shadow-input focus:ring-0 focus:ring-offset-0 focus:shadow-purple-900 focus:outline-none py-3 px-4 rounded"
        placeholder={t('account.ask.textAreaPlaceholder')}
        onFocus={(e) => (e.target.placeholder = '')}
      />
      <p className="pt-1 text-right text-sm text-opacity-70">
        {questionCharLeft} {t('account.ask.textAreaCharLeft')}
      </p>
      {error && <TextAreaErrorMessage error={error} />}
    </div>
  );
}

function TextAreaErrorMessage({ error }: { error: FieldError }) {
  return (
    <div className="flex items-center gap-1">
      <div className="pointer-events-none inset-y-0 right-0 flex items-center">
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-800"
          aria-hidden="true"
        />
      </div>
      <p className="text-sm text-red-800" id={`${error.ref}-error`}>
        {error.message}
      </p>
    </div>
  );
}
