import { ShareIcon } from '@heroicons/react/outline';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Card, Section } from 'components';
import { CardContent } from 'components/card';
import FeatureErrorCard from 'components/card/FeatureErrorCard';
import GenericErrorCard from 'components/card/GenericErrorCard';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { Fade } from 'components/transition';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMemberQuestion } from 'services/question';

import QuestionAnswer from 'features/answer/QuestionAnswer';
import ConsultationCtaCard from 'features/customer/question/ConsultationCtaCard';
import RateAnswer from 'features/rating/RateAnswer';
import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import MemberQuestionAdditionalRequest from './MemberQuestionAdditionalRequest';
import MemberQuestionTimeline from './MemberQuestionTimeline';
import ShareMemberQuestion from './ShareMemberQuestion';
import MemberQuestionCard from './components/MemberQuestionCard';

interface MemberQuestionProps {
  questionId: number;
}

const MemberQuestion = ({ questionId }: MemberQuestionProps) => {
  const { t } = useTranslation('customer');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    data: question,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-question', questionId],
    queryFn: () => getMemberQuestion(questionId)
  });

  if (isLoading || !isSuccess) return <QuestionSectionSkeleton />;

  return (
    <Fade>
      <Section className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-12">
        <div className="space-y-6 lg:col-span-8">
          <section aria-labelledby="alert">
            <Alert title={t('alert.privateQuestion.title')}>
              <p>{t('alert.privateQuestion.paragraph')}</p>
            </Alert>
          </section>
          <section aria-labelledby="question">
            <MemberQuestionCard question={question} />
          </section>
          {question.answer_id && (
            <ErrorBoundary
              fallback={
                <FeatureErrorCard
                  title={t('account.question.answer.error.title')}
                />
              }
            >
              <QuestionAnswer answerId={question.answer_id} />
            </ErrorBoundary>
          )}
          {question.additional_request_id && (
            <MemberQuestionAdditionalRequest
              additionalRequestId={question.additional_request_id}
            />
          )}
          {question.can_rate_answer && (
            <Card>
              <CardContent>
                <ErrorBoundary
                  fallback={
                    <FeatureErrorCard
                      title={t('account.question.vote.error.title')}
                    />
                  }
                >
                  <RateAnswer
                    questionId={question.id}
                    answerId={question.answer_id}
                  />
                </ErrorBoundary>
              </CardContent>
            </Card>
          )}
          {question.can_ask_consultation && (
            <section aria-labelledby="consultation">
              <ConsultationCtaCard
                questionId={question.id}
                lawyerId={question.lawyer_id}
              />
            </section>
          )}
        </div>
        <section aria-labelledby="timeline" className="lg:col-span-4">
          <MemberQuestionTimeline questionId={questionId} />
          {question.can_share_question && (
            <Card compact className="mt-6">
              <Button
                icon={<ShareIcon className="w-5" />}
                variant="tertiary"
                size="medium"
                onClick={() => setIsModalOpen(true)}
                fullWidth
                label={t('account.question.share.cta')}
              />
              <LayoutModal
                isModalOpen={isModalOpen}
                handleModalClose={() => setIsModalOpen(false)}
              >
                <ErrorBoundary fallback={<GenericErrorCard />}>
                  <ShareMemberQuestion
                    questionId={questionId}
                    onShare={() => setIsModalOpen(false)}
                  />
                </ErrorBoundary>
              </LayoutModal>
            </Card>
          )}
        </section>
      </Section>
    </Fade>
  );
};

export default MemberQuestion;
