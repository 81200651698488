/* eslint-disable no-plusplus */

/* eslint-disable no-await-in-loop */
import { privateAxios, privateAxiosJsonLd } from 'context/auth/auth.utils';

import { Answer, ApiAnswer } from 'types/answer';
import { Lawyer } from 'types/lawyer';
import { Question } from 'types/question';

import { formatAnswerFromApi } from './formatting';

export async function getAnswers(
  lawyerId: Lawyer['id']
): Promise<Answer[] | []> {
  const numberOfAnswers = await privateAxiosJsonLd
    .get(
      `/answers?lawyer.id=${lawyerId}&status[]=0&status[]=10&order[createdDate]=desc&itemsPerPage=1`,
      {
        headers: {
          'Content-Type': 'application/ld+json'
        }
      }
    )
    .then((r) => r.data['hydra:totalItems']);
  const nbOfPagesToRequest = Math.ceil(numberOfAnswers / 200);
  const answers: ApiAnswer[] = [];
  for (let i = 1; i <= nbOfPagesToRequest; i++) {
    await privateAxios
      .get(
        `/answers?lawyer.id=${lawyerId}&status[]=0&status[]=10&order[createdDate]=desc&itemsPerPage=200&page=${i}`
      )
      .then((res: any) => answers.push(...(res.data as ApiAnswer[])));
  }

  const formattedAnswers = answers.map((answerFromApi) =>
    formatAnswerFromApi(answerFromApi)
  );

  return formattedAnswers;
}

export async function getAnswerById(answerId: string): Promise<Answer> {
  const res = await privateAxios.get(`/answers/${answerId}`);
  const answer = res.data;

  return answer;
}

type CreateAnswerParamsProps = {
  answer_id: Answer['id'];
  content: string;
  formal_notice_proposal: boolean;
};

export async function createAnswer(
  answerId: Answer['id'],
  content: string,
  formalNoticeProposal: boolean
): Promise<Answer> {
  const payload: CreateAnswerParamsProps = {
    answer_id: answerId,
    content,
    formal_notice_proposal: formalNoticeProposal
  };
  // Add status in case of new draft
  const res = await privateAxios.post(`/answers/validate`, payload);
  const createdAnswer = res.data;
  return createdAnswer;
}

type CreatDraftParams = {
  lawyer_id: number;
  question_id: string;
  content: string;
  formal_notice_proposal: boolean;
  answer_id?: number;
};

export async function createAnswerDraft(
  lawyerId: Lawyer['id'],
  questionId: Question['id'],
  content: string,
  formalNoticeProposal: boolean,
  answerId?: number
): Promise<Answer> {
  const data: CreatDraftParams = {
    lawyer_id: lawyerId,
    question_id: questionId,
    content,
    formal_notice_proposal: formalNoticeProposal
  };
  if (answerId) data.answer_id = answerId;
  const res = await privateAxios.post(`/answers/save-draft`, data);
  const createdDraft = res.data;
  return createdDraft;
}

export async function cancelFormalNoticeProposal(
  relatedAnswerId: number,
  formalNoticeId: string
): Promise<boolean> {
  const res = await privateAxios.post(`/formal-notice/cancel`, {
    relatedAnswerId,
    formalNoticeId
  });
  return res.data;
}

type TranslateAnswerResponse = {
  translatedContent: string;
  sourceLanguageCode: 'FR' | 'EN';
  targetLanguageCode: 'FR' | 'EN';
};

export async function translateAnswer(
  text: string
): Promise<TranslateAnswerResponse> {
  const res = await privateAxios.post(`/answers/translate`, {
    answerContent: text
  });
  return res.data;
}

export async function putAnswerHelperVote(
  answerHelperId: string,
  payload: {
    upVote?: boolean;
    comment?: string;
  }
): Promise<any> {
  const formData = new FormData();
  formData.append('upVote', 'true');
  formData.append('comment', 'cdxds');

  const res = await privateAxios.put(
    `/lawyers/answer-helpers/${answerHelperId}/vote`,
    payload
  );

  return res.data;
}
