export default function AnsweredIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0417 5.08333C14.0417 5.30599 13.9427 5.50391 13.7943 5.65234L7.46094 11.9857C7.3125 12.1341 7.11458 12.2083 6.91667 12.2083C6.69401 12.2083 6.49609 12.1341 6.34766 11.9857L3.18099 8.81901C3.03255 8.67057 2.95833 8.47266 2.95833 8.25C2.95833 7.80469 3.30469 7.45833 3.75 7.45833C3.94792 7.45833 4.14583 7.55729 4.29427 7.70573L6.91667 10.3034L12.681 4.53906C12.8294 4.39062 13.0273 4.29167 13.25 4.29167C13.6706 4.29167 14.0417 4.63802 14.0417 5.08333Z"
        fill="#56A663"
      />
    </svg>
  );
}
