import { format, startOfMonth } from 'date-fns';

import { privateAxios } from 'context/auth/auth.utils';

import { Lawyer } from 'types/lawyer';

export async function getLawyerOverallPerformances(
  lawyerId: Lawyer['id']
): Promise<any> {
  const res = await privateAxios.get(`/lawyers/${lawyerId}/performance`);

  return res.data;
}

export async function getLawyerCurrentMonthPerformances(
  lawyerId: Lawyer['id']
): Promise<any> {
  const firstDayOfMonth = format(startOfMonth(new Date()), 'dd-MM-yyyy');
  const res = await privateAxios.get(
    `/lawyers/${lawyerId}/performance?startDate=${firstDayOfMonth}`
  );

  return res.data;
}
