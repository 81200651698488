import { SVGProps } from 'react';

export default function UmbrellaIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.8776 22.8373C59.8776 22.8373 51.7356 32.1794 49.3354 41.1514"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M65.8531 38.6686C65.8531 38.6686 55.3038 45.7937 44.5819 85.5479"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M36.9213 42.9623C36.9213 42.9623 25.276 49.6671 8.1908 75.0381"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M84.3038 38.6722C90.8828 38.6363 98.6727 38.8231 102.783 39.7466C111.073 40.7203 139.523 50.6373 147 55.0281C125.053 34.3821 115.302 30.8069 112.212 29.6679C109.121 28.5289 105.058 29.1792 101.644 27.8785C98.2307 26.5778 91.8889 21.8637 88.6407 21.5403C85.3925 21.2169 78.7237 23.4913 75.9606 23.4913H76.2193C73.4562 23.4913 66.791 21.2169 63.5392 21.5403C60.2874 21.8637 53.9492 26.5814 50.5357 27.8785C47.1223 29.1756 43.0585 28.5289 39.9684 29.6679C36.8783 30.8069 27.123 34.3785 5.17627 55.0245C12.6535 50.6337 41.1038 40.7203 49.3931 39.743C53.4785 38.8267 61.2037 38.6363 67.7575 38.6686H84.3038V38.6722Z"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M92.2983 22.8373C92.2983 22.8373 100.44 32.1794 102.841 41.1514"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.316 82.9715C66.4208 83.0793 48.3475 83.6434 44.9735 84.2039C42.8177 81.4588 19.5846 71.7574 9.02085 73.9599C10.9288 65.4694 9.73588 56.5441 5.17981 55.0242"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M147 55.0242C142.444 56.5441 141.247 65.4694 143.159 73.9599C132.595 71.7609 109.366 81.4588 107.206 84.2039C103.678 83.6146 84.063 83.029 77.9834 82.9607H75.2706"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M86.3232 38.6686C86.3232 38.6686 96.8726 45.7937 107.594 85.5479"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M76.0396 44.6152L115.255 42.9623C115.255 42.9623 126.9 49.6671 143.985 75.0381"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.1327 45.5635H75.8167"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.528 46.9H75.4215"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.528 42.9622H75.4215"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.4632 38.5858V42.9622H72.7841V45.5636H73.0716V46.9002H73.4668V111.239C73.0069 111.282 72.644 111.663 72.6404 112.133L72.5362 127.814C72.5398 127.946 72.5182 128.036 72.4895 128.191C71.9541 130.257 70.0749 131.784 67.8436 131.784C66.1153 131.784 64.6026 130.871 63.7582 129.506C63.3378 128.827 63.151 128.029 63.151 127.228V122.244C63.1546 121.533 62.5797 120.951 61.8683 120.951C61.5557 120.951 61.2431 120.951 60.9305 120.951C60.2082 120.951 59.6226 121.529 59.6226 122.248L59.6405 127.041C59.6477 128.518 59.9962 129.991 60.7508 131.259C62.2024 133.695 64.8613 135.327 67.9011 135.327C71.8679 135.327 75.1843 132.549 76.0179 128.838C76.1257 128.367 76.1617 127.882 76.1617 127.397L76.2191 112.14C76.2191 111.652 75.8383 111.256 75.3568 111.231V46.9002H75.752V45.5636H76.0395V42.9622H75.3604V38.5858"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.4633 111.238H75.3604"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M75.3606 42.9624L79.5933 38.6686"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M76.0396 44.1372L90.6096 38.7152"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M76.0396 45.5637L94.3465 49.703"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M72.8417 44.6116L36.9214 42.9623"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.5244 42.9588L69.2917 38.6686"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M72.8417 44.1336L57.6321 38.8517"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M72.8417 45.5598L57.8297 49.7027"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M73.582 23.1498L73.8587 17.8931C73.8767 17.5625 74.1461 17.3074 74.4767 17.3074C74.8073 17.3074 75.0768 17.5661 75.0947 17.8931L75.3858 23.4373"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M75.3606 115.065V122.406"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 86.9565V94.2973"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 83.5465V86.3096"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 60.723V63.4897"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 21.6622V22.8874"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 20.5304V21.1448"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 58.7503V59.7169"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M75.3606 43.6629V44.6295"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M74.6418 39.4877V41.1333"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M60.6143 122.787V126.883"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M128.654 41.1479C122.941 36.8577 118.873 34.497 116.099 33.1353"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M133.328 44.8055C132.336 44.0006 131.381 43.2425 130.464 42.5346"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M135.847 46.8825C135.362 46.4765 134.884 46.0776 134.417 45.6932"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M23.6486 41.1479C29.3616 36.8577 33.429 34.497 36.2029 33.1353"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M18.9738 44.8055C19.9655 44.0006 20.9212 43.2425 21.8375 42.5346"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
      <path
        d="M16.4551 46.8825C16.9401 46.4765 17.418 46.0776 17.8851 45.6932"
        stroke="#1D1D1B"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}
