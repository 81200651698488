import type { Answer } from './answer';
import type { Modify } from './core';
import type { Customer } from './customer';
import type { BookingConsultationOption, Lawyer } from './lawyer';
import type { ConsumerTag } from './question';

export enum ConsultationStatus {
  PENDING = 'status_pending',
  VALIDATED = 'status_validated',
  QUALIFIED = 'status_qualified',
  SCHEDULED = 'status_scheduled',
  SCHEDULED_APPOINTMENT = 'status_appointment_scheduled',
  HAS_INCIDENT = 'status_has_incident',
  TO_RESCHEDULE = 'status_to_reschedule',
  COMPLETED = 'status_completed',
  CANCELLED = 'status_cancelled'
}

export interface ApiConsultation {
  id: number;
  file: File;
  customer: Customer;
  product: string;
  order: string;
  linkedQuestion: LinkedQuestion;
  status: ConsultationStatus;
  selectedCategory: null;
  preferredCallbackTime: Date;
  qualificationSurvey: null;
  notes: null;
  caseDetail: CaseDetail;
  appointments: Appointment[];
  createdDate: Date;
  lastUpdatedDate: Date;
  document?: Document;
  statusLegacy?: ConsultationStatus;
}

export interface Document {
  id: string;
  url: string;
  name: string;
}

export interface PaginationConsultation {
  first: number;
  last: number;
  next: number;
  totalItems: number;
}

export interface Consultation {
  id: number;
  file: File;
  customer: Customer;
  product: string;
  order: string;
  linkedQuestion: LinkedQuestion;
  status: ConsultationStatus;
  selectedCategory: null;
  preferredCallbackTime: Date;
  qualificationSurvey: null;
  notes: null;
  caseDetail: CaseDetail;
  appointments: Appointment[];
  createdDate: Date;
  lastUpdatedDate: Date;
  isConsultationCompleted: boolean;
  isConsultationVoted: boolean;
  latestAppointment: Appointment | null;
  isConsultationToComplete?: boolean;
  linkedAnswerId?: Answer['id'] | null;
  document?: {
    id: string;
    url: string;
    name: string;
  };
}

export interface ConsultationFile {
  file: any;
  name: string;
  size: number;
  content: string;
}

export interface NewAppointmentVote {
  note: number;
  reason?: string;
}

export interface NewUnauthenticatedAppointmentVote extends NewAppointmentVote {
  voteLinkId: string;
}

export interface ApiConsultationConfirmation {
  id: number;
  questionId: number;
  status: Status;
  date: Date;
  customer: Customer;
  order: Order;
  comment: null;
  document: null;
}

export interface PaymentData {
  payment_intent: Payment;
  client_secret: string;
  payment_method: Payment;
}

export interface Payment {
  id: string;
}

export interface Appointment {
  id: number;
  lawyer: Lawyer;
  appointmentDate: Date;
  appointmentType: BookingConsultationOption;
  isProcessed: boolean;
  incidentType: null;
  notes: null;
  payout: null;
  vote: null;
  voteLink: null;
  videoRoom: {
    guestUrl: string;
    hostUrl: string;
    meetingCode: string;
  } | null;
}

export interface CaseDetail {
  id: number;
  lawDomain: LawDomain;
  consumerTag: ConsumerTag;
  caseDetails: string;
  customerIntent: string;
}

export interface LawDomain {
  id: number;
  name: string;
}

export interface Account {
  id: number;
  type: string;
  email: string;
}

export interface File {
  createdDate: null;
  lastUpdatedDate: null;
}

export interface LinkedQuestion {
  id: number;
  content: string;
  slug: string;
  title: string;
  answers: Answer[];
}

export interface AccountTags {
  tags: Tag[];
}

export interface Tag {
  tag: string;
  value: string;
}

export interface Order {
  id: string;
  status: string;
  paymentInformations: PaymentInformations;
}

export interface PaymentInformations {
  paymentProvider: string;
  paymentMethod: string;
  paymentData: {
    client_secret: string;
  };
  amount: Amount;
}

export interface Amount {
  amountHt: AmountHTClass;
  amountVat: AmountHTClass;
  amountTtc: AmountHTClass;
}

export interface AmountHTClass {
  amount: string;
  currency: string;
}

export interface Status {
  '@fqType': string;
  value: string;
}

export enum ConsultationCancelIntent {
  /*
   * Incidents caused by the lawyer
   */
  LAWYER_UNAVAILABLE = 10,
  /** Lawyer is not able to process this case */
  LAWYER_RECUSED = 11,
  /** Lawyer didn't show up */
  LAWYER_NOSHOW = 12,
  /*
   * Incidents caused by the customer
   */
  /** The customer was not available at the time of appointment */
  CUSTOMER_UNAVAILABLE = 20,
  /** Customer is not satisfied, e.g. because the lawyer did not provide useful informations */
  CUSTOMER_DISPUTE = 21,
  /** Lawyer attempted to call the customer, but was unable to */
  CUSTOMER_NOSHOW = 22,
  /** The order was cancelled, thus leading to the consultation cancellation */
  // CUSTOMER_ORDER_CANCELLATION = 23,
  /** The appointment has been cancelled due to an agent error */
  // ASSIGNMENT_ERROR = 98,
  /** Other incident */
  OTHER = 99
}

export enum ConsultationsFilter {
  SCHEDULED = 'scheduled',
  TOCOMPLETE = 'toComplete',
  PAST = 'past'
}

export interface ConsultationToComplete
  extends Modify<
    Consultation,
    { status: ConsultationStatus.SCHEDULED; isConsultationComplete: true }
  > {}

export interface ConsultationsPast
  extends Modify<
    Consultation,
    { status: ConsultationStatus.COMPLETED | ConsultationStatus.CANCELLED }
  > {}
