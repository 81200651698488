import { useOutletContext } from 'react-router-dom';

import {
  Consultation,
  ConsultationToComplete,
  ConsultationsPast,
  PaginationConsultation
} from 'types/consultation';
import type { LawyerQuestion } from 'types/question';
import type { QuestionAssignation } from 'types/question-assignation';

export interface OutletContext {
  questionAssignations: QuestionAssignation[];
  questionsToAnswer: LawyerQuestion[];
  consultations: {
    scheduled: {
      consultations: Consultation[] | [];
      pagination: PaginationConsultation;
    };
    toComplete: {
      consultations: ConsultationToComplete[] | [];
      pagination: PaginationConsultation;
    };
    past: {
      consultations: ConsultationsPast[] | [];
      pagination: PaginationConsultation;
    };
  };
}

export default function useLawyerAccount() {
  return useOutletContext<OutletContext>();
}
