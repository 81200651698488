import { useMutation } from 'react-query';

import { NewUnauthenticatedNpsVote } from 'types/customer';

import { createUnauthenticatedNpsVote } from './api';

export function useCreateUnauthenticatedNpsVoteMutation() {
  return useMutation(
    ({
      note,
      accountId,
      source
    }: {
      note: NewUnauthenticatedNpsVote['note'];
      accountId: NewUnauthenticatedNpsVote['accountId'];
      source: NewUnauthenticatedNpsVote['source'];
    }) => createUnauthenticatedNpsVote(note, accountId, source)
  );
}
