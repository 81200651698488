import { SVGProps } from 'react';

export default function ResidencePermitIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M134.676 33H17.8869C12.9788 33 9 36.9788 9 41.8869V110.966C9 115.874 12.9788 119.853 17.8869 119.853H134.676C139.584 119.853 143.563 115.874 143.563 110.966V41.8869C143.563 36.9788 139.584 33 134.676 33Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M52.4607 77.1124H16.2544V113.319H52.4607V77.1124Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M137.344 98.7311H122.757V113.319H137.344V98.7311Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M143.563 41.7222H9V49.8855H143.563V41.7222Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7148 62.5795H97.1696"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7148 72.7256H84.1393"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7148 82.8744H97.1696"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.351 82.8744H120.078"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7148 93.0237H97.1696"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.351 93.0237H111.699"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.351 103.169H111.699"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7148 103.169H97.1696"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7148 113.319H97.1696"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M34.3576 96.0728C37.989 96.0728 40.9328 93.129 40.9328 89.4977C40.9328 85.8663 37.989 82.9225 34.3576 82.9225C30.7263 82.9225 27.7825 85.8663 27.7825 89.4977C27.7825 93.129 30.7263 96.0728 34.3576 96.0728Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M34.3577 97.4551C26.5649 97.4551 20.0995 102.59 18.9093 109.305C18.7035 110.475 19.633 111.542 20.8198 111.542H47.8922C49.0789 111.542 50.0084 110.475 49.8026 109.305C48.6124 102.586 42.1505 97.4551 34.3542 97.4551H34.3577Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
