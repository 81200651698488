import classNames from 'classnames';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAnswersQuery } from 'api/lawyer/answers';

import { useLawyerAccount, useQuestionsPageSearch } from 'hooks';

import { useAuth } from 'context';

import { QuestionsNav } from 'features/lawyer/account/questions/nav';

export default function QuestionsPage() {
  const { lawyerId } = useAuth();
  const { questionsToAnswer, questionAssignations } = useLawyerAccount();

  const { data: answers = [], isLoading: isAnswersLoading } = useAnswersQuery({
    lawyerId: lawyerId as number
  });

  const { pathname } = useLocation();
  const [search, setSearch] = useState<string>('');

  const { questionAssignationsHits, questionsToAnswerHits, answersHits } =
    useQuestionsPageSearch({
      query: search,
      entities: {
        questionsToAnswer,
        questionAssignations,
        answers
      }
    });

  const shouldRenderOutlet = !(pathname === '/account/questions');

  return (
    <div className="lg:grid lg:grid-cols-12">
      <div
        className={classNames(
          shouldRenderOutlet
            ? 'hidden lg:block lg:col-span-4'
            : 'block col-span-4'
        )}
      >
        <QuestionsNav
          setSearch={setSearch}
          questionAssignations={questionAssignationsHits}
          questionsToAnswer={questionsToAnswerHits}
          answers={answersHits}
          isLoading={isAnswersLoading}
        />
      </div>

      <div
        className={classNames(
          shouldRenderOutlet ? 'block col-span-12 lg:col-span-8' : 'hidden'
        )}
      >
        <Outlet
          context={{
            questionAssignations,
            questionsToAnswer,
            answers
          }}
        />
      </div>
    </div>
  );
}
