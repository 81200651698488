import { useTranslation } from 'react-i18next';

import { useStripeSessionTokenQuery } from 'api/customer/subscriptions';

import { useCustomer, useCustomerAccount } from 'hooks';

import {
  SubscriptionDetails,
  SubscriptionStatus
} from 'features/customer/account/subscription';
import { Button } from 'features/shared/buttons';

function CustomerSubscription() {
  const { id, isB2b } = useCustomer();
  const { subscription } = useCustomerAccount();
  const { t } = useTranslation('customer');
  const { data: stripeSessionToken } = useStripeSessionTokenQuery({
    customerId: id
  });

  const isSubscriptionActive =
    !!subscription &&
    subscription?.active &&
    subscription?.status !== 'terminated';

  const updatePaymentInfos = !isB2b && (
    <div className="mb-6">
      <div>
        <div className="flex justify-end">
          <a
            href={`https://billing.stripe.com/session/${stripeSessionToken}`}
            target="blank"
            rel="noreferrer"
          >
            <Button
              size="medium"
              variant="secondary"
              label={t('account.subscription.managePayment')}
            />
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-[720px]">
      <div className="mb-6">
        <SubscriptionStatus subscription={subscription} />
      </div>
      {isSubscriptionActive && (
        <div className="mb-6">
          <SubscriptionDetails subscription={subscription} />
        </div>
      )}
      {updatePaymentInfos}
    </div>
  );
}

export default CustomerSubscription;
