import { privateAxios } from 'context/auth/auth.utils';

import { AnswerVote, AppointmentVote } from 'types/question';

export async function getAnswerVotes(): Promise<AnswerVote[]> {
  const res = await privateAxios.get(`/me/answer-votes`);

  return res.data.answerVotes;
}

export async function getAppointmentVotes(): Promise<AppointmentVote[]> {
  const res = await privateAxios.get(`/me/appointment-votes`);

  return res.data.appointmentVotes;
}
