import { Outlet } from 'react-router-dom';

import {
  useQuestionAssignationsQuery,
  useQuestionsToAnswerQuery
} from 'api/lawyer/queries.index';

import { OutletContext } from 'hooks/useLawyerAccount';

import { useAuth } from 'context';

import { AccountNav } from 'features/lawyer/account/sidebar';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { usePagination } from './context';

export default function AccountPage() {
  const { lawyerId } = useAuth();
  const { scheduled, toComplete, past } = usePagination();

  const questionAssignations = useQuestionAssignationsQuery({
    lawyerId: lawyerId as number
  });

  const questionsToAnswer = useQuestionsToAnswerQuery({
    lawyerId: lawyerId as number
  });

  const isSuccess =
    questionAssignations.isSuccess &&
    questionsToAnswer.isSuccess &&
    toComplete.isSuccess &&
    past.isSuccess &&
    scheduled.isSuccess;

  if (!isSuccess) return <LayoutLoadingSkeleton />;

  const outletContext: OutletContext = {
    questionAssignations: questionAssignations.data,
    questionsToAnswer: questionsToAnswer.data,
    consultations: {
      scheduled: scheduled.data,
      toComplete: toComplete.data,
      past: past.data
    }
  };

  return (
    <div className="min-h-screen bg-gray-paper md:grid md:grid-cols-account">
      <div
        id="sidebar"
        className="md:py-4 md:pl-6 md:col-span-2 bg-white md:border-r md:border-gray-600"
      >
        <AccountNav />
      </div>
      <div
        id="content"
        className="mt-[72px] md:mt-0  md:col-span-10 bg-gray-paper"
      >
        <Outlet context={outletContext} />
      </div>
    </div>
  );
}
