import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Customer, NewCustomer, PreviousCustomer } from 'types/customer';
import { NewOnboarding } from 'types/onboarding';

import {
  completeCustomer,
  createEmailFromContent,
  createOnboarding,
  getCustomer,
  getCustomerUsageMeter,
  updateAccount,
  updateCustomer,
  updateCustomerPassword
} from './api';

export function useCustomerQuery() {
  return useQuery({
    queryKey: ['customer'],
    queryFn: async () => {
      const customer = await getCustomer();
      const { id, email, phoneCellphone } = customer;

      Sentry.setUser({ email });
      Sentry.setContext('customer', {
        id,
        email,
        phoneCellphone
      });

      return customer;
    }
  });
}

export function useCustomerUsageMeterQuery({
  customerId
}: {
  customerId: Customer['id'];
}) {
  return useQuery({
    queryKey: ['customer-usage-meter'],
    queryFn: () => getCustomerUsageMeter(customerId)
  });
}

export function useUpdateCustomerPasswordMutation() {
  return useMutation({
    mutationFn: ({
      customerId,
      newPassword
    }: {
      customerId: Customer['id'];
      newPassword: string;
    }) => updateCustomerPassword(customerId, newPassword)
  });
}

export function useUpdateCustomerOptimisticMutation({
  prevCustomer
}: {
  prevCustomer: PreviousCustomer;
}) {
  const { id: customerId } = prevCustomer;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newCustomer: NewCustomer) =>
      updateCustomer(customerId, newCustomer),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info']
      });
    }
  });
}

export function useUpdateAccountOptimisticMutation(accountId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => updateAccount(accountId, data),
    onMutate: async (account: any) => {
      await queryClient.cancelQueries({ queryKey: ['customer'] });
      const previousCustomer = queryClient.getQueryData<Customer>(['customer']);
      if (previousCustomer) {
        queryClient.setQueryData<PreviousCustomer>(['customer'], {
          ...previousCustomer,
          secondaryEmail: account.secondaryEmail
        });
      }

      return { previousCustomer };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousCustomer) {
        queryClient.setQueryData<Customer>(
          ['customer'],
          context.previousCustomer
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['customer'] });
    }
  });
}

export function useUpdateOnboardingCustomerOptimisticMutation({
  id
}: {
  id: number;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newCustomer: NewCustomer) => completeCustomer(id, newCustomer),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info'],
        refetchType: 'all'
      });
    }
  });
}

export function useCreateOnboarding({
  customerId
}: {
  customerId: Customer['id'];
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ newOnboarding }: { newOnboarding: NewOnboarding }) =>
      createOnboarding(customerId, newOnboarding),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['authenticated-member-info']
      });
    }
  });
}

export function useCreateEmailFromContent() {
  return useMutation({
    mutationFn: ({ leadEmail }: { leadEmail: string }) =>
      createEmailFromContent(leadEmail)
  });
}
