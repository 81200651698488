import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGuardedSearchParams } from 'hooks';

import { Button } from 'features/shared/buttons';
import { TimerIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';

import { SetState } from 'types/core';

interface ModalQuestionAcceptedProps {
  isModalQuestionAccepted: boolean;
  setIsModalQuestionAccepted: SetState<boolean>;
}

export default function ModalQuestionAccepted({
  isModalQuestionAccepted,
  setIsModalQuestionAccepted
}: ModalQuestionAcceptedProps) {
  const { t } = useTranslation('lawyer');

  const questionId = useGuardedSearchParams({ param: 'questionId' });
  const navigate = useNavigate();

  const handleModalQuestionAcceptedClose = () =>
    setIsModalQuestionAccepted(false);

  const handleAnswerNow = () => {
    setIsModalQuestionAccepted(false);
    navigate(`/account/questions/questions-to-answer?id=${questionId}`);
  };

  return (
    <LayoutModal
      isModalOpen={isModalQuestionAccepted}
      handleModalClose={handleModalQuestionAcceptedClose}
    >
      <div>
        <div className="flex flex-col items-center">
          <TimerIcon />
          <Title variant="h3">
            {t('account.questions.inCharge.confirmation.title')}
          </Title>
        </div>
        <div className="text-center">
          <p className="text-md mt-2 font-medium ">
            {t('account.questions.inCharge.confirmation.p')}
          </p>
          <div className="mt-4">
            <div className="flex gap-4 justify-center">
              <Button
                variant="secondary"
                size="medium"
                fullWidth
                label={t('account.questions.inCharge.cta.answerLater')}
                onClick={() => setIsModalQuestionAccepted(false)}
              />
              <Button
                variant="tertiary"
                size="medium"
                fullWidth
                label={t('account.questions.inCharge.cta.answerNow')}
                onClick={() => handleAnswerNow()}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
