import clsx from 'clsx';

import { NavbarDefault, NavbarMinimal } from '../navbar';

interface LayoutDefaultProps {
  children: React.ReactNode;
  minimal?: boolean;
  illustration?: boolean;
  bgColor?: string;
}

export default function LayoutDefault({
  children,
  minimal = false,
  illustration = true,
  bgColor = 'bg-gray-400'
}: LayoutDefaultProps) {
  return (
    <div
      className={clsx(
        `min-h-screen w-full ${bgColor} pb-12`,
        illustration &&
          'object-cover object-center bg-no-repeat bg-brush bg-top sm:bg-initial'
      )}
    >
      {minimal ? <NavbarMinimal /> : <NavbarDefault />}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <main>{children}</main>
      </div>
    </div>
  );
}
