import { useTranslation } from 'react-i18next';

import { useCustomer, useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

import { Subscription } from 'types/subscription';

import SectionLine from '../dashboard/SectionLine';

const getPerMonthLabel = ({ maxNb }: { maxNb: number | null }) => {
  if (maxNb === 0) return 'general.none';
  if (maxNb === null) return 'general.unlimited';

  return 'funnel.subscription.offer.perMonth';
};

export interface SubscriptionSectionProps {
  subscription: Subscription;
  callToUpdate?: boolean;
}

export default function SubscriptionDetails({
  subscription: { product, restrictions, legalProtection, ...subscription },
  callToUpdate = false
}: SubscriptionSectionProps) {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');
  const { isB2b: isB2bCustomer } = useCustomer();

  return (
    <div className="flex w-full flex-col space-y-2">
      <SectionLine title={t('general.subscription')} value={product.name} />
      {!isB2bCustomer && (
        <SectionLine
          title={t('general.status')}
          value={
            subscription.active ? t('general.active') : t('general.inactive')
          }
        />
      )}
      {!subscription.isOfferedByPartner && !isB2bCustomer && (
        <>
          <SectionLine
            title={t('account.subscription.subscribedOnThe')}
            value={formatDateToIntl(
              new Date(subscription.startDate),
              'long',
              locale
            )}
          />
          <SectionLine
            title={
              subscription.active
                ? t('account.subscription.renewOnThe')
                : t('account.subscription.endThe')
            }
            value={formatDateToIntl(
              new Date(subscription.endDate),
              'long',
              locale
            )}
          />
        </>
      )}
      <SectionLine
        title={t('account.subscription.restrictionQuestion')}
        value={t(getPerMonthLabel(restrictions.questions), {
          total: restrictions.questions.maxNb
        })}
      />

      <SectionLine
        title={t('account.subscription.restrictionConsultations')}
        value={t(getPerMonthLabel(restrictions.consultations), {
          total: restrictions.consultations.maxNb
        })}
      />
      {legalProtection && (
        <SectionLine
          title={t('account.subscription.legalProtection')}
          value={t('general.yes')}
        />
      )}
    </div>
  );
}
