import { useMutation, useQuery } from 'react-query';

import { Consultation, ConsultationCancelIntent } from 'types/consultation';
import { Lawyer } from 'types/lawyer';

import {
  cancelAppointmentConsultation,
  cancelConsultation,
  completeConsultation,
  getPastConsultations,
  getScheduledConsultations
} from './api';

export function useScheduledConsultationsQuery({
  lawyerId,
  page
}: {
  lawyerId: Lawyer['id'];
  page: number;
}) {
  return useQuery(
    ['scheduledConsultations', { page }],
    (values: any) =>
      getScheduledConsultations(lawyerId, values.queryKey[1].page, 'after'),
    {
      enabled: !!lawyerId,
      keepPreviousData: true
    }
  );
}

export function useToCompleteConsultationsQuery({
  lawyerId,
  page
}: {
  lawyerId: Lawyer['id'];
  page: number;
}) {
  return useQuery(
    ['toCompleteConsultations', { page }],
    (values: any) =>
      getScheduledConsultations(lawyerId, values.queryKey[1].page, 'before'),
    {
      enabled: !!lawyerId,
      keepPreviousData: true
    }
  );
}

// QUERIES
export function usePastConsultationsQuery({
  lawyerId,
  page
}: {
  lawyerId: Lawyer['id'];
  page: number;
}) {
  return useQuery(
    ['pastConsultations', { page }],
    (values: any) => getPastConsultations(lawyerId, values.queryKey[1].page),
    {
      enabled: !!lawyerId,
      keepPreviousData: true
    }
  );
}

// MUTATIONS
export function useCancelConsultationMutation() {
  return useMutation(
    ({
      consultationId,
      intent
    }: {
      consultationId: Consultation['id'];
      intent: ConsultationCancelIntent;
    }) => cancelConsultation(consultationId, intent)
  );
}

// MUTATIONS
export function useCancelAppointmentConsultationMutation() {
  return useMutation(({ appointmentId }: { appointmentId: string }) =>
    cancelAppointmentConsultation(appointmentId)
  );
}

export function useCompleteConsultationMutation() {
  return useMutation(
    ({ consultationId }: { consultationId: Consultation['id'] }) =>
      completeConsultation(consultationId)
  );
}
