import { useTranslation } from 'react-i18next';

import { useStripeConfigureUrl } from 'api/lawyer/queries.index';

import { useAuth } from 'context';

import { LinkedButton } from 'features/shared/buttons';

export default function StripePaymentsNotConfigured() {
  const { t } = useTranslation('lawyer');

  const { lawyerId } = useAuth();

  const redirectUrl = `${
    process.env.REACT_APP_BASE_URL as string
  }/account/payments`;
  const { data: stripeConfigureUrlData } = useStripeConfigureUrl({
    lawyerId: lawyerId as number,
    redirectUrl
  });

  return (
    <div>
      <div className="mb-4">
        <p>{t('payments.stripe.unactivated.p')}</p>
      </div>
      <div className="flex justify-end">
        <LinkedButton
          isExternal
          href={stripeConfigureUrlData?.url as string}
          variant="primary"
          label={t('payments.stripe.unactivated.button')}
          size="medium"
        />
      </div>
    </div>
  );
}
