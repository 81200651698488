import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { consultationCancelIntents } from 'utils/consultation';

import { ConsultationCancelIntent } from 'types/consultation';

export default function SelectConsultationCancelIntent({
  register
}: {
  register: UseFormRegister<{
    intent: ConsultationCancelIntent;
  }>;
}) {
  const { t } = useTranslation('lawyer');

  return (
    <select
      defaultValue=""
      {...register('intent')}
      className="mt-1 w-full pl-3 pr-10 placeholder:text-gray-700 text-sm md:text-base block focus:ring-0 focus:ring-offset-0 focus:outline-none py-3 px-4 rounded focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900"
    >
      <option value="" disabled>
        {t('account.consultations.cancel.intent.cta')}
      </option>
      {consultationCancelIntents.map((intent) => (
        <option key={intent.code} value={intent.code}>
          {intent.label}
        </option>
      ))}
    </select>
  );
}
