import { publicAxios } from 'context/auth/auth.utils';

import { NewUnauthenticatedNpsVote } from 'types/customer';

export async function createUnauthenticatedNpsVote(
  note: NewUnauthenticatedNpsVote['note'],
  accountId: NewUnauthenticatedNpsVote['accountId'],
  source: NewUnauthenticatedNpsVote['source']
): Promise<string> {
  const res = await publicAxios.post(`/nps/vote`, {
    note,
    accountId,
    source
  });
  return res.data;
}
