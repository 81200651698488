import { SVGProps } from 'react';

export default function LockIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.625 9.1875H6.375C6.06434 9.1875 5.8125 9.43934 5.8125 9.75V17.625C5.8125 17.9357 6.06434 18.1875 6.375 18.1875H17.625C17.9357 18.1875 18.1875 17.9357 18.1875 17.625V9.75C18.1875 9.43934 17.9357 9.1875 17.625 9.1875Z"
        stroke="black"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46875 9.1875V6.65625C9.46875 5.98492 9.73543 5.34109 10.2101 4.86639C10.6848 4.39168 11.3287 4.125 12 4.125C12.6713 4.125 13.3152 4.39168 13.7899 4.86639C14.2646 5.34109 14.5312 5.98492 14.5312 6.65625V9.1875"
        stroke="black"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.5312C12.466 14.5312 12.8438 14.1535 12.8438 13.6875C12.8438 13.2215 12.466 12.8438 12 12.8438C11.534 12.8438 11.1562 13.2215 11.1562 13.6875C11.1562 14.1535 11.534 14.5312 12 14.5312Z"
        fill="black"
      />
    </svg>
  );
}
