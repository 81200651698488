import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { DatoImage } from 'types/cms';

interface CardImageProps {
  className?: string;
  imageAttributes?: DatoImage['responsiveImage'];
}

const CardImage = ({
  className,
  imageAttributes
}: PropsWithChildren<CardImageProps>) => (
  <div
    className={classNames('relative rounded-lg bg-cover', className)}
    style={{
      backgroundImage: imageAttributes
        ? `url(${imageAttributes.base64})`
        : 'rgb(194,194,194)'
    }}
  >
    {imageAttributes && (
      <>
        <div
          className={classNames(
            'l-0 t-0',
            `pt-[${100 / imageAttributes.aspectRatio}%]`
          )}
        />
        <picture
          className={classNames(
            'absolute l-0 t-0 rounded-lg w-full object-cover',
            `-mt-[${100 / imageAttributes.aspectRatio}%]`
          )}
        >
          <source srcSet={imageAttributes.webpSrcSet} type="image/webp" />
          <source className="object-cover" srcSet={imageAttributes.srcSet} />
          <img
            src={imageAttributes.src}
            alt={imageAttributes.alt}
            loading="lazy"
            className={classNames('rounded-lg w-full object-cover', className)}
          />
        </picture>
      </>
    )}
  </div>
);

export default CardImage;
