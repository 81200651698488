import { privateAxiosJsonLd } from 'context/auth/auth.utils';

type ConsumerCategorie = {
  consumerTags: String[];
  id: String;
};

export async function getConsumerCategorieBySlug(
  slug: string
): Promise<ConsumerCategorie> {
  const res = await privateAxiosJsonLd.get(`/consumer-categories?slug=${slug}`);

  const [consumerCategorie] = res.data['hydra:member'];

  return consumerCategorie;
}
