import { SVGProps } from 'react';

export default function CarRentalIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7272 23.6192H77.5005C81.7258 23.6192 85.1818 27.0767 85.1818 31.3005V59.8166C85.1818 64.042 81.7242 67.4979 77.5005 67.4979H67.8875V77.7108L53.1004 67.4979H18.7272C14.5018 67.4979 11.0459 64.0403 11.0459 59.8166V31.3005C11.0459 27.0751 14.5034 23.6192 18.7272 23.6192Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M46.1835 39.531C46.3612 39.6305 46.5177 39.7674 46.6286 39.9386C46.9367 40.408 47.0817 40.871 47.0817 41.3715V42.1784C47.0866 45.7647 47.0915 49.4733 47.0785 53.1216C47.0752 54.2007 46.3791 55.0761 45.3049 55.3532C39.8292 56.7633 34.2639 56.7666 28.767 55.3663C28.3416 55.2571 27.9585 55.0435 27.6618 54.7468C27.2379 54.323 27.0032 53.7459 26.9999 53.1232C26.9869 49.2043 26.9853 45.2512 26.9951 41.3731C26.9983 40.2548 27.696 39.3827 28.8143 39.099C34.272 37.715 39.8015 37.7183 45.2478 39.1088C45.5298 39.1805 45.7907 39.3207 46.0417 39.456C46.0759 39.474 46.1265 39.5017 46.1803 39.531H46.1835ZM31.0052 50.5948C31.2644 50.854 31.6067 50.991 31.9963 50.991C32.8049 50.991 33.4195 50.3471 33.4244 49.4896C33.4309 48.2686 33.4292 47.0264 33.4292 45.825V44.7719C33.4292 44.3628 33.2776 44.0449 32.906 43.67C32.5522 43.3293 32.0241 43.2478 31.4959 43.4564C30.9074 43.6879 30.5944 44.1411 30.5879 44.7687C30.5749 46.1348 30.5716 47.6329 30.5781 49.4847C30.5781 49.9248 30.7314 50.3193 31.0069 50.5948H31.0052Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M36.0311 39.85C37.0629 39.8142 38.0948 39.8353 39.1251 39.9103C40.8579 40.0375 42.5891 40.3195 44.3106 40.7596M30.575 40.5559C32.0584 40.2233 33.5467 40.0049 35.035 39.9022"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.0881 50.5281H68.5686L73.4052 48.2541L68.906 44.6254L67.4454 45.7877L64.8584 44.3841L63.0587 45.7388L61.1465 44.6743L59.2914 45.7877L57.3222 44.3841L55.6921 45.4959L53.2729 43.9489L51.3624 45.2546L49.6181 43.9489H47.0881V50.5281Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M73.4052 48.2541H47.0881"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.8688 37.0776L23.6094 34.8215"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M24.0951 39.924L20.9016 39.9256"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.5321 35.4834L28.5305 32.2899"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1085 128.741C36.0196 128.741 42.4342 122.328 42.4342 114.415C42.4342 106.502 36.0212 100.089 28.1085 100.089C20.1957 100.089 13.7827 106.502 13.7827 114.415C13.7827 122.328 20.1957 128.741 28.1085 128.741V128.741Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1086 125.261C34.0978 125.261 38.9524 120.406 38.9524 114.417C38.9524 108.428 34.0978 103.573 28.1086 103.573C22.1195 103.573 17.2649 108.428 17.2649 114.417C17.2649 120.406 22.1195 125.261 28.1086 125.261Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1086 115.822C28.8845 115.822 29.5137 115.193 29.5137 114.417C29.5137 113.641 28.8845 113.012 28.1086 113.012C27.3326 113.012 26.7034 113.641 26.7034 114.417C26.7034 115.193 27.3326 115.822 28.1086 115.822Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.5765 117.093C28.4428 116.692 27.9065 116.759 27.7729 117.16L26.9024 119.972L25.8314 123.519C25.7645 123.72 25.8982 123.987 26.0987 123.987C26.7018 124.121 27.3702 124.188 27.9734 124.188C28.9775 124.188 29.9817 124.054 30.919 123.787C31.1195 123.72 31.2532 123.453 31.1864 123.25L29.7812 119.837L28.5765 117.092V117.093Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M30.3844 116.022C29.9834 115.889 29.6476 116.29 29.8481 116.626L31.2533 119.237L32.9943 122.517C33.1279 122.717 33.3285 122.784 33.5306 122.651C34.0669 122.316 34.5348 121.914 35.0026 121.446C35.7394 120.709 36.341 119.907 36.8104 119.103C36.9441 118.903 36.8104 118.636 36.6099 118.567L33.1964 117.162L30.3844 116.024V116.022Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M30.8537 113.947C30.4526 114.081 30.5195 114.617 30.9205 114.751L33.7325 115.621L37.2797 116.692C37.4802 116.759 37.7475 116.626 37.7475 116.425C37.8812 115.822 37.9481 115.154 37.9481 114.55C37.9481 113.546 37.8144 112.542 37.547 111.605C37.4802 111.404 37.2129 111.271 37.0107 111.337L33.5972 112.743L30.852 113.947H30.8537Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M29.7141 112.206C29.5805 112.607 29.9815 112.943 30.3173 112.743L32.9271 111.337L36.207 109.596C36.4075 109.463 36.4743 109.262 36.3407 109.06C36.0065 108.524 35.6038 108.056 35.136 107.588C34.3992 106.851 33.5971 106.25 32.7935 105.78C32.593 105.647 32.3256 105.78 32.2572 105.981L30.852 109.394L29.7141 112.206V112.206Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M27.6392 111.739C27.7729 112.14 28.3092 112.073 28.4429 111.672L29.3134 108.86L30.3844 105.313C30.4512 105.112 30.3175 104.845 30.117 104.845C29.5139 104.711 28.8455 104.644 28.2424 104.644C27.2382 104.644 26.234 104.778 25.2967 105.045C25.0962 105.112 24.9625 105.379 25.0293 105.581L26.4345 108.995L27.6392 111.74V111.739Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.8329 112.876C26.2339 113.01 26.5698 112.609 26.3693 112.273L25.0309 109.663L23.2899 106.317C23.1562 106.116 22.9557 106.049 22.7536 106.183C22.2173 106.517 21.7494 106.92 21.2816 107.388C20.5447 108.124 19.9432 108.926 19.4737 109.73C19.3401 109.931 19.4737 110.198 19.6742 110.266L23.0878 111.672L25.8329 112.876Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M25.4301 114.952C25.8311 114.818 25.7643 114.282 25.3633 114.148L22.5513 113.277L19.0041 112.206C18.8035 112.14 18.5362 112.273 18.5362 112.474C18.4025 113.077 18.3357 113.745 18.3357 114.348C18.3357 115.353 18.4694 116.357 18.7367 117.294C18.8035 117.495 19.0709 117.628 19.273 117.561L22.6866 116.156L25.4317 114.952H25.4301Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M26.5679 116.692C26.7016 116.291 26.3006 115.956 25.9648 116.156L23.3549 117.561L20.075 119.302C19.8745 119.436 19.8077 119.636 19.9414 119.839C20.2756 120.375 20.6782 120.843 21.146 121.311C21.8829 122.047 22.6849 122.649 23.4886 123.118C23.6891 123.252 23.9564 123.118 24.0249 122.918L25.4301 119.504L26.5679 116.692V116.692Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M122.628 128.741C130.539 128.741 136.953 122.328 136.953 114.415C136.953 106.502 130.54 100.089 122.628 100.089C114.715 100.089 108.302 106.502 108.302 114.415C108.302 122.328 114.715 128.741 122.628 128.741Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M122.628 125.261C128.617 125.261 133.471 120.406 133.471 114.417C133.471 108.428 128.617 103.573 122.628 103.573C116.639 103.573 111.784 108.428 111.784 114.417C111.784 120.406 116.639 125.261 122.628 125.261Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M122.628 115.822C123.404 115.822 124.033 115.193 124.033 114.417C124.033 113.641 123.404 113.012 122.628 113.012C121.852 113.012 121.223 113.641 121.223 114.417C121.223 115.193 121.852 115.822 122.628 115.822Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M123.096 117.093C122.962 116.692 122.426 116.759 122.292 117.16L121.421 119.972L120.35 123.519C120.284 123.72 120.417 123.987 120.618 123.987C121.221 124.121 121.889 124.188 122.492 124.188C123.497 124.188 124.501 124.054 125.438 123.787C125.639 123.72 125.772 123.453 125.705 123.25L124.3 119.837L123.096 117.092V117.093Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M124.904 116.022C124.503 115.889 124.167 116.29 124.367 116.626L125.773 119.237L127.514 122.517C127.647 122.717 127.848 122.784 128.05 122.651C128.586 122.316 129.054 121.914 129.522 121.446C130.259 120.709 130.86 119.907 131.33 119.103C131.463 118.903 131.33 118.636 131.129 118.567L127.716 117.162L124.904 116.024V116.022Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M125.371 113.947C124.97 114.081 125.037 114.617 125.438 114.751L128.25 115.621L131.797 116.692C131.998 116.759 132.265 116.626 132.265 116.425C132.399 115.822 132.466 115.154 132.466 114.55C132.466 113.546 132.332 112.542 132.065 111.605C131.998 111.404 131.73 111.271 131.528 111.337L128.115 112.743L125.37 113.947H125.371Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M124.233 112.206C124.1 112.607 124.501 112.943 124.837 112.743L127.446 111.337L130.726 109.596C130.927 109.463 130.994 109.262 130.86 109.06C130.526 108.524 130.123 108.056 129.655 107.588C128.918 106.851 128.116 106.25 127.313 105.78C127.112 105.647 126.845 105.78 126.776 105.981L125.371 109.394L124.233 112.206V112.206Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M122.158 111.739C122.292 112.14 122.828 112.073 122.962 111.672L123.832 108.86L124.903 105.313C124.97 105.112 124.837 104.845 124.636 104.845C124.033 104.711 123.365 104.644 122.761 104.644C121.757 104.644 120.753 104.778 119.816 105.045C119.615 105.112 119.482 105.379 119.548 105.581L120.954 108.995L122.158 111.74V111.739Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M120.352 112.876C120.753 113.01 121.089 112.609 120.888 112.273L119.483 109.663L117.742 106.383C117.609 106.183 117.408 106.116 117.206 106.25C116.67 106.584 116.202 106.987 115.734 107.454C114.997 108.191 114.396 108.993 113.926 109.797C113.792 109.997 113.926 110.265 114.127 110.333L117.54 111.738L120.352 112.876V112.876Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M119.95 114.952C120.351 114.818 120.284 114.282 119.883 114.148L117.071 113.277L113.524 112.206C113.323 112.14 113.056 112.273 113.056 112.474C112.922 113.077 112.855 113.745 112.855 114.348C112.855 115.353 112.989 116.357 113.256 117.294C113.323 117.495 113.59 117.628 113.793 117.561L117.206 116.156L119.951 114.952H119.95Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M121.087 116.692C121.221 116.291 120.82 115.956 120.484 116.156L117.874 117.561L114.595 119.302C114.394 119.436 114.327 119.636 114.461 119.839C114.795 120.375 115.198 120.843 115.666 121.311C116.402 122.047 117.204 122.649 118.008 123.118C118.209 123.252 118.476 123.118 118.544 122.918L119.95 119.504L121.087 116.692V116.692Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3009 67.4915C17.8354 70.473 16.1172 74.2599 15.6575 75.5917C14.9875 77.4664 14.787 79.0052 14.7202 79.072C14.6534 79.1389 12.9792 79.9425 13.1129 80.813C13.3134 81.1472 14.2507 81.3494 14.3175 81.3494L14.0502 89.9843C14.0502 90.3184 13.9165 90.5874 13.716 90.7879L12.244 92.3268C11.9767 92.5941 11.843 92.8631 11.7077 93.1973C11.1714 94.5356 9.76618 98.5523 9.36516 103.439C8.82884 109.465 9.09782 113.882 9.63251 114.82C9.89985 115.356 10.2357 115.69 10.503 115.957C10.8372 116.158 11.3067 115.891 11.2398 115.49V114.485C11.2398 103.976 20.8121 95.6751 31.7911 97.9508C37.4803 99.1555 43.3048 104.778 44.5763 110.469C45.1795 113.214 45.1795 115.824 44.6432 118.233C44.5095 118.836 44.9105 119.438 45.5805 119.505L74.4977 120.041H105.022C105.893 120.041 106.494 119.237 106.294 118.367C105.691 115.891 105.691 113.212 106.294 110.467C107.699 104.242 112.586 99.3543 118.812 98.016C129.856 95.5398 139.63 103.841 139.63 114.484C139.63 115.822 139.497 117.094 139.162 118.367C139.096 118.634 139.296 118.903 139.563 118.835C139.563 118.835 141.371 118.835 141.572 118.098C141.772 117.361 142.776 104.576 140.768 102.166C138.827 99.757 138.426 99.6233 138.626 95.8071C138.827 92.9951 136.15 89.849 134.81 88.3769C134.342 87.9091 133.873 87.4396 133.338 87.1054C132.066 86.1681 129.254 84.2266 127.782 83.625C125.841 82.8214 125.239 81.2825 122.494 80.1447C120.553 79.341 117.943 78.7395 117.876 78.7395L99.4683 65.1506C99.4683 65.1506 88.0882 56.9835 85.2094 55.7789"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.6432 91.1204C30.9727 91.1204 32.8604 89.2327 32.8604 86.9032C32.8604 84.5737 30.9727 82.686 28.6432 82.686C26.3137 82.686 24.426 84.5737 24.426 86.9032C24.426 89.2327 26.3137 91.1204 28.6432 91.1204V91.1204Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M21.8424 67.4995C20.3214 70.3376 19.0157 73.0029 18.6033 74.3869"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M16.2607 74.1848L24.0251 74.7211C24.493 74.7879 24.9625 74.4538 25.163 74.0511C25.7824 72.7698 27.0914 70.0621 28.4575 67.4979"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M24.0251 74.7878L25.163 74.9883C26.3677 75.1889 27.4387 75.792 28.1755 76.7293L54.7502 108.928C56.0217 110.467 57.8964 111.471 59.9047 111.673L95.4501 115.423C96.9889 115.556 98.5294 115.155 99.8009 114.218L100.939 113.414"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.2362 67.4914C40.8596 68.9585 40.6134 70.3719 40.5596 71.5749C40.3591 76.0594 40.5596 78.8046 43.5053 84.2265C46.451 89.6484 56.8937 104.308 59.9062 106.317C62.9188 108.258 65.7308 108.793 69.3448 109.329C72.9588 109.865 105.694 114.014 105.694 114.014"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M117.874 78.671L109.507 77.6668C109.507 77.6668 93.9632 63.7568 85.1816 59.6211"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M44.4412 109.931C44.4412 109.931 52.675 110.601 53.6792 110.667C54.6834 110.734 55.6875 110.667 55.9549 110.131"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M47.8562 117.093C48.1235 117.093 101.676 118.901 101.676 118.901"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M109.507 77.6667L112.252 80.1429C112.252 80.1429 122.561 81.1471 125.707 82.1512"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M127.782 83.5565C127.782 83.5565 125.707 82.5523 125.707 83.356C125.707 84.1596 128.452 87.4395 129.59 88.5105C130.795 89.5815 135.749 92.9282 138.158 93.264"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M140.768 102.166C140.768 102.166 134.676 93.5982 118.945 93.8655"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M139.563 113.947C139.563 113.947 140.835 114.348 141.906 113.947"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.2502 85.8272C17.3284 84.0536 16.9421 82.5979 16.3878 82.5734C15.8336 82.549 15.3217 83.9656 15.2435 85.7392C15.1652 87.5112 15.5516 88.9685 16.1058 88.993C16.6601 89.0174 17.1719 87.6008 17.2502 85.8272Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M16.3943 82.5522C16.3943 82.5522 22.2857 83.6901 22.2188 85.8989C22.152 88.1078 16.127 88.9783 16.127 88.9783"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7588 78.622C18.0832 77.0277 17.9349 75.6519 17.4279 75.5492C16.9209 75.4465 16.246 76.6544 15.9216 78.2487C15.5972 79.843 15.7456 81.2188 16.2526 81.3215C16.7595 81.4242 17.4344 80.2163 17.7588 78.622Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.4654 75.5248C17.4654 75.5248 22.6868 77.4663 22.2858 79.4078C21.8848 81.3493 16.2607 81.3493 16.2607 81.3493"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M40.8269 69.6334C40.8269 69.6334 52.207 70.3034 52.207 74.2516C52.207 78.1998 41.1627 78.7361 41.1627 78.7361"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.7642 74.721C41.6974 75.8588 42.7684 76.7293 44.0399 76.5956L49.5954 75.9925C51.4033 75.792 51.7374 73.5163 49.9964 72.9131L46.7166 71.8421C42.3657 70.3701 41.8294 72.5121 41.7626 74.721H41.7642Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.8979 73.2475C41.8979 73.2475 47.4535 72.9801 51.0692 74.2517"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M41.7644 74.7211C41.7644 74.7211 44.6432 74.5206 46.5848 74.7211C48.5263 74.9216 50.6683 75.4579 50.6683 75.4579"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M64.2163 75.1759L52.207 73.9174"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M112.252 80.2097L67.8875 75.5605"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M104.152 73.1138C104.152 73.1138 107.231 85.7653 106.093 94.2666C104.955 102.701 100.872 113.411 100.872 113.411"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M105.625 97.1454C105.625 97.1454 58.4995 86.2348 48.9941 85.7653"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M106.696 96.5439L106.496 97.5481C106.429 97.9491 106.763 98.2849 107.166 98.1512L109.642 97.2807C109.976 97.1471 109.976 96.6776 109.642 96.6107L107.701 96.0076C107.233 95.8071 106.763 96.0744 106.696 96.5439V96.5439Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M98.5294 70.4371C98.7968 70.3702 99.1994 70.3034 99.6673 70.3702C100.204 70.437 100.872 70.7044 101.408 71.2407C102.412 72.2449 103.55 73.9859 103.149 75.5247C102.748 77.1321 101.408 77.7336 100.337 77.8004C99.2663 77.8673 97.3248 77.4662 96.7901 77.1304C96.2537 76.7963 96.0532 76.4604 96.1201 75.7921C96.1869 75.1221 96.4543 71.0386 98.5294 70.4371V70.4371Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M97.7925 67.9591L97.9262 70.7711"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M67.8875 74.6787L98.4609 77.6651"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M51.8713 73.1138L62.7721 74.1783"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M54.168 68.2363L54.4141 73.3826"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M56.209 69.6449L56.4242 73.5833"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M72.7566 70.9719L75.0323 68.2935"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M79.385 74.5204L86.3474 66.1529"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M82.8652 72.8463L89.8276 64.4119"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M83.8025 74.6543L86.0782 71.9091"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M96.3203 74.118C96.3203 74.118 101.005 74.1848 103.147 75.59"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
