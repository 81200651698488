import { Divider, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { MemberQuestionAdditionalRequest } from 'services/question';

import { useLocale } from 'hooks';

import SanitizedContent from 'features/customer/question/SanitizedContent';

import { formatDateToIntl } from 'utils/date';

interface AdditionalRequestCardProps {
  additionalRequest: Pick<
    MemberQuestionAdditionalRequest,
    'created_at' | 'request'
  >;
}

export default function AdditionalRequestCard({
  additionalRequest
}: AdditionalRequestCardProps) {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();
  const dateAsked = formatDateToIntl(
    new Date(additionalRequest.created_at),
    'long',
    locale
  );

  return (
    <Card>
      <CardContent>
        <Title variant="h2" className="truncate">
          {t('account.additionalRequest.title')}
        </Title>
        <Divider />
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <p className="text-sm font-medium text-gray-700">
              {t('account.additionalRequest.askedThe')}
            </p>
            <p className="mt-1 text-sm">{dateAsked}</p>
          </div>
          <div className="sm:col-span-2">
            <SanitizedContent content={additionalRequest.request} />
          </div>
        </dl>
      </CardContent>
    </Card>
  );
}
