import { useTranslation } from 'react-i18next';

import { LayoutCard, LayoutDefault } from 'features/shared/layout';

const UnavailablePage = () => {
  const { t } = useTranslation('customer');

  return (
    <LayoutDefault minimal>
      <LayoutCard
        title={t('error.unavailable.title')}
        body={
          <div className="flex flex-col gap-4">
            <p className="text-md text-center font-medium ">
              {t('error.unavailable.description')}
            </p>
          </div>
        }
      />
    </LayoutDefault>
  );
};

export default UnavailablePage;
