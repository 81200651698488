import { useParams, useSearchParams } from 'react-router-dom';

import { useAppointmentLinkQuery } from 'api/customer/queries.index';

import {
  ConsultationAppointmentVoteSatisfiedCard,
  ConsultationAppointmentVoteUnsatisfiedCard
} from 'features/customer/consultation';
import { LayoutDefault, LayoutLoadingSkeleton } from 'features/shared/layout';

export default function ConsultationAppointmentVotePage() {
  const params = useParams();
  const voteLinkId = params.voteLinkId || '';
  const [searchParams] = useSearchParams();
  const noteFromParams = searchParams.get('note') || '';
  const note = parseInt(noteFromParams, 10);
  const isSatisfied = note > 3;
  const answerVoteLink = useAppointmentLinkQuery({
    voteLinkId
  });

  if (!answerVoteLink.isSuccess) {
    return <LayoutLoadingSkeleton />;
  }

  return (
    <LayoutDefault>
      {isSatisfied ? (
        <ConsultationAppointmentVoteSatisfiedCard
          voteLinkId={voteLinkId}
          note={note}
          appointmentId={answerVoteLink.data.appointmentId}
        />
      ) : (
        <ConsultationAppointmentVoteUnsatisfiedCard
          voteLinkId={voteLinkId}
          note={note}
          appointmentId={answerVoteLink.data.appointmentId}
        />
      )}
    </LayoutDefault>
  );
}
