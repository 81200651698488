import { Section } from 'components';
import YoutubeEmbed from 'components/YoutubeEmbed';
import { Fade } from 'components/transition';
import { useParams } from 'react-router-dom';

import { PageLayout } from 'features/customer/account/content';

const VideoPage = () => {
  const { id = '' } = useParams();

  return (
    <PageLayout previousHref="/account/resources/videos">
      <Fade>
        <Section color="white">
          <YoutubeEmbed videoId={id} />
        </Section>
      </Fade>
    </PageLayout>
  );
};

export default VideoPage;
