import * as Sentry from '@sentry/react';
import axios, { AxiosInstance } from 'axios';
import { onError } from 'services/axios';

import { AuthLocalStorage } from 'types/auth';

const publicAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_TEST_MODE
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const privateAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

privateAxios.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': config.url?.includes('token')
        ? 'application/x-www-form-urlencoded'
        : 'application/json'
    }
  }),
  (error) => {
    Promise.reject(error);
  }
);

privateAxios.interceptors.response.use(
  (response) => response,
  (error) => onError(error, privateAxios)
);

const privateAxiosJsonLd: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

privateAxiosJsonLd.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      Accept: 'application/ld+json'
    }
  }),
  (error) => {
    Promise.reject(error);
  }
);

privateAxiosJsonLd.interceptors.response.use(
  (response) => response,
  (error) => onError(error, privateAxiosJsonLd)
);

const handleClearAuthAndLoginRedirect = () => {
  clearAuthStorage();
  return window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
};

const clearAuthStorage = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('email');
};

const setNewAuthStorage = ({
  accessToken,
  refreshToken,
  email
}: AuthLocalStorage) => {
  window.localStorage.setItem('accessToken', accessToken);
  window.localStorage.setItem('refreshToken', refreshToken);
  window.localStorage.setItem('email', email);
};

const logoutUser = () => {
  Sentry.getCurrentScope().setUser(null);
  handleClearAuthAndLoginRedirect();
  return true;
};

export {
  privateAxios,
  privateAxiosJsonLd,
  publicAxios,
  logoutUser,
  handleClearAuthAndLoginRedirect,
  clearAuthStorage,
  setNewAuthStorage
};
