import { DocumentTextIcon, DownloadIcon } from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { useGetDocuments } from 'services/document/document';
import { getAuthenticatedMemberInfo } from 'services/member';

function formatFileSize(bytes: number, decimalPoint?: number) {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const DocumentsList = () => {
  const { data, isLoading: isDocumentsLoading } = useGetDocuments();
  const { data: member, isLoading: isMemberInfoLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (!data || isDocumentsLoading || isMemberInfoLoading) return <div />;

  return (
    <div className="w-full rounded-2xl bg-white p-2 divide-y divide-gray-400 text-lg md:my-8">
      <ul className="divide-y divide-gray-200">
        {data.documents
          .filter(
            (document) =>
              !document.cseOnly ||
              document.cseOnly === member?.is_active_cse_member
          )
          .map((document) => (
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 max-w-3xl">
                <div className="flex-shrink-0 flex items-center justify-center bg-gray-200 rounded-lg w-12 h-12">
                  <DocumentTextIcon color="#6E6E6E" className="w-7 h-7" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="font-medium text-gray-900 truncate">
                    {document.name}
                  </p>
                  <p className="text-sm text-gray-600 truncate">
                    {formatFileSize(document.file.size)}
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-700">
                  <a
                    href={document.file.url}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon className="w-7 h-7" />
                  </a>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DocumentsList;
