import { Title } from 'components';
import { LawyerProfile } from 'containers/profile';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'context';

import { AccountContentLayout } from 'features/lawyer/account/content';

export default function ProfilePage() {
  const { t } = useTranslation('lawyer');
  const { lawyerId } = useAuth();

  return (
    <div className="min-h-screen">
      <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
        <AccountContentLayout title={t('account.account.title')}>
          <div className="pb-6">
            <div className="text-left pb-4">
              <Title variant="h3">{t('account.profile.title')}</Title>
              <div className="flex w-full flex-col space-y-2">
                <LawyerProfile lawyerId={lawyerId} />
              </div>
            </div>
          </div>
        </AccountContentLayout>
      </div>
    </div>
  );
}
