import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAdministratorsEmailsByPartnerId } from 'services/admin/administrators';

import { useCustomerAccount } from 'hooks';

import { Toast } from 'features/shared/toasts';

const AdministratorList = () => {
  const { t } = useTranslation('customer');
  const { subscription } = useCustomerAccount();

  const { data } = useQuery(
    ['administratorsEmailsByPartnerId'],
    () =>
      getAdministratorsEmailsByPartnerId(
        subscription.product.offeredByPartner.id
      ),
    {
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('admin.collaborators.form.download.error')}
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <table className="table-auto w-full">
        <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-300">
          <tr>
            <th className="p-2 whitespace-nowrap">
              <div className="font-semibold text-left">
                {t('admin.administrators.list.header.email')}
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-300">
          {data?.map((email: string) => (
            <tr>
              <td className="p-2 whitespace-nowrap">
                <div className="text-left">{email}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdministratorList;
