import { Disclosure } from '@headlessui/react';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { DesktopNav, MobileNav } from 'components';
import { Link, useLocation } from 'react-router-dom';

import GearIcon from 'features/shared/icons/GearIcon';
import GroupAddIcon from 'features/shared/icons/GroupAddIcon';

import { IMenuItem, PageName } from 'types/account';

import { CustomerProfile } from '../sidebar';

export default function AdminNav() {
  const { pathname } = useLocation();

  const accountTabs: IMenuItem[] = [
    {
      name: PageName.Collaborators,
      href: '/admin/collaborators',
      icon: GroupAddIcon,
      current: pathname.includes(PageName.Collaborators),
      newUntil: new Date('01-01-2024')
    },
    {
      name: PageName.Settings,
      href: '/admin/settings',
      current: pathname.includes(PageName.Settings),
      icon: GearIcon,
      newUntil: new Date('01-05-2023')
    }
  ];

  return (
    <>
      <div className="bg-white hidden md:flex flex-col justify-items-center items-center">
        <div className="hidden md:flex md:fixed flex-col justify-between h-full pt-10 pb-4 z-50">
          <div className="w-full md:w-auto md:max-w-lg">
            <div className="md:pb-12">
              <Link to="/account/home">
                <EkieLogo />
              </Link>
            </div>
            <div className="flex flex-col justify-between">
              <DesktopNav accountTabs={accountTabs} />
            </div>
          </div>
          <CustomerProfile />
        </div>
      </div>
      <div className="block md:hidden">
        <Disclosure as="nav">
          {({ open }) => (
            <MobileNav
              open={open}
              translation="customer"
              accountTabs={[...accountTabs]}
            />
          )}
        </Disclosure>
      </div>
    </>
  );
}
