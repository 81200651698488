import { ExclamationCircleIcon } from '@heroicons/react/solid';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  toolbarPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { forwardRef } from 'react';
import { Controller, FieldError } from 'react-hook-form';

export const Toolbar = () => (
  <>
    <BoldItalicUnderlineToggles />
    <UndoRedo />
    <BlockTypeSelect />
    <ListsToggle />
  </>
);

const AnswerTextArea = forwardRef(
  (
    {
      control,
      error,
      name = 'answerContent'
    }: {
      control: any;
      error: any;
      name?: string;
    },
    ref: any
  ) => (
    <div>
      <div className="border rounded border-gray-400">
        {error && (
          <div className="relative">
            <AnswerErrorMessage error={error} />
          </div>
        )}
        <Controller
          render={({ field }) => (
            <MDXEditor
              {...field}
              ref={ref}
              contentEditableClassName="prose"
              markdown={field.value}
              plugins={[
                headingsPlugin(),
                listsPlugin(),
                markdownShortcutPlugin(),
                toolbarPlugin({
                  toolbarContents: Toolbar
                })
              ]}
            />
          )}
          control={control}
          name={name}
        />
      </div>
    </div>
  )
);

function AnswerErrorMessage({ error }: { error: FieldError }) {
  return (
    <div className="flex items-center justify-end bg-transparent mb-0.5">
      <div className="pointer-events-none flex items-center">
        <ExclamationCircleIcon
          className="h-3 w-3 text-red-800"
          aria-hidden="true"
        />
      </div>
      <p className="text-xs text-red-800" id={`${error.ref}-error`}>
        {error.message}
      </p>
    </div>
  );
}

export default AnswerTextArea;
