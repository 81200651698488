import { useLawyerQuery } from 'api/lawyer/lawyers';

import { Lawyer } from 'types/lawyer';

export default function useLawyer({
  lawyerId
}: {
  lawyerId: Lawyer['id'];
}): Lawyer {
  const lawyer = useLawyerQuery({ lawyerId });
  if (!lawyer.isSuccess) {
    throw Error('Lawyer is not loaded.');
  }

  return lawyer.data;
}
