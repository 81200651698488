import { useCustomerQuery } from 'api/customer/customers';

import { Customer } from 'types/customer';

export default function useCustomer(): Customer {
  const customer = useCustomerQuery();
  if (!customer.isSuccess) {
    throw Error('Customer has not been fetched.');
  }

  return customer.data;
}
