import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface ParagraphProps {
  text?: string;
  size?: 'base' | 'sm';
  weight?: 'normal' | 'medium' | 'bold' | 'light';
  className?: string;
  gutterBottom?: boolean;
}

export default function Paragraph({
  text,
  children,
  size = 'base',
  weight = 'medium',
  className = '',
  gutterBottom = false
}: PropsWithChildren<ParagraphProps>) {
  const fontSize = {
    sm: 'font-sm',
    base: 'font-base'
  };

  const fontWeight = {
    light: 'font-light',
    normal: 'font-normal',
    medium: 'font-medium',
    bold: 'font-bold'
  };

  return (
    <p
      className={classNames(
        'text-black',
        fontWeight[weight],
        fontSize[size],
        className,
        gutterBottom && 'mb-6'
      )}
    >
      {text || children}
    </p>
  );
}
