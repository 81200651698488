import { Card, Tag, Title } from 'components';
import AlphabetCard from 'components/alphabet/AlphabetCard';
import { CardContent, CardImage } from 'components/card';
import AlphabetSheetSkeleton from 'components/skeleton/AlphabetSheetSkeleton';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetAlphabetSheetBySlug } from 'services/alphabet/alphabet';

import { PageLayout } from 'features/customer/account/content';

function AlphabetSheetPage() {
  const { slug } = useParams();
  const { t } = useTranslation('customer');
  const { data, isLoading, isSuccess } = useGetAlphabetSheetBySlug({
    slug: slug as string
  });

  if (!data?.alphabetSheet) return null;

  return (
    <PageLayout previousHref="/account/resources/alphabet">
      <Fade className="flex flex-col xl:flex-row gap-2 bg-gray-200">
        {isLoading && <AlphabetSheetSkeleton />}
        {isSuccess && (
          <>
            <Card
              compact
              className="flex rounded-2xl xl:min-h-screen flex-row lg:flex-col justify-between w-full xl:w-72"
            >
              <div className="flex xl:flex-col">
                <CardImage
                  className="w-28 md:w-52 xl:w-full"
                  imageAttributes={data.alphabetSheet.image.responsiveImage}
                />
                <div className="ml-6 xl:ml-0 mt-0 xl:mt-6 self-end xl:self-start">
                  <Tag>{data.alphabetSheet.category.name}</Tag>
                </div>
              </div>
              <div className="hidden xl:block">
                <div className="text-gray-700 uppercase text-xs mb-2">
                  {t('account.alphabet.next')}
                </div>
                <AlphabetCard
                  direction="row"
                  alphabetSheet={data.alphabetSheet.nextAlphabetSheet}
                  className="border-0 p-0"
                />
              </div>
            </Card>
            <Card className="rounded-2xl flex-1 !p-6 min-h-screen">
              <Title variant="h1">{data.alphabetSheet.title}</Title>
              <CardContent className="prose whitespace-pre-line mt-8">
                {data.alphabetSheet.text}
              </CardContent>
            </Card>
            <div className="flex justify-end mt-4">
              <div className="block xl:hidden max-w-xs">
                <div className="text-gray-700 uppercase text-xs mb-2">
                  {t('account.alphabet.next')}
                </div>
                <AlphabetCard
                  direction="row"
                  alphabetSheet={data.alphabetSheet.nextAlphabetSheet}
                  className="border-0 p-0 bg-transparent"
                />
              </div>
            </div>
          </>
        )}
      </Fade>
    </PageLayout>
  );
}

export default AlphabetSheetPage;
