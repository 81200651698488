import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MEMBER_DEFAULT_REDIRECT_URL } from 'routes/constants';

import { SituationCompletionForm } from 'features/customer/onboarding';
import { SKIP_ONBOARDING_COMPLETION } from 'features/customer/profile/constants';
import { LayoutOnboarding } from 'features/shared/layout';

export default function SituationCompletionPage() {
  const { t } = useTranslation('customer');

  const handleSkipOnboarding = () => {
    localStorage.setItem(SKIP_ONBOARDING_COMPLETION, 'true');
  };

  return (
    <LayoutOnboarding
      leftChild={
        <div className="h-full py-6">
          <div className="h-full container mx-auto max-w-md">
            <div className="h-full flex items-center lg:justify-center flex-col gap-6">
              <div className="w-full grid place-items-center my-4">
                <div className="w-full grid grid-cols-4 gap-4">
                  <div className="border-2 border-b rounded-lg border-purple-900" />
                  <div className="border-2 border-b rounded-lg border-purple-900" />
                  <div className="border-2 border-b rounded-lg border-gray-800" />
                  <div className="border-2 border-b rounded-lg border-gray-600" />
                </div>
              </div>
              <div className="row-span-2 lg:row-span-3">
                <div className="h-full flex flex-col items-center gap-6">
                  <div className="w-full text-center">
                    <h3 className="text-4xl font-serif italic mb-2">
                      Votre situation
                    </h3>
                    <p className="text-gray-800">
                      Si vous le souhaitez, répondez à ces questions afin de
                      recevoir du contenu adapté à votre profil.
                    </p>
                  </div>
                  <div className="w-full">
                    <SituationCompletionForm />

                    <div className="text-center mt-4">
                      <Link
                        to={`${MEMBER_DEFAULT_REDIRECT_URL}?modalOnboarding=true`}
                        onClick={handleSkipOnboarding}
                        className="cursor-pointer text-gray-700 hover:underline"
                      >
                        {t('general.finishLater')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      rightChild={
        <div className="h-full flex justify-end">
          <img
            src="/images/onboarding-situation.webp"
            className="max-h-screen py-6"
            alt="People in a metro"
          />
        </div>
      }
    />
  );
}
