import { Card } from 'components';

const AlphabetSkeleton = () => (
  <div className="flex flex-wrap gap-2">
    {Array(26)
      .fill(1)
      .map(() => (
        <Card compact className="w-1/2 sm:w-1/3 lg:max-w-[228px] ">
          <div className="animate-pulse h-52 w-full bg-gray-300 rounded" />
          <div className="animate-pulse h-3 w-1/3 bg-gray-300 rounded" />
          <div className="animate-pulse h-14 w-full bg-gray-300 rounded" />
        </Card>
      ))}
  </div>
);

export default AlphabetSkeleton;
