import { sortBy } from 'lodash';

import { ApiConsultation, ConsultationStatus } from 'types/consultation';

export function formatConsultationFromApi(apiRes: ApiConsultation[]) {
  return apiRes
    .filter((apiConsultation) => {
      if (apiConsultation.status === ConsultationStatus.PENDING) {
        return false;
      }
      if (
        apiConsultation.status === ConsultationStatus.CANCELLED &&
        !apiConsultation.appointments.length
      ) {
        return false;
      }
      return true;
    })
    .map((apiConsultation) => {
      const isConsultationCompleted =
        apiConsultation.status === ConsultationStatus.COMPLETED;
      const latestAppointment = apiConsultation.appointments.length
        ? sortBy(apiConsultation.appointments, 'appointmentDate').reverse()[0]
        : null;
      const isConsultationVoted = isConsultationCompleted
        ? !!apiConsultation.appointments[0].vote
        : false;

      return {
        latestAppointment,
        isConsultationCompleted,
        isConsultationVoted,
        ...apiConsultation
      };
    });
}
